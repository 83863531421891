function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;
function pug_style(r){if(!r)return"";if("object"==typeof r){var t="";for(var e in r)pug_has_own_property.call(r,e)&&(t=t+e+":"+r[e]+";");return t}return r+""}function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (align, error, errorMsg, inline, loadMsg, size, success, successMsg) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"fade-in\""+pug_attr("style", pug_style(`font-size:${size || '2rem'};text-align:${align || 'center'};display:${inline ? 'inline' : 'block'}`), true, true)) + "\u003E";
if (error) {
if (errorMsg) {
pug_html = pug_html + "\u003Cspan" + (pug_attr("style", pug_style(`font-size:${size || '1.2rem'}`), true, true)) + "\u003E" + (pug_escape(null == (pug_interp = errorMsg) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Ci class=\"fal fa-times\"\u003E\u003C\u002Fi\u003E";
}
}
else
if (success) {
if (successMsg) {
pug_html = pug_html + "\u003Cspan" + (pug_attr("style", pug_style(`font-size:${size || '1.2rem'}`), true, true)) + "\u003E" + (pug_escape(null == (pug_interp = successMsg) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
}
else {
pug_html = pug_html + "\u003Ci class=\"fal fa-spinner-third fa-spin\"\u003E\u003C\u002Fi\u003E";
if (loadMsg) {
pug_html = pug_html + "\u003Cspan class=\"ms-2\"\u003E" + (pug_escape(null == (pug_interp = loadMsg) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "align" in locals_for_with ?
        locals_for_with.align :
        typeof align !== 'undefined' ? align : undefined, "error" in locals_for_with ?
        locals_for_with.error :
        typeof error !== 'undefined' ? error : undefined, "errorMsg" in locals_for_with ?
        locals_for_with.errorMsg :
        typeof errorMsg !== 'undefined' ? errorMsg : undefined, "inline" in locals_for_with ?
        locals_for_with.inline :
        typeof inline !== 'undefined' ? inline : undefined, "loadMsg" in locals_for_with ?
        locals_for_with.loadMsg :
        typeof loadMsg !== 'undefined' ? loadMsg : undefined, "size" in locals_for_with ?
        locals_for_with.size :
        typeof size !== 'undefined' ? size : undefined, "success" in locals_for_with ?
        locals_for_with.success :
        typeof success !== 'undefined' ? success : undefined, "successMsg" in locals_for_with ?
        locals_for_with.successMsg :
        typeof successMsg !== 'undefined' ? successMsg : undefined));
    ;;return pug_html;};module.exports=template;