export enum AuthRoles {
    CLAdmin = 'CLAdmin',
    KnowledgeRead = 'KnowledgeRead',
    KnowledgeWrite = 'KnowledgeWrite',
    UserRead = 'UserRead',
    UserWrite = 'UserWrite',
    AnalysisRead = 'AnalysisRead',                  // Allow users to read articles and perform article reading actions like submit feedback and getting publisher lists.
    AnalysisWrite = 'AnalysisWrite',                // Writing article data and metadata, also modify article related data like publisher lists
    MarketsRead = 'MarketsRead',
    DriversRead = 'DriversRead',
    DemoFeatures = 'DemoFeatures',
    Open = 'Open',
    BookmarksRead = 'BookmarksRead',
    BookmarksWrite = 'BookmarksWrite',
    ServicesRead = 'ServicesRead',
    ServicesWrite = 'ServicesWrite',
    TasksRead = 'TasksRead',
    TasksWrite = 'TasksWrite',
    NotesAndSharingRead = 'NotesAndSharingRead',
    NotesAndSharingWrite = 'NotesAndSharingWrite',
    KpiNavigator = 'KpiNavigator',
    NoveltyReport = 'NoveltyReport',
    MipViewer = 'MipViewer',
    DaasSso = 'DaasSso',                           // When enabled, the UI will present a button that will allow interaction with a Sagemaker notebook. Requires client secret.
    FreeUser = 'FreeUser',                          // A user that self-registers with an email not associated with a client. Can create one custom portfolio and view default
                                                    // Can edit username, reset password, turn on/off portfolio alerts, view novelty report, and read articles
    Reader = 'Reader',              // can read articles
    Trending = 'Trending'
}
