function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (Date) {
      pug_html = pug_html + "\u003Cdiv class=\"input-group\"\u003E\u003Cinput" + (" class=\"form-control\""+" id=\"tm-dateSelector\" type=\"date\" name=\"date\""+pug_attr("max", new Date().toISOString().substr(0, 10), true, true)) + "\u003E\u003Cbutton class=\"btn btn-secondary ms-1\" id=\"tm-resetBtn\"\u003EReset\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cp class=\"mt-4 text-justify\"\u003EAdjust the date on which the system will consider \"now\". All metrics and counts will be adjusted as if they were being viewed on the end of selected date (UTC). Press 'Reset' to show today's date. Press 'Apply' to see data for the shown date'\u003C\u002Fp\u003E\u003Cdiv class=\"input-group\"\u003E\u003Cbutton class=\"btn btn-primary ms-1\" id=\"tm-applyBtn\"\u003EApply\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-secondary ms-1\" id=\"tm-cancelBtn\"\u003ECancel\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "Date" in locals_for_with ?
        locals_for_with.Date :
        typeof Date !== 'undefined' ? Date : undefined));
    ;;return pug_html;};module.exports=template;