import { IParsedConcept, IParsedEvent, IParsedIndicator } from '../dashboard';
import { IBaseStats, ILinkStats, ITrendStats } from '../kpiNav';
import { IComplexMarker } from '../marker/marker';
import { ICitation, IProperties, IQuotedCitation, ISignalInfo } from '../unified';

export const PARSED_CODE = 'parsedCode';
export const PARSED_CODE_KEY_DELIMITER = '/';
export const KPINAV_MAX_LINKS = 1000;

export class MentionExportColumnKeys {
    public static BASE_EVENT = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}baseEvent`;
    public static COMPANY = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}company`;
    public static DRIVER = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}driver`;
    public static FROM_COMPANY = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}fromCompany`;
    public static FROM_LOCATION = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}fromLocation`;
    public static FROM_PERSON = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}fromPerson`;
    public static HTTP_LINK = 'link';
    public static IMPACT = 'impact';
    public static INDICATOR = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}baseKPI`;
    public static INDUSTRY = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}industry`;
    public static LOCATION = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}location`;
    public static PUBLISH_DATE = 'publishDate';
    public static PRODUCT = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}product`;
    public static SEGMENT = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}segment`;
    public static SENTENCE = 'sentence';
    public static TARGET_DATE = 'targetDate';
    public static TITLE = 'title';
    public static TO_COMPANY = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}toCompany`;
    public static TO_LOCATION = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}toLocation`;
    public static TO_PERSON = `${PARSED_CODE}${PARSED_CODE_KEY_DELIMITER}toPerson`;
}

// Yes, these are ugly. This is to type the large array we will send as rows for maximum efficiency.
// Because their schema is identical, sending this square shape can save enormous traffic. Do not export
// these, outside consumers must transform the objects to nicer JS typed interfaces, but we will keep
// this large matrix in memory for very quick analysis, aggregation, and signal generation.
export type Mention = [ string, string, string, // shared
                        number, number, number, number, // elements from the object
                        string, // descriptors
                      ];

// Keep track of how many indicators and events exist
// ID is a combination of publisher, article id, and sentence
export interface IMentions {
  [ id: string]: IMentionSummary;
}

interface IMentionSummary {
  o: number; // offset
  d?: number; // direction -> not on indicators or events
  s?: number; // span
  v?: number; // value -> new, mostly indicators
}

export interface IParsedMentionSentence extends IParsedMention {
  sentenceId: string;
}

// See comment for IParsedSummary
export interface IParsedMention extends IMentionSummaryDashboard {
  parsedCode: IParsedIndicator | IParsedEvent;
  publisher: string;  // The first part of the parsed id is the publisher "ii" from above
  articleId: string;  // The second part of the parsed id is the elastic id "dDEaQ3YBOHmvGrsXyCYW"
  metaId: string;     // The string after the final slash uniquely identifies our NLP metadata object "en-b-008-002-1794"
  prettyIndicator?: string;  // A human friendly name tied to this specific mention
  alreadySwapped?: boolean;  // Used to ensure we do not accidentally invert a trend due to favorability more than once.
  filterValue?: string;      // Used to allow the user to further refine this group by a specific base KPI or base Event
  filterLabel?: string;      // The pretty value the user sees as an option to filter the group.
  mentionDate?: string,
  baseKPI?: string,
  readLink?: string,
}

// These are the raw mentions passed back from Dynamo, which have already figured out
// the target date based on the 'offset' property of each mention.
export interface IMentionSummaryDashboard {
  baseCode: string;    // "*-usa-*-*-*-*-airline_stocks"
  id: string;          // "ii_dDEaQ3YBOHmvGrsXyCYW/en-b-008-002-1794"
  publishDate: string; // the date the mention was 'said' (published) "2020-12-08"
  targetDate: string;  // the date the mention refers to (forecasts are after publish date) "2020-12-10"
  direction?: number;  // -1, 0, or 1 for down, flat, or up
  offset?: number;     // If present, number of days between publish and target date
  aggregatedDate?: string // 2022-07-12T22*-bolivia-pharmaceuticals-*-*-*-supply_demand
  o?: number,
}

interface IGenericMention {
  date: string;
  hour: number;
  company: string;
  location: string;
  industry: string;
  segment: string;
  product: string;
  feature: string;
  base: string;
  id: string;
  sentence: string;
  ref: string;
}

export interface IBaseMention extends IGenericMention {
  offset: number;
  favorable: number;
}

export interface ITrendMention extends IBaseMention {
  direction: number;
}

export interface ILinkMention extends IGenericMention {
  direction: number; // Contains the link strength
  sourceCompany: string;
  sourceLocation: string;
  sourceIndustry: string;
  sourceSegment: string;
  sourceProduct: string;
  sourceFeature: string;
  driver: string;
}

export interface IParsedMentionQueryResults {
  baseStats: IBaseStats;
  trendStats: ITrendStats;
  linkStats: ILinkStats[];
  bases: IBaseMention[];
  trends: ITrendMention[];
  links: ILinkMention[];
}

export interface IAthenaDashboardWizardMentionsAndLinksResult {
  label: string;          // indicators, links, trends, indicatorStats, linkStats or trendStats
  up: string;             // up count for stats
  down: string;           // down count for stats
  flat: string;           // flat count for stats
  date: string;           // 2020-01-01
  hour: string;           // 13
  company: string;        // apple
  location: string;       // aruba
  industry: string;       // energy
  segment: string;        // oil_gas_consumable_fuels
  product: string;        // *
  feature: string;        // This isn't fully implemented yet, but will probably be something like 'cloth'
  basekpi: string;        // air_pollution
  id: string;             // ansa_w7NpK3gB-EYq3ji78DUq
  sentence: string;       // it-b-002-001-407-1
  offset?: number;        // 34, optional because link mentions do not have offsets
  direction: number;      // 1
  favorable: number;      // -1
  sourceCompany: string;  // apple
  sourceLocation: string; // aruba
  sourceIndustry: string; // energy
  sourceSegment: string;  // oil_gas_consumable_fuels
  sourceProduct: string;  // *
  sourceFeature: string;  // This isn't fully implemented yet, but will probably be something like 'cloth'
  driver: string;         // Only for links, the source KPI or event
  ref: string;            // 221/202012172359PPI_____ASIANET_DMONITOR17122020000510/en-b-039-000-9174
}

// A unified interface for each individual complex object we detect in text,
// regardless of whether it is an indicator, event or trend.
export interface IMention extends IComplexMarker, ICitation, IProperties {
  conceptKey: string;
}

// For a large number of properties on IMention, we itemize and count the number of unique
// values within that property. For example, publisher will have a code for each with a
// count that represents not unique articles, but mentions from an array of mentions.
export interface IMentionStats {
  publisher: { [ val: string ]: number };
  article: { [ val: string ]: number };
  language: { [ val: string ]: number };
  field: { [ val: string ]: number };
  base: { [ val: string ]: number };
  company: { [ val: string ]: number };
  location: { [ val: string ]: number };
  industry: { [ val: string ]: number };
  segment: { [ val: string ]: number };
  product: { [ val: string ]: number };
  person: { [ val: string ]: number };
  extra: { [ val: string ]: number };
}

export interface IMentionMention {
  publisher: string;
  article: string;
  meta: string;
}

// The additional information added to groups as part of using the assign function
export interface IGroupMentionInfo {
  chatter?: IParsedConcept[];
  chatterTopBaseIndicator?: string;
  chatterTopDisplayIndicator?: string;
  chatterTopBasePretty?: string;
  topKPIs?: { baseKPI: string, count: number }[];
  parentCompanies?: string[];
  sector?: { label: string, icon: string };
}

// A list of mentions can be organized by their article (id) and sentence they belong to.
// The quote may not be populated until calling the sentence API.
export interface IGroupedMentions { [ id: string ]: { [ sent: string ]: { quote: string, mentions: IMention[] } } }

// After grouping mentions some additional metadata is needed for the front end to render consistently.
export interface IProcessedGroup {
  title: string;
  publisher: string;
  publisherLabel: string;
  language: string;
  languageLabel: string;
  date: Date;
  dateStr: string;
  readLink: string;
  mentions: IMention[];
}

interface ISimilarity {
  rawDistance: number;
  rawSimilarity: number;
  rawJaro: number;
  rawWinkler: number;
  simpleDistance: number;
  simpleSimilarity: number;
  simpleJaro: number;
  simpleWinkler: number;
}

export interface ICombineSimiliarMention extends IQuotedCitation { duplicates?: {quote: string, phrase: string, mention: ICombineSimiliarMention, metrics: ISimilarity}[] }

// Information about what tree elements are parents and the sector in the case
// of some company markers.
export interface IGroupParentInfo {
  parents: string[];
  sector?: { label: string, icon: string };
}

export interface ISeries {
  label: string;
  startDate: string;
  endDate: string;
  conceptKey: string;
  selectedMentions: IMention[];
  selectedSignals: { [ date: string ]: ISignalInfo };
  masterFilters: { [ column: string ]: { [ marker: string ]: number }};
  selectedFilters: { [ column: string ]: string[] };
}
