function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;
function pug_style(r){if(!r)return"";if("object"==typeof r){var t="";for(var e in r)pug_has_own_property.call(r,e)&&(t=t+e+":"+r[e]+";");return t}return r+""}function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (chartOnly, chartType, inputs, showAll) {
      pug_html = pug_html + "\u003Cdiv class=\"d-inline selectgroup\"\u003E";
if (chartType === 'bigpie') {
pug_html = pug_html + "\u003Cdiv class=\"row mb-2 fade-in\"\u003E\u003Cdiv class=\"d-flex\" style=\"flex-wrap: wrap;\"\u003E";
// iterate inputs
;(function(){
  var $$obj = inputs;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var input = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (" class=\"col\""+pug_attr("style", pug_style((!showAll && !input.options.length) ? 'display:none !important;' : ''), true, true)) + "\u003E\u003Cdiv" + (" class=\"mx-auto select-chart\""+pug_attr("id", `selectChart-${input.name}`, true, true)+" style=\"width: 350px;\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var input = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (" class=\"col\""+pug_attr("style", pug_style((!showAll && !input.options.length) ? 'display:none !important;' : ''), true, true)) + "\u003E\u003Cdiv" + (" class=\"mx-auto select-chart\""+pug_attr("id", `selectChart-${input.name}`, true, true)+" style=\"width: 350px;\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
let isEmpty = true
// iterate inputs
;(function(){
  var $$obj = inputs;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var input = $$obj[pug_index1];
isEmpty = isEmpty && !input.options.length
const className = !input.options.find((opt) => opt.selected) ? 'default-value' : ''
pug_html = pug_html + "\u003Cdiv" + (" class=\"d-inline-block select-wrapper\""+pug_attr("style", pug_style((!showAll && !input.options.length) ? 'display:none !important;' : ''), true, true)) + "\u003E\u003Cdiv" + (pug_attr("style", pug_style(chartOnly ? 'display:none !important;' : ''), true, true)) + "\u003E\u003Cselect" + (" class=\"selectpicker me-2 mb-2\""+pug_attr("name", input.name, true, true)+pug_attr("title", input.label, true, true)+pug_attr("multiple", true, true, true)+pug_attr("data-header", input.label, true, true)+pug_attr("disabled", input.options.length === 0, true, true)+" data-live-search=\"true\" data-width=\"150px\"") + "\u003E";
// iterate input.options
;(function(){
  var $$obj = input.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var opt = $$obj[pug_index2];
pug_html = pug_html + "\u003Coption" + (pug_attr("value", opt.value, true, true)+pug_attr("selected", opt.selected, true, true)+pug_attr("data-subtext", opt.count, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = opt.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var opt = $$obj[pug_index2];
pug_html = pug_html + "\u003Coption" + (pug_attr("value", opt.value, true, true)+pug_attr("selected", opt.selected, true, true)+pug_attr("data-subtext", opt.count, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = opt.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E";
if (chartType !== 'bigpie') {
pug_html = pug_html + "\u003Cdiv" + (" class=\"select-chart mb-2\""+pug_attr("id", `selectChart-${input.name}`, true, true)+" style=\"width:150px; max-height:150px\"") + "\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var input = $$obj[pug_index1];
isEmpty = isEmpty && !input.options.length
const className = !input.options.find((opt) => opt.selected) ? 'default-value' : ''
pug_html = pug_html + "\u003Cdiv" + (" class=\"d-inline-block select-wrapper\""+pug_attr("style", pug_style((!showAll && !input.options.length) ? 'display:none !important;' : ''), true, true)) + "\u003E\u003Cdiv" + (pug_attr("style", pug_style(chartOnly ? 'display:none !important;' : ''), true, true)) + "\u003E\u003Cselect" + (" class=\"selectpicker me-2 mb-2\""+pug_attr("name", input.name, true, true)+pug_attr("title", input.label, true, true)+pug_attr("multiple", true, true, true)+pug_attr("data-header", input.label, true, true)+pug_attr("disabled", input.options.length === 0, true, true)+" data-live-search=\"true\" data-width=\"150px\"") + "\u003E";
// iterate input.options
;(function(){
  var $$obj = input.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var opt = $$obj[pug_index3];
pug_html = pug_html + "\u003Coption" + (pug_attr("value", opt.value, true, true)+pug_attr("selected", opt.selected, true, true)+pug_attr("data-subtext", opt.count, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = opt.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var opt = $$obj[pug_index3];
pug_html = pug_html + "\u003Coption" + (pug_attr("value", opt.value, true, true)+pug_attr("selected", opt.selected, true, true)+pug_attr("data-subtext", opt.count, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = opt.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E";
if (chartType !== 'bigpie') {
pug_html = pug_html + "\u003Cdiv" + (" class=\"select-chart mb-2\""+pug_attr("id", `selectChart-${input.name}`, true, true)+" style=\"width:150px; max-height:150px\"") + "\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

if (!isEmpty && !chartOnly) {
pug_html = pug_html + "\u003Cbutton class=\"clearall-btn btn btn-secondary mx-2 align-top\" type=\"button\" title=\"Reset Filters\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\"\u003E\u003Ci class=\"fa fa-times\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "chartOnly" in locals_for_with ?
        locals_for_with.chartOnly :
        typeof chartOnly !== 'undefined' ? chartOnly : undefined, "chartType" in locals_for_with ?
        locals_for_with.chartType :
        typeof chartType !== 'undefined' ? chartType : undefined, "inputs" in locals_for_with ?
        locals_for_with.inputs :
        typeof inputs !== 'undefined' ? inputs : undefined, "showAll" in locals_for_with ?
        locals_for_with.showAll :
        typeof showAll !== 'undefined' ? showAll : undefined));
    ;;return pug_html;};module.exports=template;