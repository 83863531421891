function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (announcements, messages) {
      pug_html = pug_html + "\u003Cul class=\"list-unstyled\" id=\"announcementNotifs\"\u003E";
// iterate announcements
;(function(){
  var $$obj = announcements;
  if ('number' == typeof $$obj.length) {
      for (var annIdx = 0, $$l = $$obj.length; annIdx < $$l; annIdx++) {
        var announcement = $$obj[annIdx];
pug_html = pug_html + "\u003Cli class=\"media mx-1\"\u003E\u003Cdiv class=\"m-2\"\u003E\u003Cspan class=\"badge badge-danger\"\u003E\u003Ci class=\"fa fa-2x fa-alarm-clock\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"media-body\"\u003E\u003Cdiv\u003E\u003Csmall\u003E";
if (announcement.path) {
// iterate announcement.path
;(function(){
  var $$obj = announcement.path;
  if ('number' == typeof $$obj.length) {
      for (var pdx = 0, $$l = $$obj.length; pdx < $$l; pdx++) {
        var p = $$obj[pdx];
if (pdx === 0) {
pug_html = pug_html + "\u003Cb\u003E" + (pug_escape(null == (pug_interp = p) ? "" : pug_interp)) + "\u003C\u002Fb\u003E\u003Cspan\u003E could impact \u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cb\u003E" + (pug_escape(null == (pug_interp = p) ? "" : pug_interp)) + "\u003C\u002Fb\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pdx in $$obj) {
      $$l++;
      var p = $$obj[pdx];
if (pdx === 0) {
pug_html = pug_html + "\u003Cb\u003E" + (pug_escape(null == (pug_interp = p) ? "" : pug_interp)) + "\u003C\u002Fb\u003E\u003Cspan\u003E could impact \u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cb\u003E" + (pug_escape(null == (pug_interp = p) ? "" : pug_interp)) + "\u003C\u002Fb\u003E";
}
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Ca" + (pug_attr("href", announcement.readLink, true, true)+" target=\"_blank\"") + "\u003E\u003Csmall\u003E" + (pug_escape(null == (pug_interp = announcement.quote) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"float-sm-right\"\u003E\u003Csmall class=\"text-muted\"\u003E" + (pug_escape(null == (pug_interp = announcement.received) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003Cspan class=\"ms-2\"\u003E\u003Ci" + (" class=\"fa fa-trash\""+pug_attr("data-idx", annIdx, true, true)+" data-id=\"ann\"") + "\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var annIdx in $$obj) {
      $$l++;
      var announcement = $$obj[annIdx];
pug_html = pug_html + "\u003Cli class=\"media mx-1\"\u003E\u003Cdiv class=\"m-2\"\u003E\u003Cspan class=\"badge badge-danger\"\u003E\u003Ci class=\"fa fa-2x fa-alarm-clock\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"media-body\"\u003E\u003Cdiv\u003E\u003Csmall\u003E";
if (announcement.path) {
// iterate announcement.path
;(function(){
  var $$obj = announcement.path;
  if ('number' == typeof $$obj.length) {
      for (var pdx = 0, $$l = $$obj.length; pdx < $$l; pdx++) {
        var p = $$obj[pdx];
if (pdx === 0) {
pug_html = pug_html + "\u003Cb\u003E" + (pug_escape(null == (pug_interp = p) ? "" : pug_interp)) + "\u003C\u002Fb\u003E\u003Cspan\u003E could impact \u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cb\u003E" + (pug_escape(null == (pug_interp = p) ? "" : pug_interp)) + "\u003C\u002Fb\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pdx in $$obj) {
      $$l++;
      var p = $$obj[pdx];
if (pdx === 0) {
pug_html = pug_html + "\u003Cb\u003E" + (pug_escape(null == (pug_interp = p) ? "" : pug_interp)) + "\u003C\u002Fb\u003E\u003Cspan\u003E could impact \u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cb\u003E" + (pug_escape(null == (pug_interp = p) ? "" : pug_interp)) + "\u003C\u002Fb\u003E";
}
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Ca" + (pug_attr("href", announcement.readLink, true, true)+" target=\"_blank\"") + "\u003E\u003Csmall\u003E" + (pug_escape(null == (pug_interp = announcement.quote) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"float-sm-right\"\u003E\u003Csmall class=\"text-muted\"\u003E" + (pug_escape(null == (pug_interp = announcement.received) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003Cspan class=\"ms-2\"\u003E\u003Ci" + (" class=\"fa fa-trash\""+pug_attr("data-idx", annIdx, true, true)+" data-id=\"ann\"") + "\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003Cul class=\"list-unstyled\" id=\"dmNotifs\"\u003E";
// iterate messages
;(function(){
  var $$obj = messages;
  if ('number' == typeof $$obj.length) {
      for (var msgIdx = 0, $$l = $$obj.length; msgIdx < $$l; msgIdx++) {
        var message = $$obj[msgIdx];
pug_html = pug_html + "\u003Cli class=\"media mx-1\"\u003E\u003Cdiv class=\"m-2\"\u003E\u003Cspan class=\"badge badge-warning\"\u003E\u003Ci class=\"fa fa-2x fa-comment\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"media-body\"\u003E\u003Cdiv\u003E\u003Cdiv class=\"body-text\"\u003E" + (pug_escape(null == (pug_interp = `New message from ${message.creator}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Csmall\u003E" + (pug_escape(null == (pug_interp = message.comment) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"float-sm-right\"\u003E\u003Csmall class=\"text-muted\"\u003E" + (pug_escape(null == (pug_interp = message.created) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003Cspan class=\"ms-2\"\u003E\u003Ci" + (" class=\"fa fa-trash\""+pug_attr("data-idx", msgIdx, true, true)+" data-id=\"msg\"") + "\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var msgIdx in $$obj) {
      $$l++;
      var message = $$obj[msgIdx];
pug_html = pug_html + "\u003Cli class=\"media mx-1\"\u003E\u003Cdiv class=\"m-2\"\u003E\u003Cspan class=\"badge badge-warning\"\u003E\u003Ci class=\"fa fa-2x fa-comment\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"media-body\"\u003E\u003Cdiv\u003E\u003Cdiv class=\"body-text\"\u003E" + (pug_escape(null == (pug_interp = `New message from ${message.creator}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Csmall\u003E" + (pug_escape(null == (pug_interp = message.comment) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"float-sm-right\"\u003E\u003Csmall class=\"text-muted\"\u003E" + (pug_escape(null == (pug_interp = message.created) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003Cspan class=\"ms-2\"\u003E\u003Ci" + (" class=\"fa fa-trash\""+pug_attr("data-idx", msgIdx, true, true)+" data-id=\"msg\"") + "\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E";
    }.call(this, "announcements" in locals_for_with ?
        locals_for_with.announcements :
        typeof announcements !== 'undefined' ? announcements : undefined, "messages" in locals_for_with ?
        locals_for_with.messages :
        typeof messages !== 'undefined' ? messages : undefined));
    ;;return pug_html;};module.exports=template;