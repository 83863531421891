import { Socket, io } from 'socket.io-client';
import { EVENT_TARGET_ALL } from '../../../shared/event/eventUtil';
import { EventSubject } from '../../../shared/interfaces/event';
import { getClLogger } from '../../../shared/util/clLogger';
import { servicesGetEventBus } from '../api/services';
import { IEventInfo } from '../interfaces/static';
import { STORAGE_INFO, fetchCacheKey, setCacheKey } from '../util/storageUtil';

const clLogger = getClLogger(__filename);

async function getEventLocation(): Promise<IEventInfo> {
    const currentVal = fetchCacheKey<IEventInfo>(STORAGE_INFO.clEvents.scope, STORAGE_INFO.clEvents.key);
    if (currentVal) {
        return currentVal;
    }
    else {
        clLogger.debug('Finding event location');
        const ebLocation = await servicesGetEventBus();
        clLogger.debug('Got back: ', ebLocation);
        if (ebLocation && ebLocation.name === 'Events') {
            setCacheKey(STORAGE_INFO.clEvents.scope, STORAGE_INFO.clEvents.key, ebLocation);
            return ebLocation;
        }
        else {
            clLogger.debug('Unable to locate event service. Socket setup will not continue.');
            return null;
        }
    }
}

function setPathAndHostStrings(roomToJoin: string, eventService: IEventInfo) {
    // //foo.bar -> separate host, root fs          host: //foo.bar/article/view, path: /socket.io
    // //foo.bar/events -> separate host, subpath   host: //foo.bar/article/view, path: /events/socket.io
    // //localhost:3002 -> different host, root fs  host: //localhost:3002, path: /socket.io
    // /events -> same host, different path         host: //, path: /events/socket.io
    let path = '/socket.io';
    let host = roomToJoin;
    if (eventService.path.startsWith('//')) {
        const data = getHostPath(host, path, eventService, 2);
        host = data.host;
        path = data.path;
    }
    else if (eventService.path.startsWith('http')) {
        const data = getHostPath(host, path, eventService, 8);
        host = data.host;
        path = data.path;
    }
    else if (eventService.path.startsWith('/')) {
        // Config only specifies a subpath, the host will be untouched
        path = eventService.path + path;
    }

    return [ host, path ];
}

// Each page or app might have many different namespaces, but this object should help prevent us from
// initializing them repeatedly if we join multiple rooms or leave/join in sequence.
const currSockets: {[namespace: string]: Socket} = {};
async function getSocket(namespace: string, rooms: string | string[]) {
    if (currSockets[namespace] === undefined) {
        const eventService = await getEventLocation();
        const [ host, path ] = setPathAndHostStrings(namespace, eventService);
        const newSocket: Socket = io(host, { path, upgrade: false, transports: [ 'websocket', 'polling' ] });
        newSocket.on('connect', () => {
            clLogger.debug('Socket Connected.');
        });
        newSocket.on('connect_error', (err) => {
            clLogger.error('Error connecting socket IO', err);
        });
        newSocket.on('connect_timeout', () => {
            clLogger.debug('Socket IO connection timed out');
        });
        newSocket.on('reconnect', (attempt) => {
            clLogger.debug('Socket IO reconnected on attempt: ' + attempt);
            // On reconnect, we need to try to join the room again after reconnect, as the session is unique
            newSocket.emit('join', rooms);
        });
        currSockets[namespace] = newSocket;
    }
    return currSockets[namespace];
}

export async function joinRoom(nsToJoin: string, subject: EventSubject, monitorIds: string | string[], cb: (id, operation, msgBody) => void) {
    if (!monitorIds) {
        monitorIds = EVENT_TARGET_ALL;
    }
    const socket = await getSocket(nsToJoin, monitorIds);
    clLogger.debug(`Joining ${nsToJoin} for ${subject}: ${monitorIds}`);
    socket.on(subject, cb);
    socket.emit('join', monitorIds);
    return socket;
}

export async function leaveRoom(nsToLeave: string, subject: EventSubject, monitorIds: string | string[], cb: (id, operation, msgBody) => void) {
    if (!monitorIds) {
        monitorIds = EVENT_TARGET_ALL;
    }
    clLogger.debug(`Leaving ${nsToLeave} for ${subject}: ${monitorIds}`);
    const socket = await getSocket(nsToLeave, monitorIds);
    // Unwire the callback
    socket.off(subject, cb);
    // Also tell the server we are leaving the rooms, so messages won't continue to be sent.
    socket.emit('leave', monitorIds);
}

function getHostPath(host: string, path: string, eventService, index: number) {
    const subPath = eventService.path.indexOf('/', index);
    // host/subpath combined, need to prefix both path and host
    if (subPath >= 0) {
        host = eventService.path.substr(0, subPath) + host;
        path = eventService.path.substr(subPath) + path;
    }
    // No subpath, use entire value as prefix for host, default path will work
    else {
        host = eventService.path + host;
    }
    return { host, path };
}
