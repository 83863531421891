import { configurableReports } from '../interfaces/bookmark/bookmark-i';
import { IFeatureFlagDefinition, IFeatureStatus } from '../interfaces/featureFlag';

// Master list of flags. Item must be here for the front and back end to coordinate checks.
const FeatureFlags: { [key : string]: IFeatureFlagDefinition} = {
    disableMarkerEditingKey: {
        name: 'DISABLE_MARKER_EDITING',
        title: 'Disable Marker Editing',
        message: 'This KB cannot be changed',
        sinceDate: '2020',
        description: 'Disable editing of all markers',
        disable: true,
    },
    enableChat: {
        name: 'ENABLE ADMIN-USER CHAT',
        title: 'Enable chatting feature',
        sinceDate: 'October, 2022',
        description: 'Chat function will be available when enabled (and an admin is on the DMs page)',
    },
    disableSelfRegistration: {
        name: 'DISABLE SELF REGISTRATION',
        title: 'Disable Self-Registration',
        sinceDate: 'September, 2023',
        description: 'Self registration will be disabled when selected',
        message: 'Self registration is disabled',
        disable: true,
    },
    disableEmail: {
        name: 'DISABLE EMAIL',
        title: 'Disable Email',
        sinceDate: 'November, 2023',
        description: 'Disable email sending',
        message: 'Email is disabled',
        disable: true,
    },
    disableMarkerArticleTranslate: {
        name: 'DISABLE_ARTICLE_TRANSLATE',
        title: 'Disable Article Translate',
        message: 'The translate request can\'t be completed',
        sinceDate: 'April, 2024',
        description: 'Disable translate of foreign articles',
        disable: true,
    },
};
for (const key of Object.keys(configurableReports)) {
    FeatureFlags[key] = {
        name: `DISABLE ${key}`,
        title: `DISABLE ${configurableReports[key].name} alert`,
        sinceDate: 'Autogenerated flag',
        description: configurableReports[key].description,
        message: `${configurableReports[key].name} alert is disabled`,
        disable: true,
    };
}

export { FeatureFlags };

// Make sure the passed in feature is enabled
export function isFlagEnabled(flagsInEnvironment: IFeatureStatus[], flagToCheck: IFeatureStatus) {
    // Fail if the feature being tested is not a real feature
    validateFeature(flagToCheck);
    return flagsInEnvironment.some((flagInEnvironment: IFeatureStatus) => {
        return (flagInEnvironment.name === flagToCheck.name) && flagInEnvironment.enabled;
    });
}

// Make sure code is referencing a feature defined in the master list (FeatureFlags)
export function validateFeature(featureFlag: IFeatureStatus) {
    const EXISTING_FEATURE_NAMES = Object.values(FeatureFlags).map((elem) => elem.name);
    // If the master feature list doesn't include the incoming feature to check, fail
    if (!EXISTING_FEATURE_NAMES.includes(featureFlag.name)) {
        throw Error(`The feature flag definition for ${featureFlag.name} doesn't exist`);
    }
}
