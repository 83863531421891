function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;
function pug_style(r){if(!r)return"";if("object"==typeof r){var t="";for(var e in r)pug_has_own_property.call(r,e)&&(t=t+e+":"+r[e]+";");return t}return r+""}function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (confirmMessage, sizeNormal) {
      pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv" + (" class=\"col-sm-12\""+" id=\"confirmBody\""+pug_attr("style", pug_style(sizeNormal ? 'width: 360px; height: 100px;' : ''), true, true)) + "\u003E\u003Cp class=\"confirmMessage\"\u003E" + (pug_escape(null == (pug_interp = confirmMessage) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cdiv" + (" class=\"formMessage alert alert-danger confirmError\""+pug_attr("hidden", true, true, true)) + "\u003EThere was an error, please retry later.\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E \u003Cdiv class=\"col-sm-6 pe-1\"\u003E \u003Cbutton class=\"confirmConfirmed btn btn-primary btn-block\" type=\"button\"\u003EConfirm\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-sm-6 ps-1\"\u003E\u003Cbutton class=\"confirmCancel btn btn-secondary btn-block\" type=\"button\"\u003ECancel\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "confirmMessage" in locals_for_with ?
        locals_for_with.confirmMessage :
        typeof confirmMessage !== 'undefined' ? confirmMessage : undefined, "sizeNormal" in locals_for_with ?
        locals_for_with.sizeNormal :
        typeof sizeNormal !== 'undefined' ? sizeNormal : undefined));
    ;;return pug_html;};module.exports=template;