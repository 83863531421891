function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (conversations) {
      pug_html = pug_html + "\u003Cul class=\"viewNav nav nav-tabs\" role=\"tablist\"\u003E";
// iterate conversations
;(function(){
  var $$obj = conversations;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var conversation = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli class=\"nav-item body-text\"\u003E\u003Ca" + (" class=\"nav-link flex-nowrap\""+" data-bs-toggle=\"tab\" role=\"tab\""+pug_attr("aria-controls", `${conversation.id}-tab-pane`, true, true)+pug_attr("id", `${conversation.id}`, true, true)+" style=\"max-width: 12rem;\"") + "\u003E\u003Cdiv" + (" class=\"col-10 text-truncate\""+" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\""+pug_attr("title", conversation.user, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = conversation.name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cbutton" + (" class=\"col-2 clr-btn btn-transparent close-dm fas fa-times\""+" title=\"Close DM\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\""+pug_attr("id", `${conversation.id}-close`, true, true)) + "\u003E\u003C\u002Fbutton\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var conversation = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli class=\"nav-item body-text\"\u003E\u003Ca" + (" class=\"nav-link flex-nowrap\""+" data-bs-toggle=\"tab\" role=\"tab\""+pug_attr("aria-controls", `${conversation.id}-tab-pane`, true, true)+pug_attr("id", `${conversation.id}`, true, true)+" style=\"max-width: 12rem;\"") + "\u003E\u003Cdiv" + (" class=\"col-10 text-truncate\""+" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\""+pug_attr("title", conversation.user, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = conversation.name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cbutton" + (" class=\"col-2 clr-btn btn-transparent close-dm fas fa-times\""+" title=\"Close DM\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\""+pug_attr("id", `${conversation.id}-close`, true, true)) + "\u003E\u003C\u002Fbutton\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003Cdiv class=\"tab-content mt-3\"\u003E";
// iterate conversations
;(function(){
  var $$obj = conversations;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var conversation = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv" + (" class=\"tab-pane dm\""+" role=\"tabpanel\""+pug_attr("aria-labelledby", `${conversation.id}`, true, true)+pug_attr("id", `${conversation.id}-tab-pane`, true, true)) + "\u003E\u003Cdiv" + (" class=\"chat-box-admin d-flex justify-content-start align-items-end\""+pug_attr("data-id", conversation.id, true, true)) + "\u003E\u003Cdiv class=\"col-11\"\u003E\u003Cdiv" + (" class=\"messages-admin chat-scroll\""+pug_attr("id", `${conversation.id}-chat`, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (" class=\"chat-box-typespace\""+pug_attr("id", `${conversation.id}-textbox`, true, true)+pug_attr("name", conversation.id, true, true)+" type=\"text\" placeholder=\"Send a message...\" style=\"height: 10%; width: 98%;\"") + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-1\"\u003E\u003Cdiv" + (" class=\"btn btn-primary chat-btn-send\""+pug_attr("id", `${conversation.id}-sendBtn`, true, true)) + "\u003ESend\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var conversation = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv" + (" class=\"tab-pane dm\""+" role=\"tabpanel\""+pug_attr("aria-labelledby", `${conversation.id}`, true, true)+pug_attr("id", `${conversation.id}-tab-pane`, true, true)) + "\u003E\u003Cdiv" + (" class=\"chat-box-admin d-flex justify-content-start align-items-end\""+pug_attr("data-id", conversation.id, true, true)) + "\u003E\u003Cdiv class=\"col-11\"\u003E\u003Cdiv" + (" class=\"messages-admin chat-scroll\""+pug_attr("id", `${conversation.id}-chat`, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (" class=\"chat-box-typespace\""+pug_attr("id", `${conversation.id}-textbox`, true, true)+pug_attr("name", conversation.id, true, true)+" type=\"text\" placeholder=\"Send a message...\" style=\"height: 10%; width: 98%;\"") + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-1\"\u003E\u003Cdiv" + (" class=\"btn btn-primary chat-btn-send\""+pug_attr("id", `${conversation.id}-sendBtn`, true, true)) + "\u003ESend\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "conversations" in locals_for_with ?
        locals_for_with.conversations :
        typeof conversations !== 'undefined' ? conversations : undefined));
    ;;return pug_html;};module.exports=template;