import { IComplexMarker } from './marker/marker';

// Same comment as above but used for the special case of causal links
export type UnifiedLink = [ string, string, string,
                     number, number, number,
                     string, string, string, string, string, string, string ];

// Used to track numbers each 24 hour period for one conceptkey or concept
export type DailyCount = [ string, string, number, number, number, number, number, number, number, number, number, number, number, number, number ];

// Used to track summed counts of all the concept keys for a concept for each 24 hour period
export type ConceptCount = [ string, string, string[],
                             number, number, number, number, number,
                             number, number, number, number, number,
                             number, number, number, number, number,
                             number, number, number, number, number ]

// This simply gives us a nice handle in code to which array slot contains each property. If/when
// we add schema in the future, ensure those properties are inserted at the end, and also if things
// are removed, they should be left here, set to undefined, for a long period of time.
export enum MentionCol {
    conceptKey = 0,
    articleref = 1,
    datehour = 2,
    offset = 3,
    direction = 4,
    value = 5,
    span = 6,
    code = 7,
}

export enum LinkCol {
    targetKey = 0,
    articleref = 1,
    datehour = 2,
    offset = 3,     // Future
    direction = 4,  // Strength
    value = 5,      // Negation
    sourceKey = 6,
    fromCode = 7,
    fromDisplay = 8,
    toCode = 9,
    toDisplay = 10,
    dateAndId = 11,
    sentence = 12,
}

export enum ConceptCountCol {
    concept = 0,
    date = 1,
    topics = 2,
    current_indicator = 3,
    current_event = 4,
    outlook_indicator = 5,
    outlook_event = 6,
    up_current = 7,
    down_current = 8,
    positive_current_indicator = 9,
    positive_current_event = 10,
    negative_current_indicator = 11,
    negative_current_event = 12,
    flat_current_indicator = 13,
    flat_current_event = 14,
    up_outlook = 15,
    down_outlook = 16,
    positive_outlook_indicator = 17,
    positive_outlook_event = 18,
    negative_outlook_indicator = 19,
    negative_outlook_event = 20,
    flat_outlook_indicator = 21,
    flat_outlook_event = 22,
}

export enum DailyCountCol {
    conceptKey = 0,
    date = 1,
    isevent = 2,
    current = 3,
    outlook = 4,
    up_current = 5,
    down_current = 6,
    positive_current = 7,
    negative_current = 8,
    flat_current = 9,
    up_outlook = 10,
    down_outlook = 11,
    positive_outlook = 12,
    negative_outlook = 13,
    flat_outlook = 14,
}

export interface IProperties {
    offset: number;
    direction: number;
    value: number;
    span: number;
    forecast: boolean;
    up: boolean;
    down: boolean;
}

export interface ICitation {
    publisher: string;
    article: string;
    language: string;
    field: string;
    paragraph: string;
    sentence: string;
    meta: string;           // This is the offset of detection and can include dash (12345-2)
    date: string;
    hour: string;
    readLink?: string;
}

export interface IQuotedCitation extends ICitation {
    quote?: string;
    title?: string;
    phrase?: string;
}

export interface ILink extends ICitation, IProperties {
    sourceKey: string;
    targetKey: string;
    sourceBase: IComplexMarker;
    sourceDisplay: IComplexMarker;
    targetBase: IComplexMarker;
    targetDisplay: IComplexMarker;
}

export interface IStoredDailyStat {
    conceptkey: string; // Glue populates the data and does not support case sensitivity
    date: string;
    stats: number[];
}

// Interface is something between a mention and a stat as it summarizes the number of mentions by day,
// but is prior to any weight, window, or half life
export interface IDailyCount {
    conceptKey: string;
    date: string;
    isevent: boolean;           // Each concept key needs to denote if its related to event or indicator
    current: number;            // Indicator or Event mentions about past/present
    outlook: number;            // Indicator or Event mentions about future
    up_current: number;         // Only on trends saying that indicator is increasing (past/present)
    down_current: number;       // Only on trends saying that indicator is decreasing (past/present)
    positive_current: number;   // How many trends are moving in a favorable direction OR events occuring that are favorable
    negative_current: number;   // How many trends are moving in an unfavorable direction OR events occuring that are unfavorable
    flat_current: number;       // Trending flat or event with no favorable classification
    up_outlook: number;         // Only on trends saying that indicator is increasing (future)
    down_outlook: number;       // Only on trends saying that indicator is decreasing (future)
    positive_outlook: number;   // How many trends are moving in a favorable direction OR events occuring that are favorable (future)
    negative_outlook: number;   // How many trends are moving in an unfavorable direction OR events occuring that are unfavorable (future)
    flat_outlook: number;       // Trending flat or event with no favorable classification (future)
}

export interface IStoredConceptStat {
    concept: string; // Glue populates the data and does not support case sensitivity
    date: string;
    topics: string[];
    stats: number[];
}

// Interface is something between a mention and a stat as it summarizes the number of mentions by day,
// but is prior to any weight, window, or half life
export interface IConceptCount {
    concept: string;
    date: string;
    topics: string[];
    current_indicator: number;
    current_event: number;
    outlook_indicator: number;
    outlook_event: number;
    up_current: number;
    down_current: number;
    positive_current_indicator: number;
    positive_current_event: number;
    negative_current_indicator: number;
    negative_current_event: number;
    flat_current_indicator: number;
    flat_current_event: number;
    up_outlook: number;
    down_outlook: number;
    positive_outlook_indicator: number;
    positive_outlook_event: number;
    negative_outlook_indicator: number;
    negative_outlook_event: number;
    flat_outlook_indicator: number;
    flat_outlook_event: number;
}

// A prettier label to be used in UI for selection of a set of filters.
export enum EnumMentionStatsLabel {
    publisher = 'Publisher',
    article = 'Article',
    language = 'Language',
    field = 'Article Field',
    base = 'Indicator',
    company = 'Company',
    location = 'Location',
    industry = 'Industry',
    segment = 'Segment',
    product = 'Product',
    person = 'Person',
    extra = 'Additional Tags',
}

// A collection of mentions that has been filtered based on our interests can be
// converted to a daily time series with detailed statistics about each day.
export interface ISignalInfo {
    count_window: number;            // 01. How many total mentions happened in the last N days
    attention_all: number;           // 02. What proportion of these mentions was more recent (apply half life to each in last N days)
    attention_outlook: number;       // 03. What proportion of these was both recent and looking towards the future
    volume_daily: number;            // 04. How many mentions happened today
    positive_daily: number;          // 05. How many mentions were bullish today (up corrected for favor)
    negative_daily: number;          // 06. How many mentions were bearish today (down corrected for favor)
    flat_daily: number;              // 07. How many mentions did not cite a direction
    positive_outlook_daily: number;  // 08. How many mentions were bullish about the future today
    negative_outlook_daily: number;  // 09. How many mentions were bearish about the future today
    flat_outlook_daily: number;      // 10. How many mentions did not cite a direction but referred to the future
    positive_weight: number;         // 11. How many bullish mentions have there been recently (half life)
    negative_weight: number;         // 12. How many bearish mentions have there been recently (half life)
    flat_weight: number;             // 13. How many flat mentions have there been recently (half life)
    positive_outlook_weight: number; // 14. How many bullish mentions about the future have there been recently
    negative_outlook_weight: number; // 15. How many bearish mentions about the future have there been recently
    flat_outlook_weight: number;     // 16. How many flat mentions about the future have there been recently
    score_daily?: number;            // 17. What percentage of the mentions today were bullish (w prior)
    score?: number;                  // 18. What percentage of the mentions recently have been bullish
    score_current?: number;          // 19. What percentage of the recent mentions focused on current results have been bullish
    score_outlook?: number;          // 20. What percentage of the recent mentions focused on the future have been bullish
}
