import { getClLogger } from '../../../shared/util/clLogger';
import { EVENT_CATEGORY, UserFeature } from '../interfaces/analytics';
import { gtag } from '../layout';
import { onClick } from './html';

const clLogger = getClLogger(__filename);

const ENGAGEMENT_FEATURES = [];
const WINNING_ACTION_FEATURES = [
    UserFeature.KPINAVIGATOR_LINKLOAD_BUTTON_CLICK,
    UserFeature.VIEWARTICLE_ARTICLE_PAGE_OPEN,
];
const PROBLEMS = [ UserFeature.ERROR_REQUEST_TOOK_TOO_LONG ];

export async function trackAnalyticsEvent(feature: UserFeature, url?: string) {
    const DEFAULT_EVENT_LABEL = '';
    const DEFAULT_EVENT_VALUE = 0;
    const action = feature.toLowerCase();
    let eventCategory = EVENT_CATEGORY.UX;

    if (ENGAGEMENT_FEATURES.includes(feature)) {
        eventCategory = EVENT_CATEGORY.ENGAGEMENT;
    }
    else if (WINNING_ACTION_FEATURES.includes(feature)) {
        eventCategory = EVENT_CATEGORY.WINNING_ACTION;
    }
    else if (PROBLEMS.includes(feature)) {
        eventCategory = EVENT_CATEGORY.PROBLEM;
    }

    const actionDetails = {
        event_category: eventCategory,
        event_label: url || DEFAULT_EVENT_LABEL,
        value: DEFAULT_EVENT_VALUE,
    };
    let actionSuffix = '';
    try {
        actionSuffix = url ? ` @ ${new URL(url).pathname}` : '';
        gtag('event', `${action}${actionSuffix}`, actionDetails);
    }
    catch (err) {
        clLogger.debug(err);
    }
}

export function ifExistsOnClickTrackAnalyticsEvent(element: HTMLElement, feature: UserFeature) {
    onClick(element, () => {
        trackAnalyticsEvent(feature);
    });
}
