function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;
function pug_style(r){if(!r)return"";if("object"==typeof r){var t="";for(var e in r)pug_has_own_property.call(r,e)&&(t=t+e+":"+r[e]+";");return t}return r+""}function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fullPage, logo, width) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"text-center fade-in\""+pug_attr("style", pug_style(fullPage? `margin: ${logo ? '15' : '25'}vh 0 35vh 0;` : ''), true, true)) + "\u003E";
if (logo) {
pug_html = pug_html + "\u003Cimg class=\"brand-img\" id=\"progressLogo\" src=\"\u002Fimg\u002Fcausality-logo-white.png\" alt=\"\"\u003E\u003Cimg class=\"brand-img-print\" id=\"progressLogoPrint\" src=\"\u002Fimg\u002Fcausality-logo-dark.png\" alt=\"\" style=\"display: none\"\u003E";
}
pug_html = pug_html + "\u003Cdiv" + (" class=\"mx-auto\""+pug_attr("style", pug_style(`width:${width}px;`), true, true)) + "\u003E\u003Cdiv class=\"progress-bar\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fullPage" in locals_for_with ?
        locals_for_with.fullPage :
        typeof fullPage !== 'undefined' ? fullPage : undefined, "logo" in locals_for_with ?
        locals_for_with.logo :
        typeof logo !== 'undefined' ? logo : undefined, "width" in locals_for_with ?
        locals_for_with.width :
        typeof width !== 'undefined' ? width : undefined));
    ;;return pug_html;};module.exports=template;