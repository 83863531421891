import { IParsedEvent, IParsedIndicator, IRollingStats, IStat } from '../interfaces/dashboard';
import { IKpiDirections, IRollingByDay } from '../interfaces/signal';
import { parseCode } from '../streaming/dashboard';

export const DISPLAY_KPI_KEY_MARKER = 'display-';

export function extractBaseKpiDirections(trendStatData: { [key: string]: IStat[] }, afterOrOnDate: Date): IKpiDirections {
    const kpiDirections: IKpiDirections = {};
    const DELIMITER_KPI = '-';
    const TIME_SUFFIX = ' 00:00:00';
    for (const trendStatKey of Object.keys(trendStatData)) {
        const dkpiTrendByDateList: IStat[] = trendStatData[trendStatKey];
        const kpi = trendStatKey.split(DELIMITER_KPI)[1];
        for (const dkpiDayTrend of dkpiTrendByDateList) {
            // Limit aggregated trend counts to a time frame.
            // This is already done for the total trend mention counts, and needs to be refactored to happen
            //  in one place. This change simply fixes the bug (total trend count is different than pos/flat/neg subtotals added)
            //  so that a refactoring can then happen.
            const mentionDate = new Date(`${dkpiDayTrend.date}${TIME_SUFFIX}`);
            if (!afterOrOnDate || mentionDate >= afterOrOnDate) {
                const code = parseCode(dkpiDayTrend.code);
                const baseKpi = (code as IParsedIndicator).baseKPI || (code as IParsedEvent).baseEvent;
                const displayKpi = DISPLAY_KPI_KEY_MARKER + kpi;
                if (!kpiDirections[baseKpi]) {
                    kpiDirections[baseKpi] = {
                        up: 0,
                        flat: 0,
                        down: 0,
                    };
                }
                if (!kpiDirections[displayKpi]) {
                    kpiDirections[displayKpi] = {
                        up: 0,
                        flat: 0,
                        down: 0,
                    };
                }
                kpiDirections[displayKpi].up += dkpiDayTrend.up;
                kpiDirections[displayKpi].flat += dkpiDayTrend.flat;
                kpiDirections[displayKpi].down += dkpiDayTrend.down;
                kpiDirections[baseKpi].up += dkpiDayTrend.up;
                kpiDirections[baseKpi].flat += dkpiDayTrend.flat;
                kpiDirections[baseKpi].down += dkpiDayTrend.down;
            }
        }
    }
    return kpiDirections;
}

export function getReducedRollingStats(rolling: IRollingStats,
                                       weights: { [ indicator: string ]: number }): { [ date: string ]: IRollingByDay } {
    const ret: { [ date: string ]: IRollingByDay } = Object.keys(rolling)
        .filter((ind) => {
            // Dynamically initialize so that data for an indicator we haven't seen will be included
            if (weights[ind] === undefined) {
                weights[ind] = 1;
            }
            return weights[ind] !== 0;
        })
        .reduce((acc, ind) => {
            const weightMultiplier = (weights[ind] ?? 1);
            for (const date of Object.keys(rolling[ind])) {
                const d = rolling[ind][date];
                if (!acc[date]) {
                    acc[date] = { up: 0, down: 0, flat: 0, ptpSum: 0, totWeight: 0, mentions: [], dayMentions: [], dayDown: 0, dayUp: 0, dayFlat: 0 };
                }
                const mentions = d.mentions;
                const dayMentions = d.dayMentions;
                acc[date].up += d.up;
                acc[date].down += d.down;
                acc[date].flat += d.flat;
                acc[date].mentions.push(...mentions);
                acc[date].dayUp += d.dayUp;
                acc[date].dayDown += d.dayDown;
                acc[date].dayFlat += d.dayFlat;
                acc[date].dayMentions.push(...dayMentions);
                if (d.ptp !== null && !isNaN(d.ptp)) {
                    const w = weightMultiplier * (d.up + d.down);
                    acc[date].ptpSum += w * d.ptp;
                    acc[date].totWeight += Math.abs(w);
                }
            }
            return acc;
        }, {});
    return ret;
}
