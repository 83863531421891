import { DEFAULT_FORMATTING } from './dateUtil';

// Change an underscore separated name into a nice camel case name
export function camelCaseName(name: string, fallback: string = 'Unknown', indReplace: string = '') {
    if (name) {
        let prettyName = '';
        const tokens = name.split('-');
        for (let t of tokens) {
            // 'world' removed as an exclusion July 2021 - the streaming
            // system should be converting world to star unless there is no
            // company or industry
            if (t.trim() !== '' && t.trim() !== '*') {
                // Don't show underscore 2, etc.
                t = t.replace(/_2$/g, '');
                t = t.replace(/_1$/g, indReplace);
                prettyName += t.split('_').map((w) => w ? w[0].toUpperCase() + w.substr(1) : '').join(' ');
                prettyName += ' ';
            }
        }
        if (prettyName.length) {
            prettyName = prettyName.substr(0, prettyName.length - 1);
        }
        return prettyName || fallback;
    }
    return fallback;
}

// Format a number in a nice way from a string
export function prettyNumber(numString: string) {
    // Note - if a percentage is passed in it will parse but the symbol will be lost.
    const num = Number.parseFloat(numString);
    return prettyNum(num);
}

// Format from a number back to a string, with options for min and max fraction digits
export function prettyNum(num: number, minimumFractionDigits: number = 0, maximumFractionDigits: number = 2, userFormatting?: string): string {
    if (!isNaN(num) && num !== null) {
        let lang = userFormatting || DEFAULT_FORMATTING;

        // Default to browser settings if there is a browser
        const navType = typeof navigator;
        if (navType !== 'undefined') {
            lang = navigator.language;
        }
        return num.toLocaleString(lang, { minimumFractionDigits, maximumFractionDigits });
    }
    else {
        return '';
    }
}

// Convert numeric span from a number to a x/y string
function formatSpan(span: number): string {
    if (span) {
        if (span >= 365) {
            return ' y/y';
        }
        else if (span >= 90) {
            return ' q/q';
        }
        else if (span >= 28) {
            return ' m/m';
        }
        else if (span >= 7) {
            return ' w/w';
        }
        else if (span >= 2) {
            return ` ${span} days`;
        }
        else {
            return ` ${span} day`;
        }
    }
    return '';
}

export function formatTrend(name: string, relative: number, absolute: number, unit: string, span: number) {
    let realName = name || 'Trend';

    let positive = true;
    if (Number.isFinite(absolute) && absolute !== 0) {
        if (absolute < 0) {
            positive = false;
        }
        realName = prettyNumber(absolute.toString());
        if (Number.isFinite(relative) && relative !== 0) {
            if (relative < 0) {
                positive = false;
            }
            realName += ` (${prettyNumber(relative.toString())}&#37;)`;
        }
        let formatStart = '<span style=\'color: ';
        formatStart += positive ? 'green' : 'red';
        realName = `${formatStart}'>${realName}</span>`;
    }
    else if (Number.isFinite(relative) && relative !== 0) {
        if (relative < 0) {
            positive = false;
        }
        realName = prettyNumber(relative.toString()) + '&#37;';
        let formatStart = '<span style=\'color: ';
        formatStart += positive ? 'green' : 'red';
        realName = `${formatStart}'>${realName}</span>`;
    }
    if (unit) {
        realName += ' ' + unit;
    }
    realName += formatSpan(span);
    return realName;
}

export function formatEcl(name: string, relative: number) {
    let realVal = null;
    if (Number.isFinite(relative) && (relative < -1 || relative > 1)) {
        realVal = prettyNumber(relative.toString()) + '&#37;';
    }

    const tokens = name.split(' ');
    let result = '';
    let first = true;
    for (const t of tokens) {
        if (!first) {
            result += ' ';
        }

        if ('&#x2198;' === t) {
            result += '<span style="color: red;">' + t;
            if (realVal) {
                result += ' ' + realVal;
            }
            result += '</span>';
        }
        else if ('&#x2197;' === t) {
            result += '<span style="color: green;">' + t;
            if (realVal) {
                result += ' ' + realVal;
            }
            result += '</span>';
        }
        else {
            result += t;
        }
        first = false;
    }

    return result;
}

// Allow lines to wrap based on a length and newline delimiter
export function getWrappedText(textToWrap: string, maxCharactersPerLine: number, lineDelimiter: string): string {
    const regex = new RegExp(`(?![^\\n]{1,${maxCharactersPerLine}}$)([^\\n]{1,${maxCharactersPerLine}})\\s`, 'g');
    const wrappedText = textToWrap.replace(regex, `$1${lineDelimiter}`);
    return wrappedText;
}

// Get a function that can be passed to Array.sort that can sort two objects by the same string property
// Also allows an optional second string property to be used as a "then by" order.
export function sortObjByStrPropFn(orderProp: string, thenByProp?: string): (a: object, b: object) => number {
    return (a: object, b: object) => {
        if (a[orderProp] < b[orderProp]) {
            return -1;
        }
        if (a[orderProp] > b[orderProp]) {
            return 1;
        }
        if (thenByProp && a[thenByProp] < b[thenByProp]) {
            return -1;
        }
        if (thenByProp && a[thenByProp] > b[thenByProp]) {
            return 1;
        }
        return 0;
    };
}
