import { IApiHostInfo } from '../../../shared/interfaces/api/apiContracts';
import { ILoginconfig } from '../../../shared/interfaces/user/user';
import { ajaxGet } from '../ajax';

export function rootGetCoronaDescriptions() {
    return ajaxGet<{ description: string, takeaways: string[] }[]>('/coronaDescriptions.json', null, false);
}

export function rootGetKpiExplore(cycle: string) {
    return ajaxGet<string>(`/kpiExplore${cycle}.csv`, null, false);
}

export function rootGetOilSensitivitiesLarge() {
    return ajaxGet<string>('/oilSensitivitiesLarge.csv', undefined, false);
}

export function rootGetApiHost() {
    return ajaxGet<IApiHostInfo>('/apiHost', null, false);
}

export function rootGetLoginConfig() {
    return ajaxGet<ILoginconfig>('/loginConfig');
}
