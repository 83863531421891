/*
 * A simple and consistent way to get a logger with a name based on the path of the requesting module.
 * Should only be used by Causality Link code inside the 'src' folder and sub folders.
 */

import debug from 'debug';

const logLevel: string = (global.process && global.process.env.CL_LOG_LEVEL) ?? 'debug';
const includePid: boolean = (global.process?.pid && !global.process.env.AWS_LAMBDA_FUNCTION_NAME) ?? false;
const levels: {[key: string]: number} = { error: 0, warn: 1, info: 2, debug: 3 };
// This is a duplicate of what we have in envConf to avoid importing backend resources in this lib, meant for both front and backend.
const devMode: boolean = (!global.process || global.process.env.NODE_ENV === 'development');

export function getClLogger(namespace: string): DLogger {
    if (namespace) {
        // If the namespace was prefixed and set (instead of passing in a filename/path), leave it alone.
        if (namespace.indexOf('causality:') === -1) {
            const distIdx = namespace.indexOf('dist');
            // On server side filename is full path and we want to reduce it to the relative path without extension.
            if (distIdx > -1 && namespace.length > distIdx + 5) {
                namespace = `causality:${namespace.substr(distIdx + 5, namespace.length - distIdx - 8)}`;
            }
            // Fall back so that log messages are not lost.
            else {
                namespace = `causality:${namespace}`;
            }
        }
    }
    else {
        namespace = 'causality:BADMODULE';
    }
    return new DLogger(namespace);
}

class DLogger {
    private logger: debug.Debugger;
    private loggerError: debug.Debugger;
    private logLevelNum: number;
    constructor(namespace: string) {
        debug.enable('causality:*');
        this.logLevelNum = levels[logLevel];
        if (!Number.isInteger(this.logLevelNum)) {
            this.logLevelNum = levels.debug;
        }
        this.logger = debug(namespace);
        this.loggerError = debug(namespace + ':error');
    }

    public error(...args: any[]) {
        if (this.logLevelNum >= levels.error) {
            this.loggerError.apply(null, getArgs(args));
        }
    }

    public debugIfDev(...args: any[]) {
        if (devMode) {
            this.debug(...args);
        }
    }

    public debug(...args: any[]) {
        if (this.logLevelNum >= levels.debug) {
            this.logger.apply(null, getArgs(args));
        }
    }

    public info(...args: any[]) {
        if (this.logLevelNum >= levels.info) {
            this.logger.apply(null, getArgs(args));
        }
    }

    public warn(...args: any[]) {
        if (this.logLevelNum >= levels.warn) {
            this.logger.apply(null, getArgs(args));
        }
    }
}

function getArgs(args: any[]): any[] {
    if (devMode) {
        return includePid ? [ global.process.pid, ...args ] : args;
    }
    // In 'prod' mode we log to Cloudwatch and do not want newlines, so use the built in j formatter, JSON that can be parsed.
    else {
        return includePid ? [ '%j', { pid: global.process.pid, args } ] : [ '%j', { args } ];
    }
}
