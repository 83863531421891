function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_classes(s,r){return Array.isArray(s)?pug_classes_array(s,r):s&&"object"==typeof s?pug_classes_object(s):s||""}
function pug_classes_array(r,a){for(var s,e="",u="",c=Array.isArray(a),g=0;g<r.length;g++)(s=pug_classes(r[g]))&&(c&&a[g]&&(s=pug_escape(s)),e=e+u+s,u=" ");return e}
function pug_classes_object(r){var a="",n="";for(var o in r)o&&r[o]&&pug_has_own_property.call(r,o)&&(a=a+n+o,n=" ");return a}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;
function pug_style(r){if(!r)return"";if("object"==typeof r){var t="";for(var e in r)pug_has_own_property.call(r,e)&&(t=t+e+":"+r[e]+";");return t}return r+""}function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (allowClose, header, modalSize, uniqueId, white) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"modal fade\""+pug_attr("id", `modal${uniqueId}`, true, true)+" tabindex=\"-1\" role=\"dialog\""+pug_attr("aria-labelledby", (header ? `modal${uniqueId}Header` : `modal${uniqueId}`), true, true)+" aria-hidden=\"true\"") + "\u003E\u003Cdiv" + (pug_attr("class", pug_classes(["modal-dialog",modalSize ? `modal-${modalSize}` : ''], [false,true]), false, true)+" role=\"document\"") + "\u003E\u003Cdiv" + (" class=\"modal-content\""+pug_attr("style", pug_style(`${white ? 'background-color:white' : ''}`), true, true)) + "\u003E\u003Cdiv" + (pug_attr("class", pug_classes([header ? 'modal-header' : ''], [true]), false, true)) + "\u003E";
if (header) {
pug_html = pug_html + "\u003Ch4" + (" class=\"modal-title\""+pug_attr("id", `modal${uniqueId}Header`, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = header) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E";
}
else {
pug_html = pug_html + "\u003Ch4" + (" class=\"modal-title\""+pug_attr("id", `modal${uniqueId}Header`, true, true)) + "\u003E\u003C\u002Fh4\u003E";
}
if (allowClose) {
pug_html = pug_html + "\u003Cbutton class=\"close\" type=\"button\" data-bs-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"container-fluid\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "allowClose" in locals_for_with ?
        locals_for_with.allowClose :
        typeof allowClose !== 'undefined' ? allowClose : undefined, "header" in locals_for_with ?
        locals_for_with.header :
        typeof header !== 'undefined' ? header : undefined, "modalSize" in locals_for_with ?
        locals_for_with.modalSize :
        typeof modalSize !== 'undefined' ? modalSize : undefined, "uniqueId" in locals_for_with ?
        locals_for_with.uniqueId :
        typeof uniqueId !== 'undefined' ? uniqueId : undefined, "white" in locals_for_with ?
        locals_for_with.white :
        typeof white !== 'undefined' ? white : undefined));
    ;;return pug_html;};module.exports=template;