import { IStoredECL, ITargetLinkStat } from '../../../shared/interfaces/dashboard';
import { IFavorableMarker } from '../../../shared/interfaces/marker/marker';
import { ajaxGet } from '../ajax';
import { readThroughCacheIDBDatabase } from '../util/storageUtil';

export async function graphGetTargetsByConcept(concept: string, before: string) {
    const url = '/graph/targetsByConcept/:concept';
    const key = url + concept + before;
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxGet<ITargetLinkStat[]>(url, { concept, before });
    });
}

export async function graphGetSummaryModel(concept: string, before: string) {
    const url = '/graph/summaryModel/:concept';
    const key = url + concept + before;
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxGet<{result: IStoredECL[][], after: string}>(url, {
            concept,
            before,
        });
    });
}

export function graphGetSqsStats() {
    return ajaxGet<string[][]>('/graph/sqsStats');
}

export async function graphGetKpis() {
    const url = '/graph/kpis';
    return await readThroughCacheIDBDatabase(url, async () => {
        return await ajaxGet<IFavorableMarker[]>(url);
    });
}

export async function graphGetBaseEvents() {
    const url = '/graph/baseEvents';
    return await readThroughCacheIDBDatabase(url, async () => {
        return await ajaxGet<IFavorableMarker[]>(url);
    });
}
