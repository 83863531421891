// Return the current element with all parents in the hierarchy
// Useful for checking visibility on the base element or for finding a container
export function getElementGenealogy(element: HTMLElement): HTMLElement[] {
    // Case 1: no element
    if (!element) {
        return [];
    }
    // Case 2: element, no parent
    else if (element && !element.parentElement) {
        return [ element ];
    }
    // Case 3: element + parent
    else {
        return [ element ].concat(getElementGenealogy(element.parentElement));
    }
}
