import { IMention, IMentionStats, Mention } from '../interfaces/article/mentions';
import { IStatsDetail, IStatsRow, IStoredECL } from '../interfaces/dashboard';
import { IComplexMarker } from '../interfaces/marker/marker';
import {
    ConceptCount, ConceptCountCol, DailyCount, DailyCountCol, ICitation, IConceptCount, IDailyCount, ILink, IProperties, ISignalInfo,
    IStoredConceptStat, IStoredDailyStat, LinkCol, MentionCol, UnifiedLink,
} from '../interfaces/unified';
import { calculateDaysInMillis, getYearMonthDayOfISODate } from '../util/dateUtil';

function descriptorsToComplexMarker(descriptors: string[][]): IComplexMarker {
    if (descriptors.length === 1) {
        return {
            base: descriptors[0][6], // base
            company: descriptors[0][0], // company
            location: descriptors[0][1], // location
            industry: descriptors[0][2], // industry
            segment: descriptors[0][3], // segment
            product: descriptors[0][5], // product
            person: '*', // person
            extra: new Set([ descriptors[0][4] ].filter((d) => d !== '*')), // feature
        };
    }
    else if (descriptors.length === 2) {
        return {
            base: descriptors[1][5], // base
            company: descriptors[1][0], // company
            location: descriptors[1][1], // location
            industry: '*', // industry
            segment: descriptors[1][3], // segment
            product: descriptors[1][4], // product
            person: descriptors[1][2], // person
            extra: new Set([ descriptors[0][0], descriptors[0][1], descriptors[0][2] ]
                .filter((d) => d !== '*')), // feature
        };
    }
    else {
        throw new Error(`Code information cannot be deserialized, invalid descriptor pattern`);
    }
}

function encodedCitationToCitation(datehour: string, articleref: string): ICitation {
    const pubBreak = articleref.indexOf('_');
    const idBreak = articleref.lastIndexOf('/');
    const metaIdTokens = articleref.substr(idBreak + 1).split('-');
    return {
        date: getYearMonthDayOfISODate(datehour),
        hour: datehour.substr(11, 2),
        publisher: articleref.substr(0, pubBreak),
        article: articleref.substr(pubBreak + 1, idBreak - (pubBreak + 1)),
        language: metaIdTokens[0],
        field: metaIdTokens[1],
        paragraph: metaIdTokens[2],
        sentence: metaIdTokens[3],
        meta: metaIdTokens.slice(4).join('-'),
    };
}

// Allows the wire array format to be converted back to a full JS interface object
export function MentionColToGraphMention(m: Mention): IStatsRow {
    const data = {
        aggregatedDate: m[MentionCol.datehour] + m[MentionCol.code],
        conceptKey: m[MentionCol.conceptKey],
        date: m[MentionCol.datehour].substring(0, m[MentionCol.datehour].indexOf('T')),
    };
    data[m[MentionCol.articleref]] = { o: m[MentionCol.offset], s: m[MentionCol.span] };
    if (m[MentionCol.value]) {
        data[m[MentionCol.articleref]].v = m[MentionCol.value];
    }
    if (m[MentionCol.direction]) {
        data[m[MentionCol.articleref]].d = m[MentionCol.direction];
    }
    return data;
}

// Allows the wire array format to be converted back to a full JS interface object
export function MentionColToMention(m: Mention): IMention {
    const descriptors = codeToTokens(m[MentionCol.code]);
    return {
        conceptKey: m[MentionCol.conceptKey],
        ...encodedCitationToCitation(m[MentionCol.datehour], m[MentionCol.articleref]),
        offset: m[MentionCol.offset],
        direction: m[MentionCol.direction],
        value: m[MentionCol.value],
        span: m[MentionCol.span],
        ...descriptorsToComplexMarker(descriptors),
        forecast: m[MentionCol.offset] > 0,
        up: m[MentionCol.direction] > 0,
        down: m[MentionCol.direction] < 0,
    };
}

export function LinkColToLink(l: UnifiedLink): ILink {
    const sourceBDescript = codeToTokens(l[LinkCol.fromCode]);
    const sourceDDescript = codeToTokens(l[LinkCol.fromDisplay]);
    const targetBDescript = codeToTokens(l[LinkCol.toCode]);
    const targetDDescript = codeToTokens(l[LinkCol.toDisplay]);
    return {
        sourceKey: l[LinkCol.sourceKey],
        targetKey: l[LinkCol.targetKey],
        ...encodedCitationToCitation(l[LinkCol.datehour], l[LinkCol.articleref]),
        offset: l[LinkCol.offset],
        direction: l[LinkCol.direction],
        value: l[LinkCol.value],
        span: 0,
        forecast: l[LinkCol.offset] > 0,
        up: l[LinkCol.direction] > 0,
        down: l[LinkCol.direction] < 0,
        sourceBase: descriptorsToComplexMarker(sourceBDescript),
        sourceDisplay: descriptorsToComplexMarker(sourceDDescript),
        targetBase: descriptorsToComplexMarker(targetBDescript),
        targetDisplay: descriptorsToComplexMarker(targetDDescript),
    };
}

export function LinkColToGraph(l: UnifiedLink) {
    const arTokens = (l[LinkCol.articleref]).split('/')[1].split('-');
    const origLang = arTokens[0];
    const result: IStoredECL = {
        targetKey: l[LinkCol.targetKey],
        relativeStrength: l[LinkCol.direction],
        dateAndId: l[LinkCol.dateAndId],
        sentence: l[LinkCol.sentence],
        negation: l[LinkCol.value],
        toDisplay: l[LinkCol.toDisplay],
        sourceKey: l[LinkCol.sourceKey],
        date: (l[LinkCol.datehour]).split('T')[0],
        fromCode: l[LinkCol.fromCode],
        fromDisplay: l[LinkCol.fromDisplay],
        origLang,
        toCode: l[LinkCol.toCode],
        future: l[LinkCol.offset],
    };
    return result;
}

// The mapping of Concept and IConcept is really direct, we essentially save the repetition of the property names
export function DailyCountColToDaily(c: DailyCount): IDailyCount {
    return {
        conceptKey: c[DailyCountCol.conceptKey],
        date: c[DailyCountCol.date],
        isevent: Boolean(c[DailyCountCol.isevent]),
        current: c[DailyCountCol.current],
        outlook: c[DailyCountCol.outlook],
        up_current: c[DailyCountCol.up_current],
        down_current: c[DailyCountCol.down_current],
        positive_current: c[DailyCountCol.positive_current],
        negative_current: c[DailyCountCol.negative_current],
        flat_current: c[DailyCountCol.flat_current],
        up_outlook: c[DailyCountCol.up_outlook],
        down_outlook: c[DailyCountCol.down_outlook],
        positive_outlook: c[DailyCountCol.positive_outlook],
        negative_outlook: c[DailyCountCol.negative_outlook],
        flat_outlook: c[DailyCountCol.flat_outlook],
    };
}

// The mapping of Concept and IConcept is really direct, we essentially save the repetition of the property names
export function ConceptCountColToConcept(c: ConceptCount): IConceptCount {
    return {
        concept: c[ConceptCountCol.concept],
        date: c[ConceptCountCol.date],
        topics: c[ConceptCountCol.topics],
        current_indicator: c[ConceptCountCol.current_indicator],
        current_event: c[ConceptCountCol.current_event],
        outlook_indicator: c[ConceptCountCol.outlook_indicator],
        outlook_event: c[ConceptCountCol.outlook_event],
        up_current: c[ConceptCountCol.up_current],
        down_current: c[ConceptCountCol.down_current],
        positive_current_indicator: c[ConceptCountCol.positive_current_indicator],
        positive_current_event: c[ConceptCountCol.positive_current_event],
        negative_current_indicator: c[ConceptCountCol.negative_current_indicator],
        negative_current_event: c[ConceptCountCol.negative_current_event],
        flat_current_indicator: c[ConceptCountCol.flat_current_indicator],
        flat_current_event: c[ConceptCountCol.flat_current_event],
        up_outlook: c[ConceptCountCol.up_outlook],
        down_outlook: c[ConceptCountCol.down_outlook],
        positive_outlook_indicator: c[ConceptCountCol.positive_outlook_indicator],
        positive_outlook_event: c[ConceptCountCol.positive_outlook_event],
        negative_outlook_indicator: c[ConceptCountCol.negative_outlook_indicator],
        negative_outlook_event: c[ConceptCountCol.negative_outlook_event],
        flat_outlook_indicator: c[ConceptCountCol.flat_outlook_indicator],
        flat_outlook_event: c[ConceptCountCol.flat_outlook_event],
    };
}

export function mentionsToSignalSlow(mentions: IMention[], window: number, decay: number) {
    const scoreCard: { [ date: string ]: { count: number, attention: number, up: number, down: number, flat: number } } = {};
    for (const mention of mentions) {
        // Each mention decays over the course of the entire window
        const baseDate = new Date(`${mention.date}T00:00:00Z`).getTime();
        for (let i = 0; i < window; i++) {
            const target = getYearMonthDayOfISODate(new Date(baseDate + i * calculateDaysInMillis(1)).toISOString());
            const weight = Math.pow(2, i / (-1.0 * decay));
            if (scoreCard[target] === undefined) {
                scoreCard[target] = { count: 0, attention: 0, up: 0, down: 0, flat: 0 };
            }
            scoreCard[target].count++;
            scoreCard[target].attention += weight;
            if (mention.direction > 0) {
                scoreCard[target].up += weight;
            }
            else if (mention.direction < 0) {
                scoreCard[target].down += weight;
            }
            else {
                scoreCard[target].flat += weight;
            }
        }
    }
    return scoreCard;
}

function mentionsToScoreCard(mentions: (IProperties & { base?: string, date: string, publisher: string })[],
                             window: number,
                             decay: number,
                             mode: 'trends' | 'mentions',
                             weights?: { [column: string]: { [base: string]: number } }): { max: number, relDate: number, scoreCard: { [offset: number]: ISignalInfo } } {
    const scoreCard: { [offset: number]: ISignalInfo } = {};
    if (!mentions || !mentions.length) {
        return { max: 0, relDate: undefined, scoreCard };
    }
    const relDate = new Date(`${mentions[0].date}T00:00:00Z`).getTime();
    // Precompute how much each mention is worth 0-x days in the future
    const decayWeight = [];
    for (let i = 0; i < window; i++) {
        decayWeight[i] = Math.pow(2, i / (-1.0 * decay));
    }
    // Track the most recent relative date.
    let max = 0;
    for (const mention of mentions) {
        const baseDate = new Date(`${mention.date}T00:00:00Z`).getTime();
        // Figure out how many seconds between this mention and our benchmark
        const key = baseDate - relDate;
        max = Math.max(max, key);
        const kpiWeight = weights?.base ? weights.base?.[mention.base] ?? 0 : 1.0;
        const pubWeight = weights?.publisher ? weights.publisher?.[mention.publisher] ?? 0 : 1.0;
        // Each mention decays over the course of the entire window
        for (let i = 0; i < window; i++) {
            const samedate = i === 0;
            const target = key + (i * calculateDaysInMillis(1));
            let weight = decayWeight[i] * kpiWeight * pubWeight;
            // Invert when weight is -1 (same as 'unfavorable')
            let up = mention.up;
            let down = mention.down;
            // Event and indicator mentions measure percentage of favorable (weight > 0)
            if (mode !== 'trends') {
                if (weight > 0) {
                    up = true;
                }
                else if (weight < 0) {
                    down = true;
                    weight = weight * -1.0;
                }
            }
            // Whether a trend is good or bad is a function of both weight and direction
            else if (weight < 0) {
                // Only invert where direction, not flat
                if (up || down) {
                    up = !up;
                    down = !down;
                }
                weight = weight * -1.0;
            }
            // All the metrics we will track
            if (scoreCard[target] === undefined) {
                scoreCard[target] = {
                    count_window: 0,
                    attention_all: 0,
                    attention_outlook: 0,
                    volume_daily: 0,
                    positive_daily: 0,
                    negative_daily: 0,
                    flat_daily: 0,
                    positive_outlook_daily: 0,
                    negative_outlook_daily: 0,
                    flat_outlook_daily: 0,
                    positive_weight: 0,
                    negative_weight: 0,
                    flat_weight: 0,
                    positive_outlook_weight: 0,
                    negative_outlook_weight: 0,
                    flat_outlook_weight: 0,
                };
            }
            // Base stuff for all mentions
            scoreCard[target].count_window++;
            scoreCard[target].attention_all += weight;
            if (up) {
                scoreCard[target].positive_weight += weight;
            }
            else if (down) {
                scoreCard[target].negative_weight += weight;
            }
            else {
                scoreCard[target].flat_weight += weight;
            }
            // Track counts for mentions on the target date (not decayed)
            if (samedate) {
                scoreCard[target].volume_daily++;
                if (up) {
                    scoreCard[target].positive_daily++;
                }
                else if (down) {
                    scoreCard[target].negative_daily++;
                }
                else {
                    scoreCard[target].flat_daily++;
                }
                if (mention.forecast) {
                    if (up) {
                        scoreCard[target].positive_outlook_daily++;
                    }
                    else if (down) {
                        scoreCard[target].negative_outlook_daily++;
                    }
                    else {
                        scoreCard[target].flat_outlook_daily++;
                    }
                }
            }
            // Separate set of stats for mentions talking about the future
            if (mention.forecast) {
                scoreCard[target].attention_outlook += weight;
                if (up) {
                    scoreCard[target].positive_outlook_weight += weight;
                }
                else if (down) {
                    scoreCard[target].negative_outlook_weight += weight;
                }
                else {
                    scoreCard[target].flat_outlook_weight += weight;
                }
            }
        }
    }
    return { max, relDate, scoreCard };
}

// Optimized function to convert mentions to signal statistics, with the ability to
// specify the window for each day, the half life (decay) over time, and to specify custom
// weights that are a combination of 'favorable' and weighting.
export function mentionsToSignal(mentions: (IProperties & { base?: string, date: string, publisher: string })[],
                                 window: number,
                                 decay: number,
                                 mode: 'trends' | 'mentions',
                                 weights?: { [column: string]: { [base: string]: number } }): { [date: string]: ISignalInfo } {
    const { max, relDate, scoreCard } = mentionsToScoreCard(mentions, window, decay, mode, weights);
    // Now we have to convert our relative date lookup back to ISO days. At the same time
    // generate the PTPs since the data should now be finalized.
    return Object.keys(scoreCard).reduce((p: { [date: string]: ISignalInfo }, n) => {
        const num = +n;
        // Don't project beyond the last date with a mention.
        if (num <= max) {
            const newKey = getYearMonthDayOfISODate(new Date(num + relDate).toISOString());
            p[newKey] = scoreCard[n];
            p[newKey].score_daily = (p[newKey].positive_daily + 1.0) /
                (p[newKey].negative_daily + p[newKey].positive_daily + 2.0);
            p[newKey].score = (p[newKey].positive_weight + 1.0) /
                (p[newKey].negative_weight + p[newKey].positive_weight + 2.0);
            p[newKey].score_current = (p[newKey].positive_weight - p[newKey].positive_outlook_weight + 1.0) /
                (p[newKey].negative_weight + p[newKey].positive_weight -
                    p[newKey].positive_outlook_weight - p[newKey].negative_outlook_weight + 2.0);
            p[newKey].score_outlook = (p[newKey].positive_outlook_weight + 1.0) /
                (p[newKey].negative_outlook_weight + p[newKey].positive_outlook_weight + 2.0);
        }
        return p;
    }, {});
}

export function mentionsToScore(mentions: (IProperties & { base?: string, date: string, publisher: string })[],
                                window: number,
                                decay: number,
                                forDate: string,
                                mode: 'trends' | 'mentions',
                                weights?: { [column: string]: { [base: string]: number } }): ISignalInfo {
    const { relDate, scoreCard } = mentionsToScoreCard(mentions, window, decay, mode, weights);
    const baseDate = new Date(`${forDate}T00:00:00Z`).getTime();
    // Figure out how many seconds between this mention and our benchmark
    const key = baseDate - relDate;
    if (scoreCard[key]) {
        const result = scoreCard[key];
        result.score_daily = (result.positive_daily + 1.0) /
            (result.negative_daily + result.positive_daily + 2.0);
        result.score = (result.positive_weight + 1.0) /
            (result.negative_weight + result.positive_weight + 2.0);
        result.score_current = (result.positive_weight - result.positive_outlook_weight + 1.0) /
            (result.negative_weight + result.positive_weight -
                result.positive_outlook_weight - result.negative_outlook_weight + 2.0);
        result.score_outlook = (result.positive_outlook_weight + 1.0) /
            (result.negative_outlook_weight + result.positive_outlook_weight + 2.0);
        return result;
    }
    return undefined;
}

// Given an array of mentions, quickly itemizes the statistics and breakdown of each
// independent property. Used before and after filtering to let a user drill down.
export function measureMentionStats(mentions: (IComplexMarker & ICitation)[]): IMentionStats {
    const stats: IMentionStats = {
        publisher: {},
        article: {},
        language: {},
        field: {},
        base: {},
        company: {},
        location: {},
        industry: {},
        segment: {},
        product: {},
        person: {},
        extra: {},
    };
    const measure = (val: string, lookup: { [ val: string ]: number }) => {
        if (!lookup[val]) {
            lookup[val] = 0;
        }
        lookup[val]++;
    };
    for (const mention of mentions) {
        measure(mention.publisher, stats.publisher);
        measure(mention.article, stats.article);
        measure(mention.language, stats.language);
        measure(mention.field, stats.field);
        measure(mention.base, stats.base);
        measure(mention.company, stats.company);
        measure(mention.location, stats.location);
        measure(mention.industry, stats.industry);
        measure(mention.segment, stats.segment);
        measure(mention.product, stats.product);
        measure(mention.person, stats.person);
        if (mention.extra) {
            for (const extra of mention.extra) {
                measure(extra, stats.extra);
            }
        }
    }
    return stats;
}

// Quick way to turn a complex serialized code from Dynamo into 2d arrays
// where colons are used between objects (from and to) and dashes separate markers.
function codeToTokens(code: string): string[][] {
    return code.split(':').map((outer) => outer.split('-'));
}

// Useful to use a generator pattern due to size of data, saving so many deep array copies.
function* statRowToMention(row: IStatsRow): Generator<Mention> {
    const dateHour = row.aggregatedDate.substr(0, 13);
    const code = row.aggregatedDate.substr(13);
    for (const attribute of Object.keys(row)) {
        const val = row[attribute];
        if (typeof (val) === 'object' && val) {
            yield statRowAttributeToMention(row.conceptKey, dateHour, code, attribute, val);
        }
    }
    return;
}

export function storedEclToLink(row: IStoredECL): UnifiedLink {
    const firstUnder = row.dateAndId.indexOf('_');
    const lastUnder = row.dateAndId.lastIndexOf('_');
    const fullDate = row.dateAndId.substring(0, firstUnder);
    const fullMeta = row.dateAndId.substring(lastUnder + 1);
    const fullArticle = row.dateAndId.substring(firstUnder + 1, lastUnder);
    const lang = row.origLang || 'en';
    const position = fullMeta.indexOf('Body') >= 0 ? 'b' : fullMeta.indexOf('Grid') >= 0 ? 'g' : 't';
    const positionTokens = row.sentence.split('-').map((t) => t.substring(1));
    // Prolog considers the title to be paragraph '0' and the first body paragraph to be paragraph 1
    const paragraphOffset = position === 'b' ? 1 : 0;
    const paragraph = (+positionTokens[0] - paragraphOffset).toString().padStart(3, '0');
    const sentence = (+positionTokens[1] - 1).toString().padStart(3, '0');
    const idx = fullMeta.indexOf('-', fullMeta.indexOf('-') + 1);
    const metaOffset = fullMeta.substring(idx + 1);
    return [
        row.targetKey,
        `${fullArticle}/${lang}-${position}-${paragraph}-${sentence}-${metaOffset}`,
        fullDate.substring(0, 13),
        row.future,
        row.relativeStrength,
        row.negation,
        row.sourceKey,
        row.fromCode,
        row.fromDisplay,
        row.toCode,
        row.toDisplay,
        row.dateAndId,
        row.sentence,
    ];
}

// Rows in dynamo compress the information into key and time information, then mentions by property.
// This is one step in flattening that into a more normalized shape.
function statRowAttributeToMention(conceptKey: string,
                                   dateHour: string,
                                   code: string,
                                   publisherAndArticle: string,
                                   val: IStatsDetail): Mention {
    return [
        conceptKey,
        publisherAndArticle,
        dateHour,
        val.o ?? 0,
        val.d ?? 0,
        val.v ?? 0,
        val.s ?? 0,
        code,
    ];
}

// External helper that turns a stats row from dynamo into N mentions, where N can be 0 - very large, capped by Dynamo row size (400k)
export function* statsRowsToMentions(rows: IStatsRow[]): Generator<Mention> {
    for (const row of rows) {
        yield* statRowToMention(row);
    }
    return;
}

// Converts a JSON object with simple attribute names to the compact array of strings/numbers
export function storedDailyStatToDailyStat(row: IStoredDailyStat): DailyCount {
    return [
        row.conceptkey,
        row.date,
        row.stats[0],
        row.stats[1],
        row.stats[2],
        row.stats[3],
        row.stats[4],
        row.stats[5],
        row.stats[6],
        row.stats[7],
        row.stats[8],
        row.stats[9],
        row.stats[10],
        row.stats[11],
        row.stats[12],
    ];
}

// Converts a JSON object with simple attribute names to the compact array of strings/numbers
export function storedConceptStatToConceptCount(row: IStoredConceptStat): ConceptCount {
    return [
        row.concept,
        row.date,
        row.topics,
        row.stats[0],
        row.stats[1],
        row.stats[2],
        row.stats[3],
        row.stats[4],
        row.stats[5],
        row.stats[6],
        row.stats[7],
        row.stats[8],
        row.stats[9],
        row.stats[10],
        row.stats[11],
        row.stats[12],
        row.stats[13],
        row.stats[14],
        row.stats[15],
        row.stats[16],
        row.stats[17],
        row.stats[18],
        row.stats[19],
    ];
}

// Concept rollup function can return different objects in each row dynamically, so use their length to map properly.
export function DynamicStatMapper(a: ConceptCount | DailyCount): IConceptCount | IDailyCount {
    if (a.length === 23) {
        return ConceptCountColToConcept(a);
    }
    else if (a.length === 15) {
        return DailyCountColToDaily(a);
    }
    throw new Error(`Unexpected length ${a}`);
}

// The total mentions for one day for one concept is the sum of four separate properties we track individually.
export function conceptTotalMentions(total: IConceptCount): number {
    return total ? total.current_event + total.current_indicator + total.outlook_event + total.outlook_indicator : 0;
}

export function conceptPtp(total: IConceptCount): number {
    return (total?.positive_current_indicator + total?.positive_outlook_indicator + 1.0) /
           (total?.positive_current_indicator + total?.positive_outlook_indicator +
            total?.negative_current_indicator + total?.negative_outlook_indicator + 2.0);
}

// Each KPI or event has a number of mentions for past/present (current) and future (outlook).
export function statTotalMentions(total: IDailyCount): number {
    return total ? total.current + total.outlook : 0;
}

export function statPtp(stat: IDailyCount): number {
    return (stat?.positive_current + stat?.positive_outlook + 1.0) /
           (stat?.positive_current + stat?.positive_outlook +
            stat?.negative_current + stat?.negative_outlook + 2.0);
}
