function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (acl, name, roles) {
      pug_html = pug_html + "\u003Ca class=\"nav-link dropdown-toggle\" id=\"userLink\" href=\"javascript:void(0);\" data-bs-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"\u003E\u003Ci class=\"fa-solid fa-user\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Cdiv class=\"dropdown-menu dropdown-menu-end\" aria-labelledby=\"userLink\"\u003E\u003Cdiv class=\"ms-3\"\u003E" + (pug_escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Chr class=\"soft mt-1 mb-1\"\u003E";
if ((acl.hasRole(roles.BookmarksRead) && acl.hasRole(roles.NotesAndSharingRead))) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"favoritesMenuLink\" href=\"\u002Fbookmark\u002Fbookmarks\"\u003E\u003Cspan\u003E\u003Ci class=\"far fa-bookmark fa-fw\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"ms-1\"\u003EFavorites\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
if ((!acl.hasRole(roles.FreeUser) || acl.hasRole(roles.CLAdmin))) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item triggerTimeMachineModal\" id=\"timeMachineMenuLink\" href=\"javascript:void(0);\"\u003E\u003Cspan\u003E\u003Ci class=\"fa fa-history fa-fw\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"ms-1\"\u003ETime Machine\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
if ((acl.hasRole(roles.CLAdmin))) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item triggerStemModal\" id=\"stemmingMenuLink\" href=\"javascript:void(0);\"\u003E\u003Cspan\u003E\u003Ci class=\"fa fa-file-alt fa-fw\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"ms-1\"\u003EStemming\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ca class=\"dropdown-item triggerMetadataMonitorModal\" id=\"monitorMenuLink\" href=\"javascript:void(0);\"\u003E\u003Cspan\u003E\u003Ci class=\"fa fa-signal fa-fw\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"ms-1\"\u003EMonitor\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
if ((acl.hasRole(roles.DaasSso))) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item triggerDaasSsoModal\" id=\"daasSsoMenuLink\" href=\"javascript:void(0);\"\u003E\u003Cspan\u003E\u003Ci class=\"fa fa-notebook fa-fw\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"ms-1\"\u003EDaaS\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
if ((!acl.hasRole(roles.FreeUser) || acl.hasRole(roles.CLAdmin))) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"profileMenuLink\" href=\"\u002Fuser\u002FmyProfile\"\u003E\u003Cspan\u003E\u003Ci class=\"fa fa-user fa-fw\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"ms-1\"\u003EMy Profile\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
if ((acl.hasRole(roles.FreeUser) && !acl.hasRole(roles.CLAdmin))) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"cancelAccount\" type=\"button\" href=\"\u002Ftrial\u002Fcancel\"\u003E\u003Cspan\u003E\u003Ci class=\"fa fa-trash fa-fw\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"ms-1\"\u003EDelete Account\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"logoutMenuLink\" href=\"\u002Flogout\"\u003E\u003Cspan\u003E\u003Ci class=\"fa fa-sign-out fa-fw\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"ms-1\"\u003ELogout\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "acl" in locals_for_with ?
        locals_for_with.acl :
        typeof acl !== 'undefined' ? acl : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "roles" in locals_for_with ?
        locals_for_with.roles :
        typeof roles !== 'undefined' ? roles : undefined));
    ;;return pug_html;};module.exports=template;