import { getClLogger } from '../../../shared/util/clLogger';
import { attr, create, getByClassFirst, getById, modal, onEvent, qsa } from '../../lib/util/html';
import { ModalSizeEnum } from '../interfaces/static';
import { Spinner } from '../widgets/spinner/spinner';
const modalTemplate = require('./modalWidget.pug');
const clLogger = getClLogger(__filename);

const CLASS_PREFIX = 'backdropModal-';
const BACKDROP_SELECTOR = 'div.modal-backdrop';

// A class to help ensure the way we open modals is consistent, that they will show a loading icon while AJAX is being done,
// and that the zIndex will make sure each subsequent modal appears on top of any priors.
export class ClModal {
    private static zIndex = 1100;
    private static id = 100;

    private id: number;
    private myModal: HTMLDivElement;
    private myBody: HTMLDivElement;
    private backdropClass: string;

    // Create a modal of a fixed size and header. If closing is not allowed, there is no X on the top right,
    // and the keyboard or clicking will not close.
    // Render: Return a string which is the body of the modal
    // Wire:   After rendering, the modal body container is passed so that events can be wired
    // Unwire: If something dynamic is done during rendering or wiring, this function can allow us to undo that work.
    public constructor(size: ModalSizeEnum, header: string, allowClose: boolean,
                       private render: (params?: any) => string | Promise<string>,
                       private wire?: (bodyContainer: HTMLDivElement, params?: any) => void,
                       private unwire?: () => void, white?: boolean) {
        this.id = ClModal.id++;
        this.backdropClass = CLASS_PREFIX + this.id;
        const containerDiv = create<HTMLDivElement>('DIV');
        containerDiv.classList.add('clModalContainer');
        if (header === '') {
            header = ' ';
        }
        containerDiv.innerHTML = modalTemplate({ modalSize: size, header, allowClose, uniqueId: this.id, white });
        document.body.append(containerDiv);
        this.myModal = containerDiv.firstChild as HTMLDivElement;
        this.myBody = getByClassFirst<HTMLDivElement>('container-fluid', containerDiv);
        if (!allowClose) {
            attr(this.myModal, 'data-bs-backdrop', 'static');
            attr(this.myModal, 'data-bs-keyboard', 'false');
        }
        onEvent(this.myModal, 'hidden.bs.modal', () => {
            clLogger.debug('The modal was closed: ' + this.id);
            this.myBody.innerHTML = '';
            if (this.unwire) {
                this.unwire();
            }
            if (qsa('.modal.fade.show').length) {
                // modal scroll lost fix
                document.body.classList.add('modal-open');
            }
            this.eliminateBackdrops();
        });
        onEvent(this.myModal, 'shown.bs.modal', () => {
            clLogger.debug('SHOWING MODAL');
            this.tagBackdrops();
        });
    }

    public async open(params?: any) {
        if (!params) {
            params = {};
        }
        this.myModal.style.zIndex = ClModal.zIndex++ + '';
        // Bootstrap 5 has a different API for creating and opening the modal
        const bs5Modal = modal(this.myModal);
        bs5Modal.toggle();
        let spinner = new Spinner(this.myBody);
        try {
            const html = await this.render(params);
            spinner.dismiss();
            this.myBody.innerHTML = html;
            if (this.wire) {
                // need to create a new spinner because the old one was overwritten by this.myBody.innerHTML = html;
                // the new spinner needs to be in a new div to avoid overwriting anything in this.myBody
                const newSpinnerDiv = create('div');
                this.myBody.appendChild(newSpinnerDiv);
                spinner = new Spinner(newSpinnerDiv);
                // This timeout helps plotly generate a correct autosize
                //  by helping ensure that data load times end after Modal render is complete.
                // If the modal has not finished rendering & resizing by the time plotly initializes
                //  plotly can autosize to a smaller chart than will fill the Modal space.
                // This could be applied to individual Modals, but troubleshooting and predictability may
                //  take a hit because it would be harder to track which has help with the race condition.
                setTimeout(async () => {
                    try {
                        await this.wire(this.myBody, params);
                        spinner.dismiss();
                    }
                    catch (err) {
                        spinner.error();
                        throw err;
                    }
                }, 500);
            }
        }
        catch (err) {
            spinner.error();
            throw err;
        }
        return this.getBodyContainer();
    }

    public close() {
        modal(this.myModal, 'hide');
    }

    public changeHeader(text: string) {
        getById(this.getHeaderId()).innerHTML = text;
    }

    private getHeaderId() {
        return this.getModalId() + 'Header';
    }

    private getModalId() {
        return 'modal' + this.id;
    }

    private tagBackdrops() {
        // When editing a marker, deleting a synonym and then adding a new synonym bootstrap is adding duplicated div.modal-backdrop
        // and the modal only references one div.modal-backdrop (not necessarily the first one) leaving orphan divs blocking the background body.
        // To avoid this when creating a new modal get all the div.modal-backdrop not tagged and tag them so it can be removed on close.
        qsa(BACKDROP_SELECTOR).forEach((mb) => {
            if (!mb.classList.toString().includes(CLASS_PREFIX)) {
                mb.classList.add(this.backdropClass);
            }
        });
    }

    public getBodyContainer() {
        return this.myBody;
    }

    private eliminateBackdrops() {
        // Eliminate all div.modal-backdrop tagged for this modal
        qsa(BACKDROP_SELECTOR + '.' + this.backdropClass).forEach((mb) => {
            mb.remove();
        });
        // Just in case, if no modal is open and there are modal-backdrop showing remove them
        if (!qsa('.modal.fade.show').length) {
            qsa(BACKDROP_SELECTOR).forEach((mb) => {
                mb.remove();
            });
        }
    }
}
