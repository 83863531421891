import { UserAgentApplication } from 'msal';
import { Acl } from '../../shared/auth/acl';
import { AuthRoles } from '../../shared/interfaces/acl/acl-i';
import { UxType } from '../../shared/interfaces/metric/metric-i';
import { ICurrentUser, ILoginconfig } from '../../shared/interfaces/user/user';
import { getClLogger } from '../../shared/util/clLogger';
import { prettyDateNoTime, stringToDate } from '../../shared/util/dateUtil';
import { FeatureFlags } from '../../shared/util/featureFlag';
import { setupAdminDMs } from '../apps/admin/adminDmWidget';
import { wireThemeToggle } from '../apps/theme';
import {
    attr, create, getByClass, getByClassFirst, getById, hide, onClick,
    onDomLoaded, onEvent, onInput, onKeyDown, qs, qsa, removeEvent, show, SPACE_KEYCODE, tooltip,
} from '../lib/util/html';
import { ajaxFormOnSubmitWithControl, newEvent } from './ajax';
import { rootGetLoginConfig } from './api/root';
import { servicesPostMetric } from './api/services';
import { Autocomplete } from './autocomplete/autocomplete';
import { isFlagEnabledForView } from './featureFlag';
import { UserFeature } from './interfaces/analytics';
import { IGtagDetails, ModalSizeEnum } from './interfaces/static';
import { setupDM } from './message/dm';
import { ifExistsOnClickTrackAnalyticsEvent, trackAnalyticsEvent } from './util/analytics';
import { getConfirmModal } from './util/confirm';
import { ClModal } from './util/modalWidget';
import { STORAGE_INFO, clearOldCache, fetchCacheKey, removeCacheKey, setCacheKey } from './util/storageUtil';
import { LoginWidget } from './widgets/login/loginWidget';
import { NavWidget } from './widgets/nav/navWidget';
import { NotifyWidget } from './widgets/notify/notifyWidget';
import { TimeMachine } from './widgets/timeMachine/timeMachine';
const genericErrorTemplate = require('../lib/util/genericError.pug');
const loginTmpl = require('../lib/util/login.pug');
const eulaTmpl = require('./userHelp/eula.pug');
const embedVideoTemplate = require('./userHelp/videoframe.pug');

const clLogger = getClLogger(__filename);
const loginWidget = new LoginWidget('userList');
const navWidget = new NavWidget('navDiv');
const notifyWidget = new NotifyWidget('notificationList');
const loginListeners = [];
export const TIME_MACHINE_LOADED_EVENT = 'time-machine-modal.loaded';
export const timeMachine = new TimeMachine(() => location.reload());
let stemWidget = null;
let metadataMonitorWidget = null;
let daasSsoWidget = null;
let unauthorizedUserModal: ClModal = null;
let timeMachineModal: ClModal = null;
let stemModal: ClModal = null;
let metaMonitorModal: ClModal = null;
let daasSsoModal: ClModal = null;
let eulaModal: ClModal = null;
let helpVidsModal: ClModal = null;
let genericErrorModal: ClModal = null;
let enableChatSupportModal: ClModal = null;
// See webpack.common.js on using define plugin to fill this using webpack
declare let GOOGLEANALYTICS: string;
declare let GOOGLEANALYTICS_V4: string;

const dlWindow: Window & { dataLayer?: IArguments[]} = window;
// Create or get a handle to a data layer shared with Google Analytics
const gdata = dlWindow.dataLayer || [];
dlWindow.dataLayer = gdata;

// Used to broadcast a new tag/event with GAs
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function gtag(_key, _val, _details?: IGtagDetails) {
    // eslint-disable-next-line prefer-rest-params
    gdata.push(arguments);
}

export function addLoginListener(func: (acl: Acl) => void) {
    loginListeners.push(func);
    // Deals with a race condition in case the listener is added after we've
    // finished init and already called those that registered prior
    const loginAcl = loginWidget.getAcl();
    if (loginAcl) {
        func(loginAcl);
    }
}

export function getUser(): ICurrentUser {
    return loginWidget.getUser();
}

export function getAcl(): Acl | null {
    return loginWidget.getAcl();
}

function clearCache() {
    setTimeout(() => {
        clearOldCache();
        clearCache();
    }, 60000);
}

/*
 * This is an important entry point for all sort of setup and authentication.
 * It should NEVER be called by a library, only by applications that are webpack entry modules,
 * and only once at the top of those pages.
 */

export function init(skipLogin?: boolean, requiredRoles?: AuthRoles[][], alternativeUrl?: string, timeMachinePage?: boolean, skipFreeUser?: boolean) {
    // This initializes UGA
    gtag('js', new Date());
    onDomLoaded(async () => {
        clearCache();
        clLogger.debug('Loaded...');
        tooltip(document.body, null, { selector: '.trendBar[data-bs-toggle="tooltip"]' });
        tooltip(document.body, null, { selector: '.trendBar[data-bs-toggle="tooltip"]' });
        tooltip(qs('.cl-content'), null, { selector: '.helpBadge[data-bs-toggle="tooltip"]' });

        // Initialize all modals so they are defined
        unauthorizedUserModal = new ClModal(ModalSizeEnum.Normal, undefined, false, () => {
            const loginUserFill = fetchCacheKey(STORAGE_INFO.clTempUser.scope, STORAGE_INFO.clTempUser.key) ||
                fetchCacheKey(STORAGE_INFO.clLastUser.scope, STORAGE_INFO.clLastUser.key);
            removeCacheKey(STORAGE_INFO.clTempUser.scope, STORAGE_INFO.clTempUser.key);
            return loginTmpl({ loginUserFill, pathname: window.location.pathname });
        }, (bodyContainer) => {
            const formLogin = getById<HTMLFormElement>('formLogin');
            const username = getByClassFirst<HTMLInputElement>('cl-username', formLogin);
            const pass = getByClassFirst<HTMLInputElement>('cl-pass', formLogin);
            const user = getByClassFirst<HTMLInputElement>('cl-user', formLogin);
            const accessToken = getByClassFirst<HTMLInputElement>('cl-accessToken', formLogin);
            const errDiv = getByClassFirst('text-danger', formLogin);
            // Deals with better experience when logging in with SSO
            let ssoMode = showPass(false, pass, user, accessToken, username);
            onInput(username, () => {
                ssoMode = showPass(ssoMode, pass, user, accessToken, username);
            });
            ajaxFormOnSubmitWithControl(formLogin, async () => {
                const cleanUser = username.value.trim();
                if (cleanUser.endsWith('@causalitylink.com') && msalSession) {
                    // msalSession.loginPopup seems to be messing with the icon, if I reload the icon after it doesn't work, if I comment msalSession.loginPopup
                    // I don't need to refresh the icon (?)
                    updateImage();
                    try {
                        const token = await msalSession.loginPopup({
                            scopes: externalLoginConfig?.scopes,
                        });
                        user.value = JSON.stringify(msalSession.getAccount());
                        accessToken.value = JSON.stringify(token);
                    }
                    catch (err) {
                        clLogger.error(err);
                        errDiv.firstElementChild.innerHTML = err.message;
                        return false;
                    }
                }
                return true;
            }, (err, result) => {
                if (err) {
                    clLogger.error('Error after submitting form...');
                    clLogger.error(JSON.stringify(err));
                }
                else {
                    if (result.message === 'Success') {
                        errDiv.firstElementChild.innerHTML = '';
                        unauthorizedUserModal.close();
                        const cleanUser = username.value.trim();
                        if (cleanUser.endsWith('@causalitylink.com')) {
                            setCacheKey(STORAGE_INFO.clLastUser.scope, STORAGE_INFO.clLastUser.key, cleanUser);
                        }
                        if (result.redirect) {
                            window.location.href = result.redirect;
                        }
                        else {
                            document.dispatchEvent(newEvent('AuthorizedUser'));
                        }
                    }
                    else {
                        errDiv.firstElementChild.innerHTML = result.message;
                        if (document.activeElement instanceof HTMLInputElement) {
                            const ae = document.activeElement as HTMLInputElement;
                            ae.select();
                        }
                    }
                }
            });
            setTimeout(() => {
                qs('input', bodyContainer).focus();
            }, 500);
        });

        timeMachineModal = new ClModal(ModalSizeEnum.Normal, 'Time Machine', true, () => {
            return '';
        }, (bodyContainer) => {
            timeMachine.mount(bodyContainer, timeMachineModal);
            // Send a message about load completion
            // If this works as a simple and scalable mechanism for load notifications, we can help further build it out
            //  by enumerating available message types
            const html = qs('html');
            if (html) {
                html.dispatchEvent(new Event(TIME_MACHINE_LOADED_EVENT));
            }
        });

        stemModal = new ClModal(ModalSizeEnum.Large, 'Manage Stemming', true, () => {
            return '<div id="stemDiv"></div>';
        }, async () => {
            if (stemWidget === null) {
                const sw = await import(/* webpackChunkName: "stem" */ './widgets/stem/stemWidget');
                stemWidget = new sw.StemWidget('stemDiv');
            }
            await stemWidget.render();
            setTimeout(() => {
                qs('input', getById('stemDiv')).focus();
            }, 500);
        });

        metaMonitorModal = new ClModal(ModalSizeEnum.Large, 'Monitor', true, () => {
            return '<div id="metaMonitorDiv"></div>';
        }, async () => {
            if (metadataMonitorWidget === null) {
                const sw = await import(/* webpackChunkName: "metadataMonitor" */ './widgets/metadataMonitor/metadataMonitorWidget');
                metadataMonitorWidget = new sw.MetadataMonitorWidget('metaMonitorDiv');
            }
            await metadataMonitorWidget.render();
        }, async () => {
            await metadataMonitorWidget.leave();
        });

        daasSsoModal = new ClModal(ModalSizeEnum.Large, 'Data-as-a-Service', true, () => {
            return '<div id="daasSsoWidgetDiv"></div>';
        }, async () => {
            if (daasSsoWidget === null) {
                const ds = await import(/* webpackChunkName: "daasSso" */ './widgets/daasSso/daasSsoWidget');
                daasSsoWidget = new ds.DaasSsoWidget('daasSsoWidgetDiv');
            }
            await daasSsoWidget.render();
        }, async () => {
            await daasSsoWidget.leave();
        });

        eulaModal = new ClModal(ModalSizeEnum.Large, 'End-User License Agreement ("Agreement")', true, () => {
            return eulaTmpl();
        });

        helpVidsModal = new ClModal(ModalSizeEnum.Large, '', true, () => {
            return '';
        }, (bodyContainer, params) => {
            bodyContainer.innerHTML = embedVideoTemplate({ embed: params.embed, width: 740, height: 600 });
        });

        genericErrorModal = new ClModal(ModalSizeEnum.Normal, 'Error', true, (params) => {
            return genericErrorTemplate(params);
        });

        const icons = {
            companyGroup: 'fa-building',
            industryGroup: 'fa-industry',
            location: 'fa-map-marker-alt',
        };

        // Msal has to be setup early on, this allows it to intercept login popup results
        let externalLoginConfig: ILoginconfig;
        let msalSession: UserAgentApplication;
        const contentDiv = getByClassFirst('cl-content');
        if (skipLogin !== true) {
            externalLoginConfig = await getLoginConfig();
            msalSession = new UserAgentApplication({
                auth: {
                    clientId: externalLoginConfig.clientId,
                    authority: externalLoginConfig.authority,
                    redirectUri: window.location.origin + '/home',
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false,
                },
            });
            await loginWidget.render();
            if (loginWidget.user) {
                // Associate the user with this GA session
                gtag('set', { user_id: loginWidget.user.id });
                const acl: Acl = loginWidget.getAcl();
                let notValidRoles = false;
                if (requiredRoles && requiredRoles.length) {
                    notValidRoles = true;
                    for (const r of requiredRoles) {
                        if (r && r.length && acl.hasRoles(r)) {
                            notValidRoles = false;
                        }
                    }
                }
                if (notValidRoles || (skipFreeUser && acl.hasRole(AuthRoles.FreeUser) && !acl.hasRole(AuthRoles.CLAdmin))) {
                    clLogger.debug('The user is missing one or more of the required roles', requiredRoles);
                    // Send previous urls to check for loops
                    let previousPages = '?previousPages=';
                    if (window.location.search.startsWith(previousPages)) {
                        previousPages = `${window.location.search},`;
                    }
                    previousPages += window.location.pathname;
                    if (alternativeUrl && previousPages.toLowerCase().includes(alternativeUrl.toLowerCase())) {
                        alternativeUrl = null;
                    }
                    window.location.replace(`${alternativeUrl || '/401'}${previousPages}`);
                    return;
                }
                await navWidget.render(acl);
                qsa('[data-bs-toggle="tooltip"]')
                    .forEach((elem: HTMLElement) => {
                        tooltip(elem);
                    });
                contentDiv.classList.remove('invisible');
                notifyWidget.render(loginWidget.user.id, acl);
                if (acl.hasRole(acl.getAuthRoles().CLAdmin)) {
                    // Hide all DM-related items to start
                    hide(getById('clientDM'));
                    hide(getById('adminDMs'));
                    hide(getById('adminDM'));
                    hide(getById('adminDmNotifs'));
                    if (getById('clientChatPage')) {
                        await setupAdminDMs(loginWidget.user.id, true);
                    }
                    else if (getById('adminDMs')) {
                        const dmsEnabled = await isFlagEnabledForView(FeatureFlags.enableChat);
                        const dmToggle = fetchCacheKey(STORAGE_INFO.offPageDMs.scope, STORAGE_INFO.offPageDMs.key);
                        if (dmsEnabled) {
                            show(getById('adminDMs'));
                        }
                        if (dmToggle === null) {
                            // If no cache history, default offPageDM support to being off
                            setCacheKey(STORAGE_INFO.offPageDMs.scope, STORAGE_INFO.offPageDMs.key, false);
                        }
                        if (dmToggle) {
                            // Only initiate when not on Client Chat page and when enabled
                            await setupAdminDMs(loginWidget.user.id, false);
                        }
                        else {
                            const dmBtnListener = () => {
                                enableChatSupportModal.open();
                            };
                            // This allows users to message you when on any page, not just the designated Client Chat page.
                            enableChatSupportModal = getConfirmModal('Enable Off-Page Chat Support?', () => `When enabled, clicking the chat bubble
                             icon on the navigation bar will open a windowed view of chat support.
                             This setting will be reset upon logout.`, async () => {
                                removeEvent(getById('adminDMs'), 'click', dmBtnListener);
                                setCacheKey(STORAGE_INFO.offPageDMs.scope, STORAGE_INFO.offPageDMs.key, true);
                                enableChatSupportModal.close();
                                await setupAdminDMs(loginWidget.user.id, false);
                            });
                            onClick(getById('adminDMs'), dmBtnListener);
                        }
                    }
                }
                else {
                    hide(getById('adminDM'));
                    await setupDM(loginWidget.user.id);
                }
                for (const func of loginListeners) {
                    func(acl);
                }
                const helpVids = getByClass('linkHelpVid');
                for (const vid of helpVids) {
                    const embed = attr(vid, 'data-embed');
                    onClick(vid, () => {
                        helpVidsModal.open({ embed });
                    });
                }

                const searchBar = getById<HTMLDivElement>('searchbar');
                if (searchBar) {
                    if (acl.hasRole(acl.getAuthRoles().DriversRead)) {
                        const indicatorSearch = new Autocomplete((term) => {
                            return { url: '/knowledge/indicatorSearch', searchString: term };
                        }, (sugg, index) => {
                            return {
                                auto: sugg.marker,
                                index,
                                html: true,
                                contents: `<i class="me-2 fa ${icons[sugg.type]}"></i><span>${sugg.label}</span>`,
                            };
                        }, false);
                        indicatorSearch.render(searchBar);
                        indicatorSearch.onSpecificSearch.push(async (selected) => {
                            trackAnalyticsEvent(UserFeature.HEADER_SEARCH_INPUT_SUBMIT);
                            await servicesPostMetric(UxType.search, selected.marker);
                            location.href = '/signal/' + selected.marker;
                        });
                        indicatorSearch.onClear.push(() => {
                            trackAnalyticsEvent(UserFeature.HEADER_SEARCH_CLEARBUTTON_CLICK);
                        });
                    }
                    else {
                        searchBar.remove();
                    }
                }
            }

            // Once the user has loaded, wire the toggle for switching the theme of the page
            wireThemeToggle();
            // This sends the GA page view, after we know/associate the user if possible
            gtag('config', GOOGLEANALYTICS);
            gtag('config', GOOGLEANALYTICS_V4);
        }
        else {
            // No user will be associated
            gtag('config', GOOGLEANALYTICS);
            gtag('config', GOOGLEANALYTICS_V4);
        }

        onKeyDown(document, (e: Event) => {
            const searchBar = getById<HTMLDivElement>('searchbar');
            // Control space to quick search
            if (e instanceof KeyboardEvent && e.ctrlKey && e.keyCode === SPACE_KEYCODE && searchBar) {
                qs('input', searchBar).focus();
            }
        });

        const eulaFooterLink = getById('eulaFooterLink');
        if (eulaFooterLink) {
            onClick(eulaFooterLink, () => {
                eulaModal.open();
            });
        }

        if (timeMachine.isActive() && timeMachinePage) {
            const div = create('div');
            div.classList.add('header-warning');
            const adjust = create('a');
            onClick(adjust, runTimeMachine);
            adjust.innerText = 'adjust';
            const reset = create('a');
            onClick(reset, () => {
                timeMachine.reset();
                location.reload();
            });
            reset.innerText = 'reset';
            // Show date as is, don't convert to local date
            div.append(`Reports are being shown for ${prettyDateNoTime(stringToDate(timeMachine.nowAsStr()), null, 'UTC')}. Use the time feature to `, adjust, ' or ', reset, '.');
            document.body.prepend(div);
        }
        ifExistsOnClickTrackAnalyticsEvent(getById('helpLink'), UserFeature.HEADER_HELP_MENU_CLICK);
        ifExistsOnClickTrackAnalyticsEvent(getById('sendFooterEmail'), UserFeature.FOOTER_CONTACT_BUTTON_CLICK);
        ifExistsOnClickTrackAnalyticsEvent(getById('eulaFooterLink'), UserFeature.FOOTER_TERMS_LINK_CLICK);
    });

    onEvent(document, 'UnauthorizedUser', () => {
        unauthorizedUserModal.open();
    });

    onEvent(document, 'RequestTimeMachine', () => {
        runTimeMachine();
    });

    onEvent(document, 'RequestStemUtility', () => {
        runStemUtil();
    });

    onEvent(document, 'RequestMetadataMonitorUtility', () => {
        runMetadataMonitorUtil();
    });

    onEvent(document, 'RequestDaasSsoModal', () => {
        daasSsoModal.open();
    });
}

// A simple way to show a message that will overlay the screen with a dismissible modal.
export function showGenericError(message: string) {
    genericErrorModal.open({ message });
}

async function getLoginConfig(): Promise<ILoginconfig> {
    const configString = fetchCacheKey<ILoginconfig>(STORAGE_INFO.clLoginConfig.scope, STORAGE_INFO.clLoginConfig.key);
    if (configString) {
        return configString;
    }
    else {
        const lc = await rootGetLoginConfig();
        setCacheKey(STORAGE_INFO.clLoginConfig.scope, STORAGE_INFO.clLoginConfig.key, lc);
        return lc;
    }
}

function runTimeMachine() {
    timeMachineModal.open();
}

function runStemUtil() {
    stemModal.open();
}

function runMetadataMonitorUtil() {
    metaMonitorModal.open();
}

function showPass(ssoMode: boolean, pass: HTMLInputElement, user: HTMLInputElement, accessToken: HTMLInputElement, username: HTMLInputElement) {
    if (username.value.trim().endsWith('@causalitylink.com')) {
        pass.value = 'zvjadsfadsf';
        pass.classList.add('invisible');
        ssoMode = true;
    }
    else {
        if (ssoMode) {
            pass.value = '';
            user.value = '';
            accessToken.value = '';
        }
        pass.classList.remove('invisible');
        ssoMode = false;
    }
    return ssoMode;
}

async function updateImage() {
    const image = qs<HTMLImageElement>('a.navbar-brand > img');
    if (image.complete) {
        await fetch(image.src, { cache: 'reload', mode: 'no-cors' });
    }
    else {
        setTimeout(updateImage, 1000);
    }
}
