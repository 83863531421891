import { getClLogger } from '../../../../shared/util/clLogger';
import { SPINNER_ERROR_TYPE } from '../../interfaces/static';
const template = require('./spinner.pug');

const clLogger = getClLogger(__filename);

const SPINNER_ERROR_EVENT = 'spinnerErrorEvent';

// Wrap additional features on top of a simple spinning image
// This makes starting, stopping, and error reporting simpler
export class Spinner {
    private spinnerErrorPoller = null;
    // Allow multiple spinners to be on the same page, and multiple pages to be open with spinners
    private static staticAnchors = [];
    private originalHTML: string = null;

    constructor(private anchor: HTMLElement,
                private options: {size?: string,
                    align?: string,
                    inline?: boolean,
                    preventAutoStart?: boolean,
                    loadMsg?: string,
                    errorMessageElement?: HTMLElement,
                    elementsToDisable?: HTMLElement[],
                    customInputsToDisable?: HTMLInputElement[],
                    // used in baseEditor to have a custom format of the message (used to show errors that link to others or that highlight other
                    // elements)
                    errorContainer?: HTMLElement, // container of the editor used to show custom errors
                    customErrorFormatter?: (msg: string, errorContainer: HTMLElement) => string } = {}, // custom error function
    ) {
        // clLogger.debug(`Spinner create: ${this.anchor.id}`);
        if (!this.options.preventAutoStart) {
            this.start();
            this.disableSpecifiedParentControls();
            this.hideExternalErrorMessage();
        }
    }

    public start() {
        // clLogger.debug(`Spinner start: ${this.anchor.id}`);
        // Initialize to a clean spinning state
        this.anchor.hidden = false;
        this.anchor.innerHTML = template(this.options);
        this.originalHTML = this.anchor.innerHTML;
        this.disableSpecifiedParentControls();
        this.hideExternalErrorMessage();
    }

    public dismiss(msg?: string) {
        // clLogger.debug(`Spinner dismiss: ${this.anchor.id}`);
        this.enableSpecifiedParentControls();
        this.hideExternalErrorMessage();
        if (this.originalHTML === this.anchor.innerHTML) {
            this.anchor.innerHTML = template(Object.assign({}, this.options, { success: true, successMsg: msg }));
        }
    }

    public error(msg?: string, type: SPINNER_ERROR_TYPE = SPINNER_ERROR_TYPE.ERROR) {
        clLogger.debug(`Spinner error: ${this.anchor.id}`);
        this.resetCallbacks();
        this.enableSpecifiedParentControls();
        if (this.options.errorMessageElement) {
            this.showExternalErrorMessage(msg, type);
            this.anchor.innerHTML = template(Object.assign({}, this.options, { error: true, successMsg: '' }));
        }
        else {
            this.anchor.innerHTML = template(Object.assign({}, this.options, { error: true, errorMsg: msg }));
        }
        if (this.options.customErrorFormatter && msg) {
            // if it has a custom error formatter is an editor and needs to hide the X icon
            this.anchor.hidden = true;
        }
    }

    public clearError() {
        this.resetCallbacks();
        this.enableSpecifiedParentControls();
        this.hideExternalErrorMessage();
    }

    // While the spinner is running, users sometimes think they can interact with the UI
    // When the UI is dependent on the result of a spinner wrapped operation, disable controls
    //  and give a hint to the user that they are not usable.
    private disableSpecifiedParentControls() {
        if (this.options.elementsToDisable && this.options.elementsToDisable.length) {
            for (const element of this.options.elementsToDisable) {
                element.style.cursor = 'wait';
                element.style.pointerEvents = 'none';
                element.style.opacity = '.5';
            }
        }
        if (this.options.customInputsToDisable && this.options.customInputsToDisable.length) {
            for (const element of this.options.customInputsToDisable) {
                element.disabled = true;
            }
        }
    }

    // Enable any controls that were previously disabled to prevent interaction while waiting
    private enableSpecifiedParentControls() {
        if (this.options.elementsToDisable && this.options.elementsToDisable.length) {
            for (const element of this.options.elementsToDisable) {
                element.style.cursor = '';
                element.style.pointerEvents = '';
                element.style.opacity = '';
            }
        }
        if (this.options.customInputsToDisable && this.options.customInputsToDisable.length) {
            for (const element of this.options.customInputsToDisable) {
                element.disabled = false;
            }
        }
    }

    // Because error messages can be displayed in a standard way, using styled alerts, allow a clear mechanism
    private hideExternalErrorMessage() {
        if (this.options.errorMessageElement) {
            this.options.errorMessageElement.hidden = true;
        }
    }

    // Allow for consistency in error messages (black spinner messages vs styled alerts)
    private showExternalErrorMessage(msg: string, type: SPINNER_ERROR_TYPE) {
        if (this.options.customErrorFormatter) {
            msg = this.options.customErrorFormatter(msg, this.options.errorContainer);
        }
        if (this.options.errorMessageElement) {
            this.options.errorMessageElement.innerHTML = msg;
            this.options.errorMessageElement.hidden = false;
            if (type === SPINNER_ERROR_TYPE.ERROR) {
                this.options.errorMessageElement.classList.remove('alert-warning');
                this.options.errorMessageElement.classList.add('alert-danger');
            }
            else if (type === SPINNER_ERROR_TYPE.WARNING) {
                this.options.errorMessageElement.classList.remove('alert-danger');
                this.options.errorMessageElement.classList.add('alert-warning');
            }
        }
    }

    // Simplify resetting spinners
    private resetCallbacks() {
        clearInterval(this.spinnerErrorPoller);
        Spinner.staticAnchors = Spinner.staticAnchors.filter((anchor) => anchor !== this.anchor);
    }

    // Allow external services to notify spinners of an error
    // When an error notification is given all spinners on the same page are notified
    public static setSpinnerError(error: string) {
        const event = new CustomEvent(SPINNER_ERROR_EVENT, { detail: error });
        Spinner.staticAnchors.forEach((anchor) => anchor.dispatchEvent(event));
    }
}
