function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (allPublishers, eclId, isFavEcl, m) {
      pug_mixins["quoteTemplate"] = pug_interp = function(data, allPublishers){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"row mb-1\"\u003E\u003Cdiv" + (" class=\"col alert articleQuote\""+pug_attr("data-id", data.articleAndLang, true, true)) + "\u003E\u003Cdiv class=\"row\"\u003E";
if (data.id) {
pug_html = pug_html + "\u003Cdiv class=\"col-9 pe-1\"\u003E\u003Cdiv\u003E\u003Csmall class=\"quoteTitle\"\u003E" + (pug_escape(null == (pug_interp = data.prettyFrom) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cspan class=\"quoteText\"\u003E\u003Ca" + (" target=\"_blank\""+pug_attr("href", data.readLink, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = `"${(data.quote || '-')}"`) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
if (eclId) {
if (isFavEcl) {
pug_html = pug_html + "\u003Cspan" + (" class=\"ms-2 fas fa-bookmark eclBtn\""+pug_attr("data-eclid", eclId, true, true)) + "\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan" + (" class=\"ms-2 far fa-bookmark eclBtn\""+pug_attr("data-eclid", eclId, true, true)) + "\u003E\u003C\u002Fspan\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003Cspan" + (" class=\"clearfix float-start sentenceText\""+pug_attr("data-id", data.articlePublisher + '_' + data.articleId, true, true)+pug_attr("data-sentence", data.sentence, true, true)+pug_attr("data-ecl-id", data.id, true, true)+pug_attr("data-origLang", data.origLang, true, true)) + "\u003E\u003C\u002Fspan\u003E";
if (data.direction === 1) {
pug_html = pug_html + "\u003Ci class=\"mx-1 fa fa-arrow-alt-square-up positive\"\u003E\u003C\u002Fi\u003E";
}
else
if (data.direction === -1) {
pug_html = pug_html + "\u003Ci class=\"mx-1 fa fa-arrow-alt-square-down negative\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-3 text-end\" style=\"display: flex; flex-direction: column; overflow-wrap: break-word;\"\u003E\u003Cdiv class=\"quoteDate float-end\"\u003E" + (pug_escape(null == (pug_interp = data.prettyDate || data.prettyPublishDate || 'Time NA') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
if (data.articlePublisher) {
const publisher = allPublishers.find((ap) => ap.publisherName === data.articlePublisher)?.label || 'NA';
pug_html = pug_html + "\u003Cdiv class=\"float-end\"\u003E" + (pug_escape(null == (pug_interp = publisher) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
else
if (data.publishers) {
const publisher = allPublishers.find((ap) => ap.publisherName === data.publishers[0])?.label || 'NA';
pug_html = pug_html + "\u003Cdiv class=\"publishDetailsLink float-end\"\u003E" + (pug_escape(null == (pug_interp = publisher) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
if (data.origLang && data.origLang !== 'en') {
pug_html = pug_html + "\u003Cspan" + (" class=\"ms-2 fa fa-language showTranslationBtn clickable\""+pug_attr("data-id", data.articlePublisher + '_' + data.articleId, true, true)+pug_attr("data-sentence", data.sentence, true, true)+pug_attr("data-ecl-id", data.id, true, true)+pug_attr("data-origLang", data.origLang, true, true)) + "\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};








pug_mixins["quote"] = pug_interp = function(quoteGroup){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (quoteGroup.readLink) {
pug_html = pug_html + "\u003Ch2\u003E\u003Ca" + (pug_attr("href", quoteGroup.readLink, true, true)+" target=\"_blank\"") + "\u003E" + (pug_escape(null == (pug_interp = quoteGroup.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fh2\u003E";
}
else
if (quoteGroup.title) {
pug_html = pug_html + "\u003Ch2\u003E\u003Cdiv\u003E" + (pug_escape(null == (pug_interp = quoteGroup.title) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fh2\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
const firstThree = quoteGroup.mentions.slice(0, 3);
const remainder = quoteGroup.mentions.slice(3);
// iterate firstThree
;(function(){
  var $$obj = firstThree;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var m = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = m.quote) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var m = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = m.quote) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

if (remainder.length) {
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = `${remainder.length} more...`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"moreQuote\""+pug_attr("hidden", true, true, true)) + "\u003E";
// iterate remainder
;(function(){
  var $$obj = remainder;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var m = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = `${m.quote}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var m = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = `${m.quote}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["quoteDate"] = pug_interp = function(quoteGroup){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"quoteDate float-end\"\u003E" + (pug_escape(null == (pug_interp = quoteGroup.dateStr || 'Time NA') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
if (quoteGroup.publisherLabel) {
pug_html = pug_html + "\u003Cdiv class=\"float-end\"\u003E" + (pug_escape(null == (pug_interp = quoteGroup.publisherLabel) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
if (quoteGroup.languageLabel) {
pug_html = pug_html + "\u003Cdiv\u003E" + (pug_escape(null == (pug_interp = quoteGroup.languageLabel) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
};
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
pug_mixins["quoteTemplate"](m, allPublishers);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "allPublishers" in locals_for_with ?
        locals_for_with.allPublishers :
        typeof allPublishers !== 'undefined' ? allPublishers : undefined, "eclId" in locals_for_with ?
        locals_for_with.eclId :
        typeof eclId !== 'undefined' ? eclId : undefined, "isFavEcl" in locals_for_with ?
        locals_for_with.isFavEcl :
        typeof isFavEcl !== 'undefined' ? isFavEcl : undefined, "m" in locals_for_with ?
        locals_for_with.m :
        typeof m !== 'undefined' ? m : undefined));
    ;;return pug_html;};module.exports=template;