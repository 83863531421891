import { compileTemplate } from 'pug';
import { Acl } from '../../../../shared/auth/acl';
import { AuthRoles } from '../../../../shared/interfaces/acl/acl-i';
import { DEFAULT_COLLECTION } from '../../../../shared/interfaces/api/apiContracts';
import { IUserBookmarkCollection } from '../../../../shared/interfaces/bookmark/bookmark-i';
import { IHomePageCallback, IHomePageStore } from '../../../../shared/interfaces/home';
import { ISymbolConcept } from '../../../../shared/interfaces/market/symbols';
import { bookmarksGetBaseEvalConcepts, bookmarksGetUserBookmarks, bookmarksPutUserBookmark } from '../../api/bookmarks';
import { UserFeature } from '../../interfaces/analytics';
import { ifExistsOnClickTrackAnalyticsEvent, trackAnalyticsEvent } from '../../util/analytics';
import { attr, ENTER_KEYCODE, getByClass, getByClassFirst, getById, onClick, onKeyDown } from '../../util/html';
import { STORAGE_INFO, fetchCacheKey, removeCacheKey, setCacheKey } from '../../util/storageUtil';
const bookmarkTemplate: compileTemplate = require('./homeBookmarkWidget.pug');

export const MAX_CONCEPTS_PER_TYPE = 20;

export class HomeBookmarkWidget {
    private timer;
    private baseConcepts: ISymbolConcept[];

    private STORE: IHomePageStore = {
        bookmarks: {
            collections: [],
            concepts: {},
        },
    };

    constructor(private acl: Acl, private showAdd: boolean, private showTreeFilters: boolean = false) {
    }

    public getCollections(ignoreDefault: boolean = true) {
        let collections = this.STORE.bookmarks.collections;
        if (ignoreDefault) {
            collections = collections.filter((c) => c.name !== DEFAULT_COLLECTION);
        }
        return collections;
    }

    // Find a portfolio and get stats about portfolio use
    private getMatchingPortfolioDetails(id?: string, username?: string, name?: string) {
        let matchingCollection: IUserBookmarkCollection = null;
        let hasAddedToAnyPortfolio = false;
        // Look at all portfolios to see if the current user has added any items to a portfolio
        if (this?.STORE?.bookmarks) {
            for (const collection of this.STORE.bookmarks.collections) {
                // Track the portfolio we're looking for
                if (name &&
                    id &&
                    username &&
                    collection.name === name &&
                    collection.username === username &&
                    collection.id === id) {
                    matchingCollection = collection;
                }
                // Track if any items have been added to a user created portfolio
                if ((collection?.name !== DEFAULT_COLLECTION) &&
                    (collection?.industryGroups?.length ||
                        collection?.companyGroups?.length ||
                        collection?.locations?.length)) {
                    hasAddedToAnyPortfolio = true;
                }
            }
        }
        return { collection: matchingCollection, hasAddedToAnyPortfolio };
    }

    public async render(callback: IHomePageCallback, returnDefault?: boolean, callbackParams?: { [key: string]: any }, hideDefault?: boolean, baseConcepts?: ISymbolConcept[]) {
        if (!this.baseConcepts) {
            if (baseConcepts) {
                this.baseConcepts = baseConcepts;
            }
            else {
                this.baseConcepts = await bookmarksGetBaseEvalConcepts();
            }
        }
        const homeBookmarkDiv: HTMLDivElement = getByClassFirst<HTMLDivElement>('homeBookmark');
        homeBookmarkDiv.innerHTML = bookmarkTemplate({ acl: this.acl, roles: AuthRoles, showAdd: this.showAdd, showTreeFilters: this.showTreeFilters });

        if (this.acl.hasRole(AuthRoles.BookmarksRead)) {
            await this.refreshBookmarks();
            const favSymbolFilter: HTMLDivElement = getByClassFirst<HTMLDivElement>('favSymbolFilter');
            let favSymbolHtml = '';
            if (!hideDefault) {
                favSymbolHtml = `<a class="dropdown-item favSymbolCollectionLink" href="#">${DEFAULT_COLLECTION}</a>`;
            }
            for (const collection of this.STORE.bookmarks.collections) {
                if (collection.name !== DEFAULT_COLLECTION) {
                    favSymbolHtml += `<a class="dropdown-item favSymbolCollectionLink"
                        data-id="${collection.id}" data-username="${collection.username}" data-name="${collection.name}" href="#">${collection.name}</a>`;
                }
            }
            favSymbolFilter.innerHTML = favSymbolHtml;
        }

        const favSymbolLinks = getByClass('favSymbolCollectionLink');
        for (const link of favSymbolLinks) {
            onClick(link, () => {
                trackAnalyticsEvent(UserFeature.DASHBOARD_PORTFOLIO_BUTTON_SELECT);
                const eventTarget: HTMLAnchorElement = event.currentTarget as HTMLAnchorElement;
                const id: string = attr(eventTarget, 'data-id');
                const username: string = attr(eventTarget, 'data-username');
                const name: string = attr(eventTarget, 'data-name');
                // Check to see if the user has added any items to a Portfolio.
                // This will be hint that a tour may need to auto-run.
                const { collection, hasAddedToAnyPortfolio: userEntriesExist } = this.getMatchingPortfolioDetails(id, username, name);
                if (collection) {
                    setCacheKey(STORAGE_INFO.clHomeBookmark.scope, STORAGE_INFO.clHomeBookmark.key, `${collection.username}_${collection.id}`);
                    callback(
                        collection,
                        this.STORE.bookmarks.concepts[`${collection.username}_${collection.id}`] || [],
                        userEntriesExist,
                        callbackParams,
                    );
                    return;
                }
                removeCacheKey(STORAGE_INFO.clHomeBookmark.scope, STORAGE_INFO.clHomeBookmark.key);
                callback(
                    null,
                    returnDefault && (!name || name === DEFAULT_COLLECTION) ? this.baseConcepts : null,
                    false,
                    callbackParams,
                );
            }, false);
        }
        if (this.acl.hasRole(AuthRoles.BookmarksWrite) && this.showAdd) {
            // A free user may only have one custom portfolio
            const newCollectionBtn = getById<HTMLButtonElement>('newPortfolioBtn');
            const newCollectionInput = getById<HTMLInputElement>('newPortfolioInput');
            const newCollectionInputBtn = getById<HTMLButtonElement>('newPortfolioInputBtn');
            onClick(newCollectionBtn, () => {
                event.stopPropagation();
                newCollectionBtn.classList.add('d-none');
                newCollectionInput.classList.remove('d-none');
                newCollectionInputBtn.classList.remove('d-none');
                newCollectionInput.focus();
            });
            onKeyDown(newCollectionInput, (e: KeyboardEvent) => {
                if (e.keyCode === ENTER_KEYCODE) {
                    this.addCollection(newCollectionInput, callback, newCollectionInputBtn, newCollectionBtn);
                }
            });
            onClick(newCollectionInputBtn, () => {
                this.addCollection(newCollectionInput, callback, newCollectionInputBtn, newCollectionBtn);
            });
        }
        const selectedId = fetchCacheKey<string>(STORAGE_INFO.clHomeBookmark.scope, STORAGE_INFO.clHomeBookmark.key);
        const { hasAddedToAnyPortfolio } = this.getMatchingPortfolioDetails();
        const found = selectedId ? this.STORE.bookmarks.collections.find((c) => `${c.username}_${c.id}` === selectedId) : null;
        if (selectedId && found) {
            callback(
                found,
                this.STORE.bookmarks.concepts[selectedId] || [],
                hasAddedToAnyPortfolio,
            );
        }
        else {
            callback(null, null, false);
        }
        ifExistsOnClickTrackAnalyticsEvent(getById('btnPickPortfolio'), UserFeature.DASHBOARD_PORTFOLIO_BUTTON_CLICK);
        ifExistsOnClickTrackAnalyticsEvent(getById('btnFilterSaves'), UserFeature.TREEBOOKMARK_BOOKMARKS_BUTTON_OPEN);
    }

    private async addCollection(newCollectionInput, callback, newCollectionInputBtn, newCollectionBtn) {
        newCollectionInput.classList.add('d-none');
        newCollectionInputBtn.classList.add('d-none');
        newCollectionBtn.classList.remove('d-none');
        newCollectionInput.dispatchEvent(new Event('blur'));
        const errorDiv = getByClassFirst('homeErrorDiv');
        errorDiv.hidden = true;
        if (this.timer != null) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if (!newCollectionInput.value) {
            return;
        }
        const bookmark = {
            id: null,
            name: newCollectionInput.value,
            companyGroups: [],
            industryGroups: [],
            locations: [],
        };
        try {
            const result = await bookmarksPutUserBookmark(bookmark);
            if (result) {
                await this.refreshBookmarks();
                let i = this.STORE.bookmarks.collections.length - 1;
                let collection = null;
                while (i >= 0 && !collection) {
                    if (this.STORE.bookmarks.collections[i].name === newCollectionInput.value) {
                        collection = this.STORE.bookmarks.collections[i];
                    }
                    i--;
                }
                newCollectionInput.value = '';
                const { hasAddedToAnyPortfolio } = this.getMatchingPortfolioDetails();
                setCacheKey(STORAGE_INFO.clHomeBookmark.scope, STORAGE_INFO.clHomeBookmark.key, `${collection.username}_${collection.id}`);
                callback(collection, this.STORE.bookmarks.concepts[`${collection.username}_${collection.id}`] || [], hasAddedToAnyPortfolio);
                this.render(callback);
            }
        }
        catch (err) {
            errorDiv.hidden = false;
            errorDiv.innerText = err;
            this.timer = setTimeout(() => {
                errorDiv.hidden = true;
            }, 10000);
        }
    }

    public async refreshBookmarks() {
        this.STORE.bookmarks = await bookmarksGetUserBookmarks({ withConcepts: true, onlyPrimaryEquity: true, withLinks: true });
    }

    public getBookmarksCount() {
        return this.STORE.bookmarks?.collections?.length;
    }
}
