import { Socket } from 'socket.io-client';
import { IComment } from '../../../shared/interfaces/comments/comment-i';
import { EventSubject } from '../../../shared/interfaces/event';
import { getClLogger } from '../../../shared/util/clLogger';
import { calculateSecondsFromMillis } from '../../../shared/util/dateUtil';
import { FeatureFlags } from '../../../shared/util/featureFlag';
import { clientsGetChatEnabled } from '../api/clients';
import { isFlagEnabledForView } from '../featureFlag';
import { getUser } from '../layout';
import { attr, getByClassFirst, getById, hide, onClick, rAttr, show } from '../util/html';
import { STORAGE_INFO, fetchCacheKey, removeCacheKey, setCacheKey } from '../util/storageUtil';
import { EXIT_MESSAGE, MessageController, NOTIFICATION_INTERVAL, PING_MESSAGE, USER_WAITING } from './message';

let waitingSocket: Socket = null;
let socket: Socket = null;
let adminOnline = false;
let joined = false;
let nc: number = null;
let isClosed = true;
let ignoreClick = false;
const activeAdmins: string[] = [];
const clLogger = getClLogger(__filename);

function addConversation(exists: boolean, conversation: { user: string, id: string, name: string }) {
    let allConvos: { user: string, id: string, name: string }[] = [];
    if (exists) {
        allConvos = fetchCacheKey<{ user: string, id: string, name: string }[]>(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
    }
    allConvos.push(conversation);
    setCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key, allConvos, STORAGE_INFO.conversations.cacheMaxTime);
}

function getRand(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

function getTimestampSeconds() {
    return Math.floor(calculateSecondsFromMillis(Date.now()));
}

function openDM(chat: HTMLElement, notif: HTMLElement, notificationCount: number) {
    show(chat);
    notificationCount = 0;
    setCacheKey(STORAGE_INFO.notificationCount.scope, STORAGE_INFO.notificationCount.key, notificationCount, STORAGE_INFO.notificationCount.cacheMaxTime);
    notif.innerText = '';
    hide(notif);
}

function closeDM(chat) {
    hide(chat);
}

export async function setupDM(userId: string) {
    const dmContainer = getById('clientDM');
    const dmCloseBtn = getById<HTMLDivElement>('clientDM-close');
    const dmBtn = getByClassFirst('chat-btn-open', dmContainer);
    const dmBtnContainer = getByClassFirst('dm-btn-container', dmContainer);
    const sendBtn = getByClassFirst<HTMLButtonElement>('chat-btn-send-client', dmContainer);
    const typespace = getByClassFirst<HTMLInputElement>('chat-box-typespace-client', dmContainer);
    const notif = getByClassFirst('message-notif', dmContainer);
    const chat = getByClassFirst('chat-box-client', dmContainer);
    const chatWindow = getByClassFirst('messages-client', dmContainer);
    const findAdminBtn = getById<HTMLButtonElement>(`clientDM-findAdmin`);
    const logoutBtn = getById('logoutMenuLink');
    const name = getUser().name;
    let chatId: number | string = getRand(10000, 999999) + getTimestampSeconds();
    let mc: MessageController = null;
    let notificationCount = 0;

    // Once all conversations are passed off messaging will be disabled, related local storage wiped, and upon page refresh DMs will be clear
    rAttr(logoutBtn, 'href');
    if (logoutBtn) {
        onClick(logoutBtn, async () => {
            // Clear local storage of conversations on logout
            clearConversations();
            window.location.replace('/logout');
        });
    }

    onClick(dmCloseBtn, () => {
        closeDM(chat);
        isClosed = true;
    });

    dragElement(dmBtnContainer);

    hide(notif);
    hide(dmBtn);
    hide(chat);
    hide(findAdminBtn);
    const flagEnabled = await isFlagEnabledForView(FeatureFlags.enableChat);
    const clientEnabled = await clientsGetChatEnabled();
    if (flagEnabled && clientEnabled) {
        mc = new MessageController(userId);
        let allConvos: { user: string, id: string, name: string }[] = [];
        nc = fetchCacheKey<number>(STORAGE_INFO.notificationCount.scope, STORAGE_INFO.notificationCount.key);
        if (nc !== null) {
            notificationCount = nc;
        }
        else {
            setCacheKey(STORAGE_INFO.notificationCount.scope, STORAGE_INFO.notificationCount.key, notificationCount, STORAGE_INFO.notificationCount.cacheMaxTime);
        }
        if (fetchCacheKey<{ user: string, id: string, name: string }[]>(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key)) {
            const oldCache = fetchCacheKey<{ user: string, id: string, name: string }[]>(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
            if (oldCache.length > 0 && (typeof (oldCache[0]) === 'string' || !oldCache[0].hasOwnProperty('name'))) {
                setCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key, allConvos, STORAGE_INFO.conversations.cacheMaxTime);
            }
        }
        const cs = fetchCacheKey<{ user: string, id: string, name: string }[]>(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
        if (cs) {
            allConvos = cs;
        }
        let match = '';
        let exists = false;
        const allConvoIds = allConvos.map(c => c.id);
        if (allConvoIds.length) {
            exists = true;
            allConvoIds.find(element => {
                if (element.endsWith(userId)) {
                    match = element;
                }
                return match;
            });
        }
        if (match !== '') {
            chatId = match;
        }
        else {
            chatId = chatId + userId;
            addConversation(exists, { user: userId, id: chatId, name });
        }
        // Join a waiting room to shout your chatId at an admin
        if (waitingSocket === null) {
            onClick(dmBtn, (event) => {
                if (ignoreClick) {
                    event.stopPropagation();
                }
                else {
                    if (isClosed && adminOnline) {
                        openDM(chat, notif, notificationCount);
                        isClosed = false;
                        chatWindow.scrollTop = chatWindow.scrollHeight;
                    }
                    else {
                        closeDM(chat);
                        isClosed = true;
                    }
                }
            }, true);
            await prepareWaitingRoom(
                mc, userId, chatId, dmBtn, notificationCount, notif, chatWindow, sendBtn, typespace, name, findAdminBtn);
            onClick(findAdminBtn, async () => {
                joined = false;
                await prepareWaitingRoom(mc, userId, chatId.toString(), dmBtn, notificationCount,
                    notif, chatWindow, sendBtn, typespace, name, findAdminBtn);
            });
        }
    }
}
async function prepareWaitingRoom(mc: MessageController, userId: string,
                                  chatId: string, dmBtn: HTMLElement, notificationCount: number, notif: HTMLElement,
                                  chatWindow: HTMLElement, sendBtn: HTMLButtonElement, typespace: HTMLInputElement, messageLast: string, findAdminBtn: HTMLButtonElement) {
    await mc.initWaitingRoom();
    waitingSocket = mc.getWaitingSocket();
    const idMessage = mc.createMessage(EventSubject.WaitMessage, userId, chatId, 'Ready to join private room.', USER_WAITING, messageLast);
    waitingSocket.emit(EventSubject.WaitMessage, idMessage);
    // Attempt to join the waiting room every few seconds until successful
    const waitingIntervalId = setInterval(() => {
        waitingSocket.emit(EventSubject.WaitMessage, idMessage);
    }, 3000);
    waitingSocket.on(EventSubject.WaitMessage, async (id: string, _op: EventSubject, msg: IComment) => {
        if (id === USER_WAITING && msg.comment === 'Join your private room.' && msg.objectId === idMessage.objectId && !joined) {
            clearInterval(waitingIntervalId);
            await mc.leaveWaitingRoom();
            hide(findAdminBtn);
            rAttr(typespace, 'readonly');
            sendBtn.classList.remove('disabled');
            // Join your chatroom
            joined = true;
            show(dmBtn);
            if (notificationCount > 0) {
                show(notif);
                // notif.innerText = notificationCount.toString();
                notif.innerText = 'New';
            }
            // Periodically sync notifications in case multiple tabs are open
            setInterval(() => {
                nc = fetchCacheKey<number>(STORAGE_INFO.notificationCount.scope, STORAGE_INFO.notificationCount.key);
                if (nc === 0) {
                    hide(notif);
                    notif.innerText = '';
                }
            }, NOTIFICATION_INTERVAL);
            adminOnline = true;
            await mc.initMessages(chatId.toString(), userId, chatWindow, sendBtn, typespace);
            chatWindow.innerHTML += `<div class='text-center' style='color: #3e444a;'>Messages are locally stored up to a week or cleared on logout.</div>`;

            socket = mc.getSocket();
            socket.on(EventSubject.Message, async (_adminId: string, _adminOp: EventSubject, adminMsg: IComment) => {
                if (adminMsg.first === PING_MESSAGE && !activeAdmins.includes(adminMsg.creator) && adminMsg.creator !== userId) {
                    // Check if an exit message needs to be removed and hide the findAdminBtn
                    const exitMessage = getByClassFirst('exit-message', chatWindow);
                    if (exitMessage) {
                        exitMessage.remove();
                    }
                    hide(findAdminBtn);
                    activeAdmins.push(adminMsg.creator);
                }
                if (adminMsg.first === EXIT_MESSAGE && adminMsg.creator !== userId) {
                    clLogger.debug('EXIT MESSAGE DETECTED. ADMINS ONLINE: ', activeAdmins);
                    // Remove admin from list of active admins
                    if (activeAdmins.includes(adminMsg.creator)) {
                        const index = activeAdmins.indexOf(adminMsg.creator);
                        activeAdmins.splice(index, 1);
                    }
                    // If no more admins are left
                    if (!activeAdmins.length) {
                        attr(typespace, 'readonly', 'true');
                        sendBtn.classList.add('disabled');
                        show(findAdminBtn);
                        clLogger.debug('ADDING EXIT MESSAGE!');
                        const exitMessage = getByClassFirst('exit-message', chatWindow);
                        if (!exitMessage) {
                            // Display message on how to rejoin the waiting room
                            chatWindow.innerHTML += '<div class= exit-message>' +
                            'No admins active, click the button in the top right corner to rejoin the waiting room.' + '</div>';
                            chatWindow.scrollTop = chatWindow.scrollHeight;
                        }
                    }
                    clLogger.debug('ADMINS ONLINE NOW: ', activeAdmins);
                }
                if (isClosed && adminMsg.first !== PING_MESSAGE && adminMsg.first !== EXIT_MESSAGE && adminMsg.creator !== userId) {
                    notificationCount += 1;
                    // notif.innerText = notificationCount.toString();
                    notif.innerText = 'New';
                    show(notif);
                    setCacheKey(STORAGE_INFO.notificationCount.scope, STORAGE_INFO.notificationCount.key, notificationCount, STORAGE_INFO.notificationCount.cacheMaxTime);
                }
            });
        }
    });
}

function clearConversations() {
    const allConvos: { user: string, id: string, name: string }[] = fetchCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
    if (allConvos) {
        for (const conversation of allConvos) {
            // Clear out caches related to this conversation from local storage
            removeCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${conversation.id}`);
        }
    }
    // Clear out caches for all conversations, notifications, and related settings from local storage
    removeCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
    removeCacheKey(STORAGE_INFO.notificationCount.scope, STORAGE_INFO.notificationCount.key);
}

function dragElement(element: HTMLElement) {
    let pos1 = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;
    element.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
        e.preventDefault();
        // Get initial mouse cursor position
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // Call the drag function whenever the mouse moves
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e.preventDefault();
        ignoreClick = true;
        // Calculate the new position of the cursor
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // Set the element's new position
        element.style.top = (element.offsetTop - pos2) + 'px';
        element.style.left = (element.offsetLeft - pos1) + 'px';
    }

    function closeDragElement() {
        // Stop moving when the mouse button is released
        document.onmouseup = null;
        document.onmousemove = null;
        setTimeout(async () => {
            ignoreClick = false;
        }, 500);
    }
}
