import { IParsedEvent, IParsedIndicator } from '../../../shared/interfaces/dashboard';
import { camelCaseName } from '../../../shared/util/viewFormatUtil';
import { attr, create, getByTag, onChange, onClick, onReset, qs, qsa, selectedCombo, selectpicker } from '../../lib/util/html';
import { UserFeature } from '../interfaces/analytics';
import { ISelectGroupInput, SELECT_GROUP_LABELS } from '../interfaces/static';
import { bigDonutSpec, donutSpec } from '../json';
import { trackAnalyticsEvent } from '../util/analytics';
import { SELECT_GROUP_DEFAULT_SCOPE, fetchCacheKey, removeCacheKey, setCacheKey } from '../util/storageUtil';
import { drawDonutPlot, updateDonutPlot } from '../viz/plotlyUtil';
const selectgroupTemplate = require('./selectGroup.pug');

export class SelectGroup {
    public static getValuesAsRegExpFromId(id: string, type: string): RegExp | null {
        const storeValue = fetchCacheKey<{[name: string]: string[]}>(SELECT_GROUP_DEFAULT_SCOPE, id);
        return storeValue ? SelectGroup.valuesToRegExp(type, storeValue) : null;
    }

    private static valuesToRegExp(type: string, values: {[name: string]: string[]}): RegExp {
        if (type === 'event') {
            const toRE = (key, v) => {
                const p = Object.assign({}, parsed);
                p[key] = `(${v.join('|')})`;
                const begin = Object.keys(p).slice(0, 3).map((k) => p[k]).join('-');
                const end = Object.keys(p).slice(3).map((k) => p[k]).join('-');
                return `(^${begin}:${end}$)`;
            };
            const parsed = {
                fromCompany: '[^-]*',
                fromLocation: '[^-]*',
                fromPerson: '[^-]*',
                toCompany: '[^-]*',
                toLocation: '[^-]*',
                toPerson: '[^-]*',
                segment: '[^-]*',
                product: '[^-]*',
                baseEvent: '[^-]*',
            };
            let all = true;
            const reChunks = [];
            for (const v in values) {
                if (values[v].length) {
                    const keys = v.split('__');
                    if (keys.length > 1) {
                        const c = [];
                        for (const key of keys) {
                            if (parsed.hasOwnProperty(key)) {
                                c.push(toRE(key, values[v]));
                                all = false;
                            }
                        }
                        reChunks.push(c.join('|'));
                    }
                    else {
                        const key = keys[0];
                        if (parsed.hasOwnProperty(key)) {
                            reChunks.push(toRE(key, values[v]));
                            all = false;
                        }
                    }
                }
            }
            return all ? null : new RegExp(reChunks.map((s) => '(?=' + s + ')').join(''));
        }
        else if (type === 'indicator') {
            const parsed = {
                company: '[^-]*',
                location: '[^-]*',
                industry: '[^-]*',
                segment: '[^-]*',
                feature: '[^-]*',
                product: '[^-]*',
                baseKPI: '[^-]*',
            };
            let all = true;
            for (const key in values) {
                if (values[key].length) {
                    if (parsed.hasOwnProperty(key)) {
                        parsed[key] = `(${values[key].join('|')})`;
                        all = false;
                    }
                }
            }
            return all ? null : new RegExp(`^${Object.keys(parsed).map((k) => parsed[k]).join('-')}$`);
        }
        throw Error('Unknown type');
    }

    private inputs: ISelectGroupInput[];
    private inputsMap: { [name: string]: ISelectGroupInput } = {};
    private htmlInputs: HTMLSelectElement[];
    private charts: HTMLDivElement[];
    private nameValues: {[name: string]: string[]};
    private optionsCache: any[];

    constructor(private inputName: string,
                private container: HTMLDivElement,
                private elements: {parsed: IParsedIndicator | IParsedEvent, count: number}[],
                private fields: (string | string[])[],
                callback: () => void, private type: 'indicator' | 'event',
                private chartType: 'pie' | 'bigpie' | 'bar',
                private showAll: boolean = false,
                private chartOnly: boolean = false,
                private selectEnabled: boolean = true) {
        if (!fields) {
            if (type === 'indicator') {
                this.fields = [ 'company', 'location', 'industry', 'segment', 'product', 'baseKPI' ];
            }
            else if (type === 'event') {
                this.fields = [ [ 'fromCompany', 'toCompany' ], [ 'fromLocation', 'toLocation' ], [ 'fromPerson', 'toPerson' ], 'segment', 'product', 'baseEvent' ];
            }
            else {
                throw Error('No fields specified and type is unknown');
            }
        }

        this.inputs = this.fields.map((n, index) => {
            const name = typeof n === 'string' ? n : n.join('__');
            const label = typeof n === 'string'
                ? SELECT_GROUP_LABELS[name] || camelCaseName(name, 'Unknown')
                : SELECT_GROUP_LABELS[n.join('__')] || n.map((m) => SELECT_GROUP_LABELS[m] || camelCaseName(m, 'Unknown')).join('/');
            const input = { name, label, index, options: [] };
            this.inputsMap[name] = input;
            return input;
        });
        this.optionsCache = new Array(this.inputs.length);
        this.nameValues = {};
        if (this.inputName) {
            const storeValue = fetchCacheKey<{[name: string]: string[]}>(SELECT_GROUP_DEFAULT_SCOPE, this.inputName);
            if (storeValue) {
                this.nameValues = storeValue;
            }
        }

        this.init();

        for (const htmlInput of this.htmlInputs) {
            const input = this.inputsMap[htmlInput.name];
            this.renderChart(input);
            this.renderChart(input, true);
            onChange(htmlInput, (event) => {
                trackAnalyticsEvent(UserFeature.SELECTGROUP_TEXTFILTER_DROPDOWN_SELECT);
                event.stopPropagation();
                this.update(input.index, false);
                this.container.dispatchEvent(new CustomEvent('change', { detail: { name: input.name, values: selectedCombo(htmlInput) } }));
            });
            onReset(htmlInput, (e) => {
                selectpicker(e.target as HTMLElement, 'deselectAll');
            });
        }
        // The 'X' that's part of this widget does not work well with multiple side-by-side. This
        // captures the click and properly dispatches it, in addition to changing the UI for clarity.
        qsa<HTMLButtonElement>('button.close', container).forEach((clearBtn) => {
            clearBtn.innerHTML = 'Clear';
            clearBtn.style.fontSize = '.8em';
            clearBtn.style.marginTop = '.5em';
            onClick(clearBtn, (event: any) => {
                event.stopPropagation();
                qs('.selectpicker', event.target.closest('.bootstrap-select')).dispatchEvent(new Event('reset'));
            });
        });
        const clearAll = qs('.clearall-btn', container);
        if (clearAll) {
            onClick(clearAll, () => {
                trackAnalyticsEvent(UserFeature.SELECTGROUP_RESET_BUTTON_CLICK);
                this.reset();
            });
        }
        if (selectEnabled) {
            onChange(container, () => {
                if (this.inputName) {
                    setCacheKey(SELECT_GROUP_DEFAULT_SCOPE, this.inputName, this.getValues());
                }
                if (callback) {
                    callback();
                }
            });
        }
    }

    public getValueAsRegExp(type?: string): RegExp | null {
        return SelectGroup.valuesToRegExp(type || this.type, this.getValues());
    }

    public hide() {
        this.container.classList.add('d-none');
    }

    public reset() {
        this.container.classList.remove('d-none');
        for (const input of this.htmlInputs) {
            input.dispatchEvent(new Event('reset'));
        }
        if (this.inputName) {
            removeCacheKey(SELECT_GROUP_DEFAULT_SCOPE, this.inputName);
        }
    }

    public getValues(): {[name: string]: string[]} {
        const values: {[name: string]: string[]} = {};
        for (const key in this.nameValues) {
            if (this.nameValues[key].length && this.nameValues[key].join()) {
                values[key] = this.nameValues[key];
            }
        }
        this.nameValues = values;
        return values;
    }

    private setValues(name: string, values: string[]) {
        if (values.length && values.join()) {
            this.nameValues[name] = values;
        }
        else {
            delete this.nameValues[name];
        }
    }

    // Returns the number of toggles that were made (values which matched)
    public toggleValues(name: string, values: string[]): number {
        let hits = 0;
        const idx = this.inputsMap[name].index;
        for (const value of values) {
            const opt = qs<HTMLOptionElement>(`option[value="${value}"]`, this.htmlInputs[idx]);
            if (opt) {
                opt.selected = !opt.selected;
                hits++;
            }
        }
        this.update(idx, true);
        return hits;
    }

    private filterOptions() {
        this.optionsCache = this.fields.map((f) => {
            const vals = this.getValues();
            const fields = Object.keys(vals)
                .filter((g) => (typeof f === 'string' ? f : f.join('__')) !== g && vals[g] && vals[g].length);
            const options = !fields.length ? this.elements
                : this.elements.filter((elt) => fields.every((g) => g.split('__').some((k) => vals[g].includes(elt.parsed[k]))));
            const res = {};
            for (const opt of options) {
                if (typeof f === 'string') {
                    res[opt.parsed[f]] = (res[opt.parsed[f]] || 0) + opt.count;
                }
                else {
                    for (const k of f) {
                        res[opt.parsed[k]] = (res[opt.parsed[k]] || 0) + opt.count;
                    }
                }
            }
            return res;
        });
    }

    private updateOptions(idx: number, neg: boolean = false) {
        if (idx < 0 || idx >= this.inputs.length) {
            return;
        }

        const opts = this.optionsCache[idx];
        const input = this.inputs[idx];
        const selected = this.getValues()[input.name] || [];

        input.options = [];
        const filteredSelected = [];
        for (const opt in opts) {
            if (opt !== '*' && opt !== 'world' && opt !== 'count' && opts[opt] > 0) {
                const s = selected.includes(opt);
                input.options.push({
                    value: opt,
                    count: opts[opt],
                    label: camelCaseName(opt, 'Unknown'),
                    selected: s,
                });
                if (s) {
                    filteredSelected.push(opt);
                }
            }
        }
        input.options.sort((a, b) => b.count - a.count);
        neg ? idx-- : idx++;
        this.updateOptions(idx, neg);
    }

    private init() {
        this.filterOptions();
        this.updateOptions(0);
        this.container.innerHTML = selectgroupTemplate({
            inputs: this.inputs,
            chartType: this.chartType,
            showAll: this.showAll,
            chartOnly: this.chartOnly,
        });
        this.htmlInputs = getByTag('select', this.container);
        for (const inp of this.htmlInputs) {
            selectpicker(inp);
        }
        this.charts = this.htmlInputs.map((h) => qs(`#selectChart-${h.name}`, h.closest('.selectgroup')));
    }

    private update(startIndex: number, refresh: boolean, renderFirstChart: boolean = true) {
        const startHtmlInput = this.htmlInputs[startIndex];
        if (refresh) {
            selectpicker(startHtmlInput, 'refresh');
        }
        this.setValues(startHtmlInput.name, selectedCombo(startHtmlInput));
        this.inputs[startIndex].options.forEach((opt) => {
            const aux = this.getValues()[startHtmlInput.name];
            opt.selected = aux ? aux.includes(opt.value) : false;
        });
        if (renderFirstChart) {
            this.renderChart(this.inputs[startIndex], true);
        }
        this.filterOptions();
        this.updateOptions(startIndex + 1);
        this.updateOptions(startIndex - 1, true);

        for (const htmlInput of this.htmlInputs.slice(0, startIndex).concat(this.htmlInputs.slice(startIndex + 1))) {
            htmlInput.innerHTML = '';
            const input = this.inputsMap[htmlInput.name];
            input.options.forEach((opt) => {
                const htmlOpt = create<HTMLOptionElement>('option');
                htmlOpt.value = opt.value;
                htmlOpt.selected = opt.selected;
                htmlOpt.innerHTML = opt.label;
                attr(htmlOpt, 'data-subtext', '' + opt.count);
                htmlInput.add(htmlOpt);
            });
            htmlInput.disabled = !htmlInput.innerHTML;
            this.setValues(startHtmlInput.name, selectedCombo(startHtmlInput));
            selectpicker(htmlInput, 'refresh');
            if (refresh) {
                selectpicker(startHtmlInput, 'refresh');
            }
            if (this.chartType) {
                this.renderChart(input, true);
            }
        }
    }

    private renderChart(src: ISelectGroupInput, update: boolean = false) {
        const callback = (values: string[]) => {
            if (this.selectEnabled) {
                trackAnalyticsEvent(UserFeature.SELECTGROUP_GRAPHICALFILTER_DONUT_CLICK);
                for (const value of values) {
                    const opt = qs<HTMLOptionElement>(`option[value="${value}"]`, this.htmlInputs[src.index]);
                    opt.selected = !opt.selected;
                }
                this.update(src.index, true, false);
                this.container.dispatchEvent(new CustomEvent('change', { detail: { name: src.name, values } }));
            }
        };
        const container = this.charts[src.index];
        const selected = this.getValues()[src.name];
        if (this.chartType === 'pie') {
            if (update) {
                updateDonutPlot(container, src.options.slice(0, 5), selected);
            }
            else {
                drawDonutPlot(container, null, donutSpec, src.options.slice(0, 5), selected, callback);
            }
        }
        else if (this.chartType === 'bigpie') {
            if (update) {
                updateDonutPlot(container, src.options.slice(0, 20), selected);
            }
            else {
                drawDonutPlot(container, this.htmlInputs[src.index].title, bigDonutSpec, src.options.slice(0, 20), selected, callback);
            }
        }
    }
}
