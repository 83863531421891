export const CHAR_SPACER = String.fromCharCode(31);
export const NEWLINE = '\n';

export function clearDoubleCharSpacer(text: string) {
    const char31 = CHAR_SPACER;
    let result = text.trim().toLowerCase().replace(/\s/g, char31);
    while (result.indexOf(char31 + char31) > -1) {
        result = result.replace(char31 + char31, char31);
    }
    return result;
}

// Constant values and functions for computing distance
const peq = new Uint32Array(0x10000);
// Faster method only good for short strings
function myers32(a: string, b: string) {
    const n = a.length;
    const m = b.length;
    const lst = 1 << (n - 1);
    let pv = -1;
    let mv = 0;
    let sc = n;
    let i = n;
    while (i--) {
        peq[a.charCodeAt(i)] |= 1 << i;
    }
    for (i = 0; i < m; i++) {
        let eq = peq[b.charCodeAt(i)];
        const xv = eq | mv;
        eq |= ((eq & pv) + pv) ^ pv;
        mv |= ~(eq | pv);
        pv &= eq;
        if (mv & lst) {
            sc++;
        }
        if (pv & lst) {
            sc--;
        }
        mv = (mv << 1) | 1;
        pv = (pv << 1) | ~(xv | mv);
        mv &= xv;
    }
    i = n;
    while (i--) {
        peq[a.charCodeAt(i)] = 0;
    }
    return sc;
}
// Slower but good for any length
function myersX(b: string, a: string) {
    const n = a.length;
    const m = b.length;
    const mhc = [];
    const phc = [];
    const hsize = Math.ceil(n / 32);
    const vsize = Math.ceil(m / 32);
    for (let i = 0; i < hsize; i++) {
        phc[i] = -1;
        mhc[i] = 0;
    }
    let j = 0;
    let mv: number;
    let pv: number;
    let start: number;
    let vlen: number;
    for (; j < vsize - 1; j++) {
        mv = 0;
        pv = -1;
        start = j * 32;
        vlen = Math.min(32, m) + start;
        for (let k = start; k < vlen; k++) {
            peq[b.charCodeAt(k)] |= 1 << k;
        }
        for (let i = 0; i < n; i++) {
            const eq = peq[a.charCodeAt(i)];
            const pb = (phc[(i / 32) | 0] >>> i % 32) & 1;
            const mb = (mhc[(i / 32) | 0] >>> i % 32) & 1;
            const xv = eq | mv;
            const xh = ((((eq | mb) & pv) + pv) ^ pv) | eq | mb;
            let ph = mv | ~(xh | pv);
            let mh = pv & xh;
            if ((ph >>> 31) ^ pb) {
                phc[(i / 32) | 0] ^= 1 << i % 32;
            }
            if ((mh >>> 31) ^ mb) {
                mhc[(i / 32) | 0] ^= 1 << i % 32;
            }
            ph = (ph << 1) | pb;
            mh = (mh << 1) | mb;
            pv = mh | ~(xv | ph);
            mv = ph & xv;
        }
        for (let k = start; k < vlen; k++) {
            peq[b.charCodeAt(k)] = 0;
        }
    }
    mv = 0;
    pv = -1;
    start = j * 32;
    vlen = Math.min(32, m - start) + start;
    for (let k = start; k < vlen; k++) {
        peq[b.charCodeAt(k)] |= 1 << k;
    }
    let score = m;
    for (let i = 0; i < n; i++) {
        const eq = peq[a.charCodeAt(i)];
        const pb = (phc[(i / 32) | 0] >>> i % 32) & 1;
        const mb = (mhc[(i / 32) | 0] >>> i % 32) & 1;
        const xv = eq | mv;
        const xh = ((((eq | mb) & pv) + pv) ^ pv) | eq | mb;
        let ph = mv | ~(xh | pv);
        let mh = pv & xh;
        score += (ph >>> ((m % 32) - 1)) & 1;
        score -= (mh >>> ((m % 32) - 1)) & 1;
        if ((ph >>> 31) ^ pb) {
            phc[(i / 32) | 0] ^= 1 << i % 32;
        }
        if ((mh >>> 31) ^ mb) {
            mhc[(i / 32) | 0] ^= 1 << i % 32;
        }
        ph = (ph << 1) | pb;
        mh = (mh << 1) | mb;
        pv = mh | ~(xv | ph);
        mv = ph & xv;
    }
    for (let k = start; k < vlen; k++) {
        peq[b.charCodeAt(k)] = 0;
    }
    return score;
}

// Computes the distance - assumes a and b are not null or undefined and same cased
function distanceInternal(a: string, b: string): number {
    if (a.length < b.length) {
        const tmp = b;
        b = a;
        a = tmp;
    }
    if (b.length === 0) {
        return a.length;
    }
    if (a.length <= 32) {
        return myers32(a, b);
    }
    return myersX(a, b);
}

// Find the distance between two strings, first checking that they are not null or undefined and normalizing case
export function distance(str1: string, str2: string): number {
    if (str1 && str2) {
        // Removing case sensitivity
        return distanceInternal(str1.toUpperCase(), str2.toUpperCase());
    }
    else if (!str1 && str2) {
        return str2.length;
    }
    else if (str1 && !str2) {
        return str1.length;
    }
    return Number.MAX_SAFE_INTEGER;
}

export function oldDistance(str1: string, str2: string): number {
    // Removing case sensitivity
    if (str1 && str2) {
        str1 = str1.toUpperCase();
        str2 = str2.toUpperCase();
        // First string must be the larger string, swapping if not
        if (str1.length < str2.length) {
            const swap: string = str2;
            str2 = str1;
            str1 = swap;
        }
        // Building the two dimensional matrix needed for calculation and
        // padding with zeroes
        const str1Arr: string[] = str1.split('');
        const str2Arr: string[] = str2.split('');
        const matrix = new Array(str1.length + 2);
        // Init / fill 2d array with zeros
        for (let i = 0; i < str1.length + 2; i++) {
            matrix[i] = new Array(str2.length + 2);
            for (let j = 0; j < str2.length + 2; j++) {
                matrix[i][j] = 0;
            }
        }
        // filling the top row and first column with the number index
        for (let i = 1; i < str1.length + 2; i++) {
            matrix[i][0] = i;
            matrix[0][i] = i;
        }
        // Comparing each letter of both strings to see if a change is needed
        for (let j = 1; j < str2.length + 2; j++) {
            for (let i = 1; i < str1.length + 2; i++) {
                let cost: number = 0;
                if (str1Arr[i - 1] !== str2Arr[j - 1]) {
                    cost = 1;
                }
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,         // deletion
                    matrix[i][j - 1] + 1,         // insertion
                    matrix[i - 1][j - 1] + cost,  // substitution
                );
            }
        }
        // The bottom right-most index of the matrix has the total number of changes
        return matrix[str1.length + 1][str2.length + 1];
    }
    else {
        if (!str1 && str2) {
            return str2.length;
        }
        else if (str1 && !str2) {
            return str1.length;
        }
        else {
            return Number.MAX_SAFE_INTEGER;
        }
    }
}

// Check for text duplication by distance comparing against anything stored in the already array
// The idea is that initially the already array is empty and while you keep testing texts those are
// added to the array
export function isDuplicatedViaLevenshtein(text: string, already: string[]): boolean {
    const IS_THE_SAME_PERCENTAGE = 0.85;
    // On a test of 1,000 sentences pulled from prototype/Americas...
    //                       Duplicates found       Time to process     Tests pass
    // Full sentence match                246                  1:48            Yes
    // 45 character diff                  242                  0:20            Yes  <- Seems the safest for now.
    // 30 character diff                  239                  0:15             No
    // 20 character diff                  248                  0:12     Yes, but not as predictable
    const CHARACTERS_TO_DIFF = 45;
    let sentenceDuplicated = false;
    const sKey = simplifySentence(text);
    if (already.includes(sKey)) {
        sentenceDuplicated = true;
    }
    let i = 0;
    while (!sentenceDuplicated && i < already.length) {
        const oldKey = already[i];
        // Create a balance between comparing entire mentions and time to compare
        const equalPercentage = levenshteinSimilarityPercentage(sKey.substr(0, CHARACTERS_TO_DIFF), oldKey.substr(0, CHARACTERS_TO_DIFF));
        if (equalPercentage >= IS_THE_SAME_PERCENTAGE) {
            sentenceDuplicated = true;
        }
        i++;
    }
    if (!sentenceDuplicated) {
        already.push(sKey);
    }
    return sentenceDuplicated;
}

export function jaroDistance(s1: string, s2: string): number {
    if (s1 === s2) {
        return 1.0;
    }

    // Length of two strings
    const len1 = s1.length;
    const len2 = s2.length;

    // Maximum distance upto which matching is allowed
    const maxDist = Math.floor(Math.max(len1, len2) / 2.0) - 1;

    // Count of matches
    let match = 0;

    // Hash for matches
    const hashS1 = Array(s1.length).fill(0);
    const hashS2 = Array(s1.length).fill(0);

    // Traverse through the first string
    for (let i = 0; i < len1; i++) {
        // Check if there are any matches
        for (let j = Math.max(0, i - maxDist); j < Math.min(len2, i + maxDist + 1); j++) {
            // If there is a match
            if (s1[i] === s2[j] && hashS2[j] === 0) {
                hashS1[i] = 1;
                hashS2[j] = 1;
                match++;
                break;
            }
        }
    }

    // If there is no match
    if (match === 0) {
        return 0.0;
    }

    // Number of transpositions
    let t = 0;
    let point = 0;

    // Count number of occurrences where two characters match but
    // there is a third matched character in between the indices
    for (let i = 0; i < len1; i++) {
        if (hashS1[i]) {
            // Find the next matched character in second string
            while (hashS2[point] === 0) {
                point++;
            }
            if (s1[i] !== s2[point++]) {
                t++;
            }
        }
    }

    t /= 2;

    // Return the Jaro Similarity
    return ((match) / (len1) + (match) / (len2) + (match - t) / (match)) / 3.0;
}

// Returns a number between 0 (different) and 1 (same) inclusive
// 1 means the sentences are exactly the same
// > .90 means a small number of words, whitespace, or punctuation is different
export const JARO_WINKLER_SAME_MEANING = 0.9;
// ~.80 means the two phrases have some words in commin
// .45 is a score for two completely different sentences
export function jaroWinklerDistance(s1: string, s2: string): number {
    let jaroDist = jaroDistance(s1, s2);
    // Algorithm only rewards a phrase that has minimum similarity
    if (jaroDist > 0.7) {
        // Find the length of common prefix
        let prefix = 0;
        for (let i = 0; i < Math.min(s1.length, s2.length); i++) {
            // If the characters match
            if (s1[i] === s2[i]) {
                prefix++;
            }
            // Else break
            else {
                break;
            }
        }
        // Maximum of 4 characters are allowed in prefix
        prefix = Math.min(4, prefix);
        // Calculate jaro winkler Similarity
        jaroDist += 0.1 * prefix * (1 - jaroDist);
    }
    return jaroDist;
}

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email: string) {
    if (!email) {
        return false;
    }
    return re.test(email.toLowerCase());
}

const freeDomainList = [
    // 'gmail.com',
    'msn.com',
    'yahoo.com',
    // 'outlook.com',
    'icloud.com',
    'aol.com',
    'aim.com',
    'mail.com',
    'yandex.com',
    'gmx.com',
    'gmx.us',
    'protonmail.com',
    'proton.me',
    'tutanota.de',
    'tutanota.com',
    'tutamail.com',
    'tuta.io',
    'keemail.me',
    'zohomail.com',
    'cazlp.com',                // Look out for disposable email addresses too
];

export function checkEmailDomain(email: string) {
    const domain = email.substring(email.indexOf('@') + 1);
    if (!freeDomainList.includes(domain)) {
        return true;
    }
    return false;
}

// Return a copy of an object with the specified fields trimmed
// Keeping this in a function can help produce consistent results when
//  there are several ways to get, save, and compare objects like User
export function trimFieldsOnObject<T>(obj: T, fieldsToTrim: string[]) {
    fieldsToTrim.forEach((fieldToTrim) => {
        if (obj[fieldToTrim] && typeof obj[fieldToTrim] === 'string') {
            obj[fieldToTrim] = obj[fieldToTrim].trim();
        }
    });
}

// Get a random string of an exact length
export function randomString(length: number) {
    let result = '';
    while (result.length < length) {
        // Generates a random string up to 10 long
        result += Math.random().toString(36).substring(2, 12);
    }
    return result.substring(0, length);
}

// Simple comparison function to sort two arrays of strings in alphabetical ascending order.
export const sortStrFn = (a: string, b: string) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

// Function to generate a sort function that will operate on the sub property of each object
// which is always a number by providing the property name as a string. I.e. [ { count: 5 }, { count: 10} ]
// The results of this sort will be returned from largest to smallest numerically.
export function getSortNumPropFn(prop: string) {
    return (a: {[key: string]: number| string}, b: {[key: string]: number| string}) => {
        if (a[prop] < b[prop]) {
            return 1;
        }
        if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    };
}

export function multiWordSearch(multiWordString: string, searchVal: string): boolean {
    // Searches each word in in a string with multiple words separated by spaces,
    // returns false if none of the words match the search value. Returns true if
    // any of the words in multiWordString match the search value.
    const arr = multiWordString.split(' ');
    for (let term of arr) {
        if (searchVal.length < term.length) {
            term = term.substr(0, searchVal.length);
        }
        if (term.toUpperCase() === searchVal.toUpperCase()) {
            return true;
        }
    }
    return false;
}

// This method has been tested with a few shapes but will likely require that the objects in the array
// contain only primitives, not nested objects or anything that can't have 'toString' called on it.
// However, that being said, it will allow us to send a file to the browser directly with no backend call.
// Use with the browserUtil 'download' function for that use case.
const safeCsvField = (m: string): string => m !== null && m !== undefined ? `"${m.toString().replace(/"/g, '""')}"` : '';
export function jsonToCSVString(exportArray: any[]): string {
    if (exportArray && exportArray.length) {
        const keys = Object.keys(exportArray[0]);
        const lines = [ keys.map((k: string) => safeCsvField(k)).join(',') ];
        lines.push(...exportArray.map((m) => keys.map((k: string) => safeCsvField(m[k])).join(',')));
        return lines.join('\n');
    }
    return '';
}

export function getFiltered(newArr: any[], term: string) {
    return newArr.filter((item) => {
        let found: boolean = true;
        if (item.clientName && item.label) {
            if (!(multiWordSearch(item.clientName, term) ||
                multiWordSearch(item.label, term))) {
                found = false;
            }
        }
        return found;
    });
}

// simplify sentence to compare
function simplifySentence(sentence: string) {
    return sentence.toLowerCase().replace(/[^0-9a-z]/g, '');
}

// get similarity percentage by Levenshtein Distance
// NOTE: this method assumes you have validated that both strings are not null, undefined,
// and have the same case (unless you want different case to be considered 'dissimilar')
export function levenshteinSimilarityPercentage(s1: string, s2: string) {
    const dist = distanceInternal(s1, s2);
    return (1.0 - (dist * 1.0 / Math.max(s1.length, s2.length)));
}

// Useful to cleanup whitespace in html data where tags are added/removed leaving different amounts of whitespace
export function collapseWhitespace(str: string): string {
    return str
        // Collapse any whitespace to a single space
        .replace(/\s{1,}/g, ' ')
        // Remove whitespace if that is the only thing between/within tags
        .replace(/>\s*</g, '><').trim();
}

// When Athena results are not yet/able to be formatted in a structured manner, this is useful to extract a list
export function getListFromString(detailedMarker: string, removeWhitespace: boolean): string[] {
    const regex = new RegExp('^\\[|\\]$' + (removeWhitespace ? '|\\s' : ''), 'g');
    return detailedMarker.replace(regex, '').split(',');
}

// Needed for building regex expressions from RegExp and string interpolation
// Otherwise, input strings could have regex characters and have undesired match results
export function escapeRegExp(regex: string) {
    return regex ? regex.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : regex;
}

// Capitalize the first character of a string
// Useful for titles and markers
// Pass null through: don't hide what seems to be unanticipated null inputs
export function firstCharUpper(text: string): string {
    if (!text) {
        return null;
    }
    return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}
