import { getYearMonthDayOfISODate } from '../../../../shared/util/dateUtil';
import { addLoginListener } from '../../layout';
import { getById, onClick } from '../../util/html';
import { ClModal } from '../../util/modalWidget';
import { STORAGE_INFO, fetchCacheKey, removeCacheKey, setCacheKey } from '../../util/storageUtil';
const timeMachineTemplate = require('./timeMachine.pug');

export class TimeMachine {
    private date: string;
    private onSubmit: () => void;
    private active: boolean;

    constructor(onSubmit?: () => void) {
        addLoginListener(() => {
            const date = fetchCacheKey<string>(STORAGE_INFO.timeMachineDate.scope, STORAGE_INFO.timeMachineDate.key);
            this.active = !!date;
            this.date = date || this.today();
            this.onSubmit = onSubmit;
        });
    }

    public now(): Date {
        if (this.active) {
            const date = new Date(this.date);
            date.setUTCHours(new Date().getUTCHours(), new Date().getUTCMinutes());
            return date;
        }
        return new Date();
    }

    public nowAsStr(): string {
        return this.active ? this.date : this.today();
    }

    public mount(container: HTMLElement, modal: ClModal) {
        container.innerHTML = timeMachineTemplate();
        const selector = getById<HTMLInputElement>('tm-dateSelector');
        selector.value = this.date;
        onClick(getById('tm-resetBtn'), () => {
            selector.value = this.today();
        });
        onClick(getById('tm-applyBtn'), () => {
            this.setDate(selector.value);
            this.onSubmit();
        });
        onClick(getById('tm-cancelBtn'), () => {
            modal.close();
        });
    }

    public reset() {
        removeCacheKey(STORAGE_INFO.timeMachineDate.scope, STORAGE_INFO.timeMachineDate.key);
        this.date = this.today();
        this.active = false;
    }

    public setDate(date: string) {
        this.active = true;
        if (!date || date === this.today()) {
            this.reset();
        }
        else {
            this.date = date;
            setCacheKey(STORAGE_INFO.timeMachineDate.scope, STORAGE_INFO.timeMachineDate.key, date);
        }
    }

    public isActive() {
        return this.active;
    }

    private today() {
        return getYearMonthDayOfISODate(new Date().toISOString());
    }
}
