import { getClLogger } from '../../shared/util/clLogger';
import { attr, getById, onClick, qs, qsa, show } from '../lib/util/html';
import { STORAGE_INFO, fetchCacheKey, setCacheKey } from '../lib/util/storageUtil';

// TODO: Remove cllogger
const clLogger = getClLogger(__filename);

// Use to toggle theme between light and dark mode
export function wireThemeToggle() {
    const menuLink = getById('themeList');
    const preferredTheme = getPreferredTheme();
    // Set the theme for the root node of the document to affect all html elements
    attr(document.documentElement, 'data-bs-theme', preferredTheme);
    wireToggleBtns();
    showActiveTheme(preferredTheme);
    show(menuLink);
}

function showActiveTheme(theme: string) {
    // Retrieve the toggle for swapping themes
    const themeSwitcher = getById('themeToggle');
    if (!themeSwitcher) {
        return;
    }
    show(themeSwitcher);

    // Highlight the option that represents the currently-selected theme
    const btnToActive = qs(`[data-bs-theme-value='${theme}']`);
    // Remove any highlights from the non-used theme/s
    qsa('[data-bs-theme-value]').forEach(toggle => {
        toggle.classList.remove('active');
        attr(toggle, 'aria-pressed', 'false');
    });

    btnToActive.classList.add('active');
    attr(btnToActive, 'aria-pressed', 'true');
}

// Check local storage for a preferred theme. If none set, default to 'dark' mode
function getPreferredTheme() {
    const storedTheme = fetchCacheKey<string>(STORAGE_INFO.theme.scope, STORAGE_INFO.theme.key);

    if (storedTheme) {
        return storedTheme;
    }
    return 'dark';
}

function wireToggleBtns() {
    // Wire for click
    qsa('[data-bs-theme-value]').forEach(toggle => {
        onClick(toggle, () => {
            const theme = attr(toggle, 'data-bs-theme-value');
            // Locally store the chosen theme
            setCacheKey(STORAGE_INFO.theme.scope, STORAGE_INFO.theme.key, theme);
            // Update the theme
            showActiveTheme(theme);
            updateProgressBar(theme);
            attr(document.documentElement, 'data-bs-theme', theme);
            clLogger.debug('TOGGLE WAS CLICKED: ', theme);
        });
    });
}

export function updateProgressBar(theme: string) {
    const progressLogo = getById('progressLogo');
    const progressLogoPrint = getById('progressLogoPrint');
    if (theme === 'light' && progressLogo) {
        attr(progressLogo, 'src', '/img/causality-logo-dark.png');
    }
    else if (theme === 'dark' && progressLogoPrint) {
        attr(progressLogoPrint, 'src', '/img/causality-logo-white.png');
    }
}
