import { IStatsRow } from '../interfaces/dashboard';
import { IMarkerTreeNode } from '../interfaces/marker/marker';
import { IObjectWithChildren } from '../interfaces/util';
import { getClLogger } from './clLogger';
const clLogger = getClLogger(__filename);

// Simple helper that can turn an array into an object keyed using some function. This is
// used often in imports so we can quickly check for each CSV row whether some concept
// already exists in the KB or not. It's built around the premise that these tables are
// relatively small, so we are much better off doing a large select and building a dictionary
// rather than overwhelm the database with queries. Note, this function will throw an error
// if you try to build a lookup and there is a duplicate key.
export function arrayToLookup<T, K = T>(arr: T[], keyFn: (a: T) => string | string[] | number | number[],
                                        valueFn?: (a: T) => K): { [key: string]: K } {
    if (arr) {
        const lookup: { [ key: string ]: K } = {};
        for (const a of arr) {
            const key = keyFn(a);
            if (key !== undefined && key !== null) {
                const value: K = valueFn ? valueFn(a) : (a as unknown as K);
                if (Array.isArray(key)) {
                    for (const k of key) {
                        if (lookup[k]) {
                            clLogger.error('Mapping array to lookup and got duplicate keys: ', k);
                            throw new Error('Duplicate key building lookup.');
                        }
                        lookup[k] = value;
                    }
                }
                else if (lookup[key]) {
                    clLogger.error('Mapping array to lookup and got duplicate keys: ', key);
                    throw new Error('Duplicate key building lookup.');
                }
                else {
                    lookup[key] = value;
                }
            }
        }
        return lookup;
    }
    return null;
}

// Similar to array to lookup, but supports duplicates. For a given key you will either get back undefined
// if there are no entries, or an array with at least one item.
export function arrayToLookupMany<T, K = T>(arr: T[], keyFn: (a: T) => string | string[] | number | number[],
                                            valueFn?: (a: T) => K): { [key: string]: K[] } {
    const lookup = {};
    for (const a of arr) {
        const key = keyFn(a);
        if (key !== undefined && key !== null) {
            const value = valueFn ? valueFn(a) : a;
            if (Array.isArray(key)) {
                for (const k of key) {
                    if (lookup[k] === undefined) {
                        lookup[k] = [];
                    }
                    lookup[k].push(value);
                }
            }
            else {
                if (lookup[key] === undefined) {
                    lookup[key] = [];
                }
                lookup[key].push(value);
            }
        }
    }
    return lookup;
}

// Return all the elements in a that are also in b, or an empty array.
export function getIntersection(a: string[], b: string[]): string[] {
    if (!a) {
        a = [];
    }
    if (!b) {
        b = [];
    }
    return a.filter((value) => -1 !== b.indexOf(value));
}

// Return all the elements in b that are not in a, or an empty array.
export function getUnique(a: string[], b: string[]): string[] {
    if (!b) {
        return [];
    }
    if (!a) {
        return b;
    }
    return b.filter((i) => {
        return a.indexOf(i) < 0;
    });
}

export function flatMapNoDuplicates<T>(array: T[], mapper: (elt: T) => (string[])) {
    const result = new Set<string>();
    array.map((obj) => (mapper(obj) || []).map((elt) => result.add(elt)));
    return [ ...result ];
}

export function removeDoubles(array: any[], key: string) {
    const result = [];
    for (const elt of array) {
        if (!result.find((e) => e[key] === elt[key])) {
            result.push(elt);
        }
    }
    return result;
}

// Simply sums an array of numbers
export function sum(array: number[]): number {
    return array.reduce((a, b) => a + b, 0);
}

// Objects whose shape repeats some properties first and then has some dynamic properties
// have been corrupted by Chrome 76-78. This makes sure the dynamic portion comes before the static.
// This issue has been seen in Node 12.x as well.
export function orderPropertiesForChrome(obj: IStatsRow) {
    const res = {};
    const keys = Object.keys(obj).sort((k) => [ 'aggregatedDate', 'date', 'conceptKey' ].includes(k) ? 1 : -1);
    for (const key of keys) {
        res[key] = obj[key];
    }
    return res as IStatsRow;
}

// Forces a boolean parameter to either use the explicit value 'true' or 'false', or it is invalid unless omitted completely.
export function getHashBoolParam(req: { [key: string]: any }, name: string): boolean | null {
    if (req && typeof req[name] === 'boolean') {
        return req[name];
    }
    const value: string = req[name] || null;
    if (value) {
        const lower = value.toLowerCase();
        if (lower === 'true') {
            return true;
        }
        else if (lower === 'false') {
            return false;
        }
        throw new Error(`The provided parameter ${name} had an invalid value: ${value}`);
    }
    return null;
}

// Useful to get back an array of strings, or an empty array for null inputs
export function getHashStringArrayParam(req: { [key: string]: any }, name: string, separator: string = ',',
                                        toLowerCase: boolean = true): string[] {
    const value: string = req[name] || null;
    if (value) {
        if (toLowerCase) {
            return value.split(separator).map((x) => {
                return x.toLowerCase();
            });
        }
        return value.split(separator);
    }
    return [];
}

export function arrayToTree<T>(markers: IObjectWithChildren<T>[], idField: string): (T & IObjectWithChildren<T>)[] {
    const map = {};
    const roots = [];
    for (const marker of markers) {
        marker.children = [];
        marker.childrenCount = 0;
        map[marker[idField]] = marker;
    }
    for (const marker of markers) {
        if (marker.parentId && map[marker.parentId]) {
            map[marker.parentId].children.push(marker);
            map[marker.parentId].childrenCount++;
        }
        else {
            roots.push(marker);
        }
    }
    return roots;
}

// Create a map with a numerical key
// This function is specific to nodes with a numeric id, and is mostly used for fast lookups in ajax tree UI component data
// Input: IMarkerTreeNode[]
// Output via parameter: Record<number, any>
// if no valueField means the entire object should be added to the map
export function treeToMap(map, treeLevel: IMarkerTreeNode[], idField: string, valueField?: string) {
    for (const t of treeLevel) {
        // If a special key is used as the value field, map the key to the original node instead of an actual value
        if (!valueField) {
            map[t[idField]] = t;
        }
        else {
            map[t[idField]] = t[valueField];
        }
        if (t.children) {
            treeToMap(map, t.children, idField, valueField);
        }
    }
}

// Convert any non-array single object into a single element array.
// Useful for ES interfaces that accept either a single element or an array
// Use this if an empty string should create an array element
export function toArray<T>(obj: T | T[]): T[] {
    let ret = obj as T[];
    if (obj === null || obj === undefined) {
        ret = [];
    }
    else if (!Array.isArray(obj)) {
        ret = [ obj ];
    }
    return ret;
}

// Handle array conversions including empty strings being passed from forms/links
// We don't want that to translate to [ '' ], because that's not a valid value for publisher, symbol, etc.
// Use this a simple split needs to return an empty array given an empty string.
export function csvToArrayWithoutEmptyStrings(csv: string): string[] {
    // Catch null or empty string
    if (!csv) {
        return [];
    }
    // Non empty string/empty-array/non-empty-array
    return csv.split(',');
}

// A short shim for generic flattening of arrays
// Collapse all embedded elements into a single dimension, maintaining order as read from left to right
// Levels is the number of levels to flatten
export function flattenArray<T>(arr: any[], levels: number = -1): T[] {
    // // Don't fail on null or undefined data
    arr = arr || [];
    // For all elements...
    return arr.reduce((acc, curr) => {
        const flatteningStillRequired = levels < 0 || levels > 0;
        // Recurse if the element is another array and there is still work to do
        // Otherwise add the element
        Array.isArray(curr) && flatteningStillRequired
            ? acc.push(...flattenArray(curr, levels - 1))
            : acc.push(curr);
        return acc;
    }, []);
}

export function getSingleFieldCSVValues<T>(objects: T[], field: string, quotes?: boolean): string {
    let values = '';
    if (objects && objects.length) {
        let join = ', ';
        if (quotes) {
            join = `"${join}"`;
        }
        values = objects.map((obj) => obj[field]).join(join);
        if (quotes && values) {
            values = `"${values}"`;
        }
    }
    return values;
}
