function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;
function pug_style(r){if(!r)return"";if("object"==typeof r){var t="";for(var e in r)pug_has_own_property.call(r,e)&&(t=t+e+":"+r[e]+";");return t}return r+""}function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (conversations, onClientPage, userStatus) {
      pug_html = pug_html + "\u003Cdiv\u003E";
// iterate conversations
;(function(){
  var $$obj = conversations;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var conversation = $$obj[pug_index0];
pug_html = pug_html + "\u003Cul class=\"body-text row pe-2\" style=\"color: black\"\u003E\u003Cspan" + (" class=\"col-1\""+pug_attr("id", `${conversation.id}-status`, true, true)+pug_attr("style", pug_style(`color: ${userStatus && userStatus[conversation.user] ? userStatus[conversation.user] : 'grey'};`), true, true)) + "\u003E●\u003C\u002Fspan\u003E\u003Ca" + (" class=\"col-8 online-user text-truncate\""+pug_attr("id", `${conversation.id}-user`, true, true)+pug_attr("data-id", `${conversation.id}`, true, true)+pug_attr("style", pug_style(onClientPage ? '' : 'color: black;'), true, true)+" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\""+pug_attr("title", conversation.user, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = conversation.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cspan" + (" class=\"col-2 ms-2 message-notif\""+pug_attr("id", `${conversation.id}-notif`, true, true)) + "\u003E\u003C\u002Fspan\u003E\u003C\u002Ful\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var conversation = $$obj[pug_index0];
pug_html = pug_html + "\u003Cul class=\"body-text row pe-2\" style=\"color: black\"\u003E\u003Cspan" + (" class=\"col-1\""+pug_attr("id", `${conversation.id}-status`, true, true)+pug_attr("style", pug_style(`color: ${userStatus && userStatus[conversation.user] ? userStatus[conversation.user] : 'grey'};`), true, true)) + "\u003E●\u003C\u002Fspan\u003E\u003Ca" + (" class=\"col-8 online-user text-truncate\""+pug_attr("id", `${conversation.id}-user`, true, true)+pug_attr("data-id", `${conversation.id}`, true, true)+pug_attr("style", pug_style(onClientPage ? '' : 'color: black;'), true, true)+" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\""+pug_attr("title", conversation.user, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = conversation.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cspan" + (" class=\"col-2 ms-2 message-notif\""+pug_attr("id", `${conversation.id}-notif`, true, true)) + "\u003E\u003C\u002Fspan\u003E\u003C\u002Ful\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "conversations" in locals_for_with ?
        locals_for_with.conversations :
        typeof conversations !== 'undefined' ? conversations : undefined, "onClientPage" in locals_for_with ?
        locals_for_with.onClientPage :
        typeof onClientPage !== 'undefined' ? onClientPage : undefined, "userStatus" in locals_for_with ?
        locals_for_with.userStatus :
        typeof userStatus !== 'undefined' ? userStatus : undefined));
    ;;return pug_html;};module.exports=template;