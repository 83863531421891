import { newPlot, react, restyle, Plots } from 'plotly.js';
import { IPerPlot, plotSymbol } from '../../../shared/interfaces/graph';
import { cloneDeep } from '../../../shared/util/cloneUtil';
import { getWrappedText, prettyNum } from '../../../shared/util/viewFormatUtil';
import { PlotlyElement, attr, qs, qsa } from '../util/html';

// These options will be used every time we call new plot or react.
// ShowLink -> Link to Chart Studio Cloud
// DisplayModeBar -> A set of 'mode' options. Some could be useful like download, but for now, hidden.
// ShowTips -> Top right floating tips like 'double click to reset zoom'
export const PLOTLY_GLOBAL_CONF = { showLink: false, displayModeBar: false, showTips: false };
const plotlyNewlineDelimiter = '<br>';

export function drawScatterPlot(graphDiv: PlotlyElement, title: string, graphRange: string[],
                                scatterSpec: any, scatterData: { x: string, y: number, text: string, symbol: plotSymbol, color: string | number }[],
                                onSelect: (selected: {xVal, yVal}[]) => void) {
    const graphData = cloneDeep(scatterSpec.data) as [ {
        x: string[],
        y: number[],
        text: string[],
        marker: {color: (string|number)[], symbol: plotSymbol[]},
        selectedpoints: number[] | null } ];
    const graphLayout = cloneDeep(scatterSpec.layout);
    const wrappedTitle = getWrappedText(title, 45, plotlyNewlineDelimiter);
    graphLayout.title = wrappedTitle;
    graphLayout.xaxis.range = graphRange;
    for (const data of scatterData) {
        graphData[0].x.push(data.x);
        graphData[0].y.push(data.y);
        graphData[0].text.push(getWrappedText(data.text, 50, plotlyNewlineDelimiter));
        graphData[0].marker.color.push(data.color);
        graphData[0].marker.symbol.push(data.symbol);
    }
    newPlot(graphDiv, graphData, graphLayout, PLOTLY_GLOBAL_CONF);
    graphDiv.on('plotly_click', (data) => {
        if (data && data.points) {
            graphData[0].selectedpoints = [];
            const clickedPoints = [];
            for (const p of data.points) {
                graphData[0].selectedpoints.push(p.pointIndex);
                const xVal = graphData[0].x[p.pointIndex];
                const yVal = graphData[0].y[p.pointIndex];
                clickedPoints.push({ xVal, yVal });
            }
            onSelect(clickedPoints);
            react(graphDiv, graphData, graphLayout, PLOTLY_GLOBAL_CONF);
        }
    });
    graphDiv.on('plotly_selected', (data) => {
        if (data && data.points) {
            const clickedPoints = [];
            for (const p of data.points) {
                graphData[0].selectedpoints.push(p.pointIndex);
                const xVal = graphData[0].x[p.pointIndex];
                const yVal = graphData[0].y[p.pointIndex];
                clickedPoints.push({ xVal, yVal });
            }
            onSelect(clickedPoints);
        }
    });

    graphDiv.on('plotly_hover', () => {
        qsa('g.hovertext').forEach((elem) => {
            elem.style.stroke = 'black';
            elem.style.strokeWidth = '.01%';
        });
    });
}

export function drawTrendBarPlot(graphDiv: PlotlyElement, title: string, graphRange: string[],
                                 trendBarSpec: any, trendData: {x1: string, x2: string, y1: number, y2: number}[],
                                 onClick: (date: string) => void) {
    const graphData = cloneDeep(trendBarSpec.data) as { x: string[], y: number[], selectedpoints: number[] | null }[];
    const graphLayout = cloneDeep(trendBarSpec.layout);
    graphLayout.title = title;
    graphLayout.xaxis.range = extendDateRange(graphRange);
    for (let date = subMonth(graphRange[0]); date <= addMonth(graphRange[1]); date = addMonth(date)) {
        const trend1 = trendData.find((e) => e.x1 === date);
        const trend2 = trendData.find((e) => e.x2 === date);
        graphData[0].x.push(date);
        graphData[0].y.push(trend1 ? trend1.y1 : null);
        graphData[1].x.push(date);
        graphData[1].y.push(trend2 ? trend2.y2 : null);
    }
    graphLayout.xaxis.dtick = 'M' + Math.ceil(graphData[0].x.length / 14);
    newPlot(graphDiv, graphData, graphLayout, PLOTLY_GLOBAL_CONF);

    graphDiv.on('plotly_hover', (e) => {
        for (const p of e.points) {
            const elt = qsa('.point path', qsa('.trace.bars', graphDiv)[p.curveNumber])[p.pointIndex];
            elt.style.stroke = elt.style.fill;
            elt.style.strokeWidth = '4px';
        }
    });
    graphDiv.on('plotly_unhover', (e) => {
        for (const p of e.points) {
            const elt = qsa('.point path', qsa('.trace.bars', graphDiv)[p.curveNumber])[p.pointIndex];
            elt.style.strokeWidth = '0';
        }
    });
    graphDiv.on('plotly_click', (data) => {
        if (data && data.points && data.points.length) {
            onClick(data.points[0].x.slice(0, 7));
        }
    });
}

const negative = 'rgb(220,53,69)';
const positive = 'rgb(40,167,69)';
const neutral = '#ffc107';

export function drawTrendPerPlot(graphDiv: PlotlyElement, title: string, graphRange: string[],
                                 trendPerSpec: any, trendData: {x: string, yLower: number, ySize: number}[], onClick: (date: string) => void) {
    const graphData = cloneDeep(trendPerSpec.data) as IPerPlot[];
    const graphLayout = cloneDeep(trendPerSpec.layout);
    graphLayout.title = title;
    graphLayout.xaxis.range = extendDateRange(graphRange);
    for (let date = subMonth(graphRange[0]); date <= addMonth(graphRange[1]); date = addMonth(date)) {
        const trend = trendData.find((e) => e.x === date);
        graphData[0].x.push(date);
        graphData[0].y.push(trend ? trend.ySize : null);
        graphData[0].base.push(trend ? trend.yLower : null);
        graphData[0].text.push(trend ? `Between ${prettyNum(trend.yLower * 100.0)}% and ${prettyNum((trend.yLower + trend.ySize) * 100.0)}%` : null);
        if (trend && trend.yLower > 0.5) {
            graphData[0].marker.color.push(positive);
        }
        else if (trend && trend.yLower + trend.ySize < 0.5) {
            graphData[0].marker.color.push(negative);
        }
        else {
            graphData[0].marker.color.push(neutral);
        }
    }
    graphLayout.xaxis.dtick = 'M' + Math.ceil(graphData[0].x.length / 14);
    newPlot(graphDiv, graphData, graphLayout, PLOTLY_GLOBAL_CONF);

    graphDiv.on('plotly_hover', (e) => {
        const elt = qsa('.trace.bars .point path', graphDiv)[e.points[0].pointIndex] as HTMLElement;
        elt.style.stroke = elt.style.fill;
        elt.style.strokeWidth = '4px';
    });
    graphDiv.on('plotly_unhover', (e) => {
        (qsa('.trace.bars .point path', graphDiv)[e.points[0].pointIndex] as HTMLElement).style.strokeWidth = '0';
    });
    graphDiv.on('plotly_click', (data) => {
        if (data && data.points && data.points.length) {
            onClick(data.points[0].x.slice(0, 7));
        }
    });
}

export function drawMentionPlot(graphDiv: PlotlyElement, title: string, graphRange: string[],
                                mentionSpec: any, mentionData: {x: string, y: number}[], onClick: (date: string) => void) {
    const graphData = cloneDeep(mentionSpec.data);
    const graphLayout = cloneDeep(mentionSpec.layout);
    graphLayout.title = title;
    graphLayout.xaxis.range = extendDateRange(graphRange);
    for (let date = subMonth(graphRange[0]); date <= addMonth(graphRange[1]); date = addMonth(date)) {
        const mention = mentionData.find((e) => e.x === date);
        graphData[0].x.push(date);
        graphData[0].y.push(mention ? mention.y : null);
    }
    graphLayout.xaxis.dtick = 'M' + Math.ceil(graphData[0].x.length / 14);

    newPlot(graphDiv, graphData, graphLayout, PLOTLY_GLOBAL_CONF);

    graphDiv.on('plotly_hover', (e) => {
        const elt = qsa('.trace.bars .point path', graphDiv)[e.points[0].pointIndex] as HTMLElement;
        elt.style.stroke = elt.style.fill;
        elt.style.strokeWidth = '4px';
    });
    graphDiv.on('plotly_unhover', (e) => {
        (qsa('.trace.bars .point path', graphDiv)[e.points[0].pointIndex] as HTMLElement).style.strokeWidth = '0';
    });

    graphDiv.on('plotly_click', (data) => {
        if (data && data.points && data.points.length) {
            onClick(data.points[0].x.slice(0, 7));
        }
    });
}

function extendDateRange(range) {
    return [
        subMonth(range[0]) + '-12',
        range[1] + '-20',
    ];
}

function addMonth(date: string) {
    const month = (+date.split('-')[1] % 12) + 1;
    const year = +date.split('-')[0];
    return `${month === 1 ? year + 1 : year}-${month < 10 ? '0' + month : month}`;
}

function subMonth(date: string) {
    let month = (+date.split('-')[1] - 1);
    month = month === 0 ? 12 : month;
    const year = +date.split('-')[0];
    return `${month === 12 ? year - 1 : year}-${month < 10 ? '0' + month : month}`;
}

export function updateDonutPlot(graphDiv: PlotlyElement, data: {label: string, value: string, count: number}[], selected: string[]) {
    const update = { values: [ [] ], labels: [ [] ], text: [ [] ] };
    for (const d of data) {
        update.values[0].push(d.count);
        update.labels[0].push(d.value);
        update.text[0].push(getWrappedText(d.label, 20, plotlyNewlineDelimiter));
    }
    restyle(graphDiv, update);

    graphDiv.shapeDiv.style.visibility = data.length ? 'hidden' : 'visible';
    graphDiv.slices = qsa('.slice', graphDiv);
    graphDiv.selected = selected || [];
    updateDonutSelection(graphDiv);
}

function updateDonutSelection(graphDiv: PlotlyElement, update?: string[]) {
    if (update && update.length) {
        for (const t of update) {
            const i = graphDiv.selected.indexOf(t);
            if (i >= 0) {
                graphDiv.selected.splice(i, 1);
            }
            else {
                graphDiv.selected.push(t);
            }
        }
    }

    if (graphDiv.selected.length) {
        for (const p of graphDiv.slices) {
            (p as HTMLElement).style.opacity = graphDiv.selected.includes(p.__data__.label) ? '1' : '0.4';
        }
    }
    else {
        for (const p of graphDiv.slices) {
            (p as HTMLElement).style.opacity = '1';
        }
    }
}

export function drawDonutPlot(graphDiv: PlotlyElement, title: string, spec: any, data: {label: string, value: string, count: number}[],
                              selected?: string[], onSelect?: (values: string[]) => void) {
    const graphData = cloneDeep(spec.data);
    const graphLayout = cloneDeep(spec.layout);
    graphLayout.title.text = title || '';
    for (const d of data) {
        graphData[0].values.push(d.count);
        graphData[0].labels.push(d.value);
        graphData[0].text.push(getWrappedText(d.label, 20, plotlyNewlineDelimiter));
    }
    newPlot(graphDiv, graphData, graphLayout, PLOTLY_GLOBAL_CONF);

    const hoverDiv = qs<SVGSVGElement>('.hoverlayer', graphDiv).parentElement;
    hoverDiv.style.left = `-${attr(hoverDiv, 'width')}px`;
    hoverDiv.style.paddingLeft = `${attr(hoverDiv, 'width')}px`;
    hoverDiv.style.overflow = 'visible';
    attr(hoverDiv, 'width', '' + Number.parseInt(attr(hoverDiv, 'width')) * 3);

    const shapeDiv = qs<SVGSVGElement>('.layer-below .shapelayer', graphDiv);
    const w = graphLayout.width / 2;
    shapeDiv.style.visibility = data.length ? 'hidden' : 'visible';
    shapeDiv.insertAdjacentHTML('beforeend',
        `<circle cx="${w}" cy="${w}" r="${0.7 * w}" style="stroke: #385d8a; fill-opacity: 0; stroke-width: 2px;"></path>`);

    graphDiv.shapeDiv = shapeDiv;
    graphDiv.slices = qsa('.slice', graphDiv);

    graphDiv.on('plotly_hover', (e) => {
        attr(e.event.target.parentElement, 'transform-origin', 'center');
        attr(e.event.target.parentElement, 'transform', 'scale(1.1)');
    });
    graphDiv.on('plotly_unhover', (e) => {
        attr(e.event.target.parentElement, 'transform', 'scale(1)');
    });

    graphDiv.selected = selected || [];

    if (onSelect) {
        graphDiv.on('plotly_click', (e) => {
            if (e && e.points && e.points.length) {
                updateDonutSelection(graphDiv, e.points.map((p) => p.label));
                onSelect(e.points.map((p) => p.label));
            }
        });
    }

    if (graphDiv.selected.length) {
        updateDonutSelection(graphDiv);
    }
}

export function drawLineChart(graphDiv: PlotlyElement, spec: any, xGraphRange: string[], yGraphRange: number[] | null,
                              data: {x: string, y: number | number[], text?: string}[][],
                              onClick?: (date: string) => void) {
    const graphData = cloneDeep(spec.data);
    const graphLayout = cloneDeep(spec.layout);
    if (xGraphRange) {
        graphLayout.xaxis.range = xGraphRange;
    }
    if (yGraphRange) {
        graphLayout.yaxis2.range = yGraphRange;
    }
    for (let k = 0; k < data.length; k++) {
        if (data[k]) {
            for (const d of data[k]) {
                graphData[k].x.push(d.x);
                graphData[k].y.push(d.y);
                if (d.text) {
                    graphData[k].text.push(d.text);
                }
            }
        }
    }

    newPlot(graphDiv, graphData, graphLayout, PLOTLY_GLOBAL_CONF);
    graphDiv.on('plotly_click', (d) => {
        if (onClick && data && d.points && d.points.length) {
            onClick(d.points[0].x.slice(0, 10));
        }
    });
}

export function drawStackedBarChart(graphDiv: PlotlyElement, spec: any, graphRange: string[], data: {x: string, y: number[] | number}[],
                                    onClick?: (date: string) => void) {
    const graphData = cloneDeep(spec.data);
    const graphLayout = cloneDeep(spec.layout);
    graphLayout.xaxis.range = graphRange;
    for (const d of data) {
        const numArray = Array.isArray(d.y) ? d.y : [ d.y ];
        for (const i in numArray) {
            if (graphData[i]) {
                graphData[i].x.push(d.x);
                graphData[i].y.push(numArray[i]);
            }
        }
    }
    newPlot(graphDiv, graphData, graphLayout, { ...PLOTLY_GLOBAL_CONF, responsive: true });

    graphDiv.on('plotly_hover', (e) => {
        for (const p of e.points) {
            const elt = qsa('.point path', qsa('.trace.bars', graphDiv)[p.curveNumber])[p.pointIndex];
            elt.style.stroke = elt.style.fill;
            elt.style.strokeWidth = '1px';
        }
    });
    graphDiv.on('plotly_unhover', (e) => {
        for (const p of e.points) {
            const elt = qsa('.point path', qsa('.trace.bars', graphDiv)[p.curveNumber])[p.pointIndex];
            elt.style.strokeWidth = '0';
        }
    });
    if (onClick) {
        graphDiv.on('plotly_click', (d) => {
            if (d && d.points && d.points.length) {
                onClick(d.points[0].x.slice(0, 10));
            }
        });
    }
}

export function resizeChart(div: HTMLDivElement) {
    Plots.resize(div);
}
