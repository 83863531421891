import { Acl } from '../../../shared/auth/acl';
import { AuthRoles } from '../../../shared/interfaces/acl/acl-i';
import { camelCaseName } from '../../../shared/util/viewFormatUtil';
import { qsa, tooltip } from '../util/html';
const conceptNavTmpl = require('./conceptNavTemplate.pug');

// Function to be used to sort numbers from largest to smallest. Allows
// for cases where one row is missing the number (null/undefined), and supports
// specification of a fallback number which is intended to be the output of another
// compare function (i.e. compare by some number column and fall back to name).
// If absValue is true, will compare them using absolute value of both, preferring positive
export function numCompare(a: number, b: number, fallback: number, absValue: boolean = false) {
    if (a !== undefined && a !== null && b !== undefined && b !== null) {
        if (absValue) {
            const absCompare = Math.abs(b) - Math.abs(a);
            if (absCompare !== 0) {
                return absCompare;
            }
        }
        // When they are equivalent use the fallback to sort consistently
        if ((b - a) === 0) {
            return fallback;
        }
        return b - a;
    }
    else if (a !== undefined && a !== null) {
        return -1;
    }
    else if (b !== undefined && b !== null) {
        return 1;
    }
    return fallback;
}

// Decorate any HMTL element with a simple class that CSS can style. Useful for large
// tables of numbers where we want to call out the winners and losers in red/green.
export function addNumClass(elem: HTMLElement, num: number) {
    if (num && num > 0) {
        elem.classList.add('upNum');
    }
    else if (!Number.isNaN(num) && num < 0) {
        elem.classList.add('downNum');
    }
}

export function renderConceptLink(target: HTMLDivElement | HTMLSpanElement,
                                  concept: { marker: string, kpi?: string, pretty?: string, prettyKpi?: string, encoded?: string },
                                  acl: Acl) {
    let fallBack = 'Unknown';
    if (concept.marker === 'world') {
        fallBack = 'World';
    }
    if (!concept.pretty) {
        concept.pretty = camelCaseName(concept.marker, fallBack);
    }
    concept.encoded = encodeURIComponent(concept.marker);
    if (concept.kpi) {
        concept.prettyKpi = camelCaseName(concept.kpi, 'KPI');
    }
    target.innerHTML = conceptNavTmpl({ acl, AuthRoles, concept });
    qsa('a[data-bs-toggle="tooltip"]')
        .forEach((elem: HTMLElement) => {
            tooltip(elem);
        });
}
