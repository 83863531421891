import { Props } from 'tippy.js';
import { IPublisher } from '../../../shared/interfaces/article/publishers';
import { IUserBookmarkCollectionAuthor, IUserBookmarkCollectionCompanyGroup } from '../../../shared/interfaces/bookmark/bookmark-i';
import { IClient } from '../../../shared/interfaces/client/client-i';
import { IFeatureStatus } from '../../../shared/interfaces/featureFlag';
export interface IClientFeatureFlagCache {
    cachedFlags: IFeatureStatus[];
    millisecondsSinceEpoch: number;
}

// This interface is described in gtag API
// (https://developers.google.com/analytics/devguides/collection/gtagjs/events)
export interface IGtagDetails {
    event_category: string; // ux, engagement, winning_action
    event_label: string;    // not yet sure how to make this useful to us
    value: number;          // a non negative value like length of search term, count, etc.
}

export interface IClientEdit extends IClient {
    authorsObj: IUserBookmarkCollectionAuthor[];
    companiesObj: IUserBookmarkCollectionCompanyGroup[];
    publishersObj: IPublisher[];
}

export interface IModalToggle {
    label: string;
    value: string;
    selected?: boolean;
    example: string;
    type: string;
}

export interface IEventInfo {
    name: string;
    path: string;
}

export interface IAutoResultItem {
    auto: string;
    index: string | number;
    html: boolean;
    contents: string;
}

export interface ITreeElement {
    id?: string;
    label: string;
    data_id?: string;
    data_label?: string;
    secondLabel?: string;
    childrenCount?: number;
    childrenLoaded?: boolean;
    children?: any[];
    neighbors?: any[];
    highlight?: boolean;
    parentId?: number | string;
}

interface ISelectGroupOption {
    value: string;
    label: string;
    selected: boolean;
    count: number;
}

export interface ISelectGroupInput {
    name: string;
    label: string;
    index: number;
    options: ISelectGroupOption[];
}

// Helper type to help manage the transition between proxy and real objects
// Abstract tooltip details in case we want to swap out implementations with jQuery5
export interface IProxyCardPopup {
    _hide?: () => void;
    _show?: () => void;
    reference?: HTMLElement;
    options?: Props;
    popper?: HTMLElement;
}

export enum SELECT_GROUP_LABELS {
    'baseKPI' = 'KPI',
    'baseEvent' = 'Event',
    'fromCompany__toCompany' = 'Company',
    'fromLocation__toLocation' = 'Location',
    'fromPerson__toPerson' = 'Person',
}

export enum SPINNER_ERROR_TYPE {
    WARNING = 1,
    ERROR =2,
}

export enum CacheScope {
    Global = 'global',
    User = 'user',
    Tab = 'tab',
}

export enum KEY_POSITION {
    middle = 1,
    suffix = 2,
    prefix = 3,
}

export enum ModalSizeEnum {
    Normal = '',
    Large = 'lg',
    XLarge = 'xl',
}

export type SelectAction = 'selectAll' | 'deselectAll' | 'render' | 'mobile' | 'refresh' | 'toggle' | 'hide' | 'show' | 'destroy';
