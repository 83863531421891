function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (acl, isEnabled, roles) {
      let otherCount = 0;
let kpiNav = acl.hasRole(roles.KpiNavigator) && ++otherCount;
let novRep = acl.hasRole(roles.NoveltyReport) && ++otherCount;
let mipRep = acl.hasRole(roles.MipViewer) && ++otherCount;
if (acl.hasRole(roles.DriversRead)) {
pug_html = pug_html + "\u003Cli class=\"nav-item\"\u003E\u003Ca class=\"nav-link\" id=\"causalityMenuLink\" href=\"\u002Fdashboard\"\u003ETrending\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"nav-item\"\u003E\u003Ca class=\"nav-link\" id=\"portfolioMenuLink\" href=\"\u002Fhome\"\u003EPortfolio\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
if (acl.hasRole(roles.AnalysisRead)) {
pug_html = pug_html + "\u003Cli class=\"nav-item\"\u003E\u003Ca class=\"nav-link\" id=\"analysisMenuLink\" href=\"\u002Fanalysis\"\u003EAnalysis\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
if (acl.hasRole(roles.KnowledgeRead)) {
pug_html = pug_html + "\u003Cli class=\"nav-item\"\u003E\u003Ca class=\"nav-link\" id=\"knowledgeMenuLink\" href=\"\u002Fadmin\u002Fmarker\"\u003EKnowledge\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
if ((otherCount === 1)) {
if (kpiNav) {
pug_html = pug_html + "\u003Cli class=\"nav-item\"\u003E\u003Ca class=\"nav-link\" id=\"kpiNavigatorMenuLink\" href=\"\u002FkpiNavigator\"\u003EKPI Navigator\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
if (novRep) {
pug_html = pug_html + "\u003Cli class=\"nav-item\"\u003E\u003Ca class=\"nav-link\" id=\"noveltyReportMenuLink\" href=\"\u002FnoveltyReport\"\u003ENovelty Report\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
if (mipRep) {
pug_html = pug_html + "\u003Cli class=\"nav-item\"\u003E\u003Ca class=\"nav-link\" id=\"mipViewerMenuLink\" href=\"\u002FmipViewer\"\u003EMIP Viewer\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
}
else
if ((otherCount > 1)) {
pug_html = pug_html + "\u003Cli class=\"nav-item dropdown\"\u003E\u003Ca class=\"nav-link dropdown-toggle\" id=\"otherMenuLink\" href=\"#\" data-bs-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"\u003EOther\u003C\u002Fa\u003E\u003Cdiv class=\"dropdown-menu\" aria-labelledby=\"otherMenuLink\"\u003E";
if (acl.hasRole(roles.KpiNavigator)) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"kpiNavigatorMenuLink\" href=\"\u002FkpiNavigator\"\u003EKPI Navigator\u003C\u002Fa\u003E";
}
if (acl.hasRole(roles.NoveltyReport)) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"noveltyReportMenuLink\" href=\"\u002FnoveltyReport\"\u003ENovelty Report\u003C\u002Fa\u003E";
}
if (acl.hasRole(roles.MipViewer)) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"mipViewerMenuLink\" href=\"\u002FmipViewer\"\u003EMIP Viewer\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E";
}
if ((acl.hasRole(roles.AnalysisWrite) || acl.hasRole(roles.UserRead))) {
pug_html = pug_html + "\u003Cli class=\"nav-item dropdown\"\u003E\u003Ca class=\"nav-link dropdown-toggle\" id=\"adminMenuLink\" href=\"#\" data-bs-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"\u003EAdmin\u003C\u002Fa\u003E\u003Cdiv class=\"dropdown-menu\" aria-labelledby=\"adminMenuLink\"\u003E";
if (acl.hasRole(roles.AnalysisWrite)) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"addArticleMenuLink\" href=\"\u002Fadmin\u002FaddArticle\"\u003EAdd Article\u003C\u002Fa\u003E";
}
if (acl.hasRole(roles.CLAdmin)) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"manageUsersArticleMenuLink\" href=\"\u002Fadmin\u002FmanageUsers\"\u003EManage Users\u003C\u002Fa\u003E\u003Ca class=\"dropdown-item\" id=\"manageVersionsMenuLink\" href=\"\u002Fadmin\u002FmanageVersions\"\u003EManage Versions\u003C\u002Fa\u003E\u003Ca class=\"dropdown-item\" id=\"manageClientsMenuLink\" href=\"\u002Fadmin\u002FclientAdm\"\u003EManage Clients\u003C\u002Fa\u003E\u003Ca class=\"dropdown-item\" id=\"managePublishersMenuLink\" href=\"\u002Fadmin\u002FpublisherAdm\"\u003EManage Publishers\u003C\u002Fa\u003E\u003Ca class=\"dropdown-item\" id=\"featureFlagsMenuLink\" href=\"\u002Fadmin\u002Fflags\"\u003EFeature Flags\u003C\u002Fa\u003E\u003Ca class=\"dropdown-item\" id=\"athenaQueriesMenuLink\" href=\"\u002Fadmin\u002Fathena\"\u003EAthena Queries\u003C\u002Fa\u003E\u003Ca class=\"dropdown-item\" id=\"systemTestsMenuLink\" href=\"\u002Fadmin\u002Fcl\u002FsystemTests\"\u003ESystem\u003C\u002Fa\u003E";
if (isEnabled) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item\" id=\"chatMenuLink\" href=\"\u002Fadmin\u002FadminDms\"\u003EClient Chat\u003C\u002Fa\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fli\u003E";
}
if ((acl.hasRole(roles.CLAdmin))) {
pug_html = pug_html + "\u003Cli class=\"nav-item\" id=\"adminDMs\"\u003E\u003Cbutton class=\"btn transparent-button adminDm-btn\" id=\"adminDmBtn\"\u003E\u003Cdiv style=\"position: relative;\"\u003E\u003Ci class=\"fa fa-comment\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"message-notif\" id=\"adminDmNotifs\" style=\"position: absolute; left: 0.75rem; bottom: 0.75rem;\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fbutton\u003E\u003C\u002Fli\u003E";
}
    }.call(this, "acl" in locals_for_with ?
        locals_for_with.acl :
        typeof acl !== 'undefined' ? acl : undefined, "isEnabled" in locals_for_with ?
        locals_for_with.isEnabled :
        typeof isEnabled !== 'undefined' ? isEnabled : undefined, "roles" in locals_for_with ?
        locals_for_with.roles :
        typeof roles !== 'undefined' ? roles : undefined));
    ;;return pug_html;};module.exports=template;