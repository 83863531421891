import { ajaxGet } from '../ajax';
import { IAutoResultItem } from '../interfaces/static';
import { attr, create, ENTER_KEYCODE, getByClassFirst, onClick, onKeyDown, onKeyUp, qsa, rAttr, tooltip } from '../util/html';
require('./autocomplete.less');
const commonTemplate = require('./autocomplete.pug');

const NO_RESULTS_TEXT = 'No results';

// The component class renders a text search bar which can make recommendations and
// help the user complete their search with either a static collection in memory or by
// making calls to an AJAX service based on the entered text
export class Autocomplete {
    // Static so that we can render more than one on a page
    private static tabindex = 1;
    // Default length before making recommendations. Some use cases (stock symbols) require
    // acting sooner, whereas large complex data sets (KB) give better results by waiting
    public minSuggestSize: number = 1;
    // How many results to render from the top suggestions. Could be limited by the service in AJAX mode.
    private firstLimitSuggestions: number = 10;
    private maxSuggestions: number = 25;
    private searchBar: HTMLInputElement;
    private searchList: HTMLDivElement;
    // Clear can be used to empty the text and also to reset the results outside the bar
    private clearBtn: HTMLDivElement;
    // Equivalent to hitting enter. If we need the user to be very specific and choose one, this can be disabled.
    private searchBtn: HTMLDivElement;
    private currentSuggestions: any[] = [];
    private currentSelected: any = null;
    // This is used to delay searching until the bar stabilizes
    private inputTimeout: number;
    // If the user types three letters and gets suggestions, keeps typing, and then backspace, don't query again.
    private searchCache: { [term: string]: any[] } = {};
    // Only set when no api endpoint
    private staticData: { [key: string]: string}[] = null;
    // When the user hits enter or presses the search button
    private submitting: boolean = false;
    // Signal to parent page the user has hit clear
    private onCleared = [];
    // The user could hit enter or search at any time and by default this should
    // tell the parent page that a fuzzy search for that term needs to start. This mode
    // can be disabled if you require a specific selection
    private onFuzzySearched = [];
    // When the user clicks or hits enter and we know exactly one suggestion they are
    // trying to 'select', we will raise this event which can return the entire item
    private onSpecificSearched = [];
    // This will be included when the common pug template is rendered.
    private initExtras: any = null;

    constructor(private apiEndpointFn: (searchTerm: string) => {url: string}, // Function takes search term and returns API url
                private resultItemHandler: (suggestion: any, index: number) => IAutoResultItem,
                private allowFuzzy: boolean, // Can the user submit open text that may or may not be an exact match?
                private showEmptySuggestions: boolean = false, // If true the service will try to show suggestions immediately upon focus
    ) {
    }

    // A static object to include as part of the base state when invoking the common template. This is just added for backwards compatibility
    // and instead if you want to customize the look/feel of the widget, simply create an extension class with a different template.
    public setInitExtras(initExtras) {
        this.initExtras = initExtras;
    }

    public setSearchValue(value) {
        this.searchBar.value = value;
    }

    // Rather than using an API for each search, fall back to looking through a static collection of all options.
    // The static collection should be an array of objects, with one or more keys in the object returning a string value.
    // Each string will be tokenized on white space and then can be matched by starting with the search phrase or containing.
    public setStaticData(collection: { [key: string]: string}[]) {
        if (this.apiEndpointFn) {
            throw new Error('To use a static autocomplete, do not set an API endpoint function.');
        }
        this.staticData = collection;
    }

    public render(target: HTMLDivElement) {
        // Render the base DOM
        target.innerHTML = this.initExtras ? commonTemplate(this.initExtras) : commonTemplate();
        // Get a handle on the sub elements
        this.searchList = getByClassFirst<HTMLDivElement>('input-auto', target);
        this.searchBar = getByClassFirst<HTMLInputElement>('searchBar', target);
        this.searchBtn = getByClassFirst<HTMLDivElement>('sub-btn', target);
        this.clearBtn = getByClassFirst<HTMLDivElement>('clr-btn', target);
        // Adjust the styling which has to be dynamic
        this.searchList.style.top = '37px';
        // Wire up events
        onKeyUp(this.searchBar, this.onKeyUp);
        onClick(this.searchBar, this.onSearchBarClick);
        onKeyDown(this.searchBar, this.onKeyDown);
        onClick(this.searchList, this.onSearchListClick);
        onKeyDown(this.searchList, this.onKeyDown);
        onClick(this.searchBtn, this.onSearchExec);
        onClick(this.clearBtn, this.onClickClear);
        attr(this.searchBar, 'tabindex', (Autocomplete.tabindex++).toString());
        attr(this.searchBtn, 'tabindex', (Autocomplete.tabindex++).toString());
        attr(this.clearBtn, 'tabindex', (Autocomplete.tabindex++).toString());
        // If there is only one it will start with focus
        this.searchBar.focus();
        // This will allow us to close the suggestions if the user clicks outside
        onClick(document, this.onClickOutsideTarget);
        if (this.allowFuzzy === false) {
            attr(this.searchBtn, 'hidden', 'hidden');
        }
        qsa('button[data-bs-toggle="tooltip"]')
            .forEach((elem: HTMLElement) => {
                tooltip(elem);
            });
    }

    // Simple static search looks through all the values to find those that include the term
    private searchStaticData(term: string): any[] {
        return this.staticData.filter((val) => {
            for (const key in val) {
                if (typeof val[key] === 'string' && val[key].toLowerCase().indexOf(term.toLowerCase()) > -1) {
                    return true;
                }
            }
            return false;
        });
    }

    // Use the current value in the search bar to get a set of results
    private async triggerSearch() {
        const term = this.searchBar.value.trim();
        if (term && term.length) {
            this.searchList.innerHTML = '';
            attr(this.searchList, 'hidden', 'hidden');
            let showNoResults = true;
            if (this.submitting) {
                this.submitting = false;
                for (const e of this.onFuzzySearched) {
                    e(term);
                }
                this.currentSuggestions = [];
                showNoResults = false;
            }
            // Check the cache
            else if (this.searchCache[term] !== undefined) {
                this.currentSuggestions = this.searchCache[term];
            }
            else if (this.apiEndpointFn) {
                const obj = this.apiEndpointFn(term);
                const url = obj.url;
                delete obj.url;
                const results = await ajaxGet(url, obj);
                if (Array.isArray(results)) {
                    this.currentSuggestions = results;
                }
                else {
                    this.currentSuggestions = [];
                }
                this.searchCache[term] = this.currentSuggestions;
            }
            else if (this.staticData) {
                this.currentSuggestions = this.searchStaticData(term);
            }
            this.renderList(showNoResults);
        }
        else if (this.showEmptySuggestions && this.staticData) {
            this.currentSuggestions = this.staticData.slice(0, this.maxSuggestions);
            this.renderList();
        }
    }

    private renderList(showNoResults: boolean = true) {
        if (this.currentSuggestions.length) {
            let startIndex = this.clearBtn.tabIndex || 1;
            const wordHTML: HTMLDivElement[] = [];
            for (let i = 0; i < this.currentSuggestions.length; i++) {
                if (i < this.maxSuggestions) {
                    const { auto, index, contents, html } = this.resultItemHandler(this.currentSuggestions[i], startIndex++);
                    const div = create<HTMLDivElement>('div');
                    attr(div, 'data-auto', auto);
                    attr(div, 'tabindex', '' + index);
                    if (html) {
                        div.innerHTML = contents;
                    }
                    else {
                        div.innerText = contents;
                    }
                    // add show more button
                    if (i === this.firstLimitSuggestions) {
                        const showMoreDiv = create<HTMLDivElement>('div');
                        const showMoreBtn = create<HTMLButtonElement>('button');
                        showMoreBtn.type = 'button';
                        showMoreDiv.appendChild(showMoreBtn);
                        showMoreBtn.innerText = 'Show More';
                        showMoreBtn.classList.add('btn-primary');
                        showMoreBtn.classList.add('btn');
                        wordHTML.push(showMoreDiv);
                        showMoreDiv.classList.add('showMore');
                        onClick(showMoreBtn, () => {
                            qsa('div', this.searchList).forEach((divToShow) => {
                                divToShow.hidden = false;
                                showMoreDiv.hidden = true;
                            });
                        });
                    }
                    // hide all labels after the first limit
                    if (i >= this.firstLimitSuggestions) {
                        div.hidden = true;
                    }
                    attr(div, 'data-index', '' + i);
                    wordHTML.push(div);
                }
                else {
                    break;
                }
            }
            this.searchList.innerHTML = '';
            this.searchList.append(...wordHTML);
            rAttr(this.searchList, 'hidden');
        }
        else {
            if (showNoResults) {
                this.searchList.innerHTML = '';
                const div = create<HTMLDivElement>('div');
                div.innerHTML = NO_RESULTS_TEXT;
                this.searchList.append(div);
                rAttr(this.searchList, 'hidden');
            }
        }
    }

    private onKeyUp = (keyUpEvent: KeyboardEvent) => {
        // Only consider suggest or clear if this wasn't an arrow key
        if ([ 37, 38, 39, 40 ].indexOf(keyUpEvent.which) === -1) {
            this.searchBar.setCustomValidity('');
            if (this.inputTimeout) {
                clearTimeout(this.inputTimeout);
            }
            if (this.searchBar.value.trim().length >= this.minSuggestSize) {
                this.inputTimeout = window.setTimeout(() => {
                    this.triggerSearch();
                }, 500);
            }
            else {
                if (keyUpEvent.which === ENTER_KEYCODE) {
                    this.showMinCharError();
                }
                attr(this.searchList, 'hidden', 'hidden');
            }
        }
    };

    private onKeyDown = (keyDown: KeyboardEvent) => {
        const subDiv = keyDown.target as HTMLElement;
        switch (keyDown.which) {
        // return
        case 13:
            keyDown.preventDefault();
            if (subDiv !== this.searchBar) {
                this.onSearchItemClick(subDiv as HTMLDivElement);
            }
            else if (document.activeElement === this.searchBar) {
                // This will cause the fuzzy seach to be sent to subscribers, but no suggestions
                if (this.allowFuzzy) {
                    this.submitting = true;
                }
                // This will consider the first selection as selected
                else if (this.searchList.firstElementChild) {
                    this.onSearchItemClick(this.searchList.firstElementChild as HTMLDivElement);
                }
            }
            break;
        // up arrow
        case 38:
            keyDown.preventDefault();
            const prev = subDiv.previousSibling;
            if (prev instanceof HTMLDivElement && attr(prev, 'data-auto')) {
                prev.focus();
            }
            else {
                this.searchBar.focus();
            }
            break;
        // down arrow
        case 40:
            keyDown.preventDefault();
            if (subDiv === this.searchBar) {
                (this.searchList.firstElementChild as HTMLDivElement).focus();
            }
            else {
                const next = subDiv.nextSibling;
                if (next instanceof HTMLDivElement && attr(next, 'data-auto')) {
                    next.focus();
                }
            }
            break;
        }
    };

    private onSearchBarClick = () => {
        if (this.searchBar.value === '' && this.showEmptySuggestions) {
            this.triggerSearch();
        }
    };

    private onSearchListClick = (clickEvent: MouseEvent) => {
        const selectDiv = clickEvent.target as HTMLDivElement;
        this.onSearchItemClick(selectDiv);
    };

    private onSearchItemClick(selectDiv: HTMLDivElement) {
        if (this.currentSuggestions.length) {
            let idxStr = attr(selectDiv, 'data-index');
            if (!idxStr) {
                idxStr = attr(selectDiv.parentElement, 'data-index');
            }
            const idx = idxStr ? Number.parseInt(idxStr) : -1;
            if (idx >= 0) {
                attr(this.searchList, 'hidden', 'hidden');
                this.currentSelected = this.currentSuggestions[idx];
                this.searchBar.value = '';
                for (const e of this.onSpecificSearched) {
                    e(this.currentSelected);
                }
                // Handy to focus to allow new search, but annoying if we automatically
                // show suggestions
                if (this.showEmptySuggestions === false) {
                    this.searchBar.focus();
                }
            }
        }
    }

    private onClickOutsideTarget = (clickEvent: MouseEvent) => {
        const clicked = clickEvent.target as HTMLElement;
        if (clicked !== this.searchBar && clicked !== this.searchList && !this.searchList.contains(clicked)) {
            attr(this.searchList, 'hidden', 'hidden');
        }
    };

    private showMinCharError() {
        this.searchBar.setCustomValidity(`To help show relevant results, please include at least ${this.minSuggestSize} characters in your search`);
        this.searchBar.reportValidity();
    }

    private onSearchExec = (event: MouseEvent): void => {
        event.preventDefault();
        this.searchBar.setCustomValidity('');
        if (this.searchBar.value.length < this.minSuggestSize) {
            this.showMinCharError();
            attr(this.searchList, 'hidden', 'hidden');
        }
        else {
            this.submitting = true;
            this.triggerSearch();
        }
    };

    private onClickClear = (event: MouseEvent): void => {
        event.preventDefault();
        this.searchBar.value = '';
        this.searchBar.focus();
        this.currentSelected = null;
        attr(this.searchList, 'hidden', 'hidden');
        for (const e of this.onCleared) {
            e();
        }
    };

    public get current() {
        return this.currentSelected;
    }

    public get onClear() {
        return this.onCleared;
    }

    public get onFuzzySearch() {
        return this.onFuzzySearched;
    }

    public get onSpecificSearch() {
        return this.onSpecificSearched;
    }

    public focusWithoutScroll() {
        this.searchBar.focus({
            preventScroll: true,
        });
    }
}
