function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (embed, height, width) {
      pug_html = pug_html + "\u003Ciframe" + (pug_attr("width", width, true, true)+pug_attr("height", height, true, true)+pug_attr("src", embed + '?rel=0&modestbranding=1', true, true)+" frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\""+pug_attr("allowfullscreen", true, true, true)) + "\u003E\u003C\u002Fiframe\u003E";
    }.call(this, "embed" in locals_for_with ?
        locals_for_with.embed :
        typeof embed !== 'undefined' ? embed : undefined, "height" in locals_for_with ?
        locals_for_with.height :
        typeof height !== 'undefined' ? height : undefined, "width" in locals_for_with ?
        locals_for_with.width :
        typeof width !== 'undefined' ? width : undefined));
    ;;return pug_html;};module.exports=template;