import { IPublisher } from '../../../shared/interfaces/article/publishers';
import { ISearchUser } from '../../../shared/interfaces/user/user';
import { IApiLoginLog, IBackendUser, ILoginLog, IUserActivityReport } from '../../../shared/interfaces/user/user-i';
import { ajaxDelete, ajaxGet, ajaxPost } from '../ajax';
import { readThroughCacheIDBDatabase } from '../util/storageUtil';

export async function userGetSubscribedPublishers() {
    const url = '/user/subscribedPublishers';
    return await readThroughCacheIDBDatabase(url, async () => {
        return ajaxGet<IPublisher[]>(url);
    });
}

export function userGetActivityReport() {
    return ajaxGet<IUserActivityReport[]>('/user/activityReport');
}

export function userGetSelfRegisteredInfo() {
    return ajaxGet('/user/selfRegisteredInfo');
}
export function userGetAutoSuggest(q: string) {
    return ajaxGet<IBackendUser[]>('/user/auto/suggest/:q', { q });
}

export function userGetAll() {
    return ajaxGet<IBackendUser[]>('/user/all');
}

export function userGetLogins(username: string) {
    return ajaxGet<(ILoginLog | IApiLoginLog)[]>('/user/logins/:username', { username });
}

export function userGetAllByUser() {
    return ajaxGet<ISearchUser[]>('/user/allByUser');
}

export function userPostReSeedOrVerify(route: string, username: string) {
    return ajaxPost(route, { username });
}

export function userDeleteUser(username: string) {
    return ajaxDelete('/user/delete/:username', { username });
}

export function userDeleteUndeleteUser(username: string) {
    return ajaxDelete('/user/undelete/:username', { username });
}
