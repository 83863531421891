import { EventOperation, EventSubject, IEventMessage } from '../interfaces/event';

// If no specific ID is applicable for an event, use this as a placeholder.
export const EVENT_TARGET_ALL = '_event_all';

// Easier inline calling by turning arguments into the interface.
export function eventArgsToMsg(subject: EventSubject, targetId: string, operation: EventOperation, messageData: any): IEventMessage {
    return {
        subject,
        operation,
        targetId,
        messageData,
    };
}
