import { FEATURE_FLAGS_EXPIRATION_IN_SECONDS } from '../../../shared/interfaces/api/apiInterfaces';
import { getClLogger } from '../../../shared/util/clLogger';
import { CacheScope, KEY_POSITION } from '../interfaces/static';
import { getUser } from '../layout';

export const FILTERS_DEFAULT_SCOPE = CacheScope.User;
export const SELECT_GROUP_DEFAULT_SCOPE = CacheScope.Tab;
export const TREE_DEFAULT_SCOPE = CacheScope.Tab;

// Utilities for interacting with local storage in a consistent way
const clLogger = getClLogger(__filename);
const CACHE_STORAGE_PREFIX = 'CL:';
const CACHE_MAX_TIME = 60000 * 3;
// Store DM-related items for a week to be safe
const CACHE_DM_MAX_TIME = 60000 * 10080;

// When adding a new key make sure it won't colide with another
export const STORAGE_INFO = {
    clJwt: { scope: CacheScope.Global, key: 'clJwt' },
    clLoginConfig: { scope: CacheScope.Global, key: 'clLoginConfig' },
    clLastUser: { scope: CacheScope.Global, key: 'clLastUser' },
    clTempUser: { scope: CacheScope.Global, key: 'clTempUser' },
    clEvents: { scope: CacheScope.Global, key: 'clEvents' },
    clApi: { scope: CacheScope.Global, key: 'clApi' },
    debug: { scope: CacheScope.Global, key: 'debug' },
    clFeatureFlags: { scope: CacheScope.Global, key: 'CL_FEATURE_FLAGS', cacheMaxTime: FEATURE_FLAGS_EXPIRATION_IN_SECONDS * 1000 },
    analysisCustomSymbols: { scope: CacheScope.Tab, key: 'analysis-customSymbols' },
    msal: { scope: CacheScope.Global, key: 'msal.', position: KEY_POSITION.prefix },
    clHomeBookmark: { scope: CacheScope.User, key: 'clHomeBookmark', position: KEY_POSITION.suffix },
    timeMachineDate: { scope: CacheScope.User, key: 'timeMachineDate', position: KEY_POSITION.suffix },
    clGraphNodePrefs: { scope: CacheScope.User, key: 'cl-graph-nodePrefs', position: KEY_POSITION.suffix },
    dashboardTourAlreadyAutoRan: { scope: CacheScope.User, key: 'dashboardTourAlreadyAutoRan,', position: KEY_POSITION.suffix },
    // filters
    indicatorsFilters: { scope: FILTERS_DEFAULT_SCOPE, key: 'indicatorsFilters', position: KEY_POSITION.prefix },
    driversFilters: { scope: FILTERS_DEFAULT_SCOPE, key: 'driversFilters', position: KEY_POSITION.prefix },
    eventsFilters: { scope: FILTERS_DEFAULT_SCOPE, key: 'eventsFilters', position: KEY_POSITION.prefix },
    signalFilters: { scope: FILTERS_DEFAULT_SCOPE, key: 'signalFilters', position: KEY_POSITION.prefix },
    modelFilters: { scope: FILTERS_DEFAULT_SCOPE, key: 'modelFilters', position: KEY_POSITION.prefix },
    // DMs
    conversation: { scope: CacheScope.User, key: 'conversation:', position: KEY_POSITION.middle, cacheMaxTime: CACHE_DM_MAX_TIME },
    conversations: { scope: CacheScope.User, key: 'conversations', position: KEY_POSITION.suffix, cacheMaxTime: CACHE_DM_MAX_TIME },
    newMessages: { scope: CacheScope.User, key: 'newMessages', position: KEY_POSITION.suffix, cacheMaxTime: CACHE_DM_MAX_TIME },
    messages: { scope: CacheScope.User, key: 'messages', position: KEY_POSITION.suffix, cacheMaxTime: CACHE_DM_MAX_TIME },
    users: { scope: CacheScope.User, key: 'users', position: KEY_POSITION.suffix, cacheMaxTime: CACHE_DM_MAX_TIME },
    notificationCount: { scope: CacheScope.User, key: 'notificationCount', position: KEY_POSITION.suffix, cacheMaxTime: CACHE_DM_MAX_TIME },
    dashNotificationCount: { scope: CacheScope.User, key: '_notificationCount', position: KEY_POSITION.suffix, cacheMaxTime: CACHE_DM_MAX_TIME },
    // Store off-page support setting for 9 hours
    offPageDMs: { scope: CacheScope.User, key: 'offPageDMs', position: KEY_POSITION.suffix, cacheMaxTime: 60000 * 540 },
    // Announcements
    clNotifyMsg: { scope: CacheScope.User, key: 'clNotifyMsg', position: KEY_POSITION.suffix },
    // Tree
    treeLoadedItems: { scope: TREE_DEFAULT_SCOPE, key: '-loadedItems', position: KEY_POSITION.suffix },
    treeCheckedItems: { scope: TREE_DEFAULT_SCOPE, key: '-checkedItems', position: KEY_POSITION.suffix },
    treeIndeterminateItems: { scope: TREE_DEFAULT_SCOPE, key: '-indeterminateItems', position: KEY_POSITION.suffix },
    // Theme
    theme: { scope: CacheScope.User, key: 'theme', position: KEY_POSITION.prefix },
};

const storageInfoKeys = Object.keys(STORAGE_INFO).filter((k) => !STORAGE_INFO[k].cacheMaxTime);
const NO_EXPIRATION_SUFFIXES_USER = storageInfoKeys.filter((k) => STORAGE_INFO[k].scope === CacheScope.User &&
    STORAGE_INFO[k].position === KEY_POSITION.suffix).map((k) => STORAGE_INFO[k].key);

const NO_EXPIRATION_SUFFIXES = storageInfoKeys.filter((k) => STORAGE_INFO[k].scope !== CacheScope.User &&
    STORAGE_INFO[k].position === KEY_POSITION.suffix).map((k) => STORAGE_INFO[k].key);

const NO_EXPIRATION_MIDDLE_USER = storageInfoKeys.filter((k) => STORAGE_INFO[k].scope === CacheScope.User &&
    STORAGE_INFO[k].position === KEY_POSITION.middle).map((k) => STORAGE_INFO[k].key);

const NO_EXPIRATION_PREFIX_USER = storageInfoKeys.filter((k) => STORAGE_INFO[k].scope === CacheScope.User &&
    STORAGE_INFO[k].position === KEY_POSITION.prefix).map((k) => STORAGE_INFO[k].key);

const NO_EXPIRATION_KEYS = storageInfoKeys.filter((k) => STORAGE_INFO[k].scope !== CacheScope.User && !STORAGE_INFO[k].position).map((k) => STORAGE_INFO[k].key);

const NO_EXPIRATION_PREFIXES = storageInfoKeys.filter((k) => STORAGE_INFO[k].scope !== CacheScope.User &&
    STORAGE_INFO[k].position === KEY_POSITION.prefix).map((k) => STORAGE_INFO[k].key);

function isTimestampKey(key: string) {
    key = key.replace(CACHE_STORAGE_PREFIX, '');
    const timestamp = true;
    if (NO_EXPIRATION_KEYS.includes(key)) {
        return false;
    }
    for (const prefix of NO_EXPIRATION_PREFIXES) {
        if (key.startsWith(prefix)) {
            return false;
        }
    }
    for (const suffix of NO_EXPIRATION_SUFFIXES) {
        if (key.endsWith(suffix)) {
            return false;
        }
    }
    try {
        const userPrefix = `${getUser().id}:`;
        if (key.startsWith(userPrefix)) {
            for (const suffix of NO_EXPIRATION_SUFFIXES_USER) {
                if (key.endsWith(suffix)) {
                    return false;
                }
            }
            for (const suffix of NO_EXPIRATION_MIDDLE_USER) {
                if (key.includes(':' + suffix)) {
                    return false;
                }
            }
            key = key.replace(userPrefix, '');
            for (const prefix of NO_EXPIRATION_PREFIX_USER) {
                if (key.startsWith(prefix)) {
                    return false;
                }
            }
        }
    }
    catch (err) {
        // ignore user is not logged
    }
    return timestamp;
}

// Proper usage of storage options:
// Local storage is global (spans tabs and windows) per hostname. Use it for caching values that would not vary between tabs and are effectively permanent.
// For local storage, we can also prefix the username (User scope) in the key so that two users of the same browser across two sessions will never see data from the prior session.
// Session storage is scoped per window/tab and therefore should be used for any widgets that might exist more than once at a time, for one user.

// When the user is logged in we will use their username so that individual preferences
// are not overriding other users of the same browser.
function getPrefix(scope: CacheScope): string {
    let start = CACHE_STORAGE_PREFIX;
    if (scope === CacheScope.User) {
        try {
            start += `${getUser().id}:`;
        }
        catch (e) {
            clLogger.error('Local storage by user cannot be used prior to login or outside a session.');
            throw e;
        }
    }
    return start;
}

export function fetchCacheKey<T>(scope: CacheScope, key: string): T | null {
    const prefixedKey = `${getPrefix(scope)}${key}`;
    const item = scope === CacheScope.Tab ? sessionStorage.getItem(prefixedKey) : localStorage.getItem(prefixedKey);
    let result = null;
    try {
        if (item !== null && item !== undefined) {
            result = JSON.parse(item);
            if (isTimestampKey(prefixedKey)) {
                if (!validateDate(result)) {
                    result = null;
                }
                else {
                    result = result?.value;
                }
            }
        }
    }
    catch (e) {
        clLogger.error(`Could not parse local ${scope} cache key ${key}`, e);
    }
    return result;
}

// Preferred method of setting a value for a key will take care of serialization as JSON if necessary.
// cacheMaxTime = 0 means no time control
export function setCacheKey<T>(scope: CacheScope, key: string, value: T, cacheMaxTime?: number): void {
    if (value !== null && value !== undefined) {
        const prefixedKey = `${getPrefix(scope)}${key}`;
        let serialized;
        if (!isTimestampKey(prefixedKey)) {
            serialized = JSON.stringify(value);
        }
        else {
            serialized = JSON.stringify({ value, cacheMaxTime, createdTimestamp: Date.now() });
        }
        if (scope === CacheScope.Tab) {
            sessionStorage.setItem(prefixedKey, serialized);
        }
        else {
            localStorage.setItem(prefixedKey, serialized);
        }
    }
    else {
        throw Error('Do not set cache keys to null or undefined - use the remove method');
    }
}

// Delete / remove a key, if it exists.
export function removeCacheKey(scope: CacheScope, key: string): void {
    const prefixedKey = `${getPrefix(scope)}${key}`;
    if (scope === CacheScope.Tab) {
        sessionStorage.removeItem(prefixedKey);
    }
    else {
        localStorage.removeItem(prefixedKey);
    }
}

// Convenience for quickly adding a value to a set, if not already present.
// This is really only intended for small lists of simple strings
// If you need a larger object or set of logic, implement separately after fetching the item
export function addToCacheSet(scope: CacheScope, key: string, value: string, cacheMaxTime?: number): void {
    const current = fetchCacheKey<{ [ key: string ]: boolean }>(scope, key) || {};
    if (!current[value]) {
        current[value] = true;
        setCacheKey(scope, key, current, cacheMaxTime);
    }
}

// Removes a particular value from a set, if it exists
export function removeFromCacheSet(scope: CacheScope, key: string, value: string, cacheMaxTime?: number): void {
    const current = fetchCacheKey<{ [ key: string ]: boolean }>(scope, key);
    if (current && current[value]) {
        delete current[value];
        setCacheKey(scope, key, current, cacheMaxTime);
    }
}

export function fetchCacheSet(scope: CacheScope, key: string): string[] {
    const current = fetchCacheKey<{ [ key: string ]: boolean }>(scope, key) || {};
    return Object.keys(current);
}

// Quick test to see if a value in a cached / serialized set has some value in the dictionary
export function cacheSetHasValue(scope: CacheScope, key: string, value: string): boolean {
    const current = fetchCacheKey<{ [ key: string ]: boolean }>(scope, key);
    return (current && current[value]) || false;
}

export async function clearOldCache() {
    const objectStore = await openIDBDatabase();
    if (objectStore) {
        const getAllKeysRequest = objectStore.getAllKeys();
        getAllKeysRequest.onsuccess = async () => {
            for (const key of getAllKeysRequest.result) {
                const data = await getCacheIDBDatabase(key);
                if (!data) {
                    await deleteCacheIDBDatabase(key);
                }
            }
        };
    }
    deleteFromStorage(sessionStorage);
    deleteFromStorage(localStorage);
}

function deleteFromStorage(storage) {
    for (const key of Object.keys(storage)) {
        if (isTimestampKey(key)) {
            const item = storage.getItem(key);
            if (!validateDate(JSON.parse(item))) {
                storage.removeItem(key);
            }
        }
    }
}

function validateDate(item) {
    const lastUpdate = item?.createdTimestamp;
    const cacheTime = item?.cacheMaxTime;
    if (!lastUpdate || !cacheTime || Date.now() - lastUpdate > cacheTime) {
        return false;
    }
    return true;
}

// ------------------------------IDBDatabase------------------------------

async function deleteCacheIDBDatabase(key: IDBValidKey) {
    const objectStore = await openIDBDatabase();
    const deleteRequest = objectStore.delete(key);
    deleteRequest.onsuccess = () => {
        // needed?
    };
}

function openIDBDatabase(): Promise<IDBObjectStore> {
    let objectStore: IDBObjectStore;
    return new Promise((resolve) => {
        if (objectStore) {
            resolve(objectStore);
        }
        let db: IDBDatabase;
        const request = window.indexedDB.open('database', 1);

        request.onerror = () => {
            return resolve(null);
        };

        request.onsuccess = () => {
            db = request.result;
            objectStore = db.transaction([ 'json' ], 'readwrite').objectStore('json');
            return resolve(objectStore);
        };

        request.onupgradeneeded = () => {
            db = request.result;
            const newObjectstore = db.createObjectStore('json', { keyPath: 'key' });
            newObjectstore.createIndex('json', 'key', { unique: true });
        };
    });
}

async function setCacheIDBDatabase(key: IDBValidKey, data: any, cacheMaxTime: number = CACHE_MAX_TIME) {
    if (!window.indexedDB) {
        return;
    }
    const storedData = await navigator.storage.estimate();
    let length: number;
    try {
        // according to firefox the size is around 3 times the string length
        length = JSON.stringify(data).length * 3;
    }
    catch (err) {
        clLogger.debug(err);
        return;
    }
    const isFirefox = navigator.userAgent.includes('Firefox');
    // firefox have a max item size but is not in the navigator.storage info
    const FIREFOX_MAX_SIZE = 170000000;
    if (isFirefox && length > FIREFOX_MAX_SIZE) {
        return;
    }
    // check used quota against length
    if (storedData.quota - storedData.usage < length) {
        return;
    }
    const objectStore = await openIDBDatabase();
    if (objectStore) {
        objectStore.put({ key, data, cacheMaxTime, createdTimestamp: Date.now() }).onsuccess = () => {
            // needed?
        };
    }
}

function getCacheIDBDatabase<T>(key: IDBValidKey): Promise<T> {
    if (!window.indexedDB) {
        return null;
    }
    return new Promise((resolve) => {
        openIDBDatabase().then((objectStore) => {
            if (!objectStore) {
                return resolve(null);
            }
            const dataReq = objectStore.get(key);
            dataReq.onsuccess = () => {
                if (!validateDate(dataReq.result)) {
                    return resolve(null);
                }
                return resolve(dataReq.result?.data);
            };
            return;
        }).catch((err) => {
            clLogger.error(err);
        });
    });
}

export async function readThroughCacheIDBDatabase<T>(key: IDBValidKey, missFunction: () => T, cacheMaxTime?: number, cacheNull: boolean = true) {
    const cachedData = await getCacheIDBDatabase<T>(key);
    let data: T = null;
    if (cachedData) {
        data = cachedData;
    }
    else {
        data = await missFunction();
        // allow to avoid cache if data is null
        if (cacheNull || data) {
            await setCacheIDBDatabase(key, data, cacheMaxTime);
        }
    }
    return data;
}

export async function cleanPartialKeys(part: string) {
    if (part) {
        const objectStore = await openIDBDatabase();
        if (objectStore) {
            const getAllKeysRequest = objectStore.getAllKeys();
            getAllKeysRequest.onsuccess = async () => {
                for (const key of getAllKeysRequest.result) {
                    if (key.toString().includes(part)) {
                        await deleteCacheIDBDatabase(key);
                    }
                }
            };
        }
    }
}
