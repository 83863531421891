import { IArticleLang } from '../../../shared/interfaces/article/article';
import {
    changeLangToEnglish, getArticleIdFromKey, getLang, getLangFromKey, getSentLocation, parseSentence, uniqBy,
} from '../../../shared/util/sentenceUtil';
import { articlesPostSentences } from '../api/articles';
import { attr, getByClass, onClick, qs, qsa } from './html';

export async function displaySentences(container: HTMLDivElement) {
    const TITLE_SUFFIX = '_t_000_000';
    const ids: IArticleLang[] = [];
    const titles = qsa('.articleQuote', container);
    titles.forEach((articleQuote) => {
        if (attr(articleQuote, 'done')) {
            return;
        }
        const id: string = attr(articleQuote, 'data-id');
        let lang: string = getLangFromKey(id);
        const articleId: string = getArticleIdFromKey(id);
        if (!lang.startsWith('en')) {
            lang = changeLangToEnglish(lang);
        }
        ids.push({
            articleSentenceId: articleId,
            lang,
        });
        ids.push({
            articleSentenceId: articleId,
            lang: lang.substring(0, 2) + TITLE_SUFFIX,
        });
    });
    const sentences = {};
    (await articlesPostSentences(uniqBy(ids, (item) => (item.articleSentenceId + item.lang)))).forEach((sentence) => {
        if (!sentences[sentence.articleSentenceId]) {
            sentences[sentence.articleSentenceId] = {};
        }
        if (sentence.lang.endsWith('_t_000_000')) {
            sentences[sentence.articleSentenceId].title = sentence.sentenceText;
        }
        sentences[sentence.articleSentenceId][sentence.lang] = sentence.sentenceText;
    });

    const QUOTE_NOT_FOUND = 'Quote not found';
    titles.forEach((articleQuote) => {
        if (attr(articleQuote, 'done')) {
            return;
        }
        attr(articleQuote, 'done', 'true');
        const key: string = attr(articleQuote, 'data-id');
        let lang = getLangFromKey(key);
        if (!lang.startsWith('en')) {
            lang = changeLangToEnglish(lang);
        }
        const articleId: string = getArticleIdFromKey(key);
        if (sentences[articleId]) {
            const title = sentences[articleId].title || QUOTE_NOT_FOUND;
            const text = sentences[articleId][lang] ? `"${sentences[articleId][lang]}"` : QUOTE_NOT_FOUND;
            qs('.quoteTitle', articleQuote).innerHTML = title;
            qs('.quoteText > a', articleQuote).innerHTML = text;
        }
    });

    wireShowTranslationButton(titles);
}

export function wireShowTranslationButton(containers: HTMLElement[]) {
    for (const container of containers) {
        const showTranslationBtns = getByClass<HTMLSpanElement>('showTranslationBtn', container);
        for (const showTranslationBtn of showTranslationBtns) {
            onClick(showTranslationBtn, async (event: MouseEvent) => {
                const targetElement = event.target as HTMLSpanElement;
                const sentenceTextSpan = qs<HTMLSpanElement>(`span.sentenceText[data-id="${attr(targetElement, 'data-id')}"]`, container);
                if (sentenceTextSpan.innerHTML !== '') {
                    sentenceTextSpan.innerHTML = '';
                }
                else {
                    const s = parseSentence(attr(targetElement, 'data-sentence'));
                    const origLang: string = attr(targetElement, 'data-origLang');
                    const location: string = getSentLocation(attr(targetElement, 'data-ecl-id'));
                    const keys = [
                    ];
                    if (origLang && origLang !== 'en') {
                        keys.push({
                            articleSentenceId: attr(targetElement, 'data-id'),
                            lang: getLang(origLang, location, s.paragraph, s.sentence),
                        });
                    }
                    else {
                        keys.push({
                            articleSentenceId: attr(targetElement, 'data-id'),
                            lang: getLang('en', location, s.paragraph, s.sentence),
                        });
                    }
                    if (keys.length) {
                        const sentences = await articlesPostSentences(keys);
                        if (sentences) {
                            let r = '';
                            sentences.forEach((sentence) => {
                                r += `<p>${sentence.sentenceText}</p>`;
                            });
                            sentenceTextSpan.innerHTML = r;
                        }
                    }
                }
            });
        }
    }
}
