import { calculateSecondsInMillis } from '../../../shared/util/dateUtil';
import { updateProgressBar } from '../../apps/theme';
import { attr, qs } from './html';
const template = require('./progressBar.pug');

export class ProgressBar {
    private bar: HTMLElement;
    private progress = 0;

    constructor(container, private options?: { width?: number, logo?: boolean, fullPage?: boolean, init?: {percent: number, duration: number}}) {
        this.options = this.options || {};
        this.options.width = this.options.width || 340;
        container.innerHTML = template(options);
        const theme = attr(document.documentElement, 'data-bs-theme');
        updateProgressBar(theme);
        this.bar = qs('.progress-bar', container);
        this.reset();
    }

    public setProgress(percent: number) {
        this.progress = Math.max(0, Math.min(percent, 100));
        this.bar.style.width = Math.ceil(this.options.width * this.progress / 100) + 'px';
    }

    public reset() {
        this.setProgress(0);
        if (this.options.init) {
            for (let t = 0; t <= this.options.init.duration; t += 0.5) {
                setTimeout(() => {
                    const p = this.options.init.percent * t / this.options.init.duration;
                    if (p > this.progress) {
                        this.setProgress(p);
                    }
                }, calculateSecondsInMillis(t));
            }
        }
    }
}
