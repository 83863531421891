function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_classes(s,r){return Array.isArray(s)?pug_classes_array(s,r):s&&"object"==typeof s?pug_classes_object(s):s||""}
function pug_classes_array(r,a){for(var s,e="",u="",c=Array.isArray(a),g=0;g<r.length;g++)(s=pug_classes(r[g]))&&(c&&a[g]&&(s=pug_escape(s)),e=e+u+s,u=" ");return e}
function pug_classes_object(r){var a="",n="";for(var o in r)o&&r[o]&&pug_has_own_property.call(r,o)&&(a=a+n+o,n=" ");return a}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (clMode, loginUserFill, pathname) {
      pug_html = pug_html + "\u003Cform id=\"formLogin\" method=\"post\" action=\"\u002Flogin\"\u003E\u003Cdiv class=\"my-4 text-center\"\u003E\u003Cimg class=\"img-fluid brand-img\" src=\"\u002Fimg\u002Fcausality-logo-white.png\" width=\"600\" height=\"106\" alt=\"\"\u003E\u003Cimg class=\"img-fluid brand-img-print\" src=\"\u002Fimg\u002Fcausality-logo-dark.png\" width=\"600\" height=\"106\" alt=\"\" style=\"display: none;\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"mb-4\"\u003E\u003Cdiv class=\"container-fluid\"\u003E";
clMode = loginUserFill && loginUserFill.endsWith('@causalitylink.com')
pug_html = pug_html + "\u003Cdiv class=\"form-group text-danger\"\u003E\u003Cdiv class=\"form-control-feedback\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cinput" + (" class=\"form-control cl-username\""+" type=\"text\" name=\"username\" placeholder=\"Username\""+pug_attr("value", loginUserFill, true, true)+pug_attr("required", true, true, true)+" autocomplete=\"username\"") + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cinput" + (pug_attr("class", pug_classes(["form-control","cl-pass",clMode ? 'invisible' : ''], [false,false,true]), false, true)+" type=\"password\" name=\"password\" placeholder=\"Password\""+pug_attr("value", clMode ? 'abc88zsd' : '', true, true)+pug_attr("required", true, true, true)+" autocomplete=\"password\"") + "\u003E\u003C\u002Fdiv\u003E\u003Cinput type=\"hidden\" name=\"webclient\" value=\"true\"\u003E\u003Cinput class=\"cl-user\" type=\"hidden\" name=\"user\"\u003E\u003Cinput class=\"cl-accessToken\" type=\"hidden\" name=\"accessToken\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"pathname\""+pug_attr("value", pathname, true, true)) + "\u003E\u003Cinput class=\"btn btn-block btn-primary\" type=\"submit\" value=\"Log In\"\u003E\u003Cdiv class=\"d-flex\"\u003E\u003Csmall\u003E\u003Ca class=\"text-primary\" href=\"\u002Fuser\u002Fregister\"\u003ERegister\u003C\u002Fa\u003E\u003C\u002Fsmall\u003E\u003Csmall class=\"ms-auto\"\u003E \u003Ca class=\"text-primary\" href=\"\u002Fuser\u002Fforgot\"\u003EForgot my password\u003C\u002Fa\u003E\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E";
    }.call(this, "clMode" in locals_for_with ?
        locals_for_with.clMode :
        typeof clMode !== 'undefined' ? clMode : undefined, "loginUserFill" in locals_for_with ?
        locals_for_with.loginUserFill :
        typeof loginUserFill !== 'undefined' ? loginUserFill : undefined, "pathname" in locals_for_with ?
        locals_for_with.pathname :
        typeof pathname !== 'undefined' ? pathname : undefined));
    ;;return pug_html;};module.exports=template;