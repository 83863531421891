// Allow self guided tours to be created for any page
import { getElementGenealogy } from '../../../shared/frontend/domUtil';
import { IShepherd, ITour, ITourMarker, ITourStepOptions, TPlacement } from '../interfaces/tour';
import { onClick, qs } from '../util/html';

const TOUR_ADVANCE_ELEMENT = 'html';

declare let Shepherd: IShepherd;

// // Placeholder for indexed parameters that should default to an empty function
// export function noop() {
//     return;
// }

// Turn tour step parameters into a well formatted tour step object
export function getTourStep(
    title: string,
    id: string,
    text: string,
    buttonText: string,
    element: string,
    location: TPlacement,
    showCancelIcon: boolean,
    inStepAction: () => void = () => {
        // Do nothing by default
    },
    afterNextAction: () => void = () => {
        // Do nothing by default
    },
    autoScroll: boolean = false,
    advanceEvent: string = 'non.existing.event'): ITourStepOptions {
    return {
        id,
        title,
        text,
        attachTo: {
            element,
            on: location,
        },
        beforeShowPromise: inStepAction,
        buttons: [
            {
                text: buttonText,
                action: afterNextAction,
            },
        ],
        cancelIcon: {
            enabled: showCancelIcon,
        },
        advanceOn: {
            selector: TOUR_ADVANCE_ELEMENT,
            event: advanceEvent,
        },
        arrow: true,
        popperOptions: {
            modifiers: [ { name: 'offset', options: { offset: [ 10, 10 ] } } ], // Without this margin, tour steps that highlight small elements or buttons partially cover them up
        },
        scrollTo: autoScroll,
    };
}

// Wire all steps into a new tour
// This is useful in order to separate tour step creation and definitions from needing an instance of tour to be passed everywhere.
function createTour(steps: ITourStepOptions[], autoScroll: boolean = false): ITour {
    // Create the actual tour and load up some default options
    const tour = new Shepherd.Tour({
        defaultStepOptions: {
            scrollTo: autoScroll,
            classes: 'shepherd-alt-button',
        },
        useModalOverlay: true,
    });
    // Now that we have a tour instance, we can tell the 'Next' buttons to go to the next step when executed (along with any other actions we want)
    steps.forEach((step) => {
        const customAction = step.buttons[0].action;
        step.buttons[0].action = () => {
            customAction();
            tour.next();
        };
        tour.addStep(step);
    });
    return tour;
}

// Help add a tour to the help menu
// Useful because the help menu template is compiled before we can pass useful data to it
export function addTourToHelpMenu<T extends ITourMarker>(TourType: new() => T, enableStepsForNotYetExistingElements: boolean = false, autoScroll: boolean = false) {
    const tour = new TourType();
    const helpMenu: HTMLElement = qs('.help-menu-link-container');
    const link = document.createElement('a');
    link.setAttribute('href', '#');
    link.classList.add('dropdown-item', 'tour-menu-item');
    link.innerHTML = tour.menuEntry;
    helpMenu.appendChild(link);
    // Defer creation of the tour until the link is clicked
    // This allows us to introspect tour steps at time of tour run to know if they are visible or hidden (in another tab for example)
    onClick(link, () => {
        runTour(TourType, enableStepsForNotYetExistingElements, autoScroll);
    });
}

// Allow a tour to be started at any time
export function runTour<T extends ITourMarker>(TourType: new() => T, enableStepsForNotYetExistingElements: boolean = false, autoScroll: boolean = false) {
    const tour = new TourType();
    // Only apply steps that target elements which are currently shown
    const filteredSteps = enableStepsForNotYetExistingElements
        ? tour.getSteps().map((step) => {
            if (autoScroll) {
                step.scrollTo = autoScroll;
            }
            return step;
        })
        : tour.getSteps().filter((step) => {
            // It seems better to either have everything auto-scroll or nothing auto-scroll because
            //  if it is required for one, the browser size is probably small enough for it to affect several steps
            if (autoScroll) {
                step.scrollTo = autoScroll;
            }
            let isShown = true;
            // If no element is specified, we show a general and centered message for a step
            if (step.attachTo.element) {
                const element: HTMLElement = qs(step.attachTo.element);
                const elementGenealogy = getElementGenealogy(element);
                const isFound: boolean = elementGenealogy.length > 0;
                // If the display property of the element or one of its parents hides an element, toss the step out
                // Start with a visibility seed based on the initial element existing
                isShown = elementGenealogy.reduce((visible, currentElement) => {
                    return visible && (currentElement?.style?.display !== 'none');
                }, isFound);
            }
            return isShown;
        });
    createTour(filteredSteps, autoScroll).start();
}
