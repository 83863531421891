/*
 * Helper functions that help deal with browser windows, classic DOM manipulations, etc.
 */
import { getAuthObj } from '../ajax';
import { attr, getByClass, qs } from './html';

// Check to see if the element is visible in the window while only considering the y axis. Should only be used
// if the page is NOT wider than the viewport
export function isInVerticalView(element: HTMLElement) {
    const r = element.getBoundingClientRect();
    // The footer should be accounted for because the text may be at a height
    //  below the scrollable area, but above the window's bottom
    const FOOTER_MARGIN = 30;
    const footerElement = qs('footer');
    // If there is a footer, get its height and margin, otherwise there is no height
    const possibleFooterHeight = footerElement ? footerElement.offsetHeight - FOOTER_MARGIN : 0;
    return r.bottom > 0 && r.top < (window.innerHeight || document.documentElement.clientHeight) - possibleFooterHeight;
}

export function switchNav(nav: HTMLElement, navLinkId: string) {
    groupClassToggle(nav, 'nav-link', navLinkId, 'active');
}

//  Show the correct content, and update the tabs to show what is selected
export function updateTabAndContent(nav: HTMLElement, navLinkId: string, contentDivs: HTMLElement[], activeContentDiv: HTMLElement) {
    const DISPLAY_NONE = 'none';
    const DISPLAY_DEFAULT = '';
    switchNav(nav, navLinkId);
    for (const div of contentDivs) {
        if (div === activeContentDiv) {
            div.style.display = DISPLAY_DEFAULT;
        }
        else {
            div.style.display = DISPLAY_NONE;
        }
    }
}

export function groupClassToggle(parent: HTMLElement, childClass: string, targetId: string, toggledClass: string) {
    const children = getByClass(childClass, parent);
    for (const child of children) {
        if (child.id !== targetId) {
            child.classList.remove(toggledClass);
        }
        else {
            child.classList.add(toggledClass);
        }
    }
}

export function toggleClass(elem: HTMLElement, className: string) {
    if (elem.classList && elem.classList.toggle) {
        elem.classList.toggle(className);
    }
    else {
        elem.className = elem.className.match(RegExp(`(^|\\s)${className}($|\\s)`))
            ? elem.className.replace(RegExp(`(^|\\s)${className}($|\\s)`), ' ')
            : elem.className + ` ${className}`;
    }
}

// To create a button that is client side and allows the user to download something from an in memory
// JSON object, create a HTMLAnchor, hide it, then pass it here along with proper blob parts.
// We emulate a click and this will cause new browsers to download the file immediately.
export function clickToDownload(hiddenAnchor: HTMLAnchorElement, blobParts: BlobPart[], filename: string = 'data.csv', type: string = 'data/csv') {
    const fileUrl = URL.createObjectURL(new Blob(blobParts, { type }));
    hiddenAnchor.href = fileUrl;
    hiddenAnchor.download = filename;
    hiddenAnchor.click();
}

// Uses convention to setup a group of buttons whose purpose is generally to toggle state. The typical usage of this is to render a group of buttons
// using the same class, then for each one wire up using this same, shared handler.
// Example Pug: button.btn.btn-secondary.active(type='button', data-type='drivers', aria-pressed='true') Drivers
//              button.btn.btn-secondary(type='button', data-type='drives') Sensitivities
// Note the suffix 'type' for the data string to associate with that button.
export function btnGroupHandler(buttons: HTMLButtonElement[], dataSuffix: string, onData: (val: string) => void) {
    return (evt: MouseEvent) => {
        if (evt.target) {
            const btn = evt.target as HTMLButtonElement;
            const val = attr(btn, `data-${dataSuffix}`);
            // If the clicked button has the attribute we expect, set it to active and de-activate others
            if (val) {
                buttons.forEach((tb) => tb.classList.remove('active'));
                onData(val);
                btn.classList.add('active');
            }
        }
    };
}

// When we login our JWT token records the previous session date
// This is useful for showing new data since last login or detecting a first login for auto-running a tour
export function getLastLogin() {
    const auth = getAuthObj();
    if (auth && auth.recentLogin) {
        return auth.recentLogin;
    }
    else {
        return null;
    }
}
