// Standardize responses from api methods

// If possible, we should update all API endpoints so we can remove version info
//  and avoid multiple versions to be maintained
export enum API_VERSION {
    V2 = 2, // Initial IApiReturn structure with enveloped data
}

// For api methods, use ApiSuccess and ApiError to return a standardized format
export interface IApiReturn<T> {
    id?: number;                 // Id of a newly created row
    statusCode?: number;         // # of rows updated
    message?: string;            // A human readable message. Can be used for UI display.
    data?: T;                    // Typed payload that can help enforce API <-> Client contracts.
    success?: boolean;           // Allows an intermediate layer to ignore status code. Normal logic doesn't have to deal with this
    apiVersion?: API_VERSION;
}

export class ApiSuccess<T> implements IApiReturn<T> {
    public success: boolean;
    public apiVersion: API_VERSION;
    constructor(public message?: string, public statusCode?: number, public data?: T) {
        this.success = true;
        this.apiVersion = API_VERSION.V2;
    }
}

export class ApiError<T> extends Error implements IApiReturn<T> {
    public success: boolean;
    public apiVersion: API_VERSION;
    constructor(public message: string, public statusCode: number, public data?: T) {
        super(message);
        this.success = false;
        this.apiVersion = API_VERSION.V2;
    }
}

export interface IServiceError<T> {
    message?: string;
    data?: T;
    success?: boolean;
}

export const NO_CONTENT_PAYLOAD = '';

export enum HTTP_METHODS {
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT',
}

export enum CONTENT_TYPE {
    NDJSON = 'application/x-ndjson',
    CSV = 'text/csv',
    PLAINTEXT = 'text/plain',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    GZIP = 'gzip',
    HTML = 'text/html',
}

export enum CONTENT_ENCODING {
    GZIP = 'gzip',
}

export enum ARTICLE_CONTEXT {
    BODY = 'BODY',
    GRID = 'GRID',
    TITLE = 'TITLE',
    NONE = 'NONE',      // Useful for rendering foreign language text where context distinctions between BODY and GRID do not exist
}

// Synced cache time for feature flags
export const FEATURE_FLAGS_EXPIRATION_IN_SECONDS = 60 * 15;

// Attempt to get signed urls that last 3 days. In practice, this is cut down depending on credential type
export const SIGNED_URL_EXPIRATION_THREE_DAYS_IN_SECONDS = 3 * 24 * 60 * 60;
// Almost 6 hours, so there is no race with AWS's hour expiration
export const SIGNED_URL_ROLE_EXPIRATION_IN_SECONDS = 60 * 60 * 5.9;
// Back off link expiration from 4 hours to 1. There seem to be periodic problems that I can't explain yet.
// The only idea I have so far is that signed urls can expire if the creds expire. Is it possible that the creds
//  on ECS tasks are temporary, rotate, or have some other ephemeral nature?
// Since we want longer expirations anyway, we should probably implement our own signing flow
// Links can expire after 1 hour, but users requesting towards the end time won't be penalized with as many net requests
export const SIGNED_URL_EARLY_EXPIRE_FOR_USER_BALANCE = 60 * 60;
export const CACHE_CONTROL_PRIVATE = `max-age=${SIGNED_URL_EXPIRATION_THREE_DAYS_IN_SECONDS}, private`;

export enum STATUS_CODE_THRESHOLD {
    BELOW_OKAY = 199,
    ABOVE_OKAY = 400,
}

export enum STATUS_CODES {
    GENERAL_OKAY = 200,

    NEW_DATA_ADDED = 201,
    NEW_OBJECT_CREATED = 201,

    OBJECT_UPDATED = 204,
    NO_CONTENT = 204,

    FOUND = 302,

    REQUEST_DATA_NOT_IN_FORMDATA_FORMAT = 400,
    REQUEST_DATA_NOT_IN_JSON_FORMAT = 400,

    BAD_CREDENTIALS = 401,

    ACCESS_DENIED_FOR_USER = 403,

    OBJECT_NOT_FOUND = 404,
    PAGE_NOT_FOUND = 404,

    DUPLICATE_ALREADY_EXISTS = 409,
    CONFLICT = 409,

    MISSING_REQUIRED_FIELD = 422,
    FIELD_IS_TOO_LONG = 422,
    FIELD_IS_WRONG_TYPE = 422,
    INVALID_VALUE_FOR_FIELD = 422,
    FOREIGN_KEY_PROBLEM = 422,

    UNEXPECTED_SERVER_ERROR = 500,

    BAD_GATEWAY = 502,

    INFRASTRUCTURE_PROBLEM = 503,
}

export enum autocompleteTypes {
    Equity = 'equity',
    Exchange = 'exchange',
    AnyMarker = 'anyMarker',
    Author = 'author',
    Indicator = 'indicator',
    CompanyGroup = 'companyGroup',
    IndustryGroup = 'industryGroup',
    Unit = 'unit',
    CompoundUnit = 'compoundUnit',
    ECL = 'ecl',
    Location = 'location',
    Suggestion = 'suggestion',
    Time = 'time',
    Trend = 'trend',
    Value = 'value',
    Untyped = 'untyped',
    Composite = 'composite',
    Intensity = 'intensity',
    Feature = 'feature',
    Collection = 'collection',
    BaseEvent = 'baseEvent',
    Context = 'context',
    EquityCompanyIndustry = 'equityCompanyIndustry',
    FeatureDetail = 'featureDetail',
    Client = 'client',
}
