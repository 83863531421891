import { AuthRoles } from '../interfaces/acl/acl-i';

// This class now lives in share and may be required by both API (back end) and Static (front end), for
// purposes of displaying options to the user in a way that's consistent with what the API must enforce.
// Make sure that any changes in here are not exposing sensitive information, or do it separately in an
// extension class that lives in API and isn't sourced by static or front end apps!
export class Acl {
    public static isAllowed(userRoles: AuthRoles[], requiredRoles: AuthRoles[][]) {
        if (!requiredRoles || requiredRoles.length === 0) {
            return false;
        }
        else if (!userRoles || userRoles.length === 0) {
            return false;
        }
        else {
            return Acl.hasAnyRole(userRoles, [ [ AuthRoles.CLAdmin ] ]) || Acl.hasAnyRole(userRoles, requiredRoles);
        }
    }

    private static hasAnyRole(userRoles: AuthRoles[], rolesList: AuthRoles[][]) {
        for (const roles of rolesList) {
            if (true === Acl.hasRoles(userRoles, roles)) {
                return true;
            }
        }
        return false;
    }

    private static hasRoles(userRoles: AuthRoles[], roles: AuthRoles[]) {
        if (userRoles && userRoles.indexOf(AuthRoles.CLAdmin) > -1) {
            return true;
        }
        return (roles && userRoles && Acl.arrayContains(userRoles, roles) === true);
    }

    private static hasRole(userRoles: AuthRoles[], role: AuthRoles) {
        // if cladmin -> no other role is required
        if (role && userRoles) {
            if (userRoles.indexOf(AuthRoles.CLAdmin) > -1) {
                return true;
            }
            if (userRoles.indexOf(role) > -1) {
                return true;
            }
        }
        return false;
    }

    private static arrayContains(superset: string[], subset: string[]) {
        return subset.every((value) => {
            return (superset.indexOf(value) >= 0);
        });
    }

    private user: { roles: AuthRoles[]};

    constructor(user: { roles: AuthRoles[]}) {
        this.user = user;
    }

    public hasRole(role: AuthRoles) {
        return Acl.hasRole(this.user.roles, role);
    }

    public hasRoles(roles: AuthRoles[]) {
        return Acl.hasRoles(this.user.roles, roles);
    }

    public static getUnion(c: string[], u: string[]): string[] {
        if (c && u) {
            const setC = new Set<string>(c);
            for (const x of u) {
                setC.add(x);
            }
            return [ ...setC ];
        }
        else if (c) {
            return c;
        }
        else if (u) {
            return u;
        }
        return [];
    }

    public getAuthRoles() {
        return AuthRoles;
    }

    get roles() {
        return AuthRoles;
    }
}
