import { ITourMarker, ITourStepOptions } from '../interfaces/tour';
import { TIME_MACHINE_LOADED_EVENT } from '../layout';
import { addOffEventListener, qs } from '../util/html';
import { getTourStep, runTour } from './tourSetup';

// The dashboard tour is broken up into two sections, because of the asynchronous nature of some tour steps
// Messages are used to link the two tours together:
// - Tour A begins and runs to its end
// - The last step of tour A ends with an async operation of loading the time machine dialog and a message listener setup
// - After the time machine dialog is finished loading, a message is sent to any subscribers
// - The message listener that was setup in tour A launches tour B

export class DashboardTourA implements ITourMarker {
    public menuEntry = 'Dashboard Tour';

    public waitingForTimeMachine = true;

    // Message handler for the handoff between tour parts
    public timeMachineLoadedHandler() {
        runTour(DashboardTourB);
    }

    public clickProfileMenu() {
        // A delay needs to exist because the rendering of dropdown states is asynchronous
        setTimeout(() => {
            const profile: HTMLElement = qs('#userLink');
            if (profile) {
                profile.click();
            }
        }, 300);
    }

    public getSteps(): ITourStepOptions[] {
        const steps = [
            getTourStep(
                'Welcome to Causality Link',
                'intro-step',
                `There are several ways to explore our insights, and we're happy to help you get started.<br><br>
                 You can exit the tour at any time by clicking the
                  <button class="shepherd-cancel-icon" type="button"><span>×</span></button> in any tour step.`,
                'Next',
                null,
                'bottom',
                true,
            ),
            getTourStep(
                'Search',
                'sorting-step',
                `If you already have a company, industry, or location in mind, you can start here.<br><br>
                This search is the starting point for viewing aggregated signals on a specific topic.`,
                'Next',
                '#searchbar',
                'left',
                true,
            ),
            getTourStep(
                'Dashboard - Charts',
                'dashboard-charts-step',
                `This dashboard summarizes the most discussed topics in the past 24 hours, aggregated by 5 dimensions:<br>
                <ul>
                <li>Companies</li>
                <li>Locations</li>
                <li>Industries</li>
                <li>Products</li>
                <li>KPIs</li>
                </ul>`,
                'Next',
                '#selectChart-company',
                'right',
                true,
            ),
            getTourStep(
                'Dashboard - Chart Filtering',
                'dashboard-chart-filtering-step-1',
                `By clicking on any slice of the donut chart, filtering is applied to only include the selected topics.<br><br>
                Go ahead and try clicking on another company now.`,
                'Next',
                '#selectChart-company',
                'right',
                true,
                () => {
                    // Simulate clicking on a plotly graph
                    // This seems to take more than a simple click() because it works off layered event handlers
                    const slice: HTMLElement = qs('#selectChart-company .main-svg .pielayer .trace .slice');
                    if (slice) {
                        const mouseEventProps = { clientX: 0, clientY: 0 };
                        slice.dispatchEvent(new MouseEvent('mouseover', mouseEventProps));
                        slice.dispatchEvent(new MouseEvent('mousedown', mouseEventProps));
                        slice.dispatchEvent(new MouseEvent('click', mouseEventProps));
                        document.dispatchEvent(new MouseEvent('mouseup', mouseEventProps));
                    }
                },
            ),
            getTourStep(
                'Dashboard - Chart Filtering',
                'dashboard-chart-filtering-step-2',
                `Clicking on either the donut charts or dropdown controls to the left will add additional filters. ` +
                `A sample of the most talked about stories after filtering will appear below.`,
                'Next',
                '#popularIndicatorsTable',
                'top',
                true,
            ),
            getTourStep(
                'Dashboard - Exporting',
                'dashboard-exporting-step',
                `Filtered results can be exported for further analysis. Exports include detailed metadata, quotes, and hyperlinks back to the original text.<br>
                <table style='border: 1px grey solid'>
                <tr style='color: #343a40'>
                    <td>Date</td>
                    <td>Company</td>
                    <td>Indicator</td>
                    <td>...&nbsp;</td>
                    <td>Quote</td>
                </tr>
                <tr style='color: #343a40'>
                    <td>June 1, 2022</td>
                    <td>Apple</td>
                    <td>High Margin Sales</td>
                    <td>...&nbsp;</td>
                    <td style='color: blue;text-decoration: underline'>With more consumers owning multiple Apple hardware products...</td>
                </tr>
                </table>
                `,
                'Next',
                '.exportXlsx',
                'left',
                true,
            ),
            getTourStep(
                'Dashboard - Portfolio',
                'dashboard-portfolio-step',
                `Recent news can automatically be filtered to your interests by building or selecting a portfolio.`,
                'Next',
                '#btnPickPortfolio',
                'left',
                true,
            ),
            getTourStep(
                'Menu - Portfolio',
                'portfolio-menu-step-1',
                `You can create a new portfolio of companies, industries, and locations by clicking on this menu item.`,
                'Next',
                '#portfolioMenuLink',
                'bottom',
                true,
            ),
            getTourStep(
                'Menu - Analysis',
                'analysis-menu-step-2',
                `If you have a preferred publisher, clicking here will allow you to see their entire feed along with metadata summaries.`,
                'Next',
                '#analysisMenuLink',
                'bottom',
                true,
            ),
            getTourStep(
                'Menu - Profile',
                'dashboard-profile-step',
                `Opening this dropdown menu allows several actions:<br>
                <table style='color: #343a40'>
                <tr><td><b>Favorites:</b></td><td>See previously bookmarked data</td></tr>
                <tr><td><b>Time Machine:</b></td><td>See all data and reports as if it was a day in the past</td></tr>
                <tr><td><b>My Profile:</b></td><td>Change your password</td></tr>`,
                'Next',
                '#userLink',
                'left',
                true,
                // Once to show the entire menu in this step
                this.clickProfileMenu,
                // Again to allow highlighting of a specific item in the next step
                this.clickProfileMenu,
            ),
            getTourStep(
                'Menu - Profile',
                'dashboard-time-machine-step',
                `Clicking here allows you to set a date when using Time Machine, or return to the present`,
                'Next',
                '#timeMachineMenuLink',
                'left',
                true,
                () => {
                    const html = qs('html');
                    if (html) {
                        addOffEventListener(html, TIME_MACHINE_LOADED_EVENT, this.timeMachineLoadedHandler);
                    }
                },
                () => {
                    // After the user has clicked tour's 'Next' button, and possibly closed the dropdown, re-open the profile menu
                    const profile: HTMLElement = qs('#userLink');
                    if (profile) {
                        profile.click();
                        // It takes the system some time to show the time machine modal, and showing a modal is asynchronous
                        // This means we need to wait before telling the tour to resume
                        const timeMachine: HTMLElement = qs('#timeMachineMenuLink');
                        if (timeMachine) {
                            timeMachine.click();
                        }
                    }
                },
            ),
        ];
        return steps;
    }
}

class DashboardTourB implements ITourMarker {
    public menuEntry = 'Dashboard Tour';

    public waitingForTimeMachine = true;

    public timeMachineLoadedHandler() {
        this.waitingForTimeMachine = false;
    }

    public clickProfileMenu() {
        setTimeout(() => {
            const profile: HTMLElement = qs('#userLink');
            if (profile) {
                profile.click();
            }
        }, 300);
    }

    public getSteps(): ITourStepOptions[] {
        const steps = [
            getTourStep(
                'Time Machine',
                'dashboard-time-machine-step',
                `Here, you can select a date to go back in time, or click reset to see the most recent results.`,
                'Next',
                '#tm-dateSelector',
                'left',
                true,
                () => {
                    return;
                },
                () => {
                    // Hide the time machine modal
                    const cancel = qs('#tm-cancelBtn');
                    if (cancel) {
                        cancel.click();
                    }
                },
            ),
            getTourStep(
                'Returning Home',
                'dashboard-return-home-step',
                `Clicking here will bring you back to this dashboard.`,
                'Next',
                '.navbar-brand',
                'right',
                true,
            ),
            getTourStep(
                'Menu - Help',
                'dashboard-menu-step-3',
                `This tour, as well as tours for other pages, can be found in the Help menu.`,
                'Finish',
                '#helpLink',
                'left',
                true,
            ),
        ];
        return steps;
    }
}
