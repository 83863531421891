import { Mention } from '../../../shared/interfaces/article/mentions';
import { IAthenaQueryTemplate, IAthenaResult } from '../../../shared/interfaces/athena/athena';
import { ConceptCount, DailyCount } from '../../../shared/interfaces/unified';
import { getLastDayOfMonth } from '../../../shared/util/dateUtil';
import { ajaxGet, ajaxPost } from '../ajax';
import { readThroughCacheIDBDatabase } from '../util/storageUtil';

export async function daasGetMention<T = Mention>(params: {compression?: boolean, type: string, conceptKey?: string, endDate?: string,
                                                  startDate?: string, onprogress?: (progressPercent: number, dataLength: number) => void, bothDatesOrNone?: boolean,
                                                limit?: number, custom?: string, concepts?: string[]}) {
    const url = `/daas/mention${params.compression ? 'gz' : ''}/${params.type}/${params.conceptKey || ''}`;
    if (params.startDate?.length === 7) {
        params.startDate += '-01';
    }
    if (params.endDate?.length === 7) {
        const tokens = params.endDate.split('-');
        params.endDate += '-' + getLastDayOfMonth(+tokens[1], +tokens[0]);
    }
    const key = url + params.compression + params.type + params.conceptKey + params.endDate + params.startDate + params.bothDatesOrNone +
        params.limit + params.custom + params.concepts?.sort();
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxPost<T[]>(url, {
            type: params.type,
            conceptKey: params.conceptKey,
            endDate: params.endDate,
            startDate: params.startDate,
            bothDatesOrNone: params.bothDatesOrNone,
            limit: params.limit,
            custom: params.custom,
            concepts: params.concepts,
        }, params.onprogress, true);
    });
}

export async function daasGetTemplate() {
    const url = '/daas/template';
    return await readThroughCacheIDBDatabase(url, async () => {
        return await ajaxGet<IAthenaQueryTemplate[]>(url);
    });
}

export async function daasGetConcept(compression: boolean, concept: string) {
    const url = `/daas/concept${compression ? 'gz' : ''}/:concept`;
    const key = url + compression + concept;
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxGet<ConceptCount[]>(url, { concept });
    });
}

export async function daasGetUnified(conceptKey: string, endDate: string, startDate: string, compression?: boolean, wrap: boolean = true,
                                     onprogress?: (progressPercent: number, dataLength: number) => void, enableIncrementalData: boolean = false) {
    const url = `/daas/unified${compression ? 'gz' : ''}/:conceptKey`;
    const key = url + compression + conceptKey + endDate + startDate + wrap + enableIncrementalData;
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxGet<DailyCount[]>(url, { conceptKey, endDate, startDate }, wrap, onprogress, enableIncrementalData);
    });
}

export async function daasGetRecentCompanyByIndustry(company: string) {
    const url = `/daas/recent/companyByIndustry/:company`;
    const key = url + company;
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxGet<ConceptCount[]>(url, { company });
    });
}

export async function daasGetCreateMarkerTrees() {
    const url = '/daas/createMarkerTrees';
    return await readThroughCacheIDBDatabase(url, async () => {
        return await ajaxGet(url);
    });
}

export function daasGetDaasClientStart(ssoKey: string, notebookName: string) {
    return ajaxGet<{ Status: string, Name: string}[]>('/clients/daasClientStart', { ssoKey, notebookName });
}

export function daasGetDaasInstanceUrl(ssoKey: string, notebookName: string) {
    return ajaxGet<string>('/clients/daasInstanceUrl', { ssoKey, notebookName });
}

export function daasGetDaasClients() {
    return ajaxGet<{ code: string, label: string, ssoKey: string, statuses: string[]}[]>('/clients/daasClients');
}

export async function daasPostRecentConceptRollup(concepts?: string[], endDate?: string, startDate?: string) {
    const url = '/daas/recent/conceptRollup';
    const key = url + concepts?.sort() + endDate + startDate;
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxPost<(ConceptCount | DailyCount)[]>(url, { concepts, endDate, startDate });
    });
}

export async function daasPostQuery(query: string) {
    const url = '/daas/query';
    const key = url + query;
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxPost<IAthenaResult<{[key: string] : string}>>(url, { query });
    });
}
