import { Tooltip, Tab, Modal, Collapse, Popover } from 'bootstrap';
import jQuery from 'jquery';
import { SelectAction } from '../interfaces/static';
import { makeView } from './jsonView';

const events = {};
export const SPACE_KEYCODE = 32;
export const ENTER_KEYCODE = 13;
const usedTooltips = new Map<HTMLElement, Tooltip>();
const usedModals = new Map<HTMLElement, Modal>();
const usedCollapses = new Map<HTMLElement, Collapse>();
const usedPopovers = new Map<HTMLElement, Popover>();
export type PlotlyElement= HTMLDivElement & { on?: (action: string, cb: (data) => void) => void, layout?, shapeDiv?: SVGSVGElement,
    slices?: PlotlyElement[], selected?: string[], __data__?: { label: string }};

// JQUERY FUNCTIONS

// jQuery dependent selectpicker even with bootstrap 5
export function selectpicker(element: HTMLElement, action?: SelectAction) {
    if (action) {
        jQuery(element).selectpicker(action);
    }
    else {
        jQuery(element).selectpicker();
    }
}

// NON JQUERY FUNCTIONS

// Convert a string to html
export function stringToHtml(html: string): HTMLElement {
    const templateStr = document.createElement('template');
    templateStr.innerHTML = html;
    return templateStr.content.cloneNode(true) as HTMLElement;
}

// json view
export function jsonView(container: HTMLElement, jsonObj: Record<string, unknown>, collapserEnabled?: boolean) {
    makeView(container, JSON.stringify(jsonObj), undefined, collapserEnabled);
}

export function popover(element: HTMLElement, action?: 'show' | 'hide' | 'toggle' | 'dispose' | 'enable' | 'disable' | 'toggleEnabled' | 'update',
                        options?: Partial<Popover.Options>) {
    let po = null;
    if (element) {
        po = usedPopovers.get(element) || new Popover(element, options);
        usedPopovers.set(element, po);
        if (action) {
            switch (action) {
            case 'show':
                po.show();
                break;
            case 'hide':
                po.hide();
                break;
            case 'toggle':
                po.toggle();
                break;
            case 'dispose':
                po.dispose();
                usedPopovers.delete(element);
                break;
            case 'enable':
                po.enable();
                break;
            case 'disable':
                po.disable();
                break;
            case 'toggleEnabled':
                po.toggleEnabled();
                break;
            case 'update':
                po.update();
                break;
            }
        }
    }
    return po;
}

export function collapse(element: HTMLElement, action: 'toggle' | 'show' | 'hide' | 'dispose') {
    const ce = usedCollapses.get(element) || new Collapse(element);
    usedCollapses.set(element, ce);
    if (action) {
        switch (action) {
        case 'show':
            ce.show();
            break;
        case 'hide':
            ce.hide();
            break;
        case 'toggle':
            ce.toggle();
            break;
        case 'dispose':
            ce.dispose();
            usedCollapses.delete(element);
            break;
        }
    }
    return ce;
}

export function modal(element: HTMLElement, action?: string) {
    const md = usedModals.get(element) || new Modal(element);
    usedModals.set(element, md);
    if (action) {
        switch (action) {
        case 'show':
            md.show();
            break;
        case 'hide':
            md.hide();
            break;
        case 'toggle':
            md.toggle();
            break;
        case 'dispose':
            md.dispose();
            usedModals.delete(element);
            break;
        case 'handleUpdate':
            md.handleUpdate();
            break;
        }
    }
    return md;
}

// remove previous listener if exists and add event to an element
export function addOffEventListener(element: HTMLElement, event: string, func: (event?: Event) => void, remove?: boolean) {
    if (!events[event]) {
        // eslint-disable-next-line func-call-spacing
        events[event] = new Map<HTMLElement, (event: Event) => void>();
    }
    if (events[event].get(element)) {
        element.removeEventListener(event, events[event].get(element));
        events[event].delete();
    }
    if (!remove) {
        onEvent(element, event, func);
        events[event].set(element, func);
    }
}

// Useful for buttons or icons representing a toggleable on/off state
export function updateTooltipTitle(element: HTMLElement, title: string) {
    tooltip(element, 'hide');
    attr(element, 'data-original-title', title);
    tooltip(element, 'update');
}

// Elements with tooltips must be initialized before they will show
export function enableAllVisibleTooltips(parent?: HTMLElement, specificTag: string = '') {
    qsa(`${specificTag}[data-bs-toggle="tooltip"]`, parent)
        .forEach((elem: HTMLElement) => {
            tooltip(elem);
        });
}

export function tooltip(element: HTMLElement, action?: 'show' | 'hide' | 'toggle' | 'dispose' | 'enable' | 'disable' | 'toggleEnabled' | 'update',
                        obj?: Partial<Tooltip.Options>) {
    let tt = null;
    if (element) {
        // avoid errors with tooltips and popover/dropdown not supported by bs5
        const noTooltipTypes = [ 'popover', 'dropdown' ];
        const type = attr(element, 'data-bs-toggle') || attr(element, 'data-bs-toggle');
        if (type && noTooltipTypes.includes(type)) {
            // clLogger.debug('Element with tooltip and popover or dropdown invalid for Bootstrap', element);
            return;
        }
        tt = usedTooltips.get(element) || new Tooltip(element, obj);
        usedTooltips.set(element, tt);
        if (action) {
            switch (action) {
            case 'show':
                tt.show();
                break;
            case 'hide':
                tt.hide();
                break;
            case 'toggle':
                tt.toggle();
                break;
            case 'dispose':
                tt.dispose();
                usedTooltips.delete(element);
                break;
            case 'enable':
                tt.enable();
                break;
            case 'disable':
                tt.disable();
                break;
            case 'toggleEnabled':
                tt.toggleEnabled();
                break;
            case 'update':
                tt.update();
                break;
            }
        }
        addOffEventListener(element, 'show.bs.tooltip', () => {
            setTimeout(() => {
                tt.hide();
            }, 5000);
        });
    }
    return tt;
}

export function tabShow(element: HTMLElement) {
    new Tab(element).show();
}

// if an element exists, add an event listener, if no container it's assumed document
export function addListenerIfExist(selector: string, event: string, func: () => void, container?: HTMLElement) {
    if (container) {
        container.querySelectorAll(selector).forEach((elem: Element) => {
            onEvent(elem, event, func);
        });
    }
    else {
        document.querySelectorAll(selector).forEach((elem: Element) => {
            onEvent(elem, event, func);
        });
    }
}

// find a parent by a target and a selector
export function parentWithSelector(target: HTMLElement, selector: string) {
    const parents: HTMLElement[] = [];
    let parent = target.parentElement;
    while (parent) {
        if (parent.matches(selector)) {
            parents.push(parent);
        }
        parent = parent.parentElement;
    }
    return parents;
}

// attach event listener to an element if it exists
export function onEvent(element: Element | Document | Window | XMLHttpRequest, type: string, listener: EventListenerOrEventListenerObject,
                        options?: boolean | AddEventListenerOptions): void {
    if (element) {
        element.addEventListener(type, listener, options);
    }
}

// remove event listener from an element if it exists
export function removeEvent(element: Element | Document | Window, type: string, listener: EventListenerOrEventListenerObject,
                            options?: boolean | AddEventListenerOptions): void {
    if (element) {
        element.removeEventListener(type, listener, options);
    }
}

export function onClick(element: Element | Document, listener: EventListenerOrEventListenerObject,
                        options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'click', listener, options);
}

export function onResize(element: HTMLElement | Document | Window, listener: EventListenerOrEventListenerObject,
                         options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'resize', listener, options);
}

export function onBlur(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                       options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'blur', listener, options);
}

export function onKeyDown(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                          options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'keydown', listener, options);
}

export function onFocus(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                        options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'focus', listener, options);
}

export function onInput(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                        options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'input', listener, options);
}

export function onSubmit(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                         options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'submit', listener, options);
}

export function onReset(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                        options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'reset', listener, options);
}

export function onMouseLeave(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                             options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'mouseleave', listener, options);
}

export function onChange(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                         options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'change', listener, options);
}

export function onKeyUp(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                        options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'keyup', listener, options);
}

export function onKeyPress(element: HTMLElement | Document, listener: EventListenerOrEventListenerObject,
                           options?: boolean | AddEventListenerOptions): void {
    onEvent(element, 'keypress', listener, options);
}

// shortcut for DomContentLoaded
export function onDomLoaded(func: EventListenerOrEventListenerObject) {
    onEvent(document, 'DOMContentLoaded', func);
}

// shortcut for popstate
export function onPopstate(listener: (this: Window, ev: PopStateEvent) => void): void {
    onEvent(window, 'popstate', listener);
}

// A more type safe way to find and return HTMLElements of a specific known tag. Use lower case strings.
export function requireElement<K extends keyof HTMLElementTagNameMap>(tagName: K, id: string): HTMLElementTagNameMap[K] {
    const element = getById<HTMLElementTagNameMap[K]>(id);
    if (element && element.tagName.toLowerCase() === tagName) {
        return element;
    }
    throw new Error(`Element ${id} does not exist or is not a ${tagName}`);
}

// shortcut for document.getElementById
export function getById<T extends HTMLElement>(id: string): T | null {
    return document.getElementById(id) as T;
}

// shortcut for getElementsByClassName, if no parent the default is document
export function getByClass<T extends HTMLElement>(className: string, parent?: HTMLElement | null): T[] {
    if (parent) {
        return Array.from(parent.getElementsByClassName(className)) as T[];
    }
    return Array.from(document.getElementsByClassName(className)) as T[];
}

// shortcut for getElementsByClassName, if no parent the default is document, only first element
// if more element throws an error
export function getByClassFirst<T extends HTMLElement>(className: string, parent?: HTMLElement | null): T | null {
    const aux = getByClass<T>(className, parent);
    if (aux.length === 0) {
        return null;
    }
    if (aux.length > 1) {
        throw new Error(className + ' found multiple times');
    }
    return aux[0] as T;
}

// shortcut for getElementsByTagName, if no parent the default is document
export function getByTag<T extends HTMLElement>(tagName: string, parent?: HTMLElement | null): T[] {
    if (parent) {
        return Array.from(parent.getElementsByTagName(tagName)) as T[];
    }
    return Array.from(document.getElementsByTagName(tagName)) as T[];
}

// shortcut for getElementsByTagName, if no parent the default is document, only first
export function getByTagFirst<T extends HTMLElement>(tagName: string, parent?: HTMLElement | null): T | null {
    const aux = getByTag<T>(tagName, parent);
    if (aux.length === 0) {
        return null;
    }
    return aux[0] as T;
}

// shortcut for document.createElement
export function create<T extends HTMLElement>(tagName: string, classes?: string[]): T {
    const element = document.createElement(tagName) as T;
    if (classes) {
        for (const c of classes) {
            element.classList.add(c);
        }
    }
    return element;
}

// shortcut for querySelector, if no container the default is document
export function qs<T extends HTMLElement | SVGSVGElement = HTMLElement>(selector: string, container?: HTMLElement): T | null {
    if (container) {
        return container.querySelector(selector) as T;
    }
    return document.querySelector(selector) as T;
}

// shortcut for querySelectorAll, if no container the default is document
export function qsa<T extends HTMLElement>(selector: string, container?: HTMLElement): T[] {
    if (container) {
        return Array.from(container.querySelectorAll(selector)) as T[];
    }
    return Array.from(document.querySelectorAll(selector)) as T[];
}

// shortcut for setAttribute and getAttribute
export function attr(element: Element, attribute: string, value?: string): string | null {
    if (value !== undefined) {
        element.setAttribute(attribute, value);
    }
    return element.getAttribute(attribute);
}

// shortcut for window.location.hash get/set
export function wHash(value?: string): string {
    if (value !== undefined) {
        window.location.hash = value;
    }
    return window.location.hash;
}

// shortcut for cloning an html element
export function clone<T extends HTMLElement>(element: HTMLElement): T {
    return element.cloneNode(true) as T;
}

// shortcut for removing an attribute
export function rAttr(element: Element, name: string) {
    element.removeAttribute(name);
}

// Returns a list of values the user has selected from a list of options. May be empty.
export function selectedCombo(combo: HTMLSelectElement): string[] {
    return Array.from(combo.options).filter(c => c.selected).map(c => c.value);
}

export function selectElementImpl(evt: KeyboardEvent, divItem: HTMLDivElement, onSelect: (elem: HTMLDivElement) => void) {
    switch (evt.which) {
    case 13:
        evt.preventDefault();
        onSelect(divItem);
        break;
    case 38:
        const prev = divItem.previousSibling;
        if (prev instanceof HTMLDivElement) {
            prev.focus();
        }
        break;
    case 40:
        const next = divItem.nextSibling;
        if (next instanceof HTMLDivElement) {
            next.focus();
        }
        break;
    }
}

// Estimate the room available on the page by removing common layout elements, if they exist.
export function getBasicCLAvailableHeight() {
    return window.innerHeight -
     (getByClassFirst('navbar')?.offsetHeight ?? 0) -
     (getByClassFirst('page-header')?.offsetHeight ?? 0) -
     (getByClassFirst('cl-footer')?.offsetHeight ?? 0);
}

// Try to consolidate all the ways we hide or show DOM so any changes in the future can be fixed centrally.
// This attribute approach seems to work well in all modern browsers. Bootstrap also has a class we can use if necessary.
export function hide(...elems: HTMLElement[]) {
    for (const elem of elems) {
        if (elem) {
            attr(elem, 'hidden', 'hidden');
        }
    }
}

// Consolidated show that inverts whatever was done in hide, above.
export function show(...elems: HTMLElement[]) {
    for (const elem of elems) {
        if (elem) {
            rAttr(elem, 'hidden');
        }
    }
}

export function errorToggle(errorElement: HTMLElement, showError: boolean, error?: string) {
    errorElement.hidden = !showError;
    if (error) {
        errorElement.innerHTML = error;
    }
    else {
        errorElement.innerHTML = '';
    }
}

export function downloadString(text: string, fileType: string, fileName: string) {
    const blob = new Blob([ text ], { type: fileType });
    const a = create<HTMLAnchorElement>('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [ fileType, a.download, a.href ].join(':');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function () {
        URL.revokeObjectURL(a.href);
    }, 1500);
}

export function createOption(value: string, label: string, text: string, select?: HTMLSelectElement, bonusAttrs?: string[], bonusAttrValues?: string[], checkPreselect?: boolean) {
    // Create an option element and set value, label, innerHTML
    const opt = create<HTMLOptionElement>('OPTION');
    opt.value = value;
    opt.label = label;
    opt.innerHTML = text;
    // Bonus attribute examples: data-subtext, data-count
    if (bonusAttrs && bonusAttrValues && bonusAttrs.length === bonusAttrValues.length) {
        for (let i = 0; i < bonusAttrs.length; i++) {
            attr(opt, bonusAttrs[i], bonusAttrValues[i]);
        }
    }
    if (checkPreselect) {
        attr(opt, 'selected', 'true');
    }
    if (select) {
        // Append the option to the selectpicker, if one exists
        select.appendChild(opt);
    }
    return opt;
}
