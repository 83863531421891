import { IMentionSummaryDashboard, IParsedMention } from '../interfaces/article/mentions';
import { CodeType, IBaseInfo, IConceptSummary, IParsedConcept, IParsedEvent, IParsedIndicator, IRollingStats, IStat, IStatsRow, IRollingStat } from '../interfaces/dashboard';
import { IIndicator, IEvent, IECL } from '../interfaces/metadata/meta-i';
import { getClLogger } from '../util/clLogger';
import { aggregatedDateToIsoDate, calculateDaysFromMillis, calculateDaysInMillis, dateDiff, dateOffset, getYearMonthDayOfISODate } from '../util/dateUtil';
import { camelCaseName } from '../util/viewFormatUtil';
const clLogger = getClLogger(__filename);

// Add additional useful details to a mention summary, including parsing the base code object,
// and splitting the ID into separate fields for publisher, article, and metadata IDs.
export function parseMention(mention: IMentionSummaryDashboard): IParsedMention {
    const parsedCode = parseCode(mention.baseCode);
    const idTokens = mention.id.split('/');
    const firstUnder = idTokens[0].indexOf('_');
    return {
        parsedCode,
        publisher: idTokens[0].substr(0, firstUnder),
        articleId: idTokens[0].substr(firstUnder + 1),
        metaId: idTokens[1],
        ...mention,
    };
}

// Routine to normalize stats rows from dynamo into a more usable object with nested properties expanded
export function groupFilterAndSortStatsRowsByConceptKey(stats: IStatsRow[]): IConceptSummary[] {
    const groupMentions: { [ conceptKey: string ]: IConceptSummary} = stats.reduce((acc: { [ conceptKey: string ]: IConceptSummary}, m) => {
        if (!acc[m.conceptKey]) {
            acc[m.conceptKey] = { conceptKey: m.conceptKey, mentions: [] };
        }
        let publishDate = null;
        let aggregatedDate = null;
        let baseCode = null;
        if (m.aggregatedDate) {
            publishDate = aggregatedDateToIsoDate(m.aggregatedDate);
            aggregatedDate = m.aggregatedDate.substr(0, 13);
            baseCode = m.aggregatedDate.substr(13);
        }
        else {
            publishDate = m.date;
            aggregatedDate = `${m.date}T${m.hour}`;
            baseCode = m.aggregatedDate.substr(13);
        }
        for (const prop of Object.keys(m)) {
            const val = m[prop];
            if (typeof (val) === 'object' && val) {
                acc[m.conceptKey].mentions.push({
                    id: prop,
                    publishDate,
                    targetDate: getYearMonthDayOfISODate(new Date(new Date(publishDate).getTime() + (val.o * calculateDaysInMillis(1))).toISOString()),
                    aggregatedDate,
                    baseCode,
                    direction: val.d,
                    offset: val.o,
                });
            }
        }
        return acc;
    }, {});
    return Object.keys(groupMentions)
        .filter((gm) => groupMentions[gm].mentions.length) // Only include groups with at least one mention
        .sort((a, b) => groupMentions[b].mentions.length - groupMentions[a].mentions.length) // Order by most discussed
        .map((key) => groupMentions[key]);
}

// Convert a raw set of summarized concepts to a parsed form ready for visualization / further filtering.
export function parseAndMapConcepts(c: IConceptSummary): IParsedConcept {
    const prettyConcept = camelCaseName(c.conceptKey, 'Unknown');
    const filteredMentions: IParsedMention[] = [];
    for (const mention of c.mentions) {
        // Try each mention separately in case there is bad/old data of some kind
        try {
            filteredMentions.push(parseMention(mention));
        }
        catch (err) {
            clLogger.error(err);
        }
    }
    return {
        conceptKey: c.conceptKey,
        prettyConcept,
        mentions: filteredMentions,
    };
}

// After manipulation or external filtering, we need to re-filter, sort, map, and slice our
// working data. This function facilitates doing that consistently. The input and output object
// is meant to be the exact same shape.
export function filterSortAndCopyParsedConcepts(pa: IParsedConcept[], max?: number): IParsedConcept[] {
    if (!pa) {
        return [];
    }
    return pa
        .filter((m) => m.mentions.length)
        .sort((a, b) => b.mentions.length - a.mentions.length)
        .slice(0, max)
        .map((c) =>
            ({
                prettyConcept: c.prettyConcept,
                conceptKey: c.conceptKey,
                mentions: c.mentions.map((m) => ({
                    id: m.id,
                    parsedCode: m.parsedCode,
                    baseCode: m.baseCode,
                    publishDate: m.publishDate,
                    targetDate: m.targetDate,
                    articleId: m.articleId,
                    publisher: m.publisher,
                    metaId: m.metaId,
                    direction: m.direction,
                    alreadySwapped: m.alreadySwapped,
                    prettyIndicator: m.prettyIndicator,
                })),
            }));
}

// Turns a compact stored code string into an object similar to how it was prior to compaction
export function parseCode(code: string): IParsedIndicator | IParsedEvent {
    const split = code.split(':');
    // INDICATORS
    if (split.length === 1) {
        const tokens = split[0].split('-');
        if (tokens.length === 7) {
            return {
                company: tokens[0],
                location: tokens[1],
                industry: tokens[2],
                segment: tokens[3],
                feature: tokens[4],
                product: tokens[5],
                baseKPI: tokens[6],
            };
        }
    }
    // EVENTS
    else if (split.length === 2) {
        const from = split[0].split('-');
        const to = split[1].split('-');
        // Some 'dynamic' people records started creeping into the system, using a dash to separate the
        // name, title, and company these people belong to. It will be changing to slash, but we need to
        // deal properly with it until they are all gone.
        let personPrefix = '';
        while (to.length > 6) {
            personPrefix = to.splice(2, 1)[0] + '/' + personPrefix;
        }
        if (personPrefix) {
            to[2] = personPrefix + to[2];
        }
        if (from.length === 3 && to.length === 6) {
            return {
                fromCompany: from[0],
                fromLocation: from[1],
                fromPerson: from[2],
                toCompany: to[0],
                toLocation: to[1],
                toPerson: to[2],
                segment: to[3],
                product: to[4],
                baseEvent: to[5],
            };
        }
    }
    throw new Error(`Could not parse code: ${code}`);
}

// When we have a raw code that's converted to condensed form with star notation
// It should first be parsed with the 'parseCode' function. From there, the object
// can be turned into a pretty label using this function.
//
// TopIndustry: In some cases the industry appears to be overly repetitive.
//              By passing in that top industry, it will be omitted from the description.
// InternalCompany: When printing trends or indicators that all
//                  (or almost all if we are rolling up dcompany)
//                  have the same value, passing it in will also omit that from the label.
//                  This also works well for products which include the company name.
export function parseToDisplay(parse: IParsedEvent | IParsedIndicator, topIndustry?: string, internalCompany?: string): string {
    const parsedEvent = parse as IParsedEvent;
    const parsedIndicator = parse as IParsedIndicator;
    let display = '';
    if (parsedEvent.baseEvent) {
        display += camelCaseName(parsedEvent.baseEvent, 'Unknown');
        let toLocation = false;
        if (parsedEvent.toCompany && parsedEvent.toCompany !== '*') {
            display += ' for ' + camelCaseName(parsedEvent.toCompany, 'Unknown');
        }
        if (parsedEvent.segment && parsedEvent.segment !== '*') {
            display += ' ' + camelCaseName(parsedEvent.segment, 'Unknown');
        }
        if (parsedEvent.product && parsedEvent.product !== '*') {
            display += ' ' + camelCaseName(parsedEvent.product, 'Unknown');
        }
        if (parsedEvent.toPerson && parsedEvent.toPerson !== '*') {
            display += ' by ' + camelCaseName(parsedEvent.toPerson, 'Unknown');
        }
        if (parsedEvent.toLocation && parsedEvent.toLocation !== '*' && parsedEvent.toLocation !== 'world') {
            display += ' in ' + camelCaseName(parsedEvent.toLocation, 'Unknown');
            toLocation = true;
        }
        if (parsedEvent.fromCompany && parsedEvent.fromCompany !== '*') {
            display += ' with ' + camelCaseName(parsedEvent.fromCompany, 'Unknown');
        }
        if (parsedEvent.fromPerson && parsedEvent.fromPerson !== '*') {
            display += ' and ' + camelCaseName(parsedEvent.fromPerson, 'Unknown');
        }
        if (parsedEvent.fromLocation && parsedEvent.fromLocation !== '*' && parsedEvent.fromLocation !== 'world') {
            display += (toLocation ? ' and ' : ' in ') + camelCaseName(parsedEvent.fromLocation, 'Unknown');
        }
        return display;
    }
    else if (parsedIndicator.baseKPI) {
        if (parsedIndicator.company && parsedIndicator.company !== '*' && parsedIndicator.company !== internalCompany) {
            display += camelCaseName(parsedIndicator.company, 'Unknown') + ' ';
        }
        if (parsedIndicator.location && parsedIndicator.location !== '*' && parsedIndicator.location !== 'world') {
            display += camelCaseName(parsedIndicator.location, 'Unknown') + ' ';
        }
        if (parsedIndicator.industry && parsedIndicator.industry !== '*' && parsedIndicator.industry !== topIndustry) {
            display += camelCaseName(parsedIndicator.industry.replace(/_1|_2/, ''), 'Unknown') + ' ';
        }
        if (parsedIndicator.segment && parsedIndicator.segment !== '*') {
            display += camelCaseName(parsedIndicator.segment, 'Unknown') + ' ';
        }
        if (parsedIndicator.feature && parsedIndicator.feature !== '*') {
            display += camelCaseName(parsedIndicator.feature, 'Unknown') + ' ';
        }
        if (parsedIndicator.product && parsedIndicator.product !== '*') {
            display += camelCaseName(parsedIndicator.product, 'Unknown') + ' ';
        }
        display += camelCaseName(parsedIndicator.baseKPI, 'Unknown');
        return display;
    }
    return Object.keys(parse).map((p) => {
        return parse[p];
    }).join(' ');
}

// Parse code, above, returns an object similar to the original code object from
// an event or indicator. However, all values are populated, meaning if no company
// was set previously, it now has a star. Use this function to correctly identify the
// concept.
export function parseToConcept(parse: IParsedEvent | IParsedIndicator): string {
    const parsedEvent = parse as IParsedEvent;
    const parsedIndicator = parse as IParsedIndicator;
    let concept = null;
    if (parsedIndicator.baseKPI) {
        if (parsedIndicator.company && parsedIndicator.company !== '*') {
            concept = parsedIndicator.company;
        }
        else if (parsedIndicator.industry && parsedIndicator.industry !== '*') {
            concept = parsedIndicator.industry;
        }
        else if (parsedIndicator.location && parsedIndicator.location !== '*') {
            concept = parsedIndicator.location;
        }
        else if (parsedIndicator.segment && parsedIndicator.segment !== '*') {
            concept = parsedIndicator.segment;
        }
        else {
            concept = 'unknown';
        }
        return `${concept}-${parsedIndicator.baseKPI}`;
    }
    else if (parsedEvent.baseEvent) {
        if (parsedEvent.toCompany && parsedEvent.toCompany !== '*') {
            concept = parsedEvent.toCompany;
        }
        else if (parsedEvent.fromCompany && parsedEvent.fromCompany !== '*') {
            concept = parsedEvent.fromCompany;
        }
        else if (parsedEvent.toLocation && parsedEvent.toLocation !== '*') {
            concept = parsedEvent.toLocation;
        }
        else if (parsedEvent.fromLocation && parsedEvent.fromLocation !== '*') {
            concept = parsedEvent.fromLocation;
        }
        else if (parsedEvent.toPerson && parsedEvent.toPerson !== '*') {
            concept = parsedEvent.toPerson;
        }
        else if (parsedEvent.fromPerson && parsedEvent.fromPerson !== '*') {
            concept = parsedEvent.fromPerson;
        }
        else if (parsedEvent.segment && parsedEvent.segment !== '*') {
            concept = parsedEvent.segment;
        }
        else if (parsedEvent.product && parsedEvent.product !== '*') {
            concept = parsedEvent.product;
        }
        else {
            concept = 'unknown';
        }
        return `${concept}-${parsedEvent.baseEvent}`;
    }
    return null;
}

// This function is used by backend streams or methods to convert a raw meta reference uploaded as Article Meta
// into a structure that is flatter but has enough information to capture the code, display, and aggregation concept
export function parseIndicator(indicator: IIndicator, pubdate: string | null): IBaseInfo {
    const info = parseCommon(indicator, pubdate);
    if (indicator.code) {
        const dbase = indicator.code.dbaseKPI || indicator.code.baseKPI;
        const base = indicator.code.baseKPI;
        const dco = indicator.code.dcompany || indicator.code.company;
        const co = indicator.code.company;
        let dind = indicator.code.dindustry || indicator.code.industry;
        let ind = indicator.code.industry;
        const dseg = indicator.code.dsegment || indicator.code.segment;
        const seg = indicator.code.segment;
        const dprod = indicator.code.dproduct || indicator.code.product;
        const prod = indicator.code.product;
        const dfeat = indicator.code.dfeature || indicator.code.feature;
        const feat = indicator.code.feature;
        let dloc = indicator.code.dlocation || indicator.code.location;
        let loc = indicator.code.location;
        // Macro economy and no industry should be treated the same.
        if (ind === 'macroeconomy') {
            ind = undefined;
        }
        if (dind === 'macroeconomy') {
            dind = ind;
        }
        // When we have a company or industry, the lack of a location and 'world' should be treated the same.
        if ((dco || dind)) {
            if (loc === 'world') {
                loc = undefined;
            }
            if (dloc === 'world') {
                dloc = loc;
            }
        }
        info.concept = dco || dind || dloc || dseg || 'unknown';
        info.base = dbase;
        info.pair = `${info.concept}-${info.base}`;
        info.dcode =
            `${(dco || '*')}-${(dloc || '*')}-${(dind || '*')}-${(dseg || '*')}-${(dfeat || '*')}-${(dprod || '*')}-${dbase}`;
        info.code =
            `${(co || '*')}-${(loc || '*')}-${(ind || '*')}-${(seg || '*')}-${(feat || '*')}-${(prod || '*')}-${base}`;
    }
    if (indicator.span !== null && indicator.span !== undefined) {
        info.span = indicator.span;
    }
    if (indicator.value !== null && indicator.value !== undefined) {
        info.value = indicator.value;
    }
    return info;
}

export function parseEvent(event: IEvent, pubdate: string | null): IBaseInfo {
    const info = parseCommon(event, pubdate);
    if (event.code) {
        const dbase = event.code.dbaseEvent || event.code.baseEvent;
        const base = event.code.baseEvent;
        const toDComp = event.code.toDCompany || event.code.dtoCompany;
        const toComp = event.code.toCompany;
        const fromDComp = event.code.fromDCompany || event.code.dfromCompany;
        const fromComp = event.code.fromCompany;
        const toDLoc = event.code.toDLocation || event.code.dtoLocation;
        const toLoc = event.code.toLocation;
        const fromDLoc = event.code.fromDLocation || event.code.dfromLocation;
        const fromLoc = event.code.fromLocation;
        const toDInd = event.code.toDIndustry || event.code.dtoIndustry;
        const toInd = event.code.toIndustry;
        const toSeg = event.code.toSegment;
        const toProd = event.code.toProduct;
        const fromPer = event.code.fromPerson;
        const toPer = event.code.toPerson;
        info.concept = toDComp || fromDComp || toDLoc || fromDLoc || toDInd || toInd || toSeg || toPer || fromPer || toProd || 'unknown';
        info.base = dbase;
        info.pair = `${info.concept}-${info.base}`;
        info.dcode =
            `${(fromDComp || '*')}-${(fromDLoc || '*')}-${(fromPer || '*')}:${(toDComp || '*')}-${(toDLoc || '*')}-${(toPer || '*')}` +
                `-${(toDInd || toInd || toSeg || '*')}-${(toProd || '*')}-${dbase}`;
        info.code =
            `${(fromComp || '*')}-${(fromLoc || '*')}-${(fromPer || '*')}:${(toComp || '*')}-${(toLoc || '*')}-${(toPer || '*')}` +
                `-${(toInd || toSeg || '*')}-${(toProd || '*')}-${base}`;
    }
    return info;
}

function parseCommon(meta: IIndicator | IEvent, pubdate: string | null): IBaseInfo {
    const info: IBaseInfo = {
        concept: 'nocode',
        base: 'nocode',
        pair: 'nocode',
        dcode: 'nocode',
        code: 'nocode',
        refdategroup: '2001-01-01T00',
        pubdategroup: '2001-01-01T00',
        origdate: '2001-01-01T00',
        refoffset: 0,
        sentence: meta.sentence,
        span: 1,
    };
    if (meta.date) {
        info.refdategroup = meta.date.substr(0, 13);
        info.origdate = meta.date;
    }
    if (pubdate) {
        info.pubdategroup = pubdate.substr(0, 13);
        if (meta.date) {
            const pub = new Date(getYearMonthDayOfISODate(info.pubdategroup));
            const mention = new Date(getYearMonthDayOfISODate(info.refdategroup));
            info.refoffset = calculateDaysFromMillis(mention.getTime() - pub.getTime());
        }
    }
    return info;
}

// Given a date object, add option number of months and or years and convert to the start of an
// ISO standard string like 2018-08, which we use as aggregation buckets.
export function getMonthlyDateBucket(when: Date, addMonths: number = 0, addYears: number = 0): string {
    let year = when.getUTCFullYear() + addYears;
    let month = when.getUTCMonth() + 1 + addMonths;
    while (month > 12) {
        year += 1;
        month -= 12;
    }
    while (month < 1) {
        year -= 1;
        month += 12;
    }
    return `${year}-${month < 10 ? '0' + month : month}`;
}

// General function that can use a configurable half life in days, and days elapsed to decrease
// the influence of a statistic calculated over a rolling period.
export function getDecayWeight(x: number, days: number, halflife: number): number {
    return x / (Math.pow(2, days / halflife));
}

// Centralized way to compute a time series in which mentions of trends grouped by days are grouped
// by a window function, decay is applied to older mentions, and a final value between 0 and 1 is returned for each day.
export function computeRollingTrendStats(trends: { [kpi: string]: IStat[] },
                                         range: { startDate: string, endDate: string },
                                         rollingWindow: number = 90,
                                         timeToDecay: number = 30,
                                         func: ((x: number, t: number, h: number) => number)): IRollingStats {
    const results: IRollingStats = {};
    for (const kpi of Object.keys(trends)) {
        results[kpi] = {};

        for (let date = range.startDate; date <= range.endDate; date = dateOffset(date, 1)) {
            const limitWindow = dateOffset(date, rollingWindow * -1);
            const stat: IRollingStat = {
                tot: 0,
                up: 0,
                down: 0,
                flat: 0,
                ptp: null,
                mentions: [],
                dayMentions: [],
                dayDown: 0,
                dayFlat: 0,
                dayUp: 0,
                dayTot: 0,
            };
            const values: {[key: number]: {up: number, down: number, offset: number}} = {};
            trends[kpi].filter((s) => s.date <= date && s.date > limitWindow)
                .forEach((s) => {
                    const offset = dateDiff(s.date, date);
                    if (!values[offset]) {
                        values[offset] = { up: 0, down: 0, offset };
                    }
                    let favorable = s.favorable;
                    if (favorable === undefined || favorable === null) {
                        favorable = 1;
                    }
                    if (favorable < 0) {
                        for (const m of s.mentions) {
                            // swap direction of mentions
                            if (!m.alreadySwapped && (m.direction === 1 || m.direction === -1)) {
                                m.direction = m.direction * -1;
                                m.alreadySwapped = true;
                            }
                        }
                    }
                    const up = favorable < 0 ? s.down : s.up;
                    const down = favorable < 0 ? s.up : s.down;
                    stat.up += up;
                    values[offset].up += up;
                    stat.down += down;
                    values[offset].down += down;
                    stat.flat += s.flat;
                    stat.tot += s.tot;
                    stat.mentions = stat.mentions.concat(s.mentions);
                    if (s.date === date) {
                        stat.dayUp += up;
                        stat.dayDown += down;
                        stat.dayFlat += s.flat;
                        stat.dayTot += s.tot;
                        stat.dayMentions = stat.dayMentions.concat(s.mentions);
                    }
                });
            if (stat.up || stat.down) {
                let n = 0;
                const p = Object.values(values).reduce((acc, v) => {
                    const wu = func(v.up, v.offset, timeToDecay);
                    const wd = func(v.up + v.down, v.offset, timeToDecay);
                    if (!Number.isNaN(wd) && wd > 0) {
                        n += wd;
                    }
                    if (!Number.isNaN(wu) && wu > 0) {
                        return acc + wu;
                    }
                    return acc;
                }, 0) as number;
                stat.ptp = Math.round((1.0 + p) * 100.0 / (n + 2.0));
            }
            results[kpi][date] = stat;
        }
    }
    return results;
}

export function getFromToECLData(indicator: IECL) {
    const info = { from: null, to: null };
    const froms = [ 'fromTrendId', 'fromIndicatorId', 'fromEventId' ];
    let i = 0;
    while (!info.from && i < froms.length) {
        if (indicator[froms[i]]) {
            info.from = indicator[froms[i]];
        }
        i++;
    }
    const tos = [ 'toTrendId', 'toIndicatorId', 'toEventId' ];
    i = 0;
    while (!info.to && i < tos.length) {
        if (indicator[tos[i]]) {
            info.to = indicator[tos[i]];
        }
        i++;
    }

    return info;
}

export function getCodeType(code: string): CodeType {
    const split = code.split(':');
    if (split.length === 1) {
        const tokens = split[0].split('-');
        if (tokens.length === 7) {
            return CodeType.INDICATOR;
        }
    }
    else if (split.length === 2) {
        const from = split[0].split('-');
        const to = split[1].split('-');
        if (from.length === 2 && to.length === 5) {
            return CodeType.EVENT;
        }
        if (from.length === 3 && to.length === 6) {
            return CodeType.EVENT;
        }
    }
    return CodeType.UNKNOWN;
}
