import { ModalSizeEnum } from '../interfaces/static';
import { getByClassFirst, onClick } from './html';
import { ClModal } from './modalWidget';
const confirmTmpl = require('./confirm.pug');

export function getConfirmModal(title: string, getConfirmMsg: (params) => string, onConfirm: (params) => void, size: ModalSizeEnum = ModalSizeEnum.Normal) {
    const modal = new ClModal(size, title, false, () => {
        return confirmTmpl({ sizeNormal: size === ModalSizeEnum.Normal });
    }, (bodyContainer, params) => {
        getByClassFirst('confirmMessage', bodyContainer).innerHTML = getConfirmMsg(params);
        confirmButtonsToggle(modal, false);
        onClick(getByClassFirst('confirmCancel', bodyContainer), () => {
            modal.close();
        });
        onClick(getByClassFirst('confirmConfirmed', bodyContainer), () => {
            confirmButtonsToggle(modal, true);
            onConfirm(params);
        });
    });
    return modal;
}

export function confirmButtonsToggle(modal: ClModal, disabled: boolean) {
    getByClassFirst<HTMLButtonElement>('confirmCancel', modal.getBodyContainer()).disabled = disabled;
    getByClassFirst<HTMLButtonElement>('confirmConfirmed', modal.getBodyContainer()).disabled = disabled;
}

export function changeConfirmMessage(modal: ClModal, message: string) {
    getByClassFirst('confirmMessage', modal.getBodyContainer()).innerHTML = message;
}

export function showConfirmError(modal: ClModal, message: string) {
    const errorM = getByClassFirst('confirmError', modal.getBodyContainer());
    errorM.innerHTML = message;
    errorM.hidden = false;
}
