import { Acl } from '../../../../shared/auth/acl';
import { AuthRoles } from '../../../../shared/interfaces/acl/acl-i';
import { ApiError, STATUS_CODES } from '../../../../shared/interfaces/api/apiInterfaces';
import { ICurrentUser } from '../../../../shared/interfaces/user/user';
import { getClLogger } from '../../../../shared/util/clLogger';
import { newEvent } from '../../ajax';
import { servicesGetCurrentUser } from '../../api/services';
import { UserFeature } from '../../interfaces/analytics';
import { ifExistsOnClickTrackAnalyticsEvent } from '../../util/analytics';
import { getByClassFirst, getById, onClick } from '../../util/html';
const widget = require('./loginWidget.pug');
const clLogger = getClLogger(__filename);

export class LoginWidget {
    private static counter: number = 1;

    public user: ICurrentUser;
    protected controlId: number;
    private containerId: string;

    constructor(containerId: string) {
        this.containerId = containerId;
        this.controlId = ++LoginWidget.counter;
    }

    public getAcl(): Acl | null {
        if (this.user && this.user.name) {
            return new Acl(this.user);
        }
        else {
            return null;
        }
    }

    public getUser() {
        if (this.user) {
            return this.user;
        }
        else {
            throw Error('User info not yet loaded');
        }
    }

    public async render(): Promise<boolean> {
        try {
            this.user = await this.loadCurrentUser();
            this.setContainerContents(this.getContents());
        }
        catch (err) {
            if (err instanceof ApiError && err.statusCode === STATUS_CODES.OBJECT_NOT_FOUND) {
                this.setContainerContents('There was an error loading your profile.');
            }
            else {
                clLogger.debug('Type of error was unknown...');
                throw err;
            }
        }
        return true;
    }

    private async loadCurrentUser(): Promise<ICurrentUser> {
        return await servicesGetCurrentUser();
    }

    protected getContents(): string {
        if (this.user && this.user.name) {
            return widget({ name: this.user.name, acl: this.getAcl(), roles: AuthRoles });
        }
        else {
            return 'Error';
        }
    }

    private setContainerContents(contents: string) {
        const container = getById(this.containerId);
        container.innerHTML = contents;
        const timeMachineButton = getByClassFirst('triggerTimeMachineModal', container);
        if (timeMachineButton) {
            onClick(timeMachineButton, () => {
                document.dispatchEvent(newEvent('RequestTimeMachine'));
            });
        }

        const stemUtilButton = getByClassFirst('triggerStemModal', container);
        if (stemUtilButton) {
            onClick(stemUtilButton, () => {
                document.dispatchEvent(newEvent('RequestStemUtility'));
            });
        }

        const monitorUtilButton = getByClassFirst('triggerMetadataMonitorModal', container);
        if (monitorUtilButton) {
            onClick(monitorUtilButton, () => {
                document.dispatchEvent(newEvent('RequestMetadataMonitorUtility'));
            });
        }

        const daasSsoButton = getByClassFirst('triggerDaasSsoModal', container);
        if (daasSsoButton) {
            onClick(daasSsoButton, () => {
                document.dispatchEvent(newEvent('RequestDaasSsoModal'));
            });
        }

        this.generateGoogleAnalytics();
    }

    private generateGoogleAnalytics() {
        ifExistsOnClickTrackAnalyticsEvent(getById('userLink'), UserFeature.HEADER_USERNAME_MENU_CLICK);
    }
}
