import { attr, create, onClick, qs, qsa } from './html';
require('../jsonView.less');

function collapser(collapsed?) {
    const item = create('span', [ 'collapser' ]);
    onClick(item, (evt) => {
        const target = evt.target as HTMLElement;
        target.classList.toggle('collapsed');
        const block = qs('.block', target.parentElement);
        const ul = qs('ul', target.parentElement);
        ul.hidden = target.classList.contains('collapsed');
        qsa('.dots, .comments', block).forEach((element) => {
            element.hidden = !ul.hidden;
        });
    });
    if (collapsed) {
        item.classList.add('collapsed');
    }
    return item;
}

function htmlEncode(html) {
    if (!html.toString()) {
        return '';
    }
    return html.toString().replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

function spanVal(val, cls: string) {
    const span = create('span', cls.split(' '));
    span.innerHTML = htmlEncode(val);
    return span;
}

function isEmptyObject(obj) {
    return obj ? Object.keys(obj).length === 0 : false;
}

function genBlock(val, level: number, nl2br: boolean, collapserEnabled: boolean) {
    switch (typeof val) {
    case 'object':
        if (val === undefined) {
            return spanVal('undefined', 'undef');
        }
        if (val === null) {
            return spanVal('null', 'null');
        }
        if (Array.isArray(val)) {
            if (!level) {
                level = 0;
            }

            let cnt = val.length;

            const output = create('span', [ 'block' ]);

            if (!cnt) {
                output.append(spanVal('[', 'b'));
                output.append(' ');
                output.append(spanVal(']', 'b'));
                return output;
            }

            output.append(spanVal('[', 'b'));

            const items = create('ul', [ 'obj', 'collapsible', 'level' + level ]);

            for (const key of Object.keys(val)) {
                const data = val[key];
                cnt--;
                const item = create('li');
                item.append(genBlock(data, level + 1, nl2br, collapserEnabled));

                if (collapserEnabled) {
                    if (typeof data === 'object' && !Array.isArray(data) && !isEmptyObject(data)) {
                        item.prepend(collapser());
                    }
                }

                if (cnt > 0) {
                    item.append(',');
                }

                items.append(item);
            }

            output.append(items);
            output.append(spanVal('...', 'dots'));
            output.append(spanVal(']', 'b'));
            if (val.length === 1) {
                output.append(spanVal('// 1 item', 'comments'));
            }
            else {
                output.append(spanVal('// ' + val.length + ' items', 'comments'));
            }

            return output;
        }
        if (!level) {
            level = 0;
        }
        const output = create('span', [ 'block' ]);
        let cnt = Object.keys(val).length;
        if (!cnt) {
            output.append(spanVal('{', 'b'));
            output.append(' ');
            output.append(spanVal('}', 'b'));
            return output;
        }
        output.append(spanVal('{', 'b'));

        const items = create('ul', [ 'obj', 'collapsible', 'level' + level ]);

        for (const key of Object.keys(val)) {
            const data = val[key];
            cnt--;
            const item = create('li');
            item.append(spanVal('"', 'q'));
            item.append(key);
            item.append(spanVal('"', 'q'));
            item.append(': ');
            item.append(genBlock(data, level + 1, nl2br, collapserEnabled));

            if (collapserEnabled) {
                if (typeof data === 'object' && !Array.isArray(data) && !isEmptyObject(data)) {
                    item.prepend(collapser());
                }
            }

            if (cnt > 0) {
                item.append(',');
            }

            items.append(item);
        }
        output.append(items);
        output.append(spanVal('...', 'dots'));
        output.append(spanVal('}', 'b'));
        if (Object.keys(val).length === 1) {
            output.append(spanVal('// 1 item', 'comments'));
        }
        else {
            output.append(spanVal('// ' + Object.keys(val).length + ' items', 'comments'));
        }
        return output;
    case 'string':
        val = htmlEncode(val);
        if (/^(http|https|file):\/\/[^\s]+$/i.test(val)) {
            const output2 = create('span');
            output2.append(spanVal('"', 'q'));
            const a = create('a');
            attr(a, 'href', val);
            a.innerText = val;
            output2.append(a);
            output2.append(spanVal('"', 'q'));
            return output2;
        }
        if (nl2br) {
            const pattern = /\n/g;
            if (pattern.test(val)) {
                val = (val + '').replace(pattern, '<br />');
            }
        }
        const text = create('span');
        text.classList.add('str');
        text.innerHTML = val;
        const output3 = create('span');
        output3.append(spanVal('"', 'q'));
        output3.append(text);
        output3.append(spanVal('"', 'q'));
        return output3;

    case 'number':
        return spanVal(val.toString(), 'num');
    case 'boolean':
        return spanVal(val ? 'true' : 'false', 'bool');
    default:
        return null;
    }
}
function formatter(json, nl2br: boolean, collapserEnabled: boolean) {
    return genBlock(json, 0, nl2br, collapserEnabled);
}

export function makeView(element: HTMLElement, json: string, nl2br: boolean = true, collapserEnabled: boolean = true) {
    if (typeof json === 'string') {
        try {
            json = JSON.parse(json);
        }
        catch (err) {
        }
    }

    const div = create('div');
    div.classList.add('json-view');
    element.append(div);
    div.append(formatter(json, nl2br, collapserEnabled));
}
