import { IListData } from '../interfaces/read';

export function singleReadLinkByUnderscoreArticleId(articleId: string, meta?: string, host?: string) {
    if (!articleId || !articleId.trim()) {
        throw new Error('Missing required fields');
    }
    return singleReadLink(articleId.replace('_', '/'), meta, host);
}

export function singleReadLinkByPublisherArticleId(publisherId: string, articleId: string, meta?: string, host?: string) {
    if (!articleId || !articleId.trim() || !publisherId || !publisherId.trim()) {
        throw new Error('Missing required fields');
    }
    return singleReadLink(`${publisherId}/${articleId}`, meta, host);
}

export function singleReadLink(id: string, meta?: string, host?: string) {
    if (!id || !id.trim()) {
        throw new Error('Missing required fields');
    }
    host = host && host.trim() ? host : '';
    meta = meta && meta.trim() ? `/${meta}` : '';
    return `${host}/read/${id}${meta}`;
}

export function singleReadLinkBySql(value: string, as: string, host: string) {
    if (!value || !value.trim() || !host || !host.trim() || !as || !as.trim()) {
        throw new Error('Missing required fields');
    }
    return `concat('https://${host}.causalitylink.com/read/', ${value}) as ${as}`;
}

export function listReadLink(quotes: IListData []) {
    if (!quotes || !quotes.length) {
        throw new Error('Missing required fields');
    }
    const links = quotes.map((m) => {
        if (m.field && m.paragraph) {
            return `${m.publisher}_${m.article},${m.field}${m.paragraph}`;
        }
        return `${m.publisher}_${m.article}`;
    }).join('/');
    return `/read/list/${links}`;
}
