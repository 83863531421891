import { IStoredECL } from '../dashboard';

export interface IUserBookmarkEclMeta {
    [key: string]: IStoredECL;
}

export interface IUserBookmarkCollection {
    username: string;
    id: string;
    name: string;
    sharer?: string;
    authors?: IUserBookmarkCollectionAuthor[];
    articles?: IUserBookmarkCollectionArticle[];
    ecls?: IUserBookmarkCollectionEcl[];
    sharing?: IUserBookmarkCollectionSharing[];
    notes?: IUserBookmarkCollectionNote[];
    eclsmeta?: IUserBookmarkEclMeta;
    drivers?: IUserBookmarkCollectionDriver[];
    industryGroups?: IUserBookmarkCollectionIndustryGroup[];
    companyGroups?: IUserBookmarkCollectionCompanyGroup[];
    locations?: IUserBookmarkCollectionLocation[];
    publishers?: IUserBookmarkCollectionPublisher[];
    alerts?: { [ key in TAlertConfigKey ]: TAlertSettings };
    treeBookmark?: ITreeBookmark;
}

export interface IUserBookmarkCollectionIndustryGroup {
    id: number;
    marker: string;
    label: string;
}

export interface IUserBookmarkCollectionCompanyGroup {
    id: number;
    marker: string;
    label: string;
}

export interface IUserBookmarkCollectionLocation {
    id: number;
    marker: string;
    label: string;
}

export interface IUserBookmarkCollectionAuthor {
    id: number;
    marker: string;
    label: string;
}

export interface IUserBookmarkCollectionPublisher {
    name: string;
    label: string;
}

export interface IUserBookmarkCollectionSharing {
    username: string;
}

export interface IUserBookmarkCollectionArticle {
    publisher: string;
    id: string;
    title: string;
    bookmarkId: string;
}

export interface IUserBookmarkCollectionDriver {
    id: string;
    title: string;
    bookmarkId: string;
}

export interface IUserBookmarkCollectionEcl {
    title: string;
    bookmarkId: string;
    targetKey: string;
    dateAndId: string;
    fromIndicatorName?: string;
    rowId?: string;
    publisher?: string;
    id?: string;
}

export interface IUserBookmarkCollectionNote {
    message: string;
    title: string;
}

export interface IUserBookmarkSymbolConcept {
    concept: string;
    label: string;
    exchange: string;
    symbol: string;
    uid: string;
}

export interface IUserBookmarkConcept {
    [key: string]: IUserBookmarkSymbolConcept[];
}

export interface ICollectionList {
    collections: IUserBookmarkCollection[];
    username: string;
    concepts: IUserBookmarkConcept;
    collIds: string[];
}

export interface ITreeBookmark {
    locations: string[];
    industries: string[];
    kpis: string[];
    events: string[];
}

// Structure details for report configuration.
// This controls how the Alerts popup knows how to render settings
// There is a different format than how Alert settings for a user are stored
//  (there is no need for most of this data to be serialized)
export enum ALERT_CONFIG_SETTINGS_NAME {
    types = 'types',
    trends = 'trends',
    counts = 'counts',
    kpisEventsSelected = 'kpisEventsSelected',
    minNovelty = 'minNovelty',
}
type TAlertConfigSettingsName = keyof typeof ALERT_CONFIG_SETTINGS_NAME;

enum ALERT_CONFIG_TYPE {
    checkbox = 'checkbox',
    number = 'number',
    selectString = 'selectString',
}
type TAlertConfigType = keyof typeof ALERT_CONFIG_TYPE;

export enum ALERT_CONFIG_KEY {
    newForecastsAlert = 'newForecastsAlert',
    noveltyReportAlert = 'noveltyReportAlert',
    trialUserBookmarkReminderReport = 'trialUserBookmarkReminderReport',
    userUnverifiedReminderReport = 'userUnverifiedReminderReport',
}
export type TAlertConfigKey = keyof typeof ALERT_CONFIG_KEY;

enum ALERT_CONFIG_FINAL_TYPE {
    int = 'int',
    string = 'string'
}
type TAlertConfigFinalType = keyof typeof ALERT_CONFIG_FINAL_TYPE;

export interface IAlertConfig {
    key: TAlertConfigKey;
    name: string;                        // New Forecasts Alert
    description: string;                 // This alert does something really neat...
    manageReportsOnly?: boolean;
    settings: {
        type: TAlertConfigType;
        name: TAlertConfigSettingsName;
        values: number[] | string[];     // 1, 2, 3 <- represents the input name within a control group like checkboxes/radios
        finalValueType: TAlertConfigFinalType; // ???
        setValues: null | number[];      // null <-The default value for a control?
        names?: string[];                // Companies, Industries, Countries <- The label associated with each value in a checkbox type group
        label?: string;                  // Include Forecasts that are <- an overall label for the entire value control group
        texts?: string[];                // Min Mentions, Max Mentions <- The label associated with each value in a number type group
        default?: number[];              // ???
        min?: number;                    // 1 <- The minimum value allowed for a number control
        max?: number;                    // 1 < - The maximum value allowed for a number control
        required?: boolean[];            // [ true, false ] <- Which controls of a group are required
        group?: string;                  // Trends <- Groups controls into expandable accordion sections
        description?: string;            // foot note
        dynamicDescription?: string;            // changing foot note
    }[];
}
export interface IConfigurableReports {
    newForecastsAlert: IAlertConfig;
    noveltyReportAlert: IAlertConfig;
    trialUserBookmarkReminderReport: IAlertConfig;
    userUnverifiedReminderReport: IAlertConfig;
}

// Structure information for the storage of Alert details
export type TAlert = {
    [ key in TAlertConfigKey ]?: TAlertSettings;
};

export type TAlertSettings = {
    [ key in TAlertConfigSettingsName]?: number[] | string[]; // Currently, only numbers are supported here, but expansion is possible
};

// Individual controls can be checkboxes or number inputs
// There are two kinds of groups:
// - Checkbox groups, created by a single setting containing multiple values
// - Control groups, represented by Accordion UIs and created by specifying a 'group' property on a setting
export const configurableReports: IConfigurableReports = {
    newForecastsAlert: {
        key: ALERT_CONFIG_KEY.newForecastsAlert,
        name: 'New Forecasts',
        description: 'Summarize the indicators for each portfolio target that were discussed each day as a forward looking statement, forecast, or future expectation.',
        settings: [
            {
                type: ALERT_CONFIG_TYPE.checkbox,
                name: ALERT_CONFIG_SETTINGS_NAME.types,
                values: [ 1, 2, 3 ],
                names: [ 'Companies', 'Industries', 'Countries' ],
                finalValueType: ALERT_CONFIG_FINAL_TYPE.int,
                label: 'Monitor',
                setValues: null,
                default: [ 1, 2, 3 ],
            },
            {
                type: ALERT_CONFIG_TYPE.checkbox,
                name: ALERT_CONFIG_SETTINGS_NAME.trends,
                values: [ 1, 2, 3 ],
                names: [ 'Up', 'Down', 'Flat' ],
                finalValueType: ALERT_CONFIG_FINAL_TYPE.int,
                label: 'Include Forecasts that are',
                setValues: null,
                default: [ 1, 2, 3 ],
            },
            {
                type: ALERT_CONFIG_TYPE.number,
                name: ALERT_CONFIG_SETTINGS_NAME.counts,
                texts: [ 'Min Mentions' ],
                values: [ 'min' ],
                finalValueType: ALERT_CONFIG_FINAL_TYPE.int,
                min: 1,
                required: [ true ],
                setValues: null,
            },
        ],
    },
    noveltyReportAlert: {
        key: ALERT_CONFIG_KEY.noveltyReportAlert,
        name: 'Novel Stories',
        description: 'Each indicator and event for the target portfolio will be assessed for novelty relative to the past one hundred days. ' +
                     'The most novel results, grouped by target, are summarized with associated quotes.',
        settings: [
            {
                type: ALERT_CONFIG_TYPE.selectString,
                name: ALERT_CONFIG_SETTINGS_NAME.kpisEventsSelected,
                texts: [ 'Saved KPI/Events selection' ],
                values: [ '' ],
                names: [ 'All' ],
                finalValueType: ALERT_CONFIG_FINAL_TYPE.string,
                required: [ false ],
                setValues: null,
                description: 'Saved selections can be created using the selections button on the novelty report page',
            },
            {
                type: ALERT_CONFIG_TYPE.number,
                name: ALERT_CONFIG_SETTINGS_NAME.minNovelty,
                texts: [ 'Minimum Novelty' ],
                values: [ 'minTrendsNovelty' ],
                finalValueType: ALERT_CONFIG_FINAL_TYPE.int,
                min: 15,
                max: 100,
                required: [ true ],
                setValues: [ 20 ],
            },
        ],
    },
    trialUserBookmarkReminderReport: {
        key: ALERT_CONFIG_KEY.trialUserBookmarkReminderReport,
        name: 'Trial User Bookmark Reminder',
        description: 'Remind self-registered users to finish creating/adding to their portfolio',
        manageReportsOnly: true,
        settings: [],
    },
    userUnverifiedReminderReport: {
        key: ALERT_CONFIG_KEY.userUnverifiedReminderReport,
        name: 'Unverified Users Reminder',
        description: 'Give unverified users a new verify link',
        manageReportsOnly: true,
        settings: [],
    },
};

// Used when default settings are needed (such as in manually running reports)
// Keep a set of default for all Alert types in one place.
export const DEFAULT_BOOKMARK_ALERTS: TAlert = {
    [ALERT_CONFIG_KEY.newForecastsAlert]: {
        [ALERT_CONFIG_SETTINGS_NAME.types]: [ 1, 2, 3 ],
        [ALERT_CONFIG_SETTINGS_NAME.trends]: [ 1, 2, 3 ],
    },
    [ALERT_CONFIG_KEY.noveltyReportAlert]: {
        [ALERT_CONFIG_SETTINGS_NAME.minNovelty]: [ 20 ],
    },
    [ALERT_CONFIG_KEY.trialUserBookmarkReminderReport]: {
    },
    [ALERT_CONFIG_KEY.userUnverifiedReminderReport]: {
    },
};
