import { IAddElement, IRemoveElement, IUpdateElement } from '../../../shared/interfaces/bookmark/bookmark';
import { ICollectionList, IUserBookmarkCollection } from '../../../shared/interfaces/bookmark/bookmark-i';
import { ISymbolConcept, ISymbolMatches } from '../../../shared/interfaces/market/symbols';
import { IValidatedReport } from '../../../shared/interfaces/report';
import { ajaxDelete, ajaxGet, ajaxPost, ajaxPut } from '../ajax';
import { readThroughCacheIDBDatabase } from '../util/storageUtil';

export function bookmarksGetUserBookmarks(values: {withConcepts?: boolean, withTreeBookmark?: boolean, withLinks?: boolean, noDefault?: boolean, onlyPrimaryEquity?: boolean}) {
    return ajaxGet<ICollectionList>('/bookmarks/userBookmarks', values);
}

export function bookmarksGetBookmarksByUser(user: string) {
    return ajaxGet<IUserBookmarkCollection[]>('/bookmarks/bookmarksByUser/:user', { user });
}

export function bookmarksPostProcessSymbolsBulkImport(values: string, maxElements: number) {
    if (maxElements > 0) {
        return ajaxPost<ISymbolMatches[]>('/bookmarks/processSymbolsBulkImport', { values, maxElements });
    }
    throw new Error('Can\'t bulk import');
}

export function bookmarksPostManageReports(reports: IValidatedReport[], portfolio: string) {
    return ajaxPost('/bookmarks/manageReports', { reports, portfolio });
}

export function bookmarksPutUserBookmarks(params: {
        addElements?: IAddElement[],
        updateElements?: IUpdateElement[],
        removeElements?: IRemoveElement[],
        removeCollections?:{id: string}[],
        renameCollections?:{id: string,
        name: string}[],
}) {
    return ajaxPut('/bookmarks/userBookmarks', params);
}

export function bookmarksPutUserBookmark(bookmark: {
    id: string | number,
    name: string,
    authors?: string[],
    sharing?: string[],
    industryGroups: string[],
    companyGroups: string[],
    publishers?: string[],
    locations?: string[],
    notes?: string[],
}) {
    return ajaxPut('/bookmarks/userBookmark', bookmark);
}

export function bookmarksDeleteCollections(type: string, id: string, collectionIds: string[]) {
    return ajaxDelete(`/bookmarks/deleteBookmark/:type/:id/:collectionIds`, { type, id, collectionIds: collectionIds.join(',') });
}

export async function bookmarksGetBaseEvalConcepts() {
    const url = '/bookmarks/baseEvalConcepts';
    return await readThroughCacheIDBDatabase(url, async () => {
        return await ajaxGet<ISymbolConcept[]>(url);
    });
}
