import { compileTemplate } from 'pug';
import { IArticleLang } from '../../../shared/interfaces/article/article';
import { IParsedMention, MentionExportColumnKeys } from '../../../shared/interfaces/article/mentions';
import { IPublisher } from '../../../shared/interfaces/article/publishers';
import { CodeType, IParsedConcept, IParsedEvent, IParsedIndicator, IStat, IStatsRow, IStoredECL } from '../../../shared/interfaces/dashboard';
import { UnifiedLink } from '../../../shared/interfaces/unified';
import { LinkColToGraph, MentionColToGraphMention } from '../../../shared/meta/unified';
import { extractMentions } from '../../../shared/pageUtil/mentions';
import { trendStatToBinom } from '../../../shared/stats/vizmath';
import {
    computeRollingTrendStats, filterSortAndCopyParsedConcepts, getCodeType,
    getMonthlyDateBucket, groupFilterAndSortStatsRowsByConceptKey, parseCode, parseToConcept, parseToDisplay,
} from '../../../shared/streaming/dashboard';
import { getArticleIdSplit } from '../../../shared/util/articleIdUtil';
import { getClLogger } from '../../../shared/util/clLogger';
import { cloneDeep } from '../../../shared/util/cloneUtil';
import { aggregatedDateToIsoDate, calculateDaysInMillis, dateOffset, prettyDateNoTime, getYearMonthDayOfISODate } from '../../../shared/util/dateUtil';
import { orderPropertiesForChrome } from '../../../shared/util/objectUtil';
import { singleReadLinkByPublisherArticleId } from '../../../shared/util/readUtil';
import { ENGLISH_TITLE, getArticleIdFromKey, getLang, getSentLocation, parseSentence } from '../../../shared/util/sentenceUtil';
import { camelCaseName } from '../../../shared/util/viewFormatUtil';
import { addOffEventListener, attr, collapse, create, getByClass, getByClassFirst, onChange, onClick, qs, qsa, selectpicker, tooltip } from '../../lib/util/html';
import { articlesBinaryPostMentionsExport, articlesPostSentences } from '../api/articles';
import { daasGetMention } from '../api/daas';
import { userGetSubscribedPublishers } from '../api/user';
import { SelectGroup } from '../forms/selectGroup';
import { UserFeature } from '../interfaces/analytics';
import { DateFilterType, DirectionFilterType, IConceptStats, IConsistentQuote, IDisplayECL, IDriverInfo, IFilterOption, ISimpleIndicator, ITrendQuery } from '../interfaces/driver';
import { ModalSizeEnum } from '../interfaces/static';
import { mentionSpec, trendBarSpec, trendPerSpec } from '../json';
import { timeMachine } from '../layout';
import { DECAY_HALFLIFE_FORMULA } from '../signal/signalUtil';
import { trackAnalyticsEvent } from '../util/analytics';
import { getLastLogin } from '../util/browserUtil';
import { numCompare } from '../util/evalUtil';
import { ClModal } from '../util/modalWidget';
import { displaySentences } from '../util/sentenceUtil';
import { drawMentionPlot, drawTrendBarPlot, drawTrendPerPlot } from '../viz/plotlyUtil';
import { Spinner } from '../widgets/spinner/spinner';
const trendHeaderTemplate: compileTemplate = require('../drivers/trendPlotHeader.pug');
const articleTitleTemplate: compileTemplate = require('../util/articleTitleTemplate.pug');
const mentionDetailTableTemplate: compileTemplate = require('../util/mentionDetailTable.pug');

const clLogger = getClLogger(__filename);
const oneDay = calculateDaysInMillis(1);
const ninetyDays = oneDay * 90;
const oneYear = oneDay * 365;

const cache = new Map<string, IConceptStats | IStatsRow[]>();
let mentionModal: ClModal = null;

// These used to be const, but I realized that if the process runs for multiple days (which it should)
// the data in memory could become outdated.
function getDates() {
    const now = timeMachine.now();
    const thisMonthBucket = getMonthlyDateBucket(now, 0, 0);
    const eighteenMonthsPastBucket = getMonthlyDateBucket(now, 6, -2);
    const today = getYearMonthDayOfISODate(now.toISOString());
    return {
        thisMonthBucket,
        eighteenMonthsPastBucket,
        today,
    };
}

// Given an array of trend stats, combine them all together and calculate
// a PTP for the result. Used primarily for forecasts.
export function trendStatsToCombinedPtp(concept: string, stats: IStat[]) {
    const trendInfo: IStat = {
        dateGroup: 'future',
        date: 'mixed',
        targetKey: concept,
        code: 'mixed',
        up: 0,
        down: 0,
        tot: 0,
        flat: 0,
    };
    // empty concept stats are not comming in daas mentions api
    if (stats) {
        for (const trendStat of stats) {
            trendInfo.tot += trendStat.tot;
            trendInfo.up += trendStat.up;
            trendInfo.down += trendStat.down;
            trendInfo.flat += trendStat.flat;
        }
        if (trendInfo.up || trendInfo.down) {
            trendInfo.ptp = Math.round((trendInfo.up + 1.0) /
                (trendInfo.up + trendInfo.down + 2.0) * 100.0);
        }
    }
    return trendInfo;
}

export async function loadRollingTrendStats(indicators: string[], publishers?: string[], articles?: string[]) {
    const endDate = timeMachine.nowAsStr();
    const trends = await loadTrendStats({ concepts: indicators, startDate: dateOffset(endDate, 0, -15), endDate }, publishers, articles);
    // Only look at forecasts
    Object.keys(trends).forEach((k) => {
        for (const group of trends[k]) {
            if (group.mentions) {
                group.mentions = group.mentions.filter((m) => m.targetDate > m.publishDate);
            }
        }
    });
    const startDate = dateOffset(endDate, 0, -12);
    const computedTrends = computeRollingTrendStats(trends, { startDate, endDate }, undefined, undefined, DECAY_HALFLIFE_FORMULA);
    const results = {};
    // TODO: The text here will always give back numbers based on all trends, not just forecasts. This is due to
    // the loadTrendStats method calling extractMentions, which aggregates and counts the stats prior to filtering by time.
    for (const key of Object.keys(computedTrends)) {
        results[key] = Object.keys(computedTrends[key]).filter((date) => computedTrends[key][date] !== null)
            .map((date) => {
                const stats = computedTrends[key][date];
                return {
                    x: date,
                    y: stats.ptp,
                    text: `tot: ${stats.tot}, up: ${stats.up}, down: ${stats.down}`,
                    mentions: stats.mentions,
                };
            });
    }
    return results;
}

// Fetches the trend statistics for up to 25 months forward including the current month.
export async function loadCurrentMonthTrendStats(keys: string[], publishers?: string[], articles?: string[]): Promise<{ [key: string]: IStat[] }> {
    const d = getDates();
    return loadTrendStats({ concepts: keys, startDate: d.thisMonthBucket, endDate: d.today }, publishers, articles);
}

// Fetches the trend statistics for up to 18 months back, excluding the current month.
// This used to be two years, but many indicators had little/nothing that far back, so trimmed 6 off.
// In the future it will be more sensible to extend to two years.
export async function loadPastEighteenMonthTrendStats(keys: string[], publishers?: string[], articles?: string[]): Promise<{ [key: string]: IStat[] }> {
    const d = getDates();
    return loadTrendStats({ concepts: keys, startDate: d.eighteenMonthsPastBucket, endDate: d.today }, publishers, articles);
}

// Fetches the trend statistics for up to 1 year forward and 18 months back for a collection of drivers.
// The range is a function of what the newest date found <= 1 year forward is
async function loadTrendStats(trendQuery:ITrendQuery, publishers?: string[], articles?: string[]):
    Promise<{ [key: string]: IStat[] }> {
    let rawTrends: IConceptStats = cache.get('trends' + JSON.stringify(trendQuery)) as IConceptStats;
    if (!rawTrends) {
        rawTrends = await getDaasStats(trendQuery, 'trends');
    }
    cache.set('trends' + JSON.stringify(trendQuery), rawTrends);
    // Normalize the data we stuff together into the range key in dynamo
    const mappedTrends: { [ conceptKey: string ]: IStat[] } = extractMentions(rawTrends, publishers, articles);
    return mappedTrends;
}

export async function getDaasStats(trendQuery: ITrendQuery, type: 'trends' | 'events') {
    const rawData = await daasGetMention({
        compression: true,
        conceptKey: trendQuery.concept,
        startDate: trendQuery.startDate,
        endDate: trendQuery.endDate,
        concepts: trendQuery.concepts,
        type,
        custom: 'graphStats',
    });
    const dataMap = rawData.map(MentionColToGraphMention).reduce((p, c) => {
        if (!p[c.aggregatedDate]) {
            p[c.aggregatedDate] = c;
        }
        else {
            for (const k of Object.keys(c)) {
                p[c.aggregatedDate][k] = c[k];
            }
        }
        return p;
    }, {});
    const dataArray = [];
    for (const k of Object.keys(dataMap)) {
        dataArray.push(dataMap[k]);
    }
    return dataArray.reduce((p, c) => {
        const cKey = c.conceptKey;
        if (!p[cKey]) {
            p[cKey] = [];
        }
        p[cKey].push(c);
        return p;
    }, {});
}

export async function loadPastMentions(concept: string, type?: 'indicator' | 'event', publishers?: string[], articles?: string[]):
    Promise<{ [key: string]: IStat[] }> {
    const table = type === 'event' ? 'events' : 'indicators';
    const endDate = timeMachine.nowAsStr();
    const startDate = dateOffset(timeMachine.nowAsStr(), 0, -18);
    const rawMentions = {};
    rawMentions[concept] = cache.get(`mentions${table}${concept}${startDate}${endDate}`) || await getMentionsFromDaas(table, concept, startDate, endDate);
    cache.set(`mentions${table}${concept}${startDate}${endDate}`, rawMentions[concept]);
    return extractMentions(rawMentions, publishers, articles, 7);
}

// Given a cell in a row, create a new row after the current one, and wire a close button within.
export function addGraphRow(open: { [ open: string ]: boolean }, key: string, source: HTMLTableCellElement, template: string): HTMLTableRowElement {
    const previousRow = source.parentElement as HTMLTableRowElement;
    const newRow = create<HTMLTableRowElement>('TR');
    previousRow.insertAdjacentElement('afterend', newRow);
    newRow.innerHTML = template;
    newRow.classList.add('collapse-row');

    const div = qs('.collapse', newRow);
    collapse(div, 'show');

    // Wire new DOM to allow close
    const closeBtn = getByClassFirst<HTMLButtonElement>('closeSubgraph', newRow);
    if (closeBtn) {
        const close = () => {
            if (!div.classList.contains('collapsing')) {
                collapse(div, 'hide');
                addOffEventListener(div, 'hidden.bs.collapse', () => {
                    previousRow.parentElement.removeChild(newRow);
                    delete open[key];
                });
            }
        };
        onClick(closeBtn, close);
        onClick(source, close);
    }
    return newRow;
}

function renderMentions(targetElement: HTMLElement,
                        conceptPretty: string,
                        trendStats: IStat[],
                        mentionStats: IStat[],
                        filterIndicator: RegExp = null,
                        displayMode: 'mentions' | 'trends' | 'ptp',
                        driverType: 'event' | 'indicator', allPublishers: IPublisher[]) {
    const dt = getDates();
    const graphDiv = getByClassFirst<HTMLDivElement>('subgraph-1', targetElement);
    const trendTarget = conceptPretty + (filterIndicator ? ' - <i>filtered</i>' : '');
    const mentionGraphTitle = `Recent Mentions of ${trendTarget}`;
    const trendGraphTitle = `Recent Trends about ${trendTarget}`;
    const graphRange = [ dt.eighteenMonthsPastBucket, dt.thisMonthBucket ];
    const noDataDiv = (text: string) => `<div style="margin-top:160px;margin-bottom:60px;">${text}</div>`;

    const aggTrends = {};

    for (const trendStat of trendStats) {
        let keyNoDay = trendStat.dateGroup;
        const code = trendStat.code;
        if (!filterIndicator || filterIndicator.test(code)) {
            // Group this month and future into 'now'. Should make
            // the number for 'this month' match the hover on forecast.
            if (keyNoDay >= dt.thisMonthBucket) {
                keyNoDay = dt.thisMonthBucket;
            }
            if (aggTrends[keyNoDay] === undefined) {
                aggTrends[keyNoDay] = { up: 0, down: 0, mentions: [] };
            }
            aggTrends[keyNoDay].up += trendStat.up;
            aggTrends[keyNoDay].down += trendStat.down;
            aggTrends[keyNoDay].mentions = aggTrends[keyNoDay].mentions.concat(trendStat.mentions);
        }
    }

    const aggMentions = {};
    for (const mentionStat of mentionStats) {
        const keyNoDay = mentionStat.dateGroup;
        const code = mentionStat.code;
        if (!filterIndicator || filterIndicator.test(code)) {
            // Group this month and future into 'now'. Should make
            // the number for 'this month' match the hover on forecast.
            if (aggMentions[keyNoDay] === undefined) {
                aggMentions[keyNoDay] = { tot: 0, mentions: [] };
            }
            aggMentions[keyNoDay].tot += mentionStat.tot;
            aggMentions[keyNoDay].mentions = aggMentions[keyNoDay].mentions.concat(mentionStat.mentions);
        }
    }

    if (driverType === 'indicator' && displayMode === 'trends') {
        const trendData = Object.keys(aggTrends).map((trendKey) => {
            return {
                x1: trendKey,
                y1: aggTrends[trendKey].up,
                x2: trendKey,
                y2: aggTrends[trendKey].down * -1,
            };
        }).filter((d) => {
            return d.y1 > 0 || d.y2 < 0;
        });
        if (trendData.length) {
            graphDiv.innerHTML = '';
            drawTrendBarPlot(graphDiv, trendGraphTitle, graphRange, trendBarSpec, trendData, (keyNoDay) => {
                const indicators = groupMentionsByIndicators(aggTrends[keyNoDay].mentions);
                openMentionModal(`Trends of <b>${trendTarget}</b> in <b>${keyNoDay}</b>`, indicators, undefined, undefined, allPublishers);
            });
        }
        else {
            graphDiv.innerHTML = noDataDiv(`No valid trends for ${trendTarget}`);
        }
    }
    else if (driverType === 'indicator' && displayMode === 'ptp') {
        const trendData = Object.keys(aggTrends).map((trendKey) => {
            const binom = trendStatToBinom(aggTrends[trendKey]);
            return {
                x: trendKey,
                yLower: binom.lower,
                ySize: binom.diff,
            };
        });
        if (trendData.length) {
            graphDiv.innerHTML = '';
            drawTrendPerPlot(graphDiv, trendGraphTitle, graphRange, trendPerSpec, trendData, (keyNoDay) => {
                const indicators = groupMentionsByIndicators(aggTrends[keyNoDay].mentions);
                openMentionModal(`Trends of <b>${trendTarget}</b> in <b>${keyNoDay}</b>`, indicators, undefined, undefined, allPublishers);
            });
        }
        else {
            graphDiv.innerHTML = noDataDiv(`No valid trends for ${trendTarget}`);
        }
    }
    else {
        const mentionData = Object.keys(aggMentions).map((mentionKey) => {
            return {
                x: mentionKey,
                y: aggMentions[mentionKey].tot,
            };
        }).filter((d) => {
            return d.y > 0;
        });
        if (mentionData.length) {
            graphDiv.innerHTML = '';
            drawMentionPlot(graphDiv, mentionGraphTitle, graphRange, mentionSpec, mentionData, (keyNoDay) => {
                const indicators = groupMentionsByIndicators(aggMentions[keyNoDay].mentions);
                openMentionModal(`Mentions of <b>${trendTarget}</b> in <b>${keyNoDay}</b>`, indicators, undefined, undefined, allPublishers);
            });
        }
        else {
            graphDiv.innerHTML = noDataDiv(`No valid mentions for ${trendTarget}`);
        }
    }
}

// Run refresh on a table row that is either new and initialized with addGraphRow, or after something
// changes with the filters or settings that justifies re-rendering the plot.
export function refreshMentions(targetElement: HTMLElement,
                                conceptPretty: string,
                                trendStats: IStat[],
                                mentionStats: IStat[],
                                driverType: 'event' | 'indicator',
                                verticalButtons: boolean,
                                mentionsOnly: boolean, allPublishers: IPublisher[]) {
    // Setup state
    const eventMode = driverType === 'event';
    let displayMode: 'mentions' | 'trends' | 'ptp' = (eventMode || mentionsOnly) ? 'mentions' : 'trends';
    let mentionBtn: HTMLButtonElement;
    let trendBtn: HTMLButtonElement;
    let ptpBtn: HTMLButtonElement;

    // We need merged stats from both indicators/events (mentions) and trends, if applicable, so that the donuts are accurate.
    const mergedStats = [];
    mergedStats.push(...trendStats.map((trendStat) => ({ parsed: parseCode(trendStat.code), count: trendStat.up + trendStat.down })));
    mergedStats.push(...mentionStats.map((mentionStat) => ({ parsed: parseCode(mentionStat.code), count: mentionStat.tot })));

    // On change we just refresh with the proper method depending on mode.
    const onChangeFunction = () => {
        if (!eventMode) {
            tooltip(mentionBtn, 'dispose');
            tooltip(trendBtn, 'dispose');
            tooltip(ptpBtn, 'dispose');
        }
        renderMentions(targetElement, conceptPretty, trendStats, mentionStats, filtSelects.getValueAsRegExp(driverType), displayMode, driverType, allPublishers);
    };

    // Setup and render the filter donuts
    const filterDiv = getByClassFirst<HTMLDivElement>('subfilter', targetElement);
    filterDiv.innerHTML = '';
    const selectContainer = create<HTMLDivElement>('div');
    selectContainer.classList.add('col');
    filterDiv.appendChild(selectContainer);
    const fields = eventMode
        ? [ 'fromCompany', 'fromLocation', 'fromPerson', 'toCompany', 'toLocation', 'toPerson', 'segment', 'product', 'baseEvent' ]
        : [ 'company', 'location', 'industry', 'segment', 'feature', 'product', 'baseKPI' ];
    const filtSelects = new SelectGroup(null, selectContainer, mergedStats, fields, onChangeFunction, 'indicator', 'pie');

    if (!eventMode && !mentionsOnly) {
        // Add and wire the buttons that toggle from trends to mentions to PTPs
        const btns = create('div');
        btns.classList.add('col-1');
        btns.innerHTML = trendHeaderTemplate({ vertical: verticalButtons, mentionsOnly });
        filterDiv.appendChild(btns);
        mentionBtn = getByClassFirst<HTMLButtonElement>('btn-modeMention', filterDiv);
        trendBtn = getByClassFirst<HTMLButtonElement>('btn-modeTrend', filterDiv);
        ptpBtn = getByClassFirst<HTMLButtonElement>('btn-modePtp', filterDiv);
        tooltip(mentionBtn);
        tooltip(trendBtn);
        tooltip(ptpBtn);

        onClick(mentionBtn, () => {
            mentionBtn.classList.add('active');
            trendBtn.classList.remove('active');
            ptpBtn.classList.remove('active');
            displayMode = 'mentions';
            onChangeFunction();
        });
        onClick(trendBtn, () => {
            mentionBtn.classList.remove('active');
            trendBtn.classList.add('active');
            ptpBtn.classList.remove('active');
            displayMode = 'trends';
            onChangeFunction();
        });
        onClick(ptpBtn, () => {
            mentionBtn.classList.remove('active');
            trendBtn.classList.remove('active');
            ptpBtn.classList.add('active');
            displayMode = 'ptp';
            onChangeFunction();
        });
    }
    onChangeFunction();
}

// After querying a stats table, you will have raw references to indicators organized
// in buckets based on one date and the specific base code, with data refering to
// each unique mention ID and the number of days to the forecast date. This method
// will return a lookup organized by base code which then has statistics and arrays
// organized by whether the mention was a forecast or historical in nature.
function organizeIndicatorStats(inds: IStatsRow[]): {
    allPublishers: {
        [code: string]: number,
    }
    allArticles: {
        [code: string]: number,
    }
    topLookup: {
        [basecode: string]: {
            total: number,
            historical: number,
            forecast: number,
            histList: ISimpleIndicator[],
            foreList: ISimpleIndicator[],
        },
    },
    allLinks: string[],
} {
    const allLinks = [];
    const topLookup = {};
    const allPublishers = {};
    const allArticles = {};
    for (const i of inds) {
        // First ten of a stat item is the date to the day level
        const publishDate = aggregatedDateToIsoDate(i.aggregatedDate);
        // Remainder is the Base Code
        const baseCode = i.aggregatedDate.substr(13);
        if (topLookup[baseCode] === undefined) {
            topLookup[baseCode] = {
                total: 0,
                historical: 0,
                forecast: 0,
                histList: [],
                foreList: [],
            };
        }
        // Every attribute except aggDate and conKey represent one indicator mention
        const alreadyInCount = [];
        for (const k of Object.keys(i)) {
            const val = i[k];
            if (typeof val === 'object') {
                const tokens = k.split('-');
                let key = k;
                // clean link if it have more than 6 positions to remove the -1 in some links
                if (tokens.length >= 7) {
                    tokens.splice(6, 1);
                    key = tokens.join('-');
                }
                if (alreadyInCount.indexOf(key) === -1) {
                    allLinks.push(key);
                    alreadyInCount.push(key);
                    const pub = k.split('_')[0];
                    if (!allPublishers[pub]) {
                        allPublishers[pub] = 0;
                    }
                    allPublishers[pub]++;
                    const art = k.split('/')[0];
                    if (!allArticles[art]) {
                        allArticles[art] = 0;
                    }
                    allArticles[art]++;
                    topLookup[baseCode].total++;
                    // We determine the forecast date by re-adding the number of days stored as the number
                    const targetDate = getYearMonthDayOfISODate(new Date(new Date(publishDate).getTime() + (val.o * calculateDaysInMillis(1))).toISOString());
                    const refData: ISimpleIndicator = { id: k, mentionDate: publishDate, targetDate };
                    if (val.o >= 1) {
                        topLookup[baseCode].forecast++;
                        topLookup[baseCode].foreList.push(refData);
                    }
                    else if (val.o < 1) {
                        topLookup[baseCode].historical++;
                        topLookup[baseCode].histList.push(refData);
                    }
                }
            }
        }
    }
    return { topLookup, allPublishers, allArticles, allLinks };
}

// Wire a click event on a target element that when triggered will display the
// modal to view mentions (indicators, trends, or events), with the capability
// of filtering, loading quotes, and opening articles to view.
export function makeModalLink(elt: HTMLElement, title: string, mentions: IParsedConcept[], xlsxTitle?: string, headers?, allPublishers: IPublisher[] = []) {
    onClick(elt, () => {
        openMentionModal(title, mentions, xlsxTitle, headers, allPublishers);
    });
}

// Open the actual mention viewing modal manually, if it doesn't make sense to instead
// rely on the makeModalLink method to wire it up
export function openMentionModal(title: string, mentions: IParsedConcept[], xlsxTitle?: string, headers?, allPublishers: IPublisher[] = []) {
    if (!mentionModal) {
        mentionModal = new ClModal(ModalSizeEnum.Large, '', true, () => {
            return '';
        }, (bodyContainer, params) => {
            mentionModal.changeHeader(params.title);
            renderMentionsBody(bodyContainer, params.mentions, false, 40, null, params.xlsxTitle, params.headers, allPublishers);
        });
    }
    mentionModal.open({ mentions, title, xlsxTitle, headers });
}

function getTimeAndKPIFilteredMentions(conceptGroups: IParsedConcept[], dateFilter: DateFilterType, directionFilter: DirectionFilterType,
                                       loadMoreBtn: HTMLButtonElement, kpiOrEventFilter: HTMLSelectElement) {
    const ATTRIBUTE_DATA_GROUP = 'data-group';
    const groupKey = attr(loadMoreBtn, ATTRIBUTE_DATA_GROUP);
    const selectedFilterValues = [];
    if (kpiOrEventFilter) {
        // Get all filter selections
        Object.keys(kpiOrEventFilter.selectedOptions).forEach((key) => selectedFilterValues.push(kpiOrEventFilter.selectedOptions[key].value));
    }
    const groupData = conceptGroups.find((cg) => cg.conceptKey === groupKey);
    // Run the filter, then return the group that corresponds to the data id we marked in the DOM
    return filterAndAnalyzeMentions([ groupData ], dateFilter, directionFilter, selectedFilterValues).analyzed[0];
}

export function renderMentionsBody(bodyContainer: HTMLDivElement,
                                   mentions: IParsedConcept[],
                                   showAddPortfolio: boolean,
                                   maxGroups: number,
                                   cb?: () => void, xlsxTitle?: string, headers?, allPublishers: IPublisher[] = []) {
    let dateFilter: DateFilterType = 'forecast';
    let directionFilter: DirectionFilterType = 'all';
    const updateFn = () => {
        const workingMentions: IParsedConcept[] = filterSortAndCopyParsedConcepts(mentions, maxGroups);
        bodyContainer.innerHTML = getMentionsBody(workingMentions, dateFilter, showAddPortfolio, directionFilter, allPublishers);
        qsa('[data-bs-toggle="tooltip"]', bodyContainer).forEach((elem) => {
            tooltip(elem);
        });
        onClick(qs('button.exportXlsx', bodyContainer), () => {
            const spinner = new Spinner(qs('.cl-spinner-export', bodyContainer), { size: '1.3rem' });
            const btn = qs<HTMLButtonElement>('button.exportXlsx', bodyContainer);
            exportXlsx(btn, spinner, bodyContainer, mentions, xlsxTitle, headers);
        });
        // Wiring
        const buttons = getByClass<HTMLButtonElement>('load-more-btn', bodyContainer);
        const filters = qsa<HTMLSelectElement>('select.selectMentionsFilterKPI', bodyContainer);
        const filtersToEnable = qsa<HTMLSelectElement>('.filtersToEnable', bodyContainer);
        filtersToEnable.forEach((select) => selectpicker(select));
        // Toggle between forecasts, past, and all
        const selectMentionsFilter = qs('.selectMentionsFilter', bodyContainer);
        if (selectMentionsFilter) {
            onChange(selectMentionsFilter, (evt) => {
                trackAnalyticsEvent(UserFeature.MENTIONPOPUP_TIMEFRAME_DROPDOWN_SELECT);
                const select = evt.target as HTMLSelectElement;
                dateFilter = select.options[select.selectedIndex].value as DateFilterType;
                bodyContainer.innerHTML = '';
                updateFn();
            });
        }
        // Toggle between up, down, and all (only applicable to trends)
        const selectDirectionFilter = qs('.selectDirectionFilter', bodyContainer);
        if (selectDirectionFilter) {
            onChange(selectDirectionFilter, (evt) => {
                trackAnalyticsEvent(UserFeature.MENTIONPOPUP_DIRECTION_DROPDOWN_SELECT);
                const select = evt.target as HTMLSelectElement;
                directionFilter = select.options[select.selectedIndex].value as DirectionFilterType;
                bodyContainer.innerHTML = '';
                updateFn();
            });
        }
        for (let i = 0; i < buttons.length; i++) {
            const btn = buttons[i];
            const filt = filters[i];
            let c = 1; // Current max number of results is remembered for each group
            // Filter for specific base KPI / base Event
            if (filt) {
                onChange(filt, () => {
                    trackAnalyticsEvent(UserFeature.MENTIONPOPUP_FILTER_DROPDOWN_SELECT);
                    // Remove everything in this cell except for load more
                    Array.from(btn.parentElement.childNodes).forEach((n) => {
                        if (n !== btn) {
                            btn.parentElement.removeChild(n);
                        }
                    });
                    let filteredMentions = getTimeAndKPIFilteredMentions(workingMentions, dateFilter, directionFilter, btn, filt);
                    if (dateFilter === 'forecast' && !filteredMentions) {
                        filteredMentions = getTimeAndKPIFilteredMentions(workingMentions, 'history', directionFilter, btn, filt);
                    }
                    for (const m of filteredMentions.mentions.slice(0, c)) {
                        const cloneM = addQuoteFields(cloneDeep(m));
                        cloneM.readLink = singleReadLinkByPublisherArticleId(cloneM.articlePublisher, cloneM.articleId, cloneM.id);
                        btn.insertAdjacentHTML('beforebegin', articleTitleTemplate({ m: cloneM, allPublishers }));
                    }
                    btn.style.display = c >= filteredMentions.mentions.length ? 'none' : 'initial';
                    displaySentences(btn.parentElement as HTMLDivElement);
                });
            }
            // Load more
            onClick(btn, () => {
                trackAnalyticsEvent(UserFeature.MENTIONPOPUP_LOADMORE_LINK_CLICK);
                let filteredMentions = getTimeAndKPIFilteredMentions(workingMentions, dateFilter, directionFilter, btn, filt);
                if (dateFilter === 'forecast' && !filteredMentions) {
                    filteredMentions = getTimeAndKPIFilteredMentions(workingMentions, 'history', directionFilter, btn, filt);
                }
                const end = c + 5;
                while (c < end && c < filteredMentions.mentions.length) {
                    const cloneM = addQuoteFields(cloneDeep(filteredMentions.mentions[c]));
                    cloneM.readLink = singleReadLinkByPublisherArticleId(cloneM.articlePublisher, cloneM.articleId, cloneM.id);
                    btn.insertAdjacentHTML('beforebegin', articleTitleTemplate({ m: cloneM, allPublishers }));
                    c++;
                }
                // Hide load more when all have been shown
                btn.style.display = c >= filteredMentions.mentions.length ? 'none' : 'initial';
                displaySentences(btn.parentElement as HTMLDivElement);
            });
        }
        displaySentences(bodyContainer);
        if (cb) {
            cb();
        }
    };
    updateFn();
}

function filterAndAnalyzeMentions(conceptGroups: IParsedConcept[],
                                  dateFilter: DateFilterType,
                                  directionFilter: DirectionFilterType,
                                  selectedBase: string[]):
                                  {
                                      analyzed: IParsedConcept[],
                                      hasUp: boolean,
                                      hasDown: boolean,
                                      hasForecast: boolean,
                                      hasHistory: boolean,
                                  } {
    let hasUp = false;
    let hasDown = false;
    let hasForecast = false;
    let hasHistory = false;
    const getDirectionFilter: (filter: string) => (m: IParsedMention) => boolean = (filter: string) => {
        return (f) => {
            const isUp = f.direction && f.direction > 0;
            const isDown = f.direction && f.direction < 0;
            hasUp = hasUp || isUp;
            hasDown = hasDown || isDown;
            if (filter === 'up') {
                return isUp;
            }
            else if (filter === 'down') {
                return isDown;
            }
            return true;
        };
    };
    const getOffFilter: (filter: string) => (m: IParsedMention) => boolean = (filter: string) => {
        return (f) => {
            const isForecast = f.targetDate > f.publishDate;
            const isHistory = f.targetDate <= f.publishDate;
            hasForecast = hasForecast || isForecast;
            hasHistory = hasHistory || isHistory;
            if (filter === 'forecast') {
                return isForecast;
            }
            else if (filter === 'history') {
                return isHistory;
            }
            return true;
        };
    };
    const dirFilter = getDirectionFilter(directionFilter);
    const offFilter = getOffFilter(dateFilter);
    const baseFilter = (m: IParsedMention) => selectedBase.length === 0 || selectedBase.includes((m).filterValue);
    const analyzed = conceptGroups
        .map((cg) => ({
            conceptKey: cg.conceptKey,
            prettyConcept: cg.prettyConcept,
            mentions: cg.mentions
                .filter(dirFilter)
                .filter(offFilter)
                .map((m) => ({
                    // A bit of cheating here as we are adding two properties to the ParsedMention for benefit of the ability to filter further
                    filterValue: (m.parsedCode as IParsedIndicator).baseKPI || (m.parsedCode as IParsedEvent).baseEvent,
                    filterLabel: camelCaseName((m.parsedCode as IParsedIndicator).baseKPI || (m.parsedCode as IParsedEvent).baseEvent),
                    ...m,
                }))
                .filter(baseFilter),
        }))
        .filter((cg) => cg.mentions.length) // After filtering, we no longer need the concept groups with zero mentions left
        .sort((a, b) => b.mentions.length - a.mentions.length); // Have to sort one more time to deal with effects of filtering
    return { analyzed, hasUp, hasDown, hasHistory, hasForecast };
}

function getMentionsBody(mentionGroups: IParsedConcept[],
                         currentOffsetFilter: DateFilterType,
                         showAddPortfolio: boolean,
                         currentDirectionFilter: DirectionFilterType, allPublishers: IPublisher[]) {
    let filteredMentionInfo = filterAndAnalyzeMentions(mentionGroups, currentOffsetFilter, currentDirectionFilter, []);
    // We may have the filter set to forecast, then select a topic with only historical mentions. Auto-correct.
    if (currentOffsetFilter === 'forecast' && !filteredMentionInfo.hasForecast && filteredMentionInfo.hasHistory) {
        filteredMentionInfo = filterAndAnalyzeMentions(mentionGroups, 'history', currentDirectionFilter, []);
    }

    const groups = cloneDeep(filteredMentionInfo.analyzed);
    for (const group of groups) {
        for (const m of group.mentions) {
            m.readLink = singleReadLinkByPublisherArticleId(m.publisher, m.articleId, m.metaId);
            addQuoteFields(m);
        }
    }

    const params = {
        groups,
        currentOffsetFilter,
        currentDirectionFilter,
        showOffsetFilter: filteredMentionInfo.hasForecast && filteredMentionInfo.hasHistory,
        showDirectionFilter: filteredMentionInfo.hasUp || filteredMentionInfo.hasDown,
        showAddPortfolio,
        allPublishers,
    };
    return mentionDetailTableTemplate(params);
}

// This will replace loadRecentIndicators once it's stable and we can test each consumer of it.
export async function loadRecentNew(concept: string) {
    const startDate = dateOffset(timeMachine.nowAsStr(), -6);
    const endDate = timeMachine.nowAsStr();
    const cacheKey = `recentindicators${concept}${endDate}${startDate}`;
    const inds: IStatsRow[] =
        cache.get(cacheKey) as IStatsRow[] || await getMentionsFromDaas('indicators', concept, startDate, endDate);
    cache.set(cacheKey, inds);
    return groupFilterAndSortStatsRowsByConceptKey(inds);
}

export async function loadRecentIndicators(concept: string, currentIndicatorKey: string) {
    return loadRecent(concept, currentIndicatorKey, 'indicators');
}

export async function loadRecentEvents(concept: string, currentIndicatorKey: string) {
    return loadRecent(concept, currentIndicatorKey, 'events');
}

async function loadRecent(concept: string, currentIndicatorKey: string, type: 'indicators' | 'events') {
    const startDate = dateOffset(timeMachine.nowAsStr(), -6);
    const endDate = timeMachine.nowAsStr();
    const inds: IStatsRow[] = await getMentionsFromDaas(type, concept, startDate, endDate);
    if (Array.isArray(inds) && inds.length) {
        const { topLookup, allPublishers, allArticles, allLinks } = organizeIndicatorStats(inds);
        let totSum = 0;
        let histSum = 0;
        let foreSum = 0;
        const histCombined = [];
        const foreCombined = [];
        const industries = {};
        const topSubjects = Object.keys(topLookup).map((tl) => {
            const parsed = parseCode(tl);
            const obj = {
                baseKPI: tl,
                count: topLookup[tl].total,
                historical: topLookup[tl].historical,
                forecast: topLookup[tl].forecast,
                parsed,
                prettyIndicator: parseToDisplay(parsed, undefined, currentIndicatorKey),
            };
            for (const h of topLookup[tl].histList) {
                histCombined.push({
                    id: h.id,
                    mentionDate: h.mentionDate,
                    targetDate: h.targetDate,
                    prettyIndicator: obj.prettyIndicator,
                    baseKPI: obj.baseKPI,
                    code: obj.parsed,
                });
            }
            for (const f of topLookup[tl].foreList) {
                foreCombined.push({
                    id: f.id,
                    mentionDate: f.mentionDate,
                    targetDate: f.targetDate,
                    prettyIndicator: obj.prettyIndicator,
                    baseKPI: obj.baseKPI,
                    code: obj.parsed,
                });
            }
            totSum += topLookup[tl].total;
            histSum += topLookup[tl].historical;
            foreSum += topLookup[tl].forecast;
            const ind = (obj.parsed as IParsedIndicator).industry;
            if (ind) {
                if (industries[ind] === undefined) {
                    industries[ind] = 0;
                }
                industries[ind]++;
            }
            return obj;
        }).sort((a, b) => {
            return b.count - a.count;
        });
        const topIndustries = Object.keys(industries).map((i) => {
            return {
                industry: i,
                count: industries[i],
            };
        }).sort((a, b) => {
            return b.count - a.count;
        });
        const topIndustry = topIndustries[0] ? topIndustries[0].industry : '';
        // Sort each by newest mention (publish date)
        histCombined.sort((a, b) => {
            return b.mentionDate.localeCompare(a.mentionDate);
        });
        foreCombined.sort((a, b) => {
            return b.mentionDate.localeCompare(a.mentionDate);
        });
        return {
            totSum,
            histSum,
            foreSum,
            histCombined,
            foreCombined,
            topIndustry,
            stats: topSubjects,
            raw: inds,
            allPublishers,
            allArticles,
            allLinks,
        };
    }
    return null;
}

// Mention id is required and should be <publisherId>_<articleId>/<metaId>
export function groupMentionsByIndicators(mentions: IParsedMention[]): IParsedConcept[] {
    return mentions.reduce((res, m) => {
        const artId = getArticleIdFromKey(m.id);
        const ids = getArticleIdSplit(artId);
        const mappedM = {
            id: m.id,
            publishDate: m.mentionDate || m.publishDate,
            targetDate: m.targetDate,
            baseCode: m.baseKPI || m.baseCode,
            parsedCode: m.parsedCode || parseCode(m.baseKPI || m.baseCode),
            publisher: ids.publisher,
            articleId: ids.id,
            // replace the publisher + article id and the / to get the meta id
            metaId: m.id.replace(artId, '').replace('/', ''),
            prettyIndicator: m.prettyIndicator,
            direction: m.direction,
        };
        const prev = res.find((elt) => elt.prettyConcept === m.prettyIndicator);
        if (prev) {
            prev.mentions.push(mappedM);
        }
        else {
            res.push({
                prettyConcept: m.prettyIndicator,
                conceptKey: 'unknown',
                mentions: [ mappedM ],
            });
        }
        return res;
    }, [] as IParsedConcept[]);
}

// Converts a summary object of articles and their count into a list suitable for selection in the filter modal.
export async function getFilterArticlesChoices(groupByArticle: { [ articleId: string ]:
    { publisher: string, articleId: string, count: number } }): Promise<IFilterOption[]> {
    // Take the top 20 with the most mentions
    const articles = Object.values(groupByArticle)
        .sort((a, b) => b.count - a.count)
        .slice(0, 20);
    const quoteKeys = articles.map((a) => ({ articleSentenceId: a.publisher + '_' + a.articleId, lang: ENGLISH_TITLE }));
    // check because when indicators is empty was failing opening the filters modal
    if (quoteKeys.length) {
        const titleSentences = await articlesPostSentences(quoteKeys);
        return articles.map((a) => ({
            value: a.publisher + '_' + a.articleId,
            label: titleSentences.find((ts) => ts.articleSentenceId === a.publisher + '_' + a.articleId)?.sentenceText.substr(0, 60) || 'Title not found',
            count: a.count,
        }));
    }
    return [];
}

// Converts a summary object of publishers and their count into a list suitable for selection in the filter modal.
export async function getFilterPublishersChoices(groupByPublisher: { [ publisher: string ]:
    { publisher: string, count: number } }): Promise<IFilterOption[]> {
    // The options need to be a union of the publishers we found in the data and the ones we have access to.
    const ap = await userGetSubscribedPublishers();
    return ap
        .map((p) => ({
            value: p.publisherName,
            label: p.label,
            count: groupByPublisher[p.publisherName]?.count,
        }))
        .filter((p) => p.count)
        .sort((a, b) => b.count - a.count);
}

export function filterConcept(concept: string, conceptCache: { [ concept: string ]: IStat[] },
                              driverGroup: string, targetLookup: { [ group: string ]: IStat[] },
                              mode: 'name' | 'baseKPI' | 'dbaseKPI') {
    if (concept && conceptCache[concept]) {
        conceptCache[concept].forEach((ft) => {
            if ((mode === 'name' && ft.code === driverGroup) ||
                (mode === 'baseKPI' && ft.code.indexOf(driverGroup.split('-')[1]) >= 0) ||
                (mode === 'dbaseKPI')) {
                if (targetLookup[ft.code] === undefined) {
                    targetLookup[ft.code] = [];
                }
                targetLookup[ft.code].push(ft);
            }
        });
    }
    else {
        clLogger.debug(`Missing trends for indicator ${concept} grouping ${driverGroup}`);
    }
}

export async function getDriversByTarget(destination: string, currentConcept: string): Promise<IDisplayECL[]> {
    const endDate = timeMachine.isActive() ? timeMachine.nowAsStr() : null;
    const startDate = dateOffset(timeMachine.nowAsStr(), 0, 0, -1);
    return getDriversByTargetDate(destination, currentConcept, startDate, endDate);
}

export async function getDriversByTargetDate(destination: string, currentConcept: string, startDate: string, endDate: string): Promise<IDisplayECL[]> {
    const drivers = (await daasGetMention<UnifiedLink>({
        compression: true,
        type: 'drivers',
        conceptKey: destination,
        endDate,
        startDate,
        bothDatesOrNone: true,
        limit: 5000,
    })).map(LinkColToGraph);
    // Convert fill all optional properties with parsing / conversion for the UI, along with author info
    const storeToDisplay: (link: IStoredECL) => IDisplayECL = (link: IStoredECL) => {
        const dateEndBrk = link.dateAndId.indexOf('_');
        const metaIdBrk = link.dateAndId.lastIndexOf('_');
        const combinedArtId = link.dateAndId.substr(dateEndBrk + 1, metaIdBrk - dateEndBrk - 1);
        const split = getArticleIdSplit(combinedArtId);
        const publishDate = link.dateAndId.substr(0, dateEndBrk);
        const parsedFromCode = parseCode(link.fromCode);
        const display: IDisplayECL = {
            ...link,
            publishDate,
            id: link.dateAndId.substr(metaIdBrk + 1),
            articleId: split.id,
            articlePublisher: split.publisher,
            prettyDate: prettyDateNoTime(new Date(publishDate)),
            parsedFromCode,
            parsedFromDisplay: parseCode(link.fromDisplay),
            prettyFrom: parseToDisplay(parsedFromCode, undefined, currentConcept),
            author: null,
        };
        return display;
    };
    return drivers.filter((d) => d.fromCode !== 'nocode').map(storeToDisplay);
}

// Given a lookup of drivers, return an array of drivers that are ordered by strength and then popularity.
export function orderAndGroupDrivers(allLinks: IDisplayECL[], driverGrouping: 'name' | 'baseKPI' | 'dbaseKPI', futureTrends: {[concept: string]: IStat[]},
                                     driverSortOrder: 'strength' | 'frequency' | 'latest'): IDriverInfo[] {
    const groupHash = {};
    const now = timeMachine.now();
    const lastQ = new Date();
    lastQ.setTime(now.getTime() - ninetyDays);
    const lastY = new Date();
    lastY.setTime(now.getTime() - oneYear);
    const lastLogin = getLastLogin();

    for (const link of allLinks) {
        let key;
        if (driverGrouping === 'name') {
            key = link.fromCode || 'Unknown';
        }
        else if (driverGrouping === 'baseKPI') {
            key = parseToConcept(link.parsedFromCode);
            if (!key) {
                key = link.parsedFromCode.baseKPI || link.parsedFromCode.baseEvent || 'Unknown';
            }
        }
        else if (driverGrouping === 'dbaseKPI') {
            key = parseToConcept(link.parsedFromDisplay);
            if (!key) {
                key = parseToConcept(link.parsedFromCode);
                if (!key) {
                    key = link.parsedFromDisplay.baseKPI || link.parsedFromDisplay.baseEvent || 'Unknown';
                }
            }
        }
        if (key) {
            if (groupHash[key] === undefined) {
                groupHash[key] = [];
            }
            groupHash[key].push(link);
        }
        else {
            clLogger.debug('Link is missing key: ', link);
        }
    }
    const groupedDrivers: IDriverInfo[] = Object.keys(groupHash)
        .map((g) => {
            return {
                pairRef: parseToConcept(groupHash[g][0].parsedFromDisplay),
                groupName: g,
                type: groupHash[g][0].parsedFromDisplay.baseKPI ? 'indicator' : 'event',
                linkCount: 0,
                strengthSum: 0,
                strengthCount: 0,
                minStrength: Infinity,
                maxStrength: -Infinity,
                avgStrength: 0,
                strengthHistory: [],
                eclUpCount: 0,
                eclDownCount: 0,
                prpStrength: 0,
                futureTrendCount: 0,
                futureTrendUpCount: 0,
                futureTrendDownCount: 0,
                futurePTP: 0,
                mostRecentDate: '',
                prettyRecentDate: '',
                groupLinks: groupHash[g],
                recentLinks: Object.keys(groupHash[g]).reduce((recents, key) => {
                    return lastLogin && groupHash[g][key].date >= lastLogin.timestamp ? recents.concat(groupHash[g][key]) : recents;
                }, []),
                groupFutureTrends: {},
                groupPastTrends: null,
                prettyGroupName: driverGrouping === 'name' ? groupHash[g][0].prettyFrom : camelCaseName(g.replace('unknown', ''), 'Unknown'),
                hasDrivers: false,
                mentionStat: null,
                groupPastMentions: null,
            };
        });

    for (const driver of groupedDrivers) {
        let mostRecentDate = new Date('2000-01-01T00:00:00.000Z');
        if (driver.type === 'indicator') {
            filterConcept(driver.pairRef, futureTrends, driver.groupName, driver.groupFutureTrends, driverGrouping);
            for (const baseCode of Object.keys(driver.groupFutureTrends)) {
                for (const trendStat of driver.groupFutureTrends[baseCode]) {
                    driver.futureTrendCount += trendStat.tot;
                    driver.futureTrendUpCount += trendStat.up;
                    driver.futureTrendDownCount += trendStat.down;
                }
            }
        }
        const strengthHistory = {};
        for (const link of driver.groupLinks) {
            if (link.negation) {
                continue;
            }
            const linkDate = new Date(link.publishDate);
            if (linkDate.getTime() > mostRecentDate.getTime()) {
                mostRecentDate = linkDate;
            }
            driver.linkCount++;
            const key = link.publishDate.substr(0, 7);
            if (!strengthHistory[key]) {
                strengthHistory[key] = { sum: 0, count: 0 };
            }
            strengthHistory[key].sum += link.relativeStrength;
            if (link.relativeStrength > 0) {
                strengthHistory[key].count++;
            }
            if (linkDate.getTime() > lastY.getTime() && link.relativeStrength !== 0) {
                driver.strengthSum += link.relativeStrength;
                driver.minStrength = Math.min(link.relativeStrength, driver.minStrength);
                driver.maxStrength = Math.max(link.relativeStrength, driver.maxStrength);
                driver.strengthCount++;
                if (link.relativeStrength > 0) {
                    driver.eclUpCount++;
                }
                else {
                    driver.eclDownCount++;
                }
            }
        }
        // Record the latest mention
        driver.mostRecentDate = mostRecentDate.toISOString();
        driver.prettyRecentDate = prettyDateNoTime(mostRecentDate);
        // Mark whether subdrivers exist and we have the right to check them out
        driver.hasDrivers = driver.type === 'indicator' || driver.type === 'event';
        // Calculate strengths
        if (driver.strengthCount) {
            driver.avgStrength = Math.round((driver.strengthSum * 1.0) / (driver.strengthCount * 1.0));
            driver.prpStrength = Math.round((driver.eclUpCount + 1) / (driver.eclUpCount + driver.eclDownCount + 2) * 100.0);
        }
        driver.strengthHistory = Object.keys(strengthHistory).filter((k) => strengthHistory[k].count)
            .map((k) => ({ x: k, y: Math.round(strengthHistory[k].sum * 1.0 / strengthHistory[k].count * 1.0) }));
        if (driver.futureTrendCount) {
            driver.futurePTP = Math.round((driver.futureTrendUpCount + 1.0) /
                (driver.futureTrendUpCount + driver.futureTrendDownCount + 2.0) * 100.0);
        }
    }
    return groupedDrivers.sort(sortDriverInfoFn(driverSortOrder));
}

function sortDriverInfoFn(sortMode: 'strength' | 'frequency' | 'latest') {
    return (a: IDriverInfo, b: IDriverInfo) => {
        // Strength mode will use absolute strength unless it's zero, in which case
        // we fall back to the frequency.
        if (sortMode === 'strength') {
            return numCompare(a.avgStrength, b.avgStrength, b.linkCount - a.linkCount, true);
        }
        else if (sortMode === 'frequency') {
            return numCompare(a.linkCount, b.linkCount, 0);
        }
        else if (sortMode === 'latest') {
            return new Date(b.mostRecentDate).getTime() - new Date(a.mostRecentDate).getTime();
        }
        else {
            throw Error('Bad sort mode');
        }
    };
}

export async function exportXlsx(btn: HTMLButtonElement, spinner: Spinner, container: HTMLElement, mentionsByConcept: IParsedConcept[],
                                 xlsxTitle: string = 'MentionExport.xlsx',
                                 headers?: string[][]) {
    spinner.start();
    trackAnalyticsEvent(UserFeature.MENTIONPOPUP_EXPORT_BUTTON_CLICK);
    btn.disabled = true;
    try {
        if (!headers) {
            const type = getCodeType(mentionsByConcept[0].mentions[0].baseCode);
            if (type === CodeType.EVENT) {
                headers = [ [ 'Date', MentionExportColumnKeys.PUBLISH_DATE ], [ 'Base Event', MentionExportColumnKeys.BASE_EVENT ],
                    [ 'From Company', MentionExportColumnKeys.FROM_COMPANY ],
                    [ 'From Location', MentionExportColumnKeys.FROM_LOCATION ],
                    [ 'From Person', MentionExportColumnKeys.FROM_PERSON ],
                    [ 'Product', MentionExportColumnKeys.PRODUCT ],
                    [ 'Segment', MentionExportColumnKeys.SEGMENT ],
                    [ 'To Company', MentionExportColumnKeys.TO_COMPANY ],
                    [ 'To Location', MentionExportColumnKeys.TO_LOCATION ],
                    [ 'To Person', MentionExportColumnKeys.TO_PERSON ],
                    [ 'Mention Sentence', MentionExportColumnKeys.SENTENCE ], [ 'Link To Full Article', MentionExportColumnKeys.HTTP_LINK ] ];
            }
            else {
                headers = [ [ 'Date', MentionExportColumnKeys.PUBLISH_DATE ], [ 'Location', MentionExportColumnKeys.LOCATION ],
                    [ 'Indicator', MentionExportColumnKeys.INDICATOR ], [ 'Industry', MentionExportColumnKeys.INDUSTRY ],
                    [ 'Product', MentionExportColumnKeys.PRODUCT ], [ 'Company', MentionExportColumnKeys.COMPANY ],
                    [ 'Segment', MentionExportColumnKeys.SEGMENT ], [ 'Mention Sentence', MentionExportColumnKeys.SENTENCE ],
                    [ 'Link To Full Article', MentionExportColumnKeys.HTTP_LINK ] ];
            }
        }
        const dateFormat = { userFormatting: navigator.language, userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
        const fileInfo = await articlesBinaryPostMentionsExport({
            format: 'xls',
            concept: xlsxTitle,
            data: mentionsByConcept,
            headers,
            dateFormat,
        });
        const fileUrl = URL.createObjectURL(fileInfo.blob);
        const reportLink = getByClassFirst<HTMLAnchorElement>('fileSetMentions', container);
        reportLink.href = fileUrl;
        reportLink.download = xlsxTitle;
        reportLink.click();
        spinner.dismiss();
    }
    catch (err) {
        clLogger.debug(err);
        spinner.error('Error exporting mentions');
    }
    finally {
        btn.disabled = false;
    }
}

// Links are stored in a less processed form that notates the paragraph and sentence in an older format.
export function driverECLToQuoteKey(link: IDisplayECL): IArticleLang {
    const s = parseSentence(link.sentence);
    const location: string = getSentLocation(link.id);
    return {
        articleSentenceId: `${link.articlePublisher}_${link.articleId}`,
        lang: getLang('en', location, s.paragraph, s.sentence),
    };
}

function addQuoteFields(mention: IConsistentQuote) {
    mention.articlePublisher = mention.publisher;
    mention.articleAndLang = mention.id; // needed to search the sentences later
    mention.id = mention.metaId;
    mention.prettyDate = prettyDateNoTime(new Date(mention.publishDate));
    const idTokens = mention.articleAndLang.split('/');
    const sentenceId = idTokens[idTokens.length - 1];
    mention.origLang = sentenceId.split('-')[0];
    mention.sentence = convertToSentence(sentenceId);
    return mention;
}

function convertToSentence(original: string) {
    const metaTokens = original.split('-');
    return `P${+metaTokens[2] + 1}-S${+metaTokens[3] + 1}`;
}

async function getMentionsFromDaas(type: string, concept: string, startDate: string, endDate: string) {
    const rawData = await daasGetMention({ compression: true, type, conceptKey: concept, endDate, startDate });
    const data = rawData.map(MentionColToGraphMention).reduce((p, c) => {
        const cKey = c.aggregatedDate + c.conceptKey;
        if (!p[cKey]) {
            p[cKey] = c;
        }
        else {
            for (const key of Object.keys(c)) {
                p[cKey][key] = c[key];
            }
        }
        return p;
    }, {});
    const result: IStatsRow[] = [];
    for (const key of Object.keys(data)) {
        result.push(orderPropertiesForChrome(data[key]));
    }
    return result;
}
