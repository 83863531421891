import { Acl } from '../../../../shared/auth/acl';
import { AuthRoles } from '../../../../shared/interfaces/acl/acl-i';
import { ApiError, STATUS_CODES } from '../../../../shared/interfaces/api/apiInterfaces';
import { getClLogger } from '../../../../shared/util/clLogger';
import { FeatureFlags } from '../../../../shared/util/featureFlag';
import { isFlagEnabledForView } from '../../featureFlag';
import { UserFeature } from '../../interfaces/analytics';
import { ifExistsOnClickTrackAnalyticsEvent } from '../../util/analytics';
import { getByClassFirst, getById } from '../../util/html';
const widget = require('./navWidget.pug');
const clLogger = getClLogger(__filename);
let enabled = false;

export class NavWidget {
    private static counter: number = 1;

    protected controlId: number;
    private containerId: string;
    private acl: Acl;

    constructor(containerId: string) {
        this.containerId = containerId;
        this.controlId = ++NavWidget.counter;
    }

    public async render(acl: Acl): Promise<boolean> {
        try {
            this.acl = acl;
            enabled = await isFlagEnabledForView(FeatureFlags.enableChat);
            this.setContainerContents(this.getContents());
        }
        catch (err) {
            if (err instanceof ApiError && err.statusCode === STATUS_CODES.OBJECT_NOT_FOUND) {
                this.setContainerContents('There was an error loading your profile.');
            }
            else {
                clLogger.debug('Type of error was unknown...');
                throw err;
            }
            return false;
        }
        return true;
    }

    protected getContents(): string {
        if (this.acl) {
            return widget({ acl: this.acl, roles: AuthRoles, isEnabled: enabled });
        }
        else {
            return 'Error';
        }
    }

    private setContainerContents(contents: string) {
        const container = getById(this.containerId);
        const menu = getByClassFirst('menu-bar-main', container);
        menu.innerHTML = contents;
        this.generateGoogleAnalytics();
    }

    private generateGoogleAnalytics() {
        ifExistsOnClickTrackAnalyticsEvent(getById('otherMenuLink'), UserFeature.HEADER_OTHER_MENU_CLICK);
        ifExistsOnClickTrackAnalyticsEvent(getById('adminMenuLink'), UserFeature.HEADER_ADMIN_MENU_CLICK);
    }
}
