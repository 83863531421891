// This class is bare bones and safe to source from api, event, Lambda, or front end.
export interface IEventMessage {
    subject: EventSubject;
    operation: EventOperation;
    targetId?: string;
    messageData?: any;
}

export enum ECLNamespaces {
    indicators = '/indicators',
}

// Each subject can be subscribed to individually
export enum EventSubject {
    // Notes about a bookmark CRUD (Dynamo)
    BookmarksNote = 'bookmarksNote',
    // User records CRUD (Dynamo)
    User = 'user',
    // Notes about a client CRUD (Dynamo)
    ClientNote = 'clientNote',
    // KB CRUD (SQL)
    Knowledge = 'knowledge',
    // Article Metadata CRUD (Dynamo)
    ArticleMeta = 'articleMeta',
    // Metadata statistics (total numbers of ECL, Trend, Indicator)
    MetaIndexStats = 'metaIndexStats',
    // Article CRUD (ES)
    Article = 'article',
    ECLByTarget = 'eclByTarget',
    Notification = 'notification',
    General = 'general',
    // Event bus used for these types of messages but they are never produced in node code (today)
    // The PollNProcess app generates and consumes to notify a new version is available.
    Prolog = 'prolog',
    MarkerCandidate = 'markerCandidate',
    // Comment CRUD
    Comment = 'comment',
    DaaSSsoStatus = 'daasSsoStatus',
    // Message for direct communication between a user and an admin.
    Message = 'message',
    WaitMessage = 'waitMessage'
}

// If possible, specify some granularity as to what type of operation occurred to the subject (CRUD)
export enum EventOperation {
    Created = 'created',
    Updated = 'updated',
    Deleted = 'deleted',
}
