import { IMentionSummaryDashboard, IParsedMention } from './article/mentions';

export enum CodeType {
    INDICATOR,
    EVENT,
    UNKNOWN,
}

// This shared interface describes the data stored in the ECLByTarget table, which is indexed
// first by the pair of concept-baseKPI, then by a complex key which is a concatenation of
// the date the ECL was published, the article publisher, article id, and meta.id of the ECL itself
// For example: "palo_alto_networks-revenue" and "2019-04-01T04:00:00Z_fo_edxGY2cBAyT0sM_Oo4fI_ECL-Body-13585"
export interface IStoredECL {
    // Keys
    targetKey: string;        // tesla-revenue
    sourceKey: string;        // tesla-net_loss
    // This date is the publish date. Also has article publisher, article id, and metadata id within the document
    dateAndId: string;        // 2020-12-10T13:46:00Z_ts_g9sXUnYBD1sQf9VCCgJX_ECL-Body-740
    // This is the publish date to the DAY. Used for global date index.
    date: string;             // 2020-12-10
    negation: number;         // 0 or 1
    relativeStrength: number; // -500 to 500, percent
    future: number;           // 0, 1, or 2
    // Copied from linked objects
    fromCode: string;         // tesla-*-*-*-*-*-net_loss
    fromDisplay: string;      // tesla-*-*-*-*-*-net_loss
    toCode: string;           // tesla-*-automobile_manufacturers-*-*-*-revenue
    toDisplay: string;        // tesla-*-automobiles-*-*-*-revenue
    // For quote generation and localization
    sentence: string;         // P5-S1
    origLang: string;         // en
}

// When finding interesting factors for a concept we often refer to the graph and the frequency
// of mention of various drivers. As this does not need the actual entire ECL, target stats tables
// can be used that simply track the quantity of links, both for all publishers and individually.
// This data structure communicates those statistics and are by convention ordered from most to least links.
export interface ITargetLinkStat {
    targetKey: string;
    linkCount: number;
}

// Indicators and events are stored in an aggregated set of rows that are based on
// the concept and indicator pair, the date of publish, and the base code. This is
// a representation of that object. Note that the indexer represents a bunch of
// unique mentions where the attribute name is an encoding of the IDs and the value
// is a number which represents the delta between publish date and date of reference
export interface IStatsRow {
    conceptKey: string;                             // display concept and display kpi or event
    aggregatedDate: string;                         // range key is date to UTC hour plus code in star format
    date: string;                                   // same as first ten of aggregated date. used for gsi
    [ uniqueId: string ]: IStatsDetail | string;    // Each mention can track sub-numbers depending on type
}

export interface IStatsDetail {
    o: number;  // Offset between publish date and reference date in days
    d?: number; // Direction, applies to trends
    s?: number; // Span of the data in days
    v?: number; // Placeholder for measured value
}

// Calling one of the summary graph APIs will return arrays grouped by concept key
// and ordered by frequency of mention, descending.
export interface IConceptSummary {
    conceptKey: string;           // "usa-stock_market"
    mentions: IMentionSummaryDashboard[];
}

// These objects are completely derived programmatically from the data compressed into IConceptSummary.
// Use the 'parseAndMapConcepts' function to do this transformation consistently and ideally only once
export interface IParsedConcept extends IConceptSummary {
    prettyConcept?: string; // A human friendly name tied to this group, which should be based on something the mentions have in common.
    mentions: IParsedMention[];
}

// Indicators and Trends use the below schema to identify the associated markers.
export interface IParsedIndicator {
    company: string;
    location: string;
    industry: string;
    segment: string;
    feature: string;
    product: string;
    baseKPI: string;
    driver?: string;
}

// Each indicator and event will be parsed in various ways for purposes of identifying how to group or uniquely identify it
export interface IBaseInfo {
    // The most important related entity
    concept: string;
    // The display KPI we are associated with
    base: string;
    // Combination of concept and base
    pair: string;
    // Unique fully formed code based on display entities
    dcode: string;
    // Unique fully formed code based on base entities
    code: string;
    // The month and Zulu hour of the indicator based on the date it is associated with contextually
    refdategroup: string;
    // The month and Zulu hour of the indicator based on the date the article mentioning it was published
    pubdategroup: string;
    // Original date
    origdate: string;
    // Number of days between publish and mention. Negative implies a mention of the past, positive implies a forecast
    refoffset: number;
    // sentence location
    sentence: string;
    // The span of the indicator - trends are separate
    span: number;
    // The value of the indicator (optional)
    value?: number;
}

export interface IStat {
    dateGroup: string;          // Used when grouping mentions with the same code to a month or day
    date: string;               // Specific date to the day
    up: number;                 // Summary of how many mentions had an up sign
    down: number;               // Summary of how many mentions had a down sign
    flat: number;               // Some trends are flat and we do not know the sign
    tot: number;                // Total is the sum of up, down, AND flat
    targetKey?: string;         // Optional bucket for aggregated information across dconcept + kpi
    code?: string;              // Full base code string in serialized, dash separated format
    ptp?: number;               // A percentage positive corresponding to up over up + down
    mentions?: IParsedMention[];// Detailed list of mentions for this group
    favorable?: number;         // The base KPI for this group may consider up trends as favorable
    prettyName?: string;        // A label for this grouping of mentions
}

// Given trends for a set of KPIs calculate a rolling measure of the overall PTP on a daily basis,
// accounting for a window, half life, and whether that KPI is favorable.
export interface IRollingStats {
    [ kpi: string ]: { [date: string ]: IRollingStat };
}

export interface IRollingStat {
    tot: number;
    up: number;
    down: number;
    flat: number;
    ptp?: number;
    mentions: IParsedMention[];
    dayMentions: IParsedMention[];
    dayDown: number;
    dayFlat: number;
    dayUp: number;
    dayTot: number;
}

// Objects in our data API and DaaS have a fixed schema but some properties are set to '*', meaning not applicable.
// This interface is the contract for all Events and also for Events that are part of Links
export interface IParsedEvent {
    fromCompany: string;
    fromLocation: string;
    fromPerson: string;
    toCompany: string;
    toLocation: string;
    toPerson: string;
    segment: string;
    product: string;
    baseEvent: string;
}
