import { IFeatureStatus } from '../../shared/interfaces/featureFlag';
import { getClLogger } from '../../shared/util/clLogger';
import { isFlagEnabled, validateFeature } from '../../shared/util/featureFlag';
import { utilGetFeatureFlags } from './api/util';
import { IClientFeatureFlagCache } from './interfaces/static';
import { STORAGE_INFO, fetchCacheKey, removeCacheKey, setCacheKey } from './util/storageUtil';

const clLogger = getClLogger(__filename);

// Find out if a feature is enabled on the client side
// This is based on a client side host keyed cache
export async function isFlagEnabledForView(featureFlag: IFeatureStatus) {
    // Keep the cache local to the browser tab
    let clientFeatureFlagCache: IClientFeatureFlagCache | null = fetchCacheKey<IClientFeatureFlagCache>(STORAGE_INFO.clFeatureFlags.scope, STORAGE_INFO.clFeatureFlags.key);
    // Refresh the cache if needed
    if (!clientFeatureFlagCache) {
        clLogger.debug('Flag cache miss. Refreshing');
        const featureStatuses = await utilGetFeatureFlags();
        clientFeatureFlagCache = {
            cachedFlags: featureStatuses,
            millisecondsSinceEpoch: new Date().getTime(),
        };
        setCacheKey(STORAGE_INFO.clFeatureFlags.scope, STORAGE_INFO.clFeatureFlags.key, clientFeatureFlagCache, STORAGE_INFO.clFeatureFlags.cacheMaxTime);
    }
    return isFlagEnabled(clientFeatureFlagCache.cachedFlags, featureFlag);
}

export function clearLocalFeatureFlagCache() {
    removeCacheKey(STORAGE_INFO.clFeatureFlags.scope, STORAGE_INFO.clFeatureFlags.key);
}

export function disableButtonFlag(elm: HTMLButtonElement, featureFlag: IFeatureStatus) {
    validateFeature(featureFlag);
    elm.disabled = true;
    if (featureFlag.message) {
        elm.title = featureFlag.message;
    }
}
