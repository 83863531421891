import { IClient } from '../../../shared/interfaces/client/client-i';
import { ajaxDelete, ajaxGet, ajaxPut } from '../ajax';

export function clientsGetClient(clientName: string) {
    return ajaxGet<IClient>('/clients/client/:clientName', { clientName });
}

export function clientsGetAllClients(q?: string) {
    return ajaxGet<IClient[]>('/clients/allClients', { q });
}

export function clientsPutClientUndelete(clientName: string) {
    return ajaxPut('/clients/client/:clientName', { deleted: null, clientName });
}

export function clientsDeleteClient(clientName: string) {
    return ajaxDelete('/clients/client/:clientName', { clientName });
}

export function clientsGetChatEnabled() {
    return ajaxGet<boolean>('/clients/chatEnabled');
}

export function clientsGetClientLang() {
    return ajaxGet<string>('/clients/clientLang');
}
