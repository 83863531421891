import { ALERT_CONFIG_KEY } from '../bookmark/bookmark-i';

export interface IMetric {
    metricName: MetricName;         // partition key
    metricTime: string;             // sort key: 2022-07-01T09:22:56.123Z
    metricType: ALERT_CONFIG_KEY | UxType;
    metricCount: number;            // 1
    metricTarget?: string;          // novelty button run click
    value?: string;                 // used to store custom values like the user search
}

export enum MetricName {
    ALERT = 'alert',
    UX = 'ux',
}

export enum UxType {
    addedElementToPortfolio ='addedElementToPortfolio',
    addedSelectionGroup ='addedSelectionGroup',
    finishedKPINavWorkflow ='finishedKPINavWorkflow',
    noveltyReportRun ='noveltyReportRun',
    visitDashboard ='visitDashboard',
    visitPortfolio ='visitPortfolio',
    visitIndicators ='visitIndicators',
    visitDrivers ='visitDrivers',
    visitModel ='visitModel',
    visitNoveltyReport ='visitNoveltyReport',
    visitKPINav ='visitKPINav',
    visitFavorites ='visitFavorites',
    visitUserProfile ='visitUserProfile',
    search = 'search',
}

export class Metric implements IMetric {
    public metricTime: string;
    constructor(public metricName: MetricName, public metricType: ALERT_CONFIG_KEY | UxType, public metricCount: number, public value?: string) {
        this.metricTime = (new Date()).toISOString() as string;
    }
}
