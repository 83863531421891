import parseISO from 'date-fns/parseISO';
import { getClLogger } from './clLogger';

export const DEFAULT_FORMATTING = 'en-US';
export const DEFAULT_TIME_ZONE = 'UTC';

const clLogger = getClLogger(__filename);

export const TIME_DELIMITER = 'T';
const oneSecondInMillis = 1000;
const oneMinuteInSeconds = 60;
const oneHourInMinutes = 60;
const oneDayInHours = 24;

// Get the current TZ date based on the passed date at UTC00:00:00
// in MDT, input '2021-04-12' -> Date.toIso() -> '2021-04-12T00:00:00.000Z'
// Note, in a non-UTC TZ, the date object will still be in the non-UTC TZ
// getStartDate handles this automatically, keeping all strings in UTC
export function getDateFromString(iso8601DateStr: string) {
    iso8601DateStr = iso8601DateStr.split(TIME_DELIMITER)[0].trim();
    const tokens: string[] = iso8601DateStr.split('-');
    const year: number = Number.parseInt(tokens[0], 10);
    const month: number = Number.parseInt(tokens[1], 10) - 1;
    const day: number = Number.parseInt(tokens[2], 10);
    return new Date(Date.UTC(year, month, day, 0));
}

// Return a padded yyyy-mm-dd date that represents the UTC date, even if running in non UTC
export function getStartDate(date: string, daysToSubtract: number = 0) {
    const d = getDateFromString(date);
    d.setDate(d.getDate() - daysToSubtract);
    return d.toISOString().split(TIME_DELIMITER)[0];
}

// Add given days, months or/and years to a given date (numbers can be negative) - format: 2019-07-20
export function dateOffset(date: string | Date, days: number, months: number = 0, years: number = 0): string {
    date = date || new Date();
    date = typeof date === 'string' ? date : date.toISOString();
    const n = new Date(date.split(TIME_DELIMITER)[0]);
    n.setUTCHours(12);
    n.setFullYear(n.getFullYear() + years, n.getMonth() + months, n.getDate() + days);
    const h = date.split(TIME_DELIMITER)[1];
    return n.toISOString().substr(0, Math.min(date.length, 10)) + (h ? TIME_DELIMITER + h : '');
}

// Calculate the days difference between two dates
export function dateDiff(date1: string, date2: string) {
    return Math.floor(calculateDaysFromMillis(Date.parse(date2) - Date.parse(date1)));
}

// Returns a date as yyyy-mm-dd, offset from today by days, positive, negative, or zero
export function addDays(relative: Date, days: number): string {
    const offset = new Date(relative.valueOf() + calculateDaysInMillis(days));
    return getYearMonthDayOfISODate(offset.toISOString());
}

// input date is assumed to be in yyyMMddmmss format
export function getVersionDateISO(str: string) {
    const year = str.substring(0, 4);
    const month = str.substring(4, 6);
    const day = str.substring(6, 8);
    const hour = str.substring(8, 10);
    const minute = str.substring(10, 12);
    return `${year}-${month}-${day}${TIME_DELIMITER}${hour}:${minute}:00.000Z`;
}

// Calculate 2 years between before and after, if no before assumes today
export function getModelDates(beforeStr: string | null) {
    const before = getStartDate(beforeStr || new Date().toISOString());
    const after = getStartDate(dateOffset(before, 0, 0, -2));
    return { before, after };
}

// Given a month offset calculates the date
export function getDateByMonthOffset(monthOffset: number, timeMachineDate: Date) {
    const date = timeMachineDate || new Date();
    date.setMonth(date.getMonth() + monthOffset);
    return date;
}

// Converts aggregatedDate to iso date string
export function aggregatedDateToIsoDate(aggregatedDate: string) {
    return aggregatedDate.substr(0, 13) + ':00:00Z';
}

export function calculateDaysInMillis(days: number) {
    return calculateHoursInMillis(oneDayInHours) * days;
}

export function calculateHoursInMillis(hours: number) {
    return calculateMinutesInMillis(oneHourInMinutes) * hours;
}

export function calculateMinutesInMillis(minutes: number) {
    return calculateSecondsInMillis(oneMinuteInSeconds) * minutes;
}

export function calculateSecondsInMillis(seconds: number) {
    return oneSecondInMillis * seconds;
}

export function calculateSecondsFromMillis(timeInMillis: number) {
    return timeInMillis / oneSecondInMillis;
}

export function calculateDaysFromMillis(timeInMillis: number) {
    return timeInMillis / oneSecondInMillis / oneMinuteInSeconds / oneHourInMinutes / oneDayInHours;
}

export function calculateDaysInSeconds(days: number) {
    return calculateDaysInMillis(days) / oneSecondInMillis;
}

// return example 2000-11
export function getYearMonthOfISODate(isoDate: string) {
    return isoDate.substring(0, 7);
}

// return example 2000-11-01
export function getYearMonthDayOfISODate(isoDate: string, removeDash?: boolean) {
    let result: string = null;
    if (isoDate) {
        result = isoDate.substring(0, 10);
        if (removeDash) {
            result = result.replace(/-/g, '');
        }
    }
    return result;
}

// Convert a date string with ISO formatted delimiters (-) to a date
export function stringToDate(date: string | Date) {
    // In TS 4.4, we can condense the duplicate typeof checks to a bool!!!
    if (typeof date === 'string' && !date.includes('-')) {
        clLogger.warn(`There may be browser specific problems parsing ${date}`);
    }
    return typeof date === 'string' ? parseISO(date) : date;
}

// Format a date in a nice way, with time
export function prettyDateHourWithSeparator(dateString: string, prefix: string = '', separator: string = ' at '): string {
    if (dateString) {
        const date = new Date(dateString);
        const ds = prettyDateNoTime(date, null, null, false, true);
        const ts = prettyHour(date, null, null, false, true);
        return `${prefix}${ds}${separator}${ts}`;
    }
    return '';
}

export function prettyDateHourMinutesWithSeparator(dateString: string, prefix: string = '', separator: string = ' at '): string {
    if (dateString) {
        const date = new Date(dateString);
        const ds = prettyDateNoTime(date, null, null, false, true);
        const ts = prettyHourMinutes(date, null, null, false, true);
        return `${prefix}${ds}${separator}${ts}`;
    }
    return '';
}

function getTimeLangFormatting(userFormatting?: string, userTimeZone?: string, ignoreBrowser?: boolean, ignoreLang?: boolean) {
    let lang = userFormatting || DEFAULT_FORMATTING;
    let timeZone = userTimeZone || DEFAULT_TIME_ZONE;
    if (!ignoreBrowser) {
        // Default to browser settings if there is a browser
        const navType = typeof navigator;
        if (navType !== 'undefined') {
            if (!ignoreLang) {
                lang = navigator.language;
            }
            timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
    }
    return { lang, timeZone };
}

export function prettyDateNoTime(date: Date, userFormatting?: string, userTimeZone?: string, ignoreBrowser?: boolean, ignoreLang?: boolean): string {
    if (date) {
        const { lang, timeZone } = getTimeLangFormatting(userFormatting, userTimeZone, ignoreBrowser, ignoreLang);
        return date.toLocaleDateString(lang, { timeZone, month: 'short', day: 'numeric', year: 'numeric' });
    }
    return '';
}

export function prettyHour(date: Date, userFormatting?: string, userTimeZone?: string, ignoreBrowser?: boolean, ignoreLang?: boolean): string {
    if (date) {
        const { lang, timeZone } = getTimeLangFormatting(userFormatting, userTimeZone, ignoreBrowser, ignoreLang);
        return date.toLocaleTimeString(lang, { timeZone, hour: 'numeric' });
    }
    return '';
}

function prettyHourMinutes(date: Date, userFormatting?: string, userTimeZone?: string, ignoreBrowser?: boolean, ignoreLang?: boolean): string {
    if (date) {
        const { lang, timeZone } = getTimeLangFormatting(userFormatting, userTimeZone, ignoreBrowser, ignoreLang);
        return date.toLocaleTimeString(lang, { timeZone, hour: 'numeric', minute: 'numeric' });
    }
    return '';
}

export function prettyMonthDayHourMinutes(date: Date, userFormatting?: string, userTimeZone?: string, ignoreBrowser?: boolean, ignoreLang?: boolean): string {
    if (date) {
        const { lang, timeZone } = getTimeLangFormatting(userFormatting, userTimeZone, ignoreBrowser, ignoreLang);
        return date.toLocaleTimeString(lang, { timeZone, month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit' });
    }
    return '';
}

// Formate dates with user formatting and time zone selected
export function prettySimpleDateHour(date: Date, userFormatting?: string, userTimeZone?: string, ignoreBrowser?: boolean, ignoreLang?: boolean): string {
    if (date) {
        const { lang, timeZone } = getTimeLangFormatting(userFormatting, userTimeZone, ignoreBrowser, ignoreLang);
        // Setting hour: 'numeric' to ignore the minutes and seconds because current formatted dates only have hour, the rest is 00:00
        return date.toLocaleTimeString(lang, { timeZone, month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' });
    }
    return '';
}

// Formate dates with user formatting and time zone selected
export function shortSimpleDateHour(date: Date, userFormatting?: string, userTimeZone?: string, ignoreBrowser?: boolean, ignoreLang?: boolean): string {
    if (date) {
        const { lang, timeZone } = getTimeLangFormatting(userFormatting, userTimeZone, ignoreBrowser, ignoreLang);
        // Setting hour: 'numeric' to ignore the minutes and seconds because current formatted dates only have hour, the rest is 00:00
        return date.toLocaleTimeString(lang, { timeZone, month: 'short', day: 'numeric', hour: 'numeric' });
    }
    return '';
}

export function dateIsValid(date: Date) {
    return date instanceof Date && !isNaN(date as unknown as number);
}

export function getLastDayOfMonth(month: number, year: number) {
    const d = new Date(year, month, 0);
    return d.getDate();
}
