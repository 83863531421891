function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;
function pug_style(r){if(!r)return"";if("object"==typeof r){var t="";for(var e in r)pug_has_own_property.call(r,e)&&(t=t+e+":"+r[e]+";");return t}return r+""}function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (customTitle, dataHtml, helpText, maxwidth) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"input-group flex-nowrap\""+pug_attr("style", pug_style(maxwidth ? `max-width: ${maxwidth}` : ''), true, true)) + "\u003E\u003Cinput class=\"form-control searchBar\" type=\"text\" placeholder=\"Search...\" autocomplete=\"off\" spellcheck=\"false\" autocorrect=\"off\" autocapitalize=\"off\"\u003E\u003Cdiv class=\"btn-group\"\u003E\u003Cbutton" + (" class=\"btn btn-secondary btn-mid sub-btn fas fa-search\""+pug_attr("title", customTitle ? customTitle : 'Search', true, true)+pug_attr("data-bs-html", dataHtml ? dataHtml : false, true, true)+" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\"") + "\u003E\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-secondary btn-end clr-btn fas fa-times\" title=\"Clear Search\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\"\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"input-auto hide-x default-scroll\""+pug_attr("hidden", true, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (helpText) {
pug_html = pug_html + "\u003Csmall class=\"form-text text-muted\"\u003E" + (pug_escape(null == (pug_interp = helpText) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
    }.call(this, "customTitle" in locals_for_with ?
        locals_for_with.customTitle :
        typeof customTitle !== 'undefined' ? customTitle : undefined, "dataHtml" in locals_for_with ?
        locals_for_with.dataHtml :
        typeof dataHtml !== 'undefined' ? dataHtml : undefined, "helpText" in locals_for_with ?
        locals_for_with.helpText :
        typeof helpText !== 'undefined' ? helpText : undefined, "maxwidth" in locals_for_with ?
        locals_for_with.maxwidth :
        typeof maxwidth !== 'undefined' ? maxwidth : undefined));
    ;;return pug_html;};module.exports=template;