(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("bootstrap"), require("jQuery"), require("Msal"), require("io"), require("Plotly"));
	else if(typeof define === 'function' && define.amd)
		define(["bootstrap", "jQuery", "Msal", "io", "Plotly"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("bootstrap"), require("jQuery"), require("Msal"), require("io"), require("Plotly")) : factory(root["bootstrap"], root["jQuery"], root["Msal"], root["io"], root["Plotly"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__9814__, __WEBPACK_EXTERNAL_MODULE__4786__, __WEBPACK_EXTERNAL_MODULE__5697__, __WEBPACK_EXTERNAL_MODULE__4676__, __WEBPACK_EXTERNAL_MODULE__2104__) => {
return 