function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (concept) {
      pug_html = pug_html + "\u003Cspan\u003E" + (pug_escape(null == (pug_interp = concept.pretty) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (concept.prettyKpi) {
pug_html = pug_html + "\u003Cspan class=\"mx-2\"\u003E" + (pug_escape(null == (pug_interp = concept.prettyKpi) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan id=\"extraConcept1\"\u003E\u003C\u002Fspan\u003E\u003Cspan id=\"extraConcept2\"\u003E\u003C\u002Fspan\u003E\u003Csmall class=\"ms-2\"\u003E\u003Ca" + (" class=\"m-1\""+pug_attr("href", `/indicators/${concept.encoded}`, true, true)+" title=\"View Indicators\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\"") + "\u003E\u003Ci class=\"far fa-table fa-xs\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca" + (" class=\"m-1\""+pug_attr("href", `/model/${concept.encoded}`, true, true)+" title=\"View Model\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\"") + "\u003E\u003Ci class=\"far fa-project-diagram fa-xs\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca" + (" class=\"m-1\""+pug_attr("href", `/signal/${concept.encoded}`, true, true)+" title=\"View Signal\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\"") + "\u003E\u003Ci class=\"far fa-wave-square fa-xs\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ca" + (" class=\"m-1\""+pug_attr("href", `/events/${concept.encoded}`, true, true)+" title=\"View Events\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\"") + "\u003E\u003Ci class=\"far fa-calendar fa-xs\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fsmall\u003E";
    }.call(this, "concept" in locals_for_with ?
        locals_for_with.concept :
        typeof concept !== 'undefined' ? concept : undefined));
    ;;return pug_html;};module.exports=template;