// This helps keep indexes consistent and readable when using it in conjunction with what granularity to extract lang keys with
export enum LANG_KEY_PART {
    LANGUAGE = 1,
    TITLE_BODY = 2,
    PARAGRAPH = 3,
    SENTENCE = 4,
    ABSOLUTE_LOCATION = 5,
    EXTRA_META_ID = 6,
}

export enum META_LOCATION {
    BODY = '-Body-',
    TITLE = '-Title-',
    GRID = '-Grid-',
    BODY_ALT = '-b-',
    TITLE_ALT = '-t-',
    GRID_ALT = '-g-',
}

export enum SENT_LOCATION {
    BODY = 'b',
    TITLE = 't',
    GRID = 'g',
}
