import { UxType } from '../../../shared/interfaces/metric/metric-i';
import { ICurrentUser } from '../../../shared/interfaces/user/user';
import { IBackendUser } from '../../../shared/interfaces/user/user-i';
import { ajaxGet, ajaxPost } from '../ajax';
import { IEventInfo } from '../interfaces/static';

export function servicesGetCurrentUser() {
    return ajaxGet<ICurrentUser>('/services/currentUser');
}

export function servicesGetUser(username: string) {
    return ajaxGet<IBackendUser>('/services/getUser/:username', { username });
}

export function servicesGetArticleRedirect(publisher: string, id: string) {
    return ajaxGet('/services/articleRedirect/:id', { publisher, id });
}

export function servicesGetEventBus() {
    return ajaxGet<IEventInfo>('/services/eventBus');
}

export function servicesPostMetric(metricType: UxType, value?: string) {
    return ajaxPost('/services/metric', { metricType, value });
}
