import { Acl } from '../../../../shared/auth/acl';
import { IComment } from '../../../../shared/interfaces/comments/comment-i';
import { EventOperation, EventSubject } from '../../../../shared/interfaces/event';
import { parseCode, parseToDisplay } from '../../../../shared/streaming/dashboard';
import { getClLogger } from '../../../../shared/util/clLogger';
import { prettyDateHourWithSeparator } from '../../../../shared/util/dateUtil';
import { singleReadLink } from '../../../../shared/util/readUtil';
import { joinRoom } from '../../../lib/ws/ioUtil';
import { ModalSizeEnum } from '../../interfaces/static';
import { attr, getByClass, getById, onClick } from '../../util/html';
import { ClModal } from '../../util/modalWidget';
import { STORAGE_INFO, fetchCacheKey, setCacheKey } from '../../util/storageUtil';
const announcementTemplate = require('./notifyModal.pug');
const widget = require('./notifyWidget.pug');
const thisNs = '/static/lib/widgets/notify/notifyWidget';
const clLogger = getClLogger(__filename);
// const conversationPrefix = 'conversation';

// A widget that can sit anywhere on the page and will show a badge for outstanding notifications.
export class NotifyWidget {
    private static counter: number = 1;
    protected controlId: number;
    private containerId: string;
    // If a message arrives while the modal is open, we will update in real time.
    private modalOpen: boolean = false;
    // Array of all the announcements received
    private announcements: {readLink: string, link: string}[] = [];
    // Array of all the unread direct messages received
    private messages: IComment[] = [];
    private notifyModal: ClModal;

    constructor(containerId: string) {
        this.containerId = containerId;
        this.controlId = ++NotifyWidget.counter;
        this.notifyModal = new ClModal(ModalSizeEnum.Normal, 'Notifications', true, () => {
            return '<div id="notifyDiv"></div>';
        }, () => {
            this.updateModalContents();
        });
    }

    public async render(username: string, acl: Acl): Promise<boolean> {
        // Get any old announcements out of storage so the user moving around doesn't lose them.
        const storedMessages = fetchCacheKey<{readLink: string, link: string}[]>(STORAGE_INFO.clNotifyMsg.scope, STORAGE_INFO.clNotifyMsg.key);
        if (storedMessages) {
            this.announcements = storedMessages;
        }
        if (acl.hasRole(acl.getAuthRoles().CLAdmin)) {
            const newMessage = fetchCacheKey<IComment[]>(STORAGE_INFO.newMessages.scope, STORAGE_INFO.newMessages.key);
            if (newMessage) {
                // Grab all the conversation ID's
                this.messages = fetchCacheKey<IComment[]>(STORAGE_INFO.newMessages.scope, STORAGE_INFO.newMessages.key);
                // Keep most recent five for now
                if (this.messages.length > 5) {
                    this.messages.pop();
                }
                setCacheKey(STORAGE_INFO.messages.scope, STORAGE_INFO.messages.key, this.messages, STORAGE_INFO.messages.cacheMaxTime);
            }
            else {
                setCacheKey(STORAGE_INFO.newMessages.scope, STORAGE_INFO.newMessages.key, this.messages, STORAGE_INFO.newMessages.cacheMaxTime);
            }
            // let conversations: IComment[] = [];
            // if (getItem(NO_EXPIRATION_PREFIXES_USER.conversations)) {
            //     // Grab all the conversation ID's
            //     conversations = getJson(NO_EXPIRATION_PREFIXES_USER.conversations);
            //     // Set up most recent message for each conversation
            //     for (let i = 0; i < conversations.length; i++) {
            //         if (getItem(`${STORAGE_INFO['conversation:']}${conversations[i]}`)) {
            //             const msgHistory = getJson(`${STORAGE_INFO['conversation:']}${conversations[i]}`);
            //             if (msgHistory.length && msgHistory[msgHistory.length - 1].creator !== username) {
            //                 const senderHistory: IComment[] = [];
            //                 for (let j = 0; j < msgHistory.length; j++) {
            //                     if (msgHistory[j].creator !== (username)) {
            //                         senderHistory.push(msgHistory[j]);
            //                     }
            //                 }
            //                 if (senderHistory.length) {
            //                     this.messages[i] = senderHistory[senderHistory.length - 1];
            //                     clLogger.debug('SENDER HISTORY: ', senderHistory);
            //                 }
            //             }
            //         }
            //     }
            //     // TODO: Notification bubble with corresponding count, update in dm.ts as well, eventually have message preview in notification pop-up window
            // }
            // else {
            //     setItem(NO_EXPIRATION_PREFIXES_USER.conversations, JSON.stringify(conversations));
            // }
        }
        this.setContainerContents(this.getContents());
        try {
            // Subscribe to be told about new announcements for me
            joinRoom(thisNs, EventSubject.Notification, username, this.gotNotificationFn());
            // joinRoom(thisNs, EventSubject.Message, username, this.gotNotificationDM());
        }
        catch (err) {
            clLogger.error('Error setting up IO subscription');
        }
        return true;
    }

    protected gotNotificationFn() {
        return (_id: string, _operation: EventOperation, msg) => {
            msg.received = prettyDateHourWithSeparator(new Date().toISOString());
            msg.path = this.processPaths(msg.path);
            // Just keep the 10 most recent announcements around, for now
            this.announcements.unshift(msg);
            while (this.announcements.length > 10) {
                this.announcements.pop();
            }
            this.afterChange();
        };
    }

    // protected gotNotificationDM() {
    //     return (_id: string, _operation: EventOperation, msg) => {
    //         // clDebug(userTarget, body, event);
    //         msg.received = prettyDateTime(new Date().toISOString());
    //         msg.path = this.processPaths(msg.path);
    //         msg.author = 'TODO1';
    //         msg.message = 'TODO2';
    //         // Just keep the 5 most recent messages around, for now
    //         this.messages.unshift(msg);
    //         while (this.messages.length > 5) {
    //             this.messages.pop();
    //         }
    //         this.afterChange(false);
    //     };
    // }

    protected getContents(): string {
        clLogger.debug('MESSAGES: ', this.messages);
        return widget({ announcements: this.announcements, messages: this.messages });
        // return widget({ announcements: this.announcements, messages: [] });
    }

    private afterChange() {
        // Copy to storage
        setCacheKey(STORAGE_INFO.clNotifyMsg.scope, STORAGE_INFO.clNotifyMsg.key, this.announcements);
        setCacheKey(STORAGE_INFO.messages.scope, STORAGE_INFO.messages.key, this.messages, STORAGE_INFO.messages.cacheMaxTime);
        this.setContainerContents(this.getContents());
        // If the modal is open, re-render with the latest
        if (this.modalOpen) {
            this.updateModalContents();
        }
    }

    private updateModalContents() {
        const modalBody = getById('notifyDiv');
        for (const a of this.announcements) {
            a.readLink = singleReadLink(a.link);
        }
        modalBody.innerHTML = announcementTemplate({ announcements: this.announcements, messages: this.messages });
        const annBody = getById('announcementNotifs');
        const dmBody = getById('dmNotifs');
        for (const trash of getByClass('fa-trash', annBody)) {
            onClick(trash, () => {
                const annIdx = Number.parseInt(attr(trash, 'data-idx'));
                // Need a way to track announcements vs messages so that both may be deleted
                if (Number.isInteger(annIdx) && this.announcements.length > annIdx) {
                    this.announcements.splice(annIdx, 1);
                    this.afterChange();
                }
                else {
                    clLogger.debug(`Got a delete announcement notification for a bad index ${annIdx}`);
                }
            });
        }
        for (const trash of getByClass('fa-trash', dmBody)) {
            onClick(trash, () => {
                const msgIdx = Number.parseInt(attr(trash, 'data-idx'));
                // Need a way to track announcements vs messages so that both may be deleted
                if (Number.isInteger(msgIdx) && this.messages.length > msgIdx) {
                    this.messages.splice(msgIdx, 1);
                    this.afterChange();
                }
                else {
                    clLogger.debug(`Got a delete message notification for a bad index ${msgIdx}`);
                }
            });
        }
    }

    private setContainerContents(contents: string) {
        // Set the badge
        const container = getById(this.containerId);
        container.innerHTML = contents;
        const notifyModals = getByClass('triggerNotificationModal', container);

        // If the content included the modal trigger button, we have one or more announcements, so handle click.
        if (notifyModals && notifyModals[0]) {
            onClick(notifyModals[0], () => {
                this.notifyModal.open();
                this.modalOpen = true;
            });
        }
    }

    private processPaths(paths: string[]): string[] {
        const results = [];
        for (const p of paths) {
            const tokens = p.split('=>');
            for (const t of tokens) {
                results.push(parseToDisplay(parseCode(t)));
            }
        }
        return results;
    }
}
