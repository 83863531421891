import { LIGHT_GREY } from './util/colorsUtil';

export const bigDonutSpec = {
    data: [ {
        values: [],
        labels: [],
        text: [],
        type: 'pie',
        hole: 0.4,
        hovertemplate: '<extra>%{text}</extra>%{value}<br>%{percent:.0%}',
        textinfo: 'text',
        textposition: 'inside',
        marker: {
            colors: [ '#385d8a', '#436ca0', '#4a7bb5', '#7292c1', '#a1b4d4', '#c2cde1' ],
        },
    } ],
    layout: {
        title: { text: '', y: 0.49, yanchor: 'middle' },
        titlefont: {
            family: 'Roboto',
            size: 18,
            color: '#6c757d',
        },
        annotations: [ {
            font: {
                family: 'Roboto',
                size: 10,
                color: LIGHT_GREY,
            },
            showarrow: false,
            text: 'Top 20',
            y: 0.55,
        } ],
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        hidesources: false,
        hoverlabel: {
            font: {
                family: 'Roboto',
                size: 13,
            },
        },
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        showlegend: false,
        autosize: true,
        margin: {
            l: 20,
            r: 20,
            t: 20,
            b: 20,
            autoexpand: true,
        },
        width: 350,
        height: 350,
    },
};
export const donutSpec = {
    data: [ {
        values: [],
        labels: [],
        text: [],
        type: 'pie',
        hole: 0.3,
        hovertemplate: '<extra>%{text}</extra>%{value}<br>%{percent:.0%}',
        textinfo: 'text',
        textposition: 'inside',
        marker: {
            colors: [ '#385d8a', '#436ca0', '#4a7bb5', '#7292c1', '#a1b4d4', '#c2cde1' ],
        },
    } ],
    layout: {
        title: { text: '' },
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        hidesources: false,
        hoverlabel: {
            font: {
                family: 'Roboto',
                size: 13,
            },
        },
        plot_bgcolor: '#fff',
        showlegend: false,
        autosize: true,
        margin: {
            l: 10,
            r: 10,
            t: 10,
            b: 10,
            autoexpand: true,
        },
        width: 150,
    },
};
export const mentionSpec = {
    data: [
        {
            type: 'bar',
            visible: true,
            name: 'Mentions',
            showlegend: false,
            marker: {
                color: 'rgb(0,130,200)',
            },
            mode: 'markers',
            x: [],
            y: [],
            base: 0,
            hovertemplate: '<extra>Mentions</extra>%{y}',
        },
    ],
    layout: {
        font: {
            family: 'Roboto',
            size: 12,
            color: 'rgb(255, 255, 255)',
        },
        title: '',
        titlefont: {
            family: 'Roboto',
            size: 20,
            color: 'rgb(255, 255, 255)',
        },
        autosize: true,
        margin: {
            l: 80,
            r: 80,
            t: 60,
            b: 60,
            pad: 8,
            autoexpand: true,
        },
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        hoverlabel: {
            namelength: 15,
            font: {
                family: 'Roboto',
                size: 13,
            },
        },
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        xaxis: {
            tickformat: '%Y-%m',
            tickangle: 45,
            anchor: 'y',
            gridcolor: 'rgba(255,255,255,0.03)',
            type: 'date',
            autorange: false,
            dtick: 'M1',
        },
        yaxis: {
            title: 'Mention Count',
            zerolinecolor: '#fff',
            gridcolor: 'rgba(255,255,255,0.4)',
            anchor: 'x',
            type: 'linear',
            autorange: true,
            nticks: 5,
        },
        breakpoints: [],
        bargap: 0.1,
        dragmode: false,
        hovermode: 'x',
    },
    frames: [],
};
export const trendBarSpec = {
    data: [
        {
            type: 'bar',
            visible: true,
            name: 'Positive Trends',
            showlegend: false,
            marker: {
                color: 'rgb(40,167,69)',
            },
            mode: 'markers',
            x: [],
            y: [],
            base: [ 0, 0, 0 ],
        },
        {
            type: 'bar',
            visible: true,
            name: 'Negative Trends',
            showlegend: false,
            marker: {
                color: 'rgb(220,53,69)',
            },
            y: [],
            x: [],
            base: [ 0, 0, 0 ],
        },
    ],
    layout: {
        font: {
            family: 'Roboto',
            size: 12,
            color: 'rgb(255, 255, 255)',
        },
        title: '',
        titlefont: {
            family: 'Roboto',
            size: 20,
            color: 'rgb(255, 255, 255)',
        },
        autosize: true,
        margin: {
            l: 80,
            r: 80,
            t: 60,
            b: 60,
            pad: 8,
            autoexpand: true,
        },
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        hoverlabel: {
            namelength: 20,
            font: {
                family: 'Roboto',
                size: 13,
            },
        },
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        xaxis: {
            tickformat: '%Y-%m',
            tickangle: 45,
            anchor: 'y',
            gridcolor: 'rgba(255,255,255,0.03)',
            type: 'date',
            autorange: false,
            dtick: 'M1',
        },
        yaxis: {
            title: 'Trend Count',
            zerolinecolor: '#fff',
            gridcolor: 'rgba(255,255,255,0.4)',
            anchor: 'x',
            type: 'linear',
            autorange: true,
            nticks: 5,
        },
        breakpoints: [],
        barmode: 'stack',
        bargap: 0.1,
        dragmode: false,
        hovermode: 'x',
        bargroupgap: 0.1,
    },
    frames: [],
};
export const trendPerSpec = {
    data: [
        {
            x: [],
            y: [],
            base: [],
            text: [],
            textposition: 'none',
            type: 'bar',
            showlegend: false,
            hoverinfo: 'x+text',
            marker: {
                color: [],
            },
        },
    ],
    layout: {
        font: {
            family: 'Roboto',
            size: 12,
            color: 'rgb(255, 255, 255)',
        },
        title: '',
        titlefont: {
            family: 'Roboto',
            size: 20,
            color: 'rgb(255, 255, 255)',
        },
        autosize: true,
        margin: {
            l: 80,
            r: 80,
            t: 60,
            b: 60,
            pad: 8,
            autoexpand: true,
        },
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        hoverlabel: {
            namelength: 15,
            font: {
                family: 'Roboto',
                size: 13,
            },
        },
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        xaxis: {
            tickformat: '%Y-%m',
            tickangle: 45,
            anchor: 'y',
            gridcolor: 'rgba(255,255,255,0.03)',
            type: 'date',
            autorange: false,
            dtick: 'M1',
        },
        yaxis: {
            title: 'Percentage Positive',
            zerolinecolor: 'rgba(0, 0, 0, 0)',
            gridcolor: 'rgba(255,255,255,0.4)',
            anchor: 'x',
            type: 'linear',
            autorange: false,
            range: [
                0,
                1,
            ],
        },
        shapes: [
            {
                type: 'line',
                xref: 'paper',
                yref: 'y',
                x0: 0,
                y0: 0.5,
                x1: 1,
                y1: 0.5,
                line: {
                    dash: 'dash',
                    color: '#fff',
                },
            },
        ],
        breakpoints: [],
        bargap: 0.1,
        dragmode: false,
        hovermode: 'x',
    },
    frames: [],
};
export const scatterSpec = {
    data: [
        {
            type: 'scatter',
            visible: true,
            name: 'ECLs',
            xaxis: 'x',
            yaxis: 'y',
            hoverlabel: {
                font: {
                    family: 'Roboto',
                    size: '13',
                },
            },
            x: [],
            y: [],
            text: [],
            mode: 'markers',
            marker: {
                symbol: [],
                opacity: 1,
                size: 10,
                color: [],
                cauto: false,
                cmin: -100,
                cmax: 100,
                autocolorscale: false,
                colorscale: [
                    [
                        0,
                        'rgb(178,10,28)',
                    ],
                    [
                        0.3,
                        'rgb(230,145,90)',
                    ],
                    [
                        0.4,
                        'rgb(220,170,132)',
                    ],
                    [
                        0.5,
                        'rgb(190,190,190)',
                    ],
                    [
                        0.65,
                        'rgb(106,137,247)',
                    ],
                    [
                        1,
                        'rgb(5,10,172)',
                    ],
                ],
                reversescale: false,
                showscale: false,
                line: {
                    color: '#444',
                    width: 0,
                },
                gradient: {
                    type: 'none',
                },
                maxdisplayed: 0,
            },
            cliponaxis: true,
            fill: 'none',
            hoveron: 'points',
            selected: {
                marker: {
                    opacity: 1,
                    size: 15,
                },
            },
            selectedpoints: null,
            unselected: {
                marker: {
                    opacity: 0.5,
                    size: 10,
                },
            },
            hoverinfo: 'x+y+text',
            hovertemplate: '<extra>%{text}</extra>%{x}<br> %{y:>}%',
            opacity: 0.9,
        },
    ],
    layout: {
        font: {
            family: 'Roboto',
            size: 12,
            color: 'rgb(255, 255, 255)',
        },
        title: '',
        titlefont: {
            family: 'Roboto',
            size: '1em',
            color: 'rgb(255, 255, 255)',
        },
        autosize: true,
        margin: {
            l: 80,
            r: 80,
            t: 80,
            b: 30,
            pad: 0,
            autoexpand: true,
        },
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        separators: '.,',
        hidesources: false,
        colorway: [
            '#1f77b4',
            '#ff7f0e',
            '#2ca02c',
            '#d62728',
            '#9467bd',
            '#8c564b',
            '#e377c2',
            '#7f7f7f',
            '#bcbd22',
            '#17becf',
        ],
        hoverlabel: {
            font: {
                family: 'Roboto',
                size: 13,
            },
        },
        plot_bgcolor: '#fff',
        xaxis: {
            type: 'date',
            visible: true,
            calendar: 'gregorian',
            autorange: false,
            rangemode: 'normal',
            hoverformat: '%Y-%m-%d',
            color: '#444',
            title: '',
            titlefont: {
                family: 'Roboto',
                size: 14,
                color: 'rgb(255, 255, 255)',
            },
            tickmode: 'auto',
            nticks: 20,
            showticklabels: true,
            tickfont: {
                family: 'Roboto',
                size: 12,
                color: 'rgb(255, 255, 255)',
            },
            tickangle: 45,
            tickformat: '%Y-%m-%d',
            tickprefix: ' ',
            ticklen: 10,
            tickwidth: 1,
            tickcolor: '#444',
            ticks: 'inside',
            showline: false,
            gridcolor: 'rgb(238, 238, 238)',
            gridwidth: 1,
            showgrid: true,
            zerolinecolor: '#444',
            zerolinewidth: 1,
            zeroline: true,
            mirror: false,
            automargin: false,
            showspikes: false,
            anchor: 'y',
            side: 'bottom',
            domain: [
                0,
                1,
            ],
            layer: 'above traces',
            rangeslider: {
                visible: true,
                bgcolor: 'white',
                bordercolor: '#444',
                borderwidth: 0,
                thickness: 0.1,
                autorange: true,

                yaxis: {
                    rangemode: 'match',
                },
            },
            rangeselector: {
                buttons: [
                    {
                        visible: true,
                        step: 'all',
                        label: 'All',
                    },
                    {
                        visible: true,
                        step: 'year',
                        stepmode: 'backward',
                        count: 1,
                        label: '1y',
                    },
                    {
                        visible: true,
                        step: 'year',
                        stepmode: 'backward',
                        count: 2,
                        label: '2y',
                    },
                ],
                visible: true,
                x: -0.17,
                y: 1.03,
                pad: 10,
                xanchor: 'left',
                yanchor: 'bottom',
                font: {
                    family: 'Roboto',
                    size: '1em',
                    color: 'rgb(0, 0, 0)',
                },
                bgcolor: '#eee',
                activecolor: '#d4d4d4',
                bordercolor: '#444',
                borderwidth: 0,
            },
            fixedrange: false,
            constrain: 'range',
            constraintoward: 'center',
            tick0: '2000-01-01',
            dtick: 'M6',
        },
        yaxis: {
            type: 'linear',
            visible: true,
            autorange: true,
            rangemode: 'normal',
            color: '#444',
            title: 'Relationship',
            titlefont: {
                family: 'Roboto',
                size: 14,
                color: 'rgb(255, 255, 255)',
            },
            tickmode: 'auto',
            nticks: 10,
            ticksuffix: '%   ',
            tickprefix: '    ',
            showticksuffix: 'all',
            showticklabels: true,
            tickfont: {
                family: 'Roboto',
                size: 12,
                color: 'rgb(255, 255, 255)',
            },
            tickangle: 'auto',
            showexponent: 'all',
            exponentformat: 'B',
            separatethousands: false,
            ticklen: 10,
            tickwidth: 1,
            tickcolor: '#444',
            ticks: 'inside',
            showline: false,
            gridcolor: 'rgb(238, 238, 238)',
            gridwidth: 1,
            showgrid: true,
            zerolinecolor: '#444',
            zerolinewidth: 1,
            zeroline: true,
            mirror: false,
            automargin: false,
            showspikes: false,
            anchor: 'x',
            side: 'left',
            domain: [
                0,
                1,
            ],
            layer: 'above traces',
            fixedrange: true,
            tick0: 0,
            dtick: 500,
        },
        dragmode: 'select',
        hovermode: 'closest',
        hoverdistance: 20,
        spikedistance: 20,
        showlegend: false,
    },
    frames: [],
};
export const coronaBarSpec = {
    data: [
        {
            type: 'bar',
            visible: true,
            name: 'Positive Trends',
            showlegend: true,
            mode: 'markers',
            x: [],
            y: [],
        },
    ],
    layout: {
        font: {
            family: 'Roboto',
            size: 12,
            color: 'rgb(255, 255, 255)',
        },
        autosize: true,
        margin: {
            l: 60,
            r: 40,
            t: 40,
            b: 60,
            pad: 6,
            autoexpand: true,
        },
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        hoverlabel: {
            namelength: 20,
            font: {
                family: 'Roboto',
                size: 13,
            },
        },
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        xaxis: {
            tickformat: '%Y-%m-%d',
            tickangle: 45,
            anchor: 'y',
            gridcolor: 'rgba(255,255,255,0.03)',
            type: 'category',
            autorange: true,
        },
        yaxis: {
            title: 'Link Count',
            zerolinecolor: '#fff',
            gridcolor: 'rgba(255,255,255,0.4)',
            anchor: 'x',
            type: 'linear',
            autorange: true,
            nticks: 5,
        },
        legend: {
            itemclick: 'toggleothers',
            itemdoubleclick: 'toggle',
            traceorder: 'normal',
        },
        barmode: 'stack',
        dragmode: false,
        hovermode: 'closest',
    },
    frames: [],
};
export const coronaHeatSpec = {
    data: [
        {
            type: 'treemap',
            branchvalues: 'remainder',
            labels: [],
            parents: [],
            values: [],
            textinfo: 'label+value',
            name: 'Treemap',
            depthfade: 'reversed',
            textfont: {
                family: 'Roboto',
                size: 12,
                color: 'rgb(255, 255, 255)',
            },
            marker: undefined,
            level: undefined,
        },
    ],
    layout: {
        font: {
            family: 'Roboto',
            size: 12,
            color: 'rgb(255, 255, 255)',
        },
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        showlegend: false,
        margin: {
            l: 20,
            r: 20,
            t: 20,
            b: 20,
            pad: 20,
            autoexpand: true,
        },
        height: 800,
        uniformtext: {
            mode: 'hide',
            minsize: 12,
        },
    },
};
export const coronaLineSpec = {
    data: [
        {
            type: 'line',
            x: [],
            y: [],
            text: [],
            hovertemplate: '<extra>%{text}</extra>%{y}%',
            name: 'Forecast',
            line: {
                shape: 'spline',
                smoothing: 0.5,
            },
            yaxis: 'y',
        },
    ],
    layout: {
        font: {
            family: 'Roboto',
            size: 12,
            color: 'rgb(255, 255, 255)',
        },
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        showlegend: true,
        margin: {
            l: 80,
            r: 60,
            t: 20,
            b: 40,
            pad: 0,
            autoexpand: true,
        },
        xaxis: {
            gridcolor: 'rgba(255,255,255,0.03)',
            type: 'date',
            autorange: false,
            domain: [ 0, 1 ],
        },
        yaxis: {
            gridcolor: 'rgba(255,255,255,0)',
            zeroline: true,
            showline: false,
            side: 'left',
            anchor: 'x',
            tickfont: {
                color: 'rgba(255,255,255,1)',
            },
            title: {
                text: 'Impact - From Negative to Neutral to Positive',
                font: {
                    color: 'rgba(255,255,255,1)',
                },
            },
            range: [ -100, 50 ],
            autorange: true,
        },
        legend: {
            orientation: 'h',
            itemclick: 'toggleothers',
        },
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        hovermode: 'x',
        height: 800,
        shapes: [
            {
                layer: 'below',
                xref: 'paper',
                yref: 'paper',
                x0: 0,
                x1: 1,
                y0: 0,
                y1: 0.2,
                type: 'rect',
                fillcolor: 'red',
                opacity: 0.2,
                line: {
                    width: 0,
                },
            },
            {
                layer: 'below',
                xref: 'paper',
                yref: 'paper',
                x0: 0,
                x1: 1,
                y0: 0.2,
                y1: 0.4,
                type: 'rect',
                fillcolor: '#FF8C00',
                opacity: 0.2,
                line: {
                    width: 0,
                },
            },
            {
                layer: 'below',
                xref: 'paper',
                yref: 'paper',
                x0: 0,
                x1: 1,
                y0: 0.4,
                y1: 0.6,
                type: 'rect',
                fillcolor: '#DAA520',
                opacity: 0.2,
                line: {
                    width: 0,
                },
            },
        ],
    },
};
