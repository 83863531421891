import { IApiHostInfo } from '../../shared/interfaces/api/apiContracts';
import {
    ApiError, API_VERSION, CONTENT_TYPE, HTTP_METHODS, NO_CONTENT_PAYLOAD, SIGNED_URL_EXPIRATION_THREE_DAYS_IN_SECONDS, STATUS_CODES,
    STATUS_CODE_THRESHOLD,
} from '../../shared/interfaces/api/apiInterfaces';
import { IAuth } from '../../shared/interfaces/user/userAuth-i';
import { getClLogger } from '../../shared/util/clLogger';
import { attr, onEvent, onSubmit, removeEvent } from '../lib/util/html';
import { rootGetApiHost } from './api/root';
import { IBinary, IDeferredPromise } from './interfaces/ajax';
import { UserFeature } from './interfaces/analytics';
import { trackAnalyticsEvent } from './util/analytics';
import { STORAGE_INFO, fetchCacheKey, removeCacheKey, setCacheKey } from './util/storageUtil';
import { Spinner } from './widgets/spinner/spinner';

const clLogger = getClLogger(__filename);
const NEWLINE = '\n';

// Wrapper to create a new event that handles slight differences
// and inconsistencies with what must be done in each browser to accomplish
// the same thing. Problems were occurring that prohibited logging in using IE.
export function newEvent(type: string): Event {
    if (CustomEvent instanceof Function) {
        return new CustomEvent(type);
    }
    else if (document.createEvent) {
        const evt = document.createEvent('HTMLEvents');
        evt.initEvent(type, false, false);
        return evt;
    }
    throw new Error('No valid method to create event of type: ' + type);
}

// Useful for knowing how to call APIs as well as API introspection in docs
export function getApiHostInfo(): IApiHostInfo {
    return fetchCacheKey<IApiHostInfo>(STORAGE_INFO.clApi.scope, STORAGE_INFO.clApi.key);
}

async function setApiPath(basePath: string, wrap: boolean): Promise<string> {
    if (wrap) {
        // The api information contains all the base host, port, etc.
        // Therefore, we need to strip this from the path if it was provided
        const absIdx = basePath.indexOf('//');
        if (absIdx >= 0) {
            basePath = basePath.substr(absIdx + 2);
            basePath = basePath.substr(basePath.indexOf('/'));
        }
        // We might already have a cached copy of the api path information.
        let apiHostInfo = getApiHostInfo();
        if (apiHostInfo) {
            return apiHostInfo.path + basePath;
        }
        // Path information is missing.
        else {
            // Make sure the request to resolve the api information does not get wrapped.
            apiHostInfo = await rootGetApiHost();
            if (apiHostInfo && apiHostInfo.name === 'API') {
                setCacheKey(STORAGE_INFO.clApi.scope, STORAGE_INFO.clApi.key, apiHostInfo);
                return apiHostInfo.path + basePath;
            }
            throw new Error('Could not find api host, cannot continue.');
        }
    }
    else {
        return basePath;
    }
}

function getAuthFromResponse(req: XMLHttpRequest) {
    // Allow our JWT token to be set / refreshed through an AJAX response header.
    const auth = req.getAllResponseHeaders().toLowerCase().indexOf('authorization') >= 0 ? req.getResponseHeader('Authorization') : undefined;
    if (auth && auth.length) {
        setCacheKey(STORAGE_INFO.clJwt.scope, STORAGE_INFO.clJwt.key, auth);
    }
}

export function getAuthObj(): IAuth {
    return jwtDecode(fetchCacheKey<string>(STORAGE_INFO.clJwt.scope, STORAGE_INFO.clJwt.key));
}

export function jwtDecode(token: string) {
    if (token) {
        const claims = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
        try {
            return JSON.parse(window.atob(claims));
        }
        catch (err) {
            return null;
        }
    }
    else {
        return null;
    }
}

function setAuthOnRequest(req: XMLHttpRequest) {
    // If we have a JWT token in storage, add it to the request appropriately.
    const token = fetchCacheKey<string>(STORAGE_INFO.clJwt.scope, STORAGE_INFO.clJwt.key);
    if (token) {
        req.setRequestHeader('Authorization', `Bearer ${token}`);
    }
}

let unauthorizedRaised = false;
const promiseQueue: IDeferredPromise[] = [];

// Callback after authentication is successful, at which time we will retry any AJAX requests
// that came back an unauthorized while we were in the unauthenticated state.
function onAuthorizedUser() {
    clLogger.debug('Processing deferred events: ' + promiseQueue.length);
    let inf = promiseQueue.shift();
    while (undefined !== inf) {
        clLogger.debug('Recalling request...');
        const resolve = inf.resolve;
        const reject = inf.reject;
        inf.originalFunc.apply(null, inf.args).then((data) => {
            resolve(data);
        }).catch((err) => {
            reject(err);
        });
        inf = promiseQueue.shift();
    }
    unauthorizedRaised = false;
    removeEvent(document, 'AuthorizedUser', onAuthorizedUser);
}

function onUnauthorizedUser(promiseHandle: IDeferredPromise) {
    // Only raise the event once until it's been cleared
    if (!unauthorizedRaised) {
        // Make sure code doesn't assume the cached token claims are still valid
        removeCacheKey(STORAGE_INFO.clJwt.scope, STORAGE_INFO.clJwt.key);
        document.dispatchEvent(newEvent('UnauthorizedUser'));
        unauthorizedRaised = true;
        onEvent(document, 'AuthorizedUser', onAuthorizedUser);
    }
    // Track the promise and retry later if the user authenticates successfully.
    promiseQueue.push(promiseHandle);
}

// If the request is successful (as defined by status code), only the data payload is returned.
//  This avoids repeated and unneeded unwrapping and parsing in the client
// If there is a problem, all metadata and data is returned
//  This allows meaningful error messages in the UI
function onRequestLoad(req: XMLHttpRequest, promiseHandle: IDeferredPromise, progressData?: any[]): (evt: Event) => void {
    return () => {
        const contentType = req.getResponseHeader('Content-Type');
        if (req.status > STATUS_CODE_THRESHOLD.BELOW_OKAY && req.status < STATUS_CODE_THRESHOLD.ABOVE_OKAY) {
            getAuthFromResponse(req);
            // For reference on Edge, Chrome, Firefox
            // - getResponseHeader parameters are case-insensitive
            // - getAllResponseHeaders returns a string (case-sensitive)
            // A previous commit mentioned that some browsers may not follow the same case-sensitivity rules for getAllResponseHeaders
            const disposition = req.getAllResponseHeaders().toLowerCase().indexOf('content-disposition') >= 0
                ? req.getResponseHeader('Content-Disposition') : undefined;
            // If an attachment is received via AJAX
            if (disposition && disposition.startsWith('attachment;')) {
                const blob = new Blob([ req.response as ArrayBuffer ], { type: contentType });
                const binaryInfo: IBinary = {
                    binary: req.response,
                    blob,
                    filename: disposition.split('=').pop(),
                };
                promiseHandle.resolve(binaryInfo);
            }
            else if (contentType?.startsWith(CONTENT_TYPE.PLAINTEXT) || contentType?.startsWith(CONTENT_TYPE.CSV)) {
                promiseHandle.resolve(req.responseText);
            }
            else if (contentType?.startsWith(CONTENT_TYPE.NDJSON)) {
                if (progressData && progressData.length) {
                    clLogger.debug(`NDJson was parsed incrementally with progress events. Rows: ${progressData.length}`);
                    promiseHandle.resolve(progressData);
                }
                else {
                    const nddata = [ ...req.responseText.split(NEWLINE).filter((rt) => rt).map((rt) => JSON.parse(rt)) ];
                    clLogger.debug(`NDJson was parsed entirely after download. Rows: ${nddata.length}`);
                    promiseHandle.resolve(nddata);
                }
            }
            // gzip was previously used, although by default AWS S3 uses application/x-gzip. Maybe includes('gzip') should be the check?
            else if (req.status === STATUS_CODES.GENERAL_OKAY && (contentType === 'gzip' || contentType === 'application/x-gzip')) {
                promiseHandle.resolve(req.responseText);
            }
            else {
                if (req.status === STATUS_CODES.NO_CONTENT) {
                    promiseHandle.resolve(NO_CONTENT_PAYLOAD);
                }
                else {
                    // Going forward, responses should be JSON objects
                    try {
                        const json = JSON.parse(req.responseText);
                        if (json.apiVersion === API_VERSION.V2 && json.data !== undefined) {
                            promiseHandle.resolve(json.data);
                        }
                        else {
                            promiseHandle.resolve(json);
                        }
                    }
                    catch (err) {
                        // This means that a non JSON response was returned
                        // Previously this was OK (V1), but these should be converted to have a more structured return
                        promiseHandle.resolve(req.responseText);
                    }
                }
            }
        }
        else if (req.status === STATUS_CODES.BAD_CREDENTIALS) {
            // Our reject is not authorized, something the page/app can respond to if it desires. However,
            // we want to be able to pick up where we left off and continue as the user, so this function
            // will capture enough information to retry the request after successful authentication.
            onUnauthorizedUser(promiseHandle);
        }
        else if (req.status === STATUS_CODES.OBJECT_NOT_FOUND) {
            Spinner.setSpinnerError(`Sorry, but we couldn't find what you're looking for.`);
            promiseHandle.reject(new ApiError(`Requested entity wasn't found`, STATUS_CODES.OBJECT_NOT_FOUND));
        }
        else if (contentType.startsWith(CONTENT_TYPE.HTML) && req.status === STATUS_CODES.BAD_GATEWAY) {
            Spinner.setSpinnerError(`Sorry, but there was a problem. ${req.responseText || ''}`);
            trackAnalyticsEvent(UserFeature.ERROR_REQUEST_TOOK_TOO_LONG, req.responseURL);
            promiseHandle.reject(new ApiError(`The request took too long`, req.status, req.responseText));
        }
        else {
            // At this point, the response could be
            // - v1 string (if headers were incorrectly sent, or are not all handled)
            // - v1 JSON
            // - v2 JSON
            try {
                // Check for any kind of JSON
                const response = JSON.parse(req.responseText);
                // If JSON does come back, return all of it
                // We're in an error state, so we either need:
                // - Everything sent from an older API version OR
                // - All v2 metadata as well as any data sent back with the error
                if (response.apiVersion === API_VERSION.V2) {
                    promiseHandle.reject(response);
                }
                else if (response.message) {
                    Spinner.setSpinnerError(response.message);
                    promiseHandle.reject(new ApiError(response.message, req.status, response.data));
                }
                // If there is no message, the API's error response should updated with more structure (ApiError)
                else {
                    clLogger.debug('Please update the error response for this API');
                    Spinner.setSpinnerError(`Sorry, but there was a problem. ${req.responseText || ''}`);
                    promiseHandle.reject(new ApiError(`Could not parse ${req.status} API response into JSON`, req.status, req.responseText));
                }
            }
            // It's possible to hit unstructured error responses, but a notification should be made so they can be turned into structured responses.
            catch (err) {
                clLogger.debug('Please update the error response for this API');
                Spinner.setSpinnerError(`Sorry, but there was a problem. ${req.responseText || ''}`);
                promiseHandle.reject(new ApiError(`Could not parse ${req.status} API response into JSON`, req.status, req.responseText));
            }
        }
    };
}

// This is only responsible for handling network level or browser rule level errors
// Another way of saying this is that it will catch all errors where the request was able to parse parameters like url, but where
//  no response came back. Often a status code of 0 will be returned.
function onRequestError(req: XMLHttpRequest, reject: (err) => void): (err: ErrorEvent) => void {
    return (err: ErrorEvent) => {
        const errorMessage = `onError in request. Status: ${req.status}, Filename: ${err.filename}, Line: ${err.lineno}`;
        clLogger.error(errorMessage);
        const apiError = new ApiError(`${err.message} <- ${errorMessage}`, req.status);
        reject(apiError);
    };
}

export function binaryPost(url: string, wrap: boolean = true, data?: any): Promise<IBinary> {
    return binarybase(url, 'POST', wrap, data);
}

function binarybase(url: string, method: string, wrap: boolean = true, data?: any): Promise<IBinary> {
    return new Promise((resolve, reject) => {
        setApiPath(url, true).then((realUrl) => {
            const req = new XMLHttpRequest();
            onEvent(req, 'load', onRequestLoad(req, { originalFunc: binarybase, args: [ url, wrap ], resolve, reject }));
            onEvent(req, 'error', onRequestError(req, reject));
            req.responseType = 'arraybuffer';
            // Check for request parsing issues
            try {
                // URL manipulation that follows is to bust caching by including the current time
                req.open(method, realUrl + ((/\?/).test(realUrl) ? '&' : '?') + (new Date()).getTime(), true);
            }
            catch (err) {
                reject(new ApiError(err.message, 0));
            }
            if ((data instanceof FormData) === false) {
                if (typeof data !== 'string') {
                    data = JSON.stringify(data);
                }
                req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
            }
            req.setRequestHeader('Accept', 'application/json');
            setAuthOnRequest(req);
            req.send(data);
        }).catch(reject);
    });
}

export function formatParams(params: Record<string, string | string[] | number | number[] | boolean>, url: string, addNocColonParamsToUrl: boolean = true) {
    if (params) {
        const values = [];
        for (const key of Object.keys(params)) {
            if (url.indexOf('/:' + key) > -1) {
                const value = params[key] ? '/' + encodeURIComponent(params[key] as string) : '';
                url = url.replace('/:' + key, value);
            }
            else if (addNocColonParamsToUrl && params[key] !== undefined && params[key] !== null) {
                values.push(`${key}=${encodeURIComponent(params[key] as string)}`);
            }
        }
        if (values.length) {
            return `${url}?${values.join('&')}`;
        }
    }
    return url;
}

export function ajaxGet<T>(url: string, params?: Record<string, string | string[] | number | number[] | boolean>, wrap: boolean = true,
                           onprogress?: (progressPercent: number, dataLength: number) => void,
                           enableIncrementalData: boolean = false, isExternalRequest: boolean = false): Promise<T> {
    return new Promise((resolve, reject) => {
        setApiPath(formatParams(params, url), wrap).then((realUrl) => {
            const req = new XMLHttpRequest();
            const progressData = [];
            if (onprogress) {
                let progressIdx = 0;
                req.addEventListener('progress', (evt) => {
                    if (enableIncrementalData) {
                        const lastLine = req.responseText.lastIndexOf(NEWLINE);
                        if (lastLine > progressIdx) {
                            const toParse = req.responseText.substr(progressIdx, lastLine - progressIdx + 1);
                            try {
                                progressData.push(...toParse.split(NEWLINE).filter((rt) => rt).map((rt) => JSON.parse(rt)));
                                progressIdx = lastLine + 1;
                            }
                            catch (err) {
                                clLogger.error(err, toParse);
                            }
                        }
                    }
                    if (evt.lengthComputable) {
                        onprogress(100 * evt.loaded / evt.total, progressData.length);
                    }
                    else {
                        onprogress(0, progressData.length);
                    }
                });
                onEvent(req, 'load', () => {
                    if (req.status === STATUS_CODES.BAD_CREDENTIALS) {
                        onprogress(0, progressData.length);
                    }
                    // There could be an extra line of JSON that does not terminate in a newline and would otherwise be excluded
                    else if (enableIncrementalData && req.responseText.length > progressIdx) {
                        const toParse = req.responseText.substr(progressIdx, req.responseText.length - progressIdx + 1);
                        try {
                            progressData.push(...toParse.split(NEWLINE).filter((rt) => rt).map((rt) => JSON.parse(rt)));
                        }
                        catch (err) {
                            clLogger.error(err, toParse);
                        }
                    }
                });
            }
            onEvent(req, 'load', onRequestLoad(req, { originalFunc: ajaxGet, args: [ url, params, wrap ], resolve, reject }, progressData));
            onEvent(req, 'error', onRequestError(req, reject));
            // URL manipulation that follows is to bust caching by including the current time
            // Only do this on internal requests. Adding extra parameters on external requests
            // can cause mismatches (signed url vs signed url + timestamp)
            if (isExternalRequest) {
                // Check for request parsing issues
                try {
                    req.open('GET', realUrl, true);
                }
                catch (err) {
                    reject(new ApiError(err.message, 0));
                }
                req.setRequestHeader('Cache-Control', `max-age=${SIGNED_URL_EXPIRATION_THREE_DAYS_IN_SECONDS}`);
            }
            else {
                // Check for request parsing issues
                try {
                    // URL manipulation that follows is to bust caching by including the current time
                    const urlAndOptionalTimestamp = realUrl + ((/\?/).test(realUrl) ? '&' : '?') + (new Date()).getTime();
                    req.open('GET', urlAndOptionalTimestamp, true);
                }
                catch (err) {
                    reject(new ApiError(err.message, 0));
                }
                req.setRequestHeader('Accept', 'application/json');
                setAuthOnRequest(req);
            }
            req.send();
        }).catch(reject);
    });
}

export function ajaxPut<T>(url: string, data?: any): Promise<T> {
    return new Promise((resolve, reject) => {
        setApiPath(formatParams(data, url, false), true).then((realUrl) => {
            commonAjaxHandler(url, data, resolve, reject, realUrl, ajaxPut, HTTP_METHODS.PUT);
        }).catch(reject);
    });
}

/*
 * Send POST via Ajax. Supports FormData (see MDN), stringified JSON, or any, which
 * will automatically be converted to stringified, so must be serializable.
 */
export function ajaxPost<T>(url: string, data: any, onprogress?: (progressPercent: number, dataLength: number) => void, enableIncrementalData?: boolean): Promise<T> {
    return new Promise((resolve, reject) => {
        setApiPath(url, true).then((realUrl) => {
            commonAjaxHandler(url, data, resolve, reject, realUrl, ajaxPost, HTTP_METHODS.POST, onprogress, enableIncrementalData);
        }).catch(reject);
    });
}

export function ajaxPatch<T>(url: string, data: any): Promise<T> {
    return new Promise((resolve, reject) => {
        setApiPath(url, true).then((realUrl) => {
            commonAjaxHandler(url, data, resolve, reject, realUrl, ajaxPatch, HTTP_METHODS.PATCH);
        }).catch(reject);
    });
}

function commonAjaxHandler(url: string, data: any, resolve, reject, realUrl, originalFunc, httpMethod: HTTP_METHODS,
                           onprogress?: (progressPercent: number, dataLength: number) => void, enableIncrementalData?: boolean) {
    const req = new XMLHttpRequest();
    const progressData = [];
    if (onprogress) {
        let progressIdx = 0;
        req.addEventListener('progress', (evt) => {
            if (enableIncrementalData) {
                const lastLine = req.responseText.lastIndexOf(NEWLINE);
                if (lastLine > progressIdx) {
                    const toParse = req.responseText.substr(progressIdx, lastLine - progressIdx + 1);
                    try {
                        progressData.push(...toParse.split(NEWLINE).filter((rt) => rt).map((rt) => JSON.parse(rt)));
                        progressIdx = lastLine + 1;
                    }
                    catch (err) {
                        clLogger.error(err, toParse);
                    }
                }
            }
            if (evt.lengthComputable) {
                onprogress(100 * evt.loaded / evt.total, progressData.length);
            }
            else {
                onprogress(0, progressData.length);
            }
        });
        onEvent(req, 'load', () => {
            if (req.status === STATUS_CODES.BAD_CREDENTIALS) {
                onprogress(0, progressData.length);
            }
            // There could be an extra line of JSON that does not terminate in a newline and would otherwise be excluded
            else if (enableIncrementalData && req.responseText.length > progressIdx) {
                const toParse = req.responseText.substr(progressIdx, req.responseText.length - progressIdx + 1);
                try {
                    progressData.push(...toParse.split(NEWLINE).filter((rt) => rt).map((rt) => JSON.parse(rt)));
                }
                catch (err) {
                    clLogger.error(err, toParse);
                }
            }
        });
    }
    onEvent(req, 'load', onRequestLoad(req, { originalFunc, args: [ url, data ], resolve, reject }, progressData));
    onEvent(req, 'error', onRequestError(req, reject));
    // Expecting the data to be JSON but already serialized.
    // Check for request parsing issues
    try {
        req.open(httpMethod, realUrl, true);
    }
    catch (err) {
        reject(new ApiError(err.message, 0));
    }
    if ((data instanceof FormData) === false) {
        if (typeof data !== 'string') {
            data = JSON.stringify(data);
        }
        req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    }
    req.setRequestHeader('Accept', 'application/json');
    setAuthOnRequest(req);
    req.send(data);
}

export function ajaxDelete<T>(url: string, params?: Record<string, string | number | boolean>): Promise<T> {
    return new Promise((resolve, reject) => {
        setApiPath(formatParams(params, url), true).then((realUrl) => {
            commonAjaxHandler(url, params, resolve, reject, realUrl, ajaxDelete, HTTP_METHODS.DELETE);
        }).catch(reject);
    });
}

export function ajaxFormOnSubmit(targetForm: HTMLFormElement, cb: (err, data?) => void = null): void {
    // Sometimes, the correct form id/class is not set
    // When this happens, a generic form is submitted and a 404 is generated. This is a hint as to why
    //  a form may not me submitting, which has happened a few times with code changes
    if (!targetForm) {
        clLogger.debug('Unknown form handlers setup');
    }
    else {
        onSubmit(targetForm, (evt) => {
            evt.preventDefault();
            submitForm(targetForm, cb);
        });
    }
}

export function ajaxFormOnSubmitWithControl(targetForm: HTMLFormElement,
                                            testBeforeSubmit: () => Promise<boolean>,
                                            cb: (err, data?) => void = null): void {
    onSubmit(targetForm, (evt) => {
        evt.preventDefault();
        testBeforeSubmit().then((success: boolean) => {
            if (success) {
                submitForm(targetForm, cb);
            }
        }).catch((err) => {
            cb(err, null);
        });
    });
}

// Map available http methods to their method handler
// eslint-disable-next-line func-call-spacing
const methodToHandlerMap = new Map<string, (url: string, data: any) => Promise<any>>();
methodToHandlerMap.set(HTTP_METHODS.PATCH, ajaxPatch);
methodToHandlerMap.set(HTTP_METHODS.POST, ajaxPost);
methodToHandlerMap.set(HTTP_METHODS.PUT, ajaxPut);

function submitForm(targetForm: HTMLFormElement, cb: (err, data?) => void = null) {
    const formData = new FormData(targetForm);
    let submitFunction = ajaxPost;
    // targetForm.method always returns get.
    // targetForm.getAttribute() must be used to get the actual setting
    // Match the form method with the correct handler
    let method = attr(targetForm, 'method');
    if (!method) {
        clLogger.debug('No method was selected for form:', targetForm);
        method = 'post';
    }
    const candidateHandler = methodToHandlerMap.get(method.toUpperCase());
    if (method && candidateHandler) {
        submitFunction = candidateHandler;
    }

    submitFunction(targetForm.action, formData)
        .then((result) => {
            // clLogger.debug('Successfully submitted form via ajax, result was: ' + JSON.stringify(result));
            if (cb) {
                cb(null, result);
            }
        })
        .catch((err) => {
            cb(err);
        });
}
