import { AuthRoles } from '../../shared/interfaces/acl/acl-i';
import { IPublisher } from '../../shared/interfaces/article/publishers';
import { IUserBookmarkCollection } from '../../shared/interfaces/bookmark/bookmark-i';
import { IConceptSummary, IParsedConcept, IStatsRow } from '../../shared/interfaces/dashboard';
import { UxType } from '../../shared/interfaces/metric/metric-i';
import { MentionColToGraphMention } from '../../shared/meta/unified';
import { filterSortAndCopyParsedConcepts, groupFilterAndSortStatsRowsByConceptKey, parseAndMapConcepts } from '../../shared/streaming/dashboard';
import { newEvent } from '../lib/ajax';
import { bookmarksPutUserBookmarks } from '../lib/api/bookmarks';
import { daasGetMention } from '../lib/api/daas';
import { servicesPostMetric } from '../lib/api/services';
import { userGetSubscribedPublishers } from '../lib/api/user';
import { SelectGroup } from '../lib/forms/selectGroup';
import { renderMentionsBody } from '../lib/indicators/driverUtil';
import { UserFeature } from '../lib/interfaces/analytics';
import { addLoginListener, init, timeMachine } from '../lib/layout';
import { DashboardTourA } from '../lib/tour/dashboardTour';
import { addTourToHelpMenu, runTour } from '../lib/tour/tourSetup';
import { ifExistsOnClickTrackAnalyticsEvent, trackAnalyticsEvent } from '../lib/util/analytics';
import { getLastLogin } from '../lib/util/browserUtil';
import { attr, create, getByClass, getById, onClick, onDomLoaded, qs, tabShow, tooltip, wHash, onEvent } from '../lib/util/html';
import { ProgressBar } from '../lib/util/progressBar';
import { STORAGE_INFO, fetchCacheKey, setCacheKey } from '../lib/util/storageUtil';
import { HomeBookmarkWidget } from '../lib/widgets/bookmark/homeBookmarkWidget';
import { Spinner } from '../lib/widgets/spinner/spinner';

const TYPE_TO_KEY = {
    event: 'popularEvents',
    indicator: 'popularIndicators',
};
// Selection of the current portfolio (or default / none)
let bookmarkWidget: HomeBookmarkWidget = null;
// When the page is displaying data, the master copy is kept in this reference.
let currentConcepts: IParsedConcept[];
// After the data table is rendered, a select group of pies shows the distribution of base elements.
let currentSelectGroup: SelectGroup;
let allPublishers: IPublisher[] = null;

init(false, [ [ AuthRoles.DriversRead ] ], '/analysis', true);

onDomLoaded(() => {
    // These are a cache of the reference data downloaded from backend (or a second layer cache).
    // Do not directly use or alter these, a pointer to the current one will be set on tab activation
    let recentIndicators: IParsedConcept[];
    let recentEvents: IParsedConcept[];
    // When we toggle a tab, we fetch data (only the first time), then render.
    onEvent(qs('.nav-tabs a[href="#popularIndicatorsRef"]'), 'show.bs.tab', async () => {
        wHash(TYPE_TO_KEY.indicator);
        if (!recentIndicators) {
            const rawConcepts = await getConcepts('indicator');
            if (rawConcepts) {
                recentIndicators = rawConcepts.map(parseAndMapConcepts);
            }
        }
        currentConcepts = recentIndicators;
        await renderTable('indicator');
        // If this is a user's first login, auto-run a tour
        // Do it here, after indicator data has finished loading. This will allow the tour to start the
        //  tour after all the data (donut charts & quotes) has been rendered
        const lastLogin = getLastLogin();
        const tourAlreadyAutoRan = fetchCacheKey<boolean>(STORAGE_INFO.dashboardTourAlreadyAutoRan.scope, STORAGE_INFO.dashboardTourAlreadyAutoRan.key);
        if (!lastLogin && !tourAlreadyAutoRan) {
            setCacheKey(STORAGE_INFO.dashboardTourAlreadyAutoRan.scope, STORAGE_INFO.dashboardTourAlreadyAutoRan.key, true);
            runTour(DashboardTourA, true, true);
        }
    });
    onEvent(qs('.nav-tabs a[href="#popularEventsRef"]'), 'show.bs.tab', async () => {
        wHash(TYPE_TO_KEY.event);
        if (!recentEvents) {
            const rawConcepts = await getConcepts('event');
            if (rawConcepts) {
                recentEvents = rawConcepts.map(parseAndMapConcepts);
            }
        }
        currentConcepts = recentEvents;
        await renderTable('event');
    });

    // Setup the portfolio and trigger code that would show the default tab, or the one from the hash (refresh on events)
    addLoginListener(async (acl) => {
        allPublishers = await userGetSubscribedPublishers();
        servicesPostMetric(UxType.visitDashboard);
        const hash = wHash() || '#popularIndicators';
        tabShow(qs(`.nav-tabs a[href="${hash}Ref"]`));
        bookmarkWidget = new HomeBookmarkWidget(acl, false);
    });
    ifExistsOnClickTrackAnalyticsEvent(getById('indicatorsTabLink'), UserFeature.DASHBOARD_INDICATOR_TAB_CLICK);
    ifExistsOnClickTrackAnalyticsEvent(getById('eventsTabLink'), UserFeature.DASHBOARD_EVENT_TAB_CLICK);
    addTourToHelpMenu(DashboardTourA, true, true);
});

// Responsible for getting the past 24 hours of indicators or events, via API or cache.
async function getConcepts(type: 'event' | 'indicator'): Promise<IConceptSummary[]> {
    const date = timeMachine.nowAsStr();
    const key = TYPE_TO_KEY[type];
    const table = getById<HTMLDivElement>(`${key}Table`);
    const filters = getById<HTMLDivElement>(`${key}Filters`);

    filters.innerHTML = '';
    const spinner = new Spinner(filters, { preventAutoStart: true });
    try {
        // This could take some time. Use a progress bar instead of spinner.
        const progressBar = new ProgressBar(table, { logo: true, fullPage: true, init: { percent: 50, duration: 10 } });
        const downloadData = await getMentionsFromDaas(type, date, progressBar);
        if (downloadData && downloadData.length) {
            return downloadData;
        }
        else {
            spinner.start();
            spinner.dismiss('No data available for this date.');
            table.innerHTML = `<div class="dashboard-empty text-center">Try the <a href="#">time machine</a></div>`;
            onClick(qs('a', table), () => document.dispatchEvent(newEvent('RequestTimeMachine')));
        }
    }
    catch (err) {
        spinner.start();
        spinner.error();
        table.innerHTML = '';
        throw err;
    }
    return null;
}

async function getMentionsFromDaas(type: string, startDate: string, progressBar: ProgressBar) {
    const rawData = (await daasGetMention({
        startDate,
        compression: true,
        type: type + 's',
        limit: 20000,
        onprogress: (p: number) => p === 0 ? progressBar.reset() : progressBar.setProgress(50 + p * 0.5),
        custom: 'dashboard',
    }));

    const data = rawData.map(MentionColToGraphMention).reduce((p, c) => {
        const cKey = c.aggregatedDate + c.conceptKey;
        if (!p[cKey]) {
            p[cKey] = c;
        }
        else {
            for (const key of Object.keys(c)) {
                p[cKey][key] = c[key];
            }
        }
        return p;
    }, {});

    const result: IStatsRow[] = [];
    for (const key of Object.keys(data)) {
        result.push(data[key]);
    }
    return groupFilterAndSortStatsRowsByConceptKey(result);
}

// Run any time the user sets a portfolio and the first time the page runs if there is already one 'active'
function onBookmarkChange(container: HTMLDivElement, type: 'indicator' | 'event') {
    return (collection: IUserBookmarkCollection) => {
        container.hidden = false;
        if (collection && currentSelectGroup) {
            // This feature will only apply filtering to companies right now. It could support industries and
            // locations, but the select group currently treats the selection of multiple columns as an AND
            // which becomes too restrictive.
            const markers = [];
            if (collection.companyGroups) {
                collection.companyGroups.forEach((s) => {
                    markers.push(s.marker);
                });
            }
            if (markers.length) {
                currentSelectGroup.reset();
                const matches = currentSelectGroup.toggleValues(type === 'indicator' ? 'company' : 'fromCompany__toCompany', markers);
                if (matches) {
                    onFilterChange(container)();
                }
                else {
                    currentSelectGroup.hide();
                    container.hidden = true;
                    qs('.dashboard-empty', container.parentElement).innerHTML = `No recent discussions of companies in active portfolio: ${collection.name}`;
                }
            }
            else {
                currentSelectGroup.hide();
                container.hidden = true;
                qs('.dashboard-empty', container.parentElement).innerHTML = `There are no companies yet added to portfolio: ${collection.name}`;
            }
        }
        // Function is triggered with no collection for 'default'
        else if (currentSelectGroup) {
            currentSelectGroup.reset();
        }
    };
}

// When the user changes filters in donuts we have to update the table of top concepts
function onFilterChange(container: HTMLDivElement) {
    return () => {
        // This is a shallow copy of the master data
        const newConcepts: IParsedConcept[] = currentConcepts.map((c) =>
            ({ conceptKey: c.conceptKey, mentions: c.mentions, prettyConcept: c.prettyConcept }));
        const exp = currentSelectGroup.getValueAsRegExp();
        if (exp) {
            for (const concept of newConcepts) {
                concept.mentions = concept.mentions.filter((m) => exp.test(m.baseCode));
            }
        }
        renderTopConceptsList(filterSortAndCopyParsedConcepts(newConcepts, 20), container);
    };
}

// Basic rendering loop
async function renderTable(type: 'indicator' | 'event') {
    if (currentConcepts && currentConcepts.length) {
        const key = TYPE_TO_KEY[type];
        const table = getById<HTMLDivElement>(`${key}Table`);
        const filters = getById<HTMLDivElement>(`${key}Filters`);
        // Organize the currently active data by groups of base codes before sending to the select group
        const filtData = {};
        for (const c of currentConcepts) {
            for (const m of c.mentions) {
                if (!filtData[m.baseCode]) {
                    filtData[m.baseCode] = { parsed: m.parsedCode, count: 0 };
                }
                filtData[m.baseCode].count++;
            }
        }
        currentSelectGroup = new SelectGroup(null, filters, Object.keys(filtData).map((k) => filtData[k]),
            null, onFilterChange(table), type, 'bigpie');
        renderTopConceptsList(filterSortAndCopyParsedConcepts(currentConcepts, 20), table);
        // This both creates the HTML, but also loads the bookmarks and will trigger a callback immediately if one is active
        await bookmarkWidget.render(onBookmarkChange(table, type));
    }
}

// The table of mentions organized by most discussed and cut off at top 40.
function renderTopConceptsList(newConcepts: IParsedConcept[], table: HTMLDivElement) {
    renderMentionsBody(table, newConcepts, true, 20, () => {
        // Allow a concept in the list to be added to any of my portfolios.
        for (const btn of getByClass('addToPortfolioBtn', table)) {
            onClick(btn, () => {
                trackAnalyticsEvent(UserFeature.MENTIONPOPUP_ADDTOPORTFOLIO_BUTTON_CLICK);
                const container = qs('.addToPortfolioItems', btn.closest('td'));
                const concept = attr(btn, 'data-concept');
                container.innerHTML = '';
                for (const coll of bookmarkWidget.getCollections()) {
                    const nextBtn = create('button');
                    nextBtn.classList.add('dropdown-item');
                    nextBtn.innerText = coll.name;
                    container.appendChild(nextBtn);
                    onClick(nextBtn, async () => {
                        await bookmarksPutUserBookmarks({ addElements: [ { id: coll.id, type: 'concept', add: concept } ] });
                    });
                }
            });
            // Wire a tooltip to the carrot for adding to my portfolio.
            tooltip(btn);
        }
    }, undefined, undefined, allPublishers);
}
