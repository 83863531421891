// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.input-auto {
  position: absolute;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  border: 1px solid rgba(250, 250, 250, 0.15);
  background-color: #111;
  color: #e1e1e1;
  z-index: 103;
  width: 100%;
  max-height: 50vh;
  overflow: auto;
  border-radius: 0.25rem;
}
div.input-auto div {
  padding: 0.25rem 1.5rem;
  cursor: pointer;
}
div.input-auto div:focus {
  background-color: #007bff;
}
div.input-auto div:hover:not(.showMore) {
  background-color: #343a40;
}
input.searchBar {
  min-width: 300px !important;
}
`, "",{"version":3,"sources":["webpack://./dist/static/lib/autocomplete/autocomplete.less"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,iBAAA;EACA,oBAAA;EACA,2CAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;AADJ;AAVA;EAcQ,uBAAA;EACA,eAAA;AADR;AAKA;EACI,yBAAA;AAHJ;AAMA;EACI,yBAAA;AAJJ;AAOA;EACI,2BAAA;AALJ","sourcesContent":["@import '../colors.less';\n\ndiv.input-auto {\n    position: absolute;\n    padding: .5rem 0;\n    margin: .125rem 0 0;\n    border: 1px solid rgba(250, 250, 250, .15);\n    background-color: #111;\n    color: #e1e1e1;\n    z-index: 103;\n    width: 100%;\n    max-height: 50vh;\n    overflow: auto;\n    border-radius: .25rem;\n\n    div {\n        padding: .25rem 1.5rem;\n        cursor: pointer;\n    }\n}\n\ndiv.input-auto div:focus {\n    background-color: @blue;\n}\n\ndiv.input-auto div:hover:not(.showMore) {\n    background-color: @darkGrey;\n}\n\ninput.searchBar {\n    min-width: 300px !important;\n    // max-width: 600px;\n    // width: 430px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
