import { ApiError, STATUS_CODES } from '../../../shared/interfaces/api/apiInterfaces';
import { IComment } from '../../../shared/interfaces/comments/comment-i';
import { EventSubject } from '../../../shared/interfaces/event';
import { getClLogger } from '../../../shared/util/clLogger';
import { FeatureFlags } from '../../../shared/util/featureFlag';
import { servicesGetUser } from '../../lib/api/services';
import { isFlagEnabledForView } from '../../lib/featureFlag';
import { getUser } from '../../lib/layout';
import { ADMIN_WAITING, EXIT_MESSAGE, MessageController, NOTIFICATION_INTERVAL, PING_INTERVAL, PING_MESSAGE, USER_WAITING } from '../../lib/message/message';
import { getById, getByClass, hide, show, onClick, getByClassFirst, attr, rAttr, onEvent } from '../../lib/util/html';
import { STORAGE_INFO, fetchCacheKey, removeCacheKey, setCacheKey } from '../../lib/util/storageUtil';
const dmTemplate = require('../../lib/message/adminDmModal.pug');
const usersTemplate = require('../../lib/message/adminUsersListModal.pug');
const pinnedDmTemplate = require('../../lib/message/adminWindowedDmModal.pug');

const clLogger = getClLogger(__filename);

/*
    ------------
    | Glossary |
    ------------

        * This Admin - The admin currently using the Client Chat page
        * User - A single customer using the Causality Link product
        * Session - The time this admin has spent on the Client Chat page
        * Conversation/DM - A chat between/chatroom of a user and this admin
        * MC - Message Controller, used to establish websocket communication between this admin and a user
        * Waiting Room - Websocket that listens for any online users
        * Active - A conversation with a message history
        * Chatroom Window - A div that displays a conversation
        * Online/Green Dot (shape) - Denotes a user who is online and ping responsive within the last minute
        * Offline/Grey Dot (shape) - Denotes a user who was online and is ping non-responsive within the last minute

    ---------
    | Notes |
    ---------

        * Before, an admin had to be on the Client Chat page for the DM button to appear to users. Now an admin can enable site-wide chatting.
        * Each chatroom window is initialized for this admin once a user loads any page
        * Chatroom windows are hidden from view until a user sends a message or this admin clicks on a user in the 'Online Users' section
        * Session history is tied to local storage

    -------------------
    | Bugs/"Features" |
    -------------------

        * B: Occasionally, a DM's tab doesn't recognize it is being clicked.
            Clicking on its corresponding userBtn instead of the tab or ultimately refreshing the page fixes it.

        * F: Currently, you will receive notifications for all open DMs, even for the currently selected one.
            If you have one open and receive a new message you might not notice. Could tweak later if too annoying.

*/

// Declare variables
// Holds all DM conversations
const dmContainer: HTMLDivElement = getById('dmList');
// Holds all users that have been online for this session
const onlineUsersContainer: HTMLDivElement = getById('activeUsersList');
// Tracks which DMs were manually closed
const wasClosed: { [ tabId: string ]: boolean } = {};
// Different templates and wiring are used for the Client Chat page vs. other pages
let clientChatPage: boolean = true;
let logoutBtn: HTMLButtonElement = null;

// Navbar DM notifications (visible outside of Client Chat page)
let dmNotifs = null;
// Top of windowed DM outside of Client Chat page, displays the name of the user whose conversation is visible
let chatHeader = null;

// Collapsible sidebar
let dmListOpen = null;
let dmListClose = null;
let dmListDiv = null;

// ID of this admin, used to initiate a waiting room
let adminId: string;
let userWaitingRoom: MessageController;
let adminWaitingRoom: MessageController;

const activeConversations: { user: string, id: string, name: string }[] = [];
const activeMCs: MessageController[] = [];

// All users, conversations, and MCs
let conversations: { user: string, id: string, name: string }[] = [];
let otherConversations: { user: string, id: string, name: string }[] = [];
const mcs: MessageController[] = [];

// Used to set up this admin's waiting room, used in layout.ts
export async function setupAdminDMs(id: string, onClientPage: boolean) {
    // Check if chatting feature is enabled
    const enabled = await isFlagEnabledForView(FeatureFlags.enableChat);
    if (enabled) {
        if (fetchCacheKey<{ user: string, id: string, name: string }[]>(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key)) {
            const oldCache = fetchCacheKey<{ user: string, id: string, name: string }[]>(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
            if (oldCache.length && (typeof (oldCache[0]) === 'string' || !oldCache[0].hasOwnProperty('name'))) {
                setCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key, conversations, STORAGE_INFO.conversations.cacheMaxTime);
            }
        }
        adminId = id;
        clientChatPage = onClientPage;
        // Check for existing history of users & their respective conversations, create if none found
        if (!fetchCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key)) {
            setCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key, conversations, STORAGE_INFO.conversations.cacheMaxTime);
        }
        const storedConversations = fetchCacheKey<{ user: string, id: string, name: string }[]>(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
        const conversationIds = conversations.map(c => c.id);

        for (let i = 0; i < storedConversations.length; i++) {
            const user = await servicesGetUser(storedConversations[i].user);
            // If user exists and has not been deleted
            if (user && !user.deleted && !conversationIds.includes(storedConversations[i].id)) {
                conversations.push(storedConversations[i]);
            }
        }

        // Update the cache to reflect if a user was deleted/no longer exists
        if (storedConversations.length !== conversations.length) {
            setCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key, conversations, STORAGE_INFO.conversations.cacheMaxTime);
        }

        let msgHistory: IComment[] = [];
        // Set up pre-existing conversations
        for (let i = 0; i < conversations.length; i++) {
            const convoId = conversations[i].id;
            // If the user is offline, change their icon to grey and disable messaging windows
            if (fetchCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${convoId}`)) {
                msgHistory = fetchCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${convoId}`);
            }
            const mc = new MessageController(adminId, msgHistory, convoId);
            mcs.push(mc);
            if (msgHistory.length) {
                if (!activeConversations.includes(conversations[i]) && !activeMCs.includes(mc)) {
                    activeConversations.push(conversations[i]);
                    activeMCs.push(mc);
                }
            }
        }

        // Once all conversations are passed off messaging will be disabled, related local storage wiped, and upon page refresh DMs will be clear
        logoutBtn = getById('logoutMenuLink');
        rAttr(logoutBtn, 'href');

        if (logoutBtn) {
            onClick(logoutBtn, async () => {
                // Clear local storage of conversations on logout
                await clearConversations();
                window.location.replace('/logout');
            });
        }

        if (!onClientPage) {
            // Wire navbar chat icon & notifications
            const chatbox = getById<HTMLDivElement>('adminDM');
            const closeDM = getById<HTMLDivElement>('adminDM-close');
            dmNotifs = getById('adminDmNotifs');
            chatHeader = getByClassFirst('chat-title', chatbox);
            hide(chatbox);
            hide(dmNotifs);
            let chatHidden = true;
            onClick(closeDM, () => {
                hide(chatbox);
                chatHidden = true;
            });
            onClick(getById('adminDMs'), () => {
                if (chatHidden) {
                    show(chatbox);
                    chatHidden = false;
                }
                else {
                    hide(chatbox);
                    chatHidden = true;
                }
            });
            dragElement(chatbox);

            const totalNotifCount = fetchCacheKey<number>(STORAGE_INFO.dashNotificationCount.scope, `${adminId}${STORAGE_INFO.dashNotificationCount.key}`);
            if (totalNotifCount > 0) {
                dmNotifs.innerText = totalNotifCount.toString();
                show(dmNotifs);
            }

            // Wire collapsible sidebar of online users
            dmListOpen = getByClassFirst('dm-list-open');
            dmListClose = getByClassFirst('dm-list-close');
            dmListDiv = getByClassFirst('dm-list');

            onClick(dmListClose, () => {
                hide(dmListClose, dmListDiv);
                show(dmListOpen);
            });
            onClick(dmListOpen, () => {
                hide(dmListOpen);
                show(dmListClose, dmListDiv);
            });

            hide(dmListClose, dmListDiv);
        }

        await updateWindows();

        try {
            await setupMCs();
        }
        catch (err) {
            if (err instanceof ApiError && err.statusCode === STATUS_CODES.OBJECT_NOT_FOUND) {
                setContainerContents('There was an error loading client DMs.', dmContainer);
                setContainerContents('', onlineUsersContainer);
            }
            else {
                clLogger.debug('ERROR! Type of error was unknown...');
                throw err;
            }
        }
    }
}

// Templatize all conversations
function getContents(): string {
    if (fetchCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key)) {
        conversations = fetchCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
    }
    return clientChatPage ? dmTemplate({ conversations }) : pinnedDmTemplate({ conversations });
}

function setContainerContents(contents: string, container: HTMLDivElement) {
    container.innerHTML = contents;
}

async function setupMCs() {
    let consolidatedChat = false;
    adminWaitingRoom = new MessageController(adminId);
    adminWaitingRoom.setId(ADMIN_WAITING);
    await adminWaitingRoom.initWaitingRoom(true);
    const adminWaitingSocket = adminWaitingRoom.getWaitingSocket();
    const idMessage = adminWaitingRoom.createMessage(EventSubject.WaitMessage, adminId, ADMIN_WAITING, 'Waiting for existing conversations.', ADMIN_WAITING, '');
    await adminWaitingSocket.emit(EventSubject.WaitMessage, idMessage);
    adminWaitingSocket.on(EventSubject.WaitMessage, async (_adminWaitingId: string, _adminWaitingOp: EventSubject, adminWaitingMsg: IComment) => {
        if (adminWaitingMsg.objectId === ADMIN_WAITING && adminWaitingMsg.comment === 'Waiting for existing conversations.' && adminWaitingMsg.creator !== adminId) {
            const allMessageHistories: { [id: string]: IComment[]; } = {};
            for (const mc of mcs) {
                allMessageHistories[mc.mc_id] = mc.messageHistory;
            }
            // If an admin joins the admin waiting room
            const allConversationsMessage = adminWaitingRoom.createMessage(EventSubject.WaitMessage, adminId, ADMIN_WAITING,
                JSON.stringify(conversations), ADMIN_WAITING, 'allConversations', JSON.stringify(allMessageHistories));
            await adminWaitingSocket.emit(EventSubject.WaitMessage, allConversationsMessage);
            clLogger.debug('ADMIN JOINED ADMIN WAITING ROOM');
        }
        else if (adminWaitingMsg.objectId === ADMIN_WAITING && adminWaitingMsg.last === 'allConversations' && adminWaitingMsg.creator !== adminId) {
            // TODO: Triggering twice for some reason
            otherConversations = JSON.parse(adminWaitingMsg.comment);
            const otherMessageHistories: { [id: string]: IComment[]; } = JSON.parse(adminWaitingMsg.msgHistory);
            // Check this admin's conversation ids and users to ensure no duplicates are added
            const users = conversations.map(c => c.user);
            const conversationIds = conversations.map(c => c.id);
            for (const conversation of otherConversations) {
                const userId = conversation.user;
                const chatId = conversation.id;
                let changed = false;
                // Check if conversation has been consolidated
                if (users.includes(userId) && !conversationIds.includes(chatId)) {
                    const index = users.indexOf(userId);
                    conversations[index] = conversation;
                    changed = true;
                }
                else if (!users.includes(userId) && !conversationIds.includes(chatId)) {
                    // If conversation is not already in the list of conversations for this admin, add it
                    conversations.push(conversation);
                    changed = true;
                }
                if (changed) {
                    let mc: MessageController = new MessageController(adminId);
                    mc.setId(chatId);
                    ({ mc, consolidatedChat } = checkMC(chatId, userId, consolidatedChat, mc));
                    mc.messageHistory = otherMessageHistories[chatId];
                    // Update conversations storage
                    setCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${chatId}`, mc.messageHistory, STORAGE_INFO.conversation.cacheMaxTime);
                    setCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key, conversations, STORAGE_INFO.conversations.cacheMaxTime);
                    await checkMessageHistory(chatId, userId, mc, consolidatedChat, conversation);
                }
            }
            clLogger.debug('ALL CONVERSATIONS FROM ADMIN ', adminWaitingMsg.creator, ' ARE: ', adminWaitingMsg.comment);
            clLogger.debug('ALL CONVERSATIONS ARE NOW: ', conversations);
        }
    });

    userWaitingRoom = new MessageController(adminId);
    await userWaitingRoom.initWaitingRoom();
    const userWaitingSocket = userWaitingRoom.getWaitingSocket();
    userWaitingSocket.on(EventSubject.WaitMessage, async (id: string, _op: EventSubject, msg: IComment) => {
        const conversation = { user: msg.creator, id: msg.objectId, name: msg.last };
        // Listen for a user seeking a chatroom and let them know a room is available
        if (id === USER_WAITING && msg.comment === 'Ready to join private room.') {
            await userWaitingSocket.emit(EventSubject.WaitMessage, userWaitingRoom.createMessage(EventSubject.WaitMessage, adminId, msg.objectId,
                'Join your private room.', USER_WAITING, getUser().name));
            const chatId = msg.objectId;
            const userId = msg.creator;
            let mc: MessageController = new MessageController(adminId);
            mc.setId(chatId);
            ({ mc, consolidatedChat } = checkMC(chatId, userId, consolidatedChat, mc));

            conversations.push(conversation);

            await checkMessageHistory(chatId, userId, mc, consolidatedChat, conversation);
        }
    });
}

async function checkMessageHistory(chatId: string, userId: string, mc: any, consolidatedChat: boolean, conversation: { user: string; id: string; name: string; }) {
    let msgHistory: IComment[] = [];
    // Check for an existing conversation history, create if none
    if (!fetchCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${chatId}`)) {
        setCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${chatId}`, msgHistory, STORAGE_INFO.conversation.cacheMaxTime);
    }
    else {
        msgHistory = fetchCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${chatId}`);
    }

    const activeUsers = activeConversations.map(c => c.user);
    const activeConversationIds = activeConversations.map(c => c.id);

    if (activeUsers.includes(userId) && !activeConversationIds.includes(chatId) && activeMCs.includes(mc) && consolidatedChat) {
        // If this is an existing conversation with a new conversation ID (in case a user clears their local storage during this session)
        const index = activeUsers.indexOf(userId);
        activeConversations[index] = conversation;
    }
    else {
        if (!activeUsers.includes(userId) && msgHistory.length) {
            // If this is a brand-new conversation
            activeConversations.push(conversation);
            activeMCs.push(mc);
        }
    }
    await updateWindows(userId);
}

function checkMC(chatId: string, userId: string, consolidatedChat: boolean, mc: MessageController) {
    let match: MessageController = null;
    // Check if an MC for this conversation already exists to avoid adding duplicates
    if (mcs.length) {
        mcs.find(element => {
            if (element.mc_id === chatId) {
                match = element;
            }
            else if (element.mc_id.endsWith(userId)) {
                // Check if an old conversation exists and update its ID
                consolidatedChat = true;
                const index = mcs.indexOf(element);
                element.setId(chatId);
                mcs[index] = element;
                match = element;
            }
            return match;
        });
    }
    if (match != null) {
        mc = match;
    }
    else {
        mcs.push(mc);
    }
    return { mc, consolidatedChat };
}

async function clearConversations() {
    conversations = fetchCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
    // Alert all users in a conversation with this admin that this admin is no longer available
    if (conversations) {
        const allMessageHistories: { [id: string]: IComment[]; } = {};
        for (const mc of mcs) {
            allMessageHistories[mc.mc_id] = mc.messageHistory;
            const socket = mc.getSocket();
            // TODO: Send exit message for users as well?
            const exitMessage = mc.createMessage(EventSubject.Message, adminId, mc.mc_id, `${adminId} is currently unavailable.`, EXIT_MESSAGE, getUser().name);
            if (socket) {
                // Dispatch exit message, then disconnect from conversation
                await socket.emit(EventSubject.Message, exitMessage);
                // Disable typespace, sendBtn and grey-out chat window
                const chat = getById(mc.mc_id);
                attr(mc.typespace, 'readonly', 'true');
                mc.sendBtn.classList.add('disabled');
                chat.classList.add('grey-out');
            }

            await mc.killSubscription(mc.mc_id);

            // Clear out caches related to this conversation from local storage
            removeCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${mc.mc_id}`);
            removeCacheKey(STORAGE_INFO.dashNotificationCount.scope, `${mc.mc_id}${STORAGE_INFO.dashNotificationCount.key}`);
        }
    }
    // Clear out caches for all conversations, notifications, and related settings from local storage. Then leave admin waiting room.
    removeCacheKey(STORAGE_INFO.offPageDMs.scope, STORAGE_INFO.offPageDMs.key);
    removeCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
    removeCacheKey(STORAGE_INFO.dashNotificationCount.scope, `${adminId}${STORAGE_INFO.dashNotificationCount.key}`);
}

async function updateWindows(userId?: string) {
    setContainerContents(getContents(), dmContainer);
    let userStatus = null;
    if (userId) {
        userStatus = {};
        for (const conversation of conversations) {
            const statusIcon = getById(`${conversation.id}-status`);
            if (statusIcon) {
                userStatus[conversation.user] = statusIcon.style.color;
            }
        }
        userStatus[userId] = 'green';
    }
    setContainerContents(usersTemplate({
        userStatus,
        conversations,
        onClientPage: clientChatPage,
    }), onlineUsersContainer);
    await setupWindows();
    if (!clientChatPage) {
        manageWindowedConversations();
    }
    else {
        manageTabbedConversations();
    }
}

const intervals = {};

// Set up chatroom windows for all active conversations
async function setupWindows() {
    const windows = getByClass<HTMLElement>('messages-admin', dmContainer);
    const sendBtns = getByClass<HTMLButtonElement>('chat-btn-send', dmContainer);
    const typespaces = getByClass<HTMLInputElement>('chat-box-typespace', dmContainer);

    for (let i = 0; i < conversations.length; i++) {
        const mc = mcs[i];
        const chatId = conversations[i].id;
        let chatWindow: HTMLElement = null;
        let sendBtn: HTMLButtonElement = null;
        let typespace: HTMLInputElement = null;

        // Find corresponding window, sendBtn, and typespace for this active conversation
        for (let j = 0; j < conversations.length; j++) {
            if (windows[j].id === `${chatId}-chat` && sendBtns[j].id === `${chatId}-sendBtn` && typespaces[j].id === `${chatId}-textbox`) {
                chatWindow = windows[j];
                sendBtn = sendBtns[j];
                typespace = typespaces[j];
                onEvent(typespace, 'mousedown', (e) => {
                    e.stopPropagation();
                }, false);
                await mc.initMessages(chatId, adminId, chatWindow, sendBtn, typespace);
                chatWindow.innerHTML += `<div class='text-center' style='color: #3e444a;'>Messages are locally stored up to a week or cleared on logout.</div>`;
                keepAlivePing(mc, conversations[j].user, true);
                // Every 5 seconds send a ping to check the online/offline status of the user
                if (intervals[conversations[j].user]) {
                    clearInterval(intervals[conversations[j].user]);
                }
                intervals[conversations[j].user] = setInterval(() => {
                    keepAlivePing(mc, conversations[j].user, false);
                }, PING_INTERVAL);

                // Set up notifications
                const notif = getById(`${chatId}-notif`);
                updateNotifications(chatId, notif);
                // Periodically sync notifications in case multiple tabs are open
                setInterval(() => {
                    updateNotifications(chatId, notif);
                }, NOTIFICATION_INTERVAL);

                onClick(chatWindow, () => {
                    removeNotifications(chatId, notif);
                });

                onClick(typespace, () => {
                    removeNotifications(chatId, notif);
                });

                break;
            }
        }
    }
}

function keepAlivePing(mc: MessageController, target: string, initial: boolean) {
    const pingMessage = mc.createMessage(EventSubject.Message, adminId, mc.mc_id, '', PING_MESSAGE, target);
    mc.getSocket().emit(EventSubject.Message, pingMessage);
    setTimeout(() => {
        const statusIcon = getById(`${mc.mc_id}-status`);
        const timestamp = +statusIcon.getAttribute('timestamp');
        const chat = getById(mc.mc_id);
        if (!isNaN(timestamp) && ((new Date().getTime() - timestamp) > PING_INTERVAL) && !initial) {
            // TODO: 'initial' messes up when you initially load the page and a user is actually offline
            // If user is offline, disable the conversation
            statusIcon.style.color = 'grey';
            attr(mc.typespace, 'readonly', 'true');
            mc.sendBtn.classList.add('disabled');
            chat.classList.add('grey-out');
        }
        else {
            // Make sure admin typespace and sendBtn are enabled when user online
            rAttr(mc.typespace, 'readonly');
            mc.sendBtn.classList.remove('disabled');
            chat.classList.remove('grey-out');
        }
    }, 2000);
}

function updateNotifications(chatId: string, notif: HTMLElement) {
    let notificationCount = 0;
    const nc = fetchCacheKey<number>(STORAGE_INFO.dashNotificationCount.scope, `${chatId}${STORAGE_INFO.dashNotificationCount.key}`);
    if (nc !== null) {
        notificationCount = nc;
    }
    else {
        setCacheKey(STORAGE_INFO.dashNotificationCount.scope, `${chatId}${STORAGE_INFO.dashNotificationCount.key}`, notificationCount,
            STORAGE_INFO.dashNotificationCount.cacheMaxTime);
    }
    if (notificationCount > 0) {
        show(notif);
        notif.innerText = notificationCount.toString();
        show(dmNotifs);
    }
    else {
        hide(notif);
        const totalNotifCount = fetchCacheKey<number>(STORAGE_INFO.dashNotificationCount.scope, `${adminId}${STORAGE_INFO.dashNotificationCount.key}`);
        if (totalNotifCount === 0) {
            hide(dmNotifs);
        }
    }
}

function removeNotifications(chatId: string, notif: HTMLElement) {
    let notificationCount = fetchCacheKey<number>(STORAGE_INFO.dashNotificationCount.scope, `${chatId}${STORAGE_INFO.dashNotificationCount.key}`);
    if (notificationCount > 0) {
        // If not on Client Chat page, subtract from grand total first
        if (chatId !== adminId) {
            let totalNotifCount = fetchCacheKey<number>(STORAGE_INFO.dashNotificationCount.scope, `${adminId}${STORAGE_INFO.dashNotificationCount.key}`);
            if (totalNotifCount >= notificationCount) {
                totalNotifCount -= notificationCount;
                setCacheKey(STORAGE_INFO.dashNotificationCount.scope, `${adminId}${STORAGE_INFO.dashNotificationCount.key}`, totalNotifCount,
                    STORAGE_INFO.dashNotificationCount.cacheMaxTime);
                if (!clientChatPage) {
                    dmNotifs.innerText = totalNotifCount.toString();
                    if (totalNotifCount === 0) {
                        hide(dmNotifs);
                    }
                }
            }
            else {
                clLogger.debug('ERROR W/NOTIF COUNT. TOTAL NOTIFS: ', totalNotifCount, 'NEW NOTIFS: ', notificationCount);
            }
        }
        notificationCount = 0;
        setCacheKey(STORAGE_INFO.dashNotificationCount.scope, `${chatId}${STORAGE_INFO.dashNotificationCount.key}`, notificationCount,
            STORAGE_INFO.dashNotificationCount.cacheMaxTime);
        notif.innerText = '';
        hide(notif);
    }
}

// Wire all conversation windows outside the Client Chat page
function manageWindowedConversations() {
    const allChatWindows = getByClass<HTMLElement>('chat-box-admin-inner', dmContainer);
    const allUserBtns = getByClass<HTMLButtonElement>('online-user', onlineUsersContainer);

    let activeChatWindow: HTMLElement = null;
    const hiddenChatWindows: HTMLElement[] = [];
    // If there is more than one active conversation
    if (conversations.length) {
        // Populate an array of all visible tabs
        const activeConversationIds = activeConversations.map(c => c.id);
        for (let i = 0; i < conversations.length; i++) {
            const { matchPane, matchUserBtn } = findHtmlMatches(allChatWindows, allUserBtns, conversations, i);
            if (matchPane !== null && matchUserBtn !== null) {
                const notif = getById(`${conversations[i].id}-notif`);
                // Only display a default window if an active conversation that has not been closed exists, or if no active conversations exists
                if (((activeConversationIds.includes(conversations[i].id)) || (!activeConversations.length)) && !activeChatWindow) {
                    activeChatWindow = matchPane;
                    chatHeader.innerText = 'Chatting with: ' + matchUserBtn.innerText;
                    show(activeChatWindow);
                    activeChatWindow.classList.add('active');
                }
                else {
                    if (!activeConversationIds.includes(conversations[i].id)) {
                        hide(notif);
                    }
                    hiddenChatWindows.push(matchPane);
                    hide(matchPane);
                }

                onClick(matchUserBtn, async () => {
                    // Hide the currently displayed chatroom window, if any
                    allChatWindows.find(tabPane => {
                        if (tabPane.classList.contains('active')) {
                            tabPane.classList.remove('active');
                            hide(tabPane);
                            return true;
                        }
                        return false;
                    });

                    // Remove notification indicator
                    removeNotifications(matchPane.id, notif);

                    // Reveal the corresponding chatroom window
                    show(matchPane);
                    chatHeader.innerText = 'Chatting with: ' + matchUserBtn.innerText;
                    matchPane.classList.add('active');
                });
            }
        }
    }
}

// Manage all conversation tabs on the Client Chat page
function manageTabbedConversations() {
    const allTabs = getByClass<HTMLElement>('nav-link', dmContainer);
    const allTabPanes = getByClass<HTMLElement>('tab-pane', dmContainer);
    // Buttons in the Online Users div represented by a user's username
    const allUserBtns = getByClass<HTMLButtonElement>('online-user', onlineUsersContainer);
    // Close buttons in the user tabs
    const allCloseBtns = getByClass<HTMLButtonElement>('close-dm', dmContainer);

    let activeTab: HTMLElement = null;
    let activeTabPane: HTMLElement = null;
    const hiddenTabPanes: HTMLElement[] = [];

    // If there is more than one active conversation
    if (conversations.length) {
        const activeConversationIds = activeConversations.map(c => c.id);
        // Populate an array of all visible tabs
        for (let i = 0; i < conversations.length; i++) {
            const { matchPane, matchUserBtn, matchTab, matchCloseBtn, closed } = findHtmlMatches(allTabPanes, allUserBtns, conversations, i, allTabs, allCloseBtns);
            if (matchTab !== null && matchPane !== null && matchUserBtn !== null && matchCloseBtn !== null) {
                const notif = getById(`${conversations[i].id}-notif`);
                // Only display a default window if an active conversation that has not been closed exists, or if no active conversations exists
                if (((activeConversationIds.includes(conversations[i].id)) ||
                (!activeConversations.length)) && !activeTab && !activeTabPane && !closed) {
                    activeTab = matchTab;
                    activeTabPane = matchPane;
                    show(activeTabPane);
                    // Kept add active for the tab so that the css would behave
                    activeTab.classList.add('active');
                }
                else {
                    hiddenTabPanes.push(matchPane);
                    hide(matchPane);
                    // Hide empty or previously-closed conversation tabs by default
                    if (!activeConversationIds.includes(conversations[i].id) || closed) {
                        // Only hide notifications of empty conversations
                        if (!closed) {
                            hide(notif);
                        }
                        hide(matchTab);
                    }
                }

                onClick(matchUserBtn, async () => {
                    // Deselect the current tab, if any
                    allTabs.find(tab => {
                        if (tab.classList.contains('active')) {
                            tab.classList.remove('active');
                            // Hide the current chatroom window, if any
                            allTabPanes.find(tabPane => {
                                if (tabPane.id === `${tab.id}-tab-pane`) {
                                    hide(tabPane);
                                    return true;
                                }
                                return false;
                            });
                            return true;
                        }
                        return false;
                    });

                    // Remove notification indicator
                    removeNotifications(matchTab.id, notif);

                    // Reveal the corresponding chatroom window
                    show(matchTab);
                    show(matchPane);
                    matchTab.classList.add('active');
                    // If the tab was manually closed before
                    matchTab.classList.remove('closed');
                    wasClosed[conversations[i].id] = false;
                });

                onClick(matchCloseBtn, (event) => {
                    let success = false;
                    // If active tab, remove
                    matchTab.classList.remove('active');
                    matchTab.classList.add('closed');
                    wasClosed[conversations[i].id] = true;
                    hide(matchTab);
                    hide(matchPane);
                    allTabs.find(tab => {
                        // Check if any open chatroom tabs
                        if (!tab.classList.contains('closed')) {
                            tab.classList.add('active');
                            allTabPanes.find(tabPane => {
                                // Check for the corresponding tab pane
                                if (tabPane.id === `${tab.id}-tab-pane`) {
                                    show(tabPane);
                                    success = true;
                                    return success;
                                }
                                return success;
                            });
                            return success;
                        }
                        return success;
                    });
                    // To prevent the wiring for clicking within the tab to trigger, since the close button is located inside the tab
                    event.stopPropagation();
                });
            }
        }

        // Wire tabs for active conversations
        allTabs.forEach(tab => {
            // Wiring for all chatroom window tabs
            onClick(tab, () => {
                let tabPaneToActivate = null;
                let tabPaneToHide;
                hiddenTabPanes.find(tabPane => {
                    if (tabPane.id === `${tab.id}-tab-pane`) {
                        tabPaneToActivate = tabPane;
                        // Remove notification indicator
                        const notif = getById(`${tab.id}-notif`);
                        removeNotifications(tab.id, notif);
                    }
                    return tabPaneToActivate;
                });
                // Only activate if already hidden, else: do nothing
                if (tabPaneToActivate !== null) {
                    const index = hiddenTabPanes.indexOf(tabPaneToActivate);
                    tabPaneToHide = activeTabPane;
                    hide(tabPaneToHide);
                    activeTabPane = tabPaneToActivate;
                    show(activeTabPane);
                    hiddenTabPanes[index] = tabPaneToHide;

                    activeTab.classList.remove('active');
                    tab.classList.add('active');
                    activeTab = tab;
                }
            });
        });
    }
}

// For locating a conversation's corresponding HTML elements
function findHtmlMatches(allPanes: HTMLElement[], allUserBtns: HTMLButtonElement[],
                         allConversations: { user: string, id: string, name: string }[], i: number, allTabs?: HTMLElement[],
                         allCloseBtns?: HTMLButtonElement[]) {
    let matchPane: HTMLElement = null;
    let matchUserBtn: HTMLButtonElement = null;
    let matchTab: HTMLElement = null;
    let matchCloseBtn: HTMLButtonElement = null;
    let closed: boolean = null;
    const chatId = allConversations[i].id;

    if (allPanes.length) {
        allPanes.find(pane => {
            if ((pane.id === `${chatId}-tab-pane` && clientChatPage) || (pane.id === chatId && !clientChatPage)) {
                allUserBtns.find(userBtn => {
                    if (userBtn.id === `${chatId}-user` && clientChatPage) {
                        allTabs.find(tab => {
                            if (tab.id === chatId) {
                                allCloseBtns.find(closeBtn => {
                                    if (closeBtn.id === `${chatId}-close`) {
                                        matchCloseBtn = closeBtn;
                                    }
                                    return matchCloseBtn;
                                });
                                matchTab = tab;
                            }
                            return matchTab;
                        });
                        matchUserBtn = userBtn;
                    }
                    else if (userBtn.id === `${chatId}-user` && !clientChatPage) {
                        // Only need the userBtn and tabPane matches if not on the Client Chat page
                        matchUserBtn = userBtn;
                    }
                    return matchUserBtn;
                });
                matchPane = pane;
                if (clientChatPage && wasClosed[chatId]) {
                    closed = wasClosed[chatId];
                    matchTab.classList.add('closed');
                }
            }
            return matchPane;
        });
    }
    return { matchPane, matchUserBtn, matchTab, matchCloseBtn, closed };
}

function dragElement(element: HTMLElement) {
    let pos1 = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;
    element.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
        e.preventDefault();
        // Get initial mouse cursor position
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // Call the drag function whenever the mouse moves
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e.preventDefault();
        // Calculate the new position of the cursor
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // Set the element's new position
        element.style.top = (element.offsetTop - pos2) + 'px';
        element.style.left = (element.offsetLeft - pos1) + 'px';
    }

    function closeDragElement() {
        // Stop moving when the mouse button is released
        document.onmouseup = null;
        document.onmousemove = null;
    }
}
