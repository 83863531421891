// Format:
//  PAGE_CONTEXT+CONTROL_ACTION
// Where:
//  PAGE examples are Header, Dashboard, Signal, KPI Navigator
//  CONTEXT examples are Search, Help, Portfolio, Indicator
//  CONTROL examples are Menu, Button, Tab, Link, Input (sometimes context is needed: clearbutton instead of button)
//  ACTION examples are Click, Select, Enter, Submit, Open (Special case for linking somewhere important)
export enum UserFeature {
    // ERRORS
    ERROR_REQUEST_TOOK_TOO_LONG = 'ERROR_REQUEST_TOOK_TOO_LONG',
    // HEADER
    HEADER_SEARCH_INPUT_SUBMIT = 'HEADER_SEARCH_INPUT_SUBMIT',
    HEADER_SEARCH_CLEARBUTTON_CLICK = 'HEADER_SEARCH_CLEARBUTTON_CLICK',
    HEADER_HELP_MENU_CLICK = 'HEADER_HELP_MENU_CLICK',
    HEADER_USERNAME_MENU_CLICK = 'HEADER_USERNAME_MENU_CLICK',
    HEADER_OTHER_MENU_CLICK = 'HEADER_OTHER_MENU_CLICK',
    HEADER_ADMIN_MENU_CLICK = 'HEADER_ADMIN_MENU_CLICK',
    HEADER_ANALYSIS_MENU_CLICK = 'HEADER_ANALYSIS_MENU_CLICK',
    HEADER_PORTFOLIO_MENU_CLICK = 'HEADER_PORTFOLIO_MENU_CLICK',
    HEADER_KNOWLEDGE_MENU_CLICK = 'HEADER_KNOWLEDGE_MENU_CLICK',
    HEADER_CAUSALITYLINK_MENU_CLICK = 'HEADER_CAUSALITYLINK_MENU_CLICK',
    HEADER_FAVORITES_MENU_CLICK = 'HEADER_FAVORITES_MENU_CLICK',
    HEADER_TIMEMACHINE_MENU_CLICK = 'HEADER_TIMEMACHINE_MENU_CLICK',
    HEADER_STEMMING_MENU_CLICK = 'HEADER_STEMMING_MENU_CLICK',
    HEADER_MONITOR_MENU_CLICK = 'HEADER_MONITOR_MENU_CLICK',
    HEADER_MYPROFILE_MENU_CLICK = 'HEADER_MYPROFILE_MENU_CLICK',
    HEADER_LOGOUT_MENU_CLICK = 'HEADER_LOGOUT_MENU_CLICK',
    HEADER_ADDARTICLE_MENU_CLICK = 'HEADER_ADDARTICLE_MENU_CLICK',
    HEADER_MANAGEUSERS_MENU_CLICK = 'HEADER_MANAGEUSERS_MENU_CLICK',
    HEADER_KPINAVIGATOR_MENU_CLICK = 'HEADER_KPINAVIGATOR_MENU_CLICK',
    HEADER_LEARNINGDEMO_MENU_CLICK = 'HEADER_LEARNINGDEMO_MENU_CLICK',
    HEADER_NOVELTYREPORT_MENU_CLICK = 'HEADER_NOVELTYREPORT_MENU_CLICK',
    HEADER_MANAGEVERSIONS_MENU_CLICK = 'HEADER_MANAGEVERSIONS_MENU_CLICK',
    HEADER_MANAGECLIENTS_MENU_CLICK = 'HEADER_MANAGECLIENTS_MENU_CLICK',
    HEADER_MANAGEPUBLISHERS_MENU_CLICK = 'HEADER_MANAGEPUBLISHERS_MENU_CLICK',
    HEADER_SYSTEMTESTS_MENU_CLICK = 'HEADER_SYSTEMTESTS_MENU_CLICK',
    HEADER_FEATUREFLAGS_MENU_CLICK = 'HEADER_FEATUREFLAGS_MENU_CLICK',
    HEADER_ATENAQUERIES_CLICK = 'HEADER_ATENAQUERIES_CLICK',
    // FOOTER
    FOOTER_CONTACT_BUTTON_CLICK = 'FOOTER_CONTACT_BUTTON_CLICK',
    FOOTER_DOCUMENTATION_LINK_CLICK = 'FOOTER_DOCUMENTATION_LINK_CLICK',
    FOOTER_TERMS_LINK_CLICK = 'FOOTER_TERMS_LINK_CLICK',
    FOOTER_LOGO_LINK_CLICK = 'FOOTER_LOGO_LINK_CLICK',
    // DASHBOARD
    DASHBOARD_PORTFOLIO_BUTTON_CLICK = 'DASHBOARD_PORTFOLIO_BUTTON_CLICK',
    DASHBOARD_PORTFOLIO_BUTTON_SELECT = 'DASHBOARD_PORTFOLIO_BUTTON_SELECT',
    DASHBOARD_INDICATOR_TAB_CLICK = 'DASHBOARD_INDICATOR_TAB_CLICK',
    DASHBOARD_EVENT_TAB_CLICK = 'DASHBOARD_EVENT_TAB_CLICK',
    MENTIONPOPUP_ADDTOPORTFOLIO_BUTTON_CLICK = 'MENTIONPOPUP_ADDTOPORTFOLIO_BUTTON_CLICK ',
    // SELECTGROUP
    SELECTGROUP_RESET_BUTTON_CLICK = 'SELECTGROUP_RESET_BUTTON_CLICK',
    SELECTGROUP_GRAPHICALFILTER_DONUT_CLICK = 'SELECTGROUP_GRAPHICALFILTER_DONUT_CLICK',
    SELECTGROUP_TEXTFILTER_DROPDOWN_SELECT = 'SELECTGROUP_TEXTFILTER_DROPDOWN_SELECT',
    // KPI NAVIGATOR
    KPINAVIGATOR_SELECT_TAB_CLICK = 'KPINAVIGATOR_SELECT_TAB_CLICK',
    KPINAVIGATOR_EXPLAIN_TAB_CLICK = 'KPINAVIGATOR_EXPLAIN_TAB_CLICK',
    KPINAVIGATOR_TRENDLOAD_BUTTON_CLICK = 'KPINAVIGATOR_TRENDLOAD_BUTTON_CLICK',
    KPINAVIGATOR_TRENDMOREMENTIONS_BUTTON_CLICK = 'KPINAVIGATOR_TRENDMOREMENTIONS_BUTTON_CLICK',
    KPINAVIGATOR_LINKLOAD_BUTTON_CLICK = 'KPINAVIGATOR_LINKLOAD_BUTTON_CLICK',
    KPINAVIGATOR_LINKMOREMENTIONS_BUTTON_CLICK = 'KPINAVIGATOR_LINKMOREMENTIONS_BUTTON_CLICK',
    KPINAVIGATOR_NOTES_BUTTON_CLICK = 'KPINAVIGATOR_NOTES_BUTTON_CLICK',
    KPINAVIGATOR_BOOKMARKS_BUTTON_CLICK = 'KPINAVIGATOR_BOOKMARKS_BUTTON_CLICK',
    KPINAVIGATOR_CHANGETIMEFRAME_BUTTON_CLICK = 'KPINAVIGATOR_CHANGETIMEFRAME_BUTTON_CLICK',
    KPINAVIGATOR_TREE_RESETBUTTON_CLICK = 'KPINAVIGATOR_TREE_RESETBUTTON_CLICK',
    KPINAVIGATOR_SELECTIONFILTER_MULTISELECT_SELECT = 'KPINAVIGATOR_SELECTIONFILTER_MULTISELECT_SELECT',
    // TREEBOOKMARK
    TREEBOOKMARK_BOOKMARKS_BUTTON_OPEN = 'TREEBOOKMARK_BOOKMARKS_BUTTON_OPEN',
    TREEBOOKMARK_BOOKMARKS_BUTTON_SELECT = 'TREEBOOKMARK_BOOKMARKS_BUTTON_SELECT',
    TREEBOOKMARK_BOOKMARKSADD_BUTTON_CLICK = 'TREEBOOKMARK_BOOKMARKSADD_BUTTON_CLICK',
    TREEBOOKMARK_BOOKMARKSREMOVE_BUTTON_CLICK = 'TREEBOOKMARK_BOOKMARKSREMOVE_BUTTON_CLICK',
    // TREE
    TREE_EXPAND_CHECKBOX_CLICK = 'TREE_EXPAND_CHECKBOX_CLICK',
    TREE_SEARCH_INPUT_TYPE = 'TREE_SEARCH_INPUT_TYPE',
    TREE_SEARCH_CLEARBUTTON_CLICK = 'KPINAVIGATOR_TREE_CLEARBUTTON_CLICK',
    // MENTIONPOPUP
    MENTIONPOPUP_EXPORT_BUTTON_CLICK = 'MENTIONPOPUP_EXPORT_BUTTON_CLICK',
    MENTIONPOPUP_LOADMORE_LINK_CLICK = 'MENTIONPOPUP_LOADMORE_LINK_CLICK',
    MENTIONPOPUP_TIMEFRAME_DROPDOWN_SELECT = 'MENTIONPOPUP_TIMEFRAME_DROPDOWN_SELECT',
    MENTIONPOPUP_DIRECTION_DROPDOWN_SELECT = 'MENTIONPOPUP_DIRECTION_DROPDOWN_SELECT',
    MENTIONPOPUP_FILTER_DROPDOWN_SELECT = 'MENTIONPOPUP_FILTER_DROPDOWN_SELECT',
    // VIEW ARTICLE
    VIEWARTICLE_ARTICLE_PAGE_OPEN = 'VIEWARTICLE_ARTICLE_PAGE_OPEN',
}

export enum EVENT_CATEGORY {
    UX = 'ux',
    ENGAGEMENT = 'engagement',
    WINNING_ACTION = 'winning action',
    PROBLEM = 'problem',
}
