function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (announcements, messages) {
      if (announcements.length || messages.length) {
pug_html = pug_html + "\u003Ca class=\"nav-link triggerNotificationModal\" id=\"notifyLink\" href=\"javascript:void(0);\"\u003E\u003Ci class=\"fa fa-bell\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"badge badge-success\"\u003E" + (pug_escape(null == (pug_interp = announcements.length + messages.length) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
    }.call(this, "announcements" in locals_for_with ?
        locals_for_with.announcements :
        typeof announcements !== 'undefined' ? announcements : undefined, "messages" in locals_for_with ?
        locals_for_with.messages :
        typeof messages !== 'undefined' ? messages : undefined));
    ;;return pug_html;};module.exports=template;