import { IMentionMention } from '../interfaces/article/mentions';
import { IParsedEvent, IParsedIndicator, IStat, IStatsDetail, IStatsRow } from '../interfaces/dashboard';
import { IFavorableMarker } from '../interfaces/marker/marker';
import { parseCode, parseToDisplay } from '../streaming/dashboard';
import { aggregatedDateToIsoDate, calculateDaysInMillis } from '../util/dateUtil';

export const GRAPH_RANGE_MONTH_OFFSET = -12;
const TIME_SUFFIX = ' 00:00:00';
export const DELIMITER_ARTICLE = '/';

const DISPLAY_DELIMITER = '-';

function parseMention(mentionStr: string): IMentionMention {
    const DELIMITER_PUBLISHER = '_';
    const PART_PUBLISHER = 0;
    const PART_ARTICLE = 0;
    const PART_META = 1;
    const EMPTY_STRING = '';
    const publisher = mentionStr.split(DELIMITER_PUBLISHER)[PART_PUBLISHER] || EMPTY_STRING;
    const article = mentionStr.split(DELIMITER_ARTICLE)[PART_ARTICLE] || EMPTY_STRING;
    const meta = mentionStr.split(DELIMITER_ARTICLE)[PART_META] || EMPTY_STRING;
    const mention = {
        publisher,
        article,
        meta,
    };
    return mention;
}

function filterFunctionForExistingPublisherArticleMentions(publishers: string[], articles: string[]) {
    return (mentionStr: string) => {
        const mention = parseMention(mentionStr);
        const publisher = mention.publisher;
        const article = mention.article;

        const publisherOkay =
            !publishers ||
            (publishers && publishers.length === 0) ||
            (publishers && publishers.length && publishers.includes(publisher));

        const authorOkay =
            !articles ||
            (articles && articles.length === 0) ||
            (articles && articles.length && articles.includes(article));

        return (publisherOkay && authorOkay);
    };
}

export function getDateWithOffset(date: Date | string, daysOffset: number): string {
    const millisPerDay = calculateDaysInMillis(1);
    const fullDateStr = new Date(new Date(date).getTime() + (daysOffset * millisPerDay)).toISOString();
    return getyyyymmddPrefix(fullDateStr);
}

function getyyyymmddPrefix(dateStr: string) {
    const LENGTH_OF_YYYY_MM_DD = 'yyyy-mm-dd'.length;
    return dateStr.substr(0, LENGTH_OF_YYYY_MM_DD);
}

// Allows us to mark a mention as having occurred on a specific UTC day
export const DATE_TO_DAY_LEN = 'yyyy-mm-dd'.length;
// For plotting we default to grouping indicators to the month
const DATE_TO_MONTH_LEN = 'yyyy-mm'.length;
// Data in Dynamo uses the granularity of the hour to index information
const DATE_TO_HOUR_LEN = 'yyyy-mm-ddThh'.length;
function getStatFromStatsRow(statsRow: IStatsRow, groupLengthOverride?: number): IStat {
    const DATE_GROUP_LEN = groupLengthOverride || DATE_TO_MONTH_LEN;
    const BEGINNING = 0;
    return {
        dateGroup: statsRow.aggregatedDate.substr(BEGINNING, DATE_GROUP_LEN),
        date: statsRow.aggregatedDate.substr(BEGINNING, DATE_TO_DAY_LEN),
        targetKey: statsRow.conceptKey,
        code: statsRow.aggregatedDate.substr(DATE_TO_HOUR_LEN),
        mentions: [],
        tot: 0,
        up: 0,
        down: 0,
        flat: 0,
    };
}

export function sortIndicators(indicators, displayMarkerMentionCounts, baseMarkerMentionCounts) {
    indicators.sort((first, second) => {
        return displayMarkerMentionCounts[second.marker] - displayMarkerMentionCounts[first.marker];
    });
    for (const indicator of indicators) {
        indicator.baseKpis.sort((first, second) => {
            return baseMarkerMentionCounts[second.marker] - baseMarkerMentionCounts[first.marker];
        });
    }
}

export function shouldOffsetBeIncluded(offset, filter) {
    const FILTER_ALL = 'all';
    const FILTER_HISTORICAL = 'historical';
    const FILTER_FORECAST = 'forecast';
    const PRESENT = 0;

    let includeOffset = false;
    if (offset === undefined) {
        includeOffset = false;
    }
    else if (filter === FILTER_ALL) {
        includeOffset = true;
    }
    else if (filter === FILTER_HISTORICAL) {
        includeOffset = offset <= PRESENT;
    }
    else if (filter === FILTER_FORECAST) {
        includeOffset = offset > PRESENT;
    }
    return includeOffset;
}

export function countMentionsByKeyAndTimeframe(data, key: string, filter: 'all' | 'forecast' | 'historical'): number {
    const nonArticleKeyNames = [ 'aggregatedDate', 'conceptKey', 'date' ];
    // Keep a running count of all articles whose offset matches the filter
    const sum = data[key].reduce((keySum, articleInfo) => {
        const articleKeys = Object.keys(articleInfo).filter((keyName) => !nonArticleKeyNames.includes(keyName));
        // Aggregate any sums within a concept key
        const groupSumTotal = articleKeys.reduce((groupSum, articleKey) => {
            const offset = articleInfo[articleKey].o;
            const includeOffset = shouldOffsetBeIncluded(offset, filter);
            return (includeOffset) ? groupSum + 1 : groupSum;
        }, 0);
        return keySum + groupSumTotal;
    }, 0);
    return sum;
}

export function extractMentions(mentionRowsByConcept: { [conceptKey: string]: IStatsRow[] },
                                publishers: string[],
                                articles: string[],
                                groupLengthOverride?: number) {
    const mentionStatsByConcept: { [conceptKey: string]: IStat[] } = {};
    for (const conceptKey of Object.keys(mentionRowsByConcept)) {
        mentionStatsByConcept[conceptKey] = mentionRowsByConcept[conceptKey].map((row: IStatsRow) => {
            // There are many shared properties within a stats row that cross all mentions
            const stat = getStatFromStatsRow(row, groupLengthOverride);

            // Get unique mentions (each key captures the encoded article and location of a mention)
            const filteredMentions = Object.keys(row)
                .filter((key: string) => typeof (row[key]) === 'object')
                .filter(filterFunctionForExistingPublisherArticleMentions(publishers, articles));

            // Go through each mention and track statistics as well as normalizing the resulting rows
            for (const mentionKey of filteredMentions) {
                const detail = row[mentionKey] as IStatsDetail;

                // Each key will aggregate trend statistics
                switch (detail.d) {
                case 1: stat.up++; break;
                case -1: stat.down++; break;
                default: stat.flat++; break;
                }
                stat.tot++;

                //  Add mention metadata
                const code = parseCode(stat.code);
                const prettyIndicator = parseToDisplay(code);
                const targetDate = getDateWithOffset(stat.date, detail.o);
                const direction = detail.d ?? 0;
                const parsedMention = parseMention(mentionKey);
                stat.mentions.push({
                    id: mentionKey,
                    publisher: parsedMention.publisher,
                    articleId: parsedMention.article,
                    metaId: parsedMention.meta,
                    publishDate: aggregatedDateToIsoDate(row.aggregatedDate),
                    targetDate,
                    direction,
                    baseCode: stat.code,
                    parsedCode: code,
                    prettyIndicator,
                });
            }
            return stat;
        });
    }
    return mentionStatsByConcept;
}

export function getBaseAndDisplayMentionCounts(mentionsByDisplay, afterOrOnDate?): { [key: string]: number }[] {
    const displayMentionCounts = {};
    const baseMentionCounts = {};
    for (const conceptKey of Object.keys(mentionsByDisplay)) {
        if (mentionsByDisplay[conceptKey].length) {
            const CORRECTLY_PARSED_DISPLAY_PART_COUNT = 2;
            const DISPLAY_PART_AGGREGATENAME = 1;
            const displayParts = conceptKey.split(DISPLAY_DELIMITER);
            if (displayParts.length === CORRECTLY_PARSED_DISPLAY_PART_COUNT) {
                const aggregateKeyName = displayParts[DISPLAY_PART_AGGREGATENAME];
                displayMentionCounts[aggregateKeyName] = mentionsByDisplay[conceptKey].reduce((acc, curr) => {
                    const mentionDate = new Date(`${curr.date}${TIME_SUFFIX}`);
                    if (!afterOrOnDate || mentionDate >= afterOrOnDate) {
                        return acc + curr.mentions.length;
                    }
                    else {
                        return acc;
                    }
                }, 0);
            }
            for (const m of mentionsByDisplay[conceptKey]) {
                if (m.mentions && m.mentions.length) {
                    const parsedCode = parseCode(m.code);
                    const baseMarker = (parsedCode as IParsedIndicator).baseKPI || (parsedCode as IParsedEvent).baseEvent;
                    if (!baseMentionCounts[baseMarker]) {
                        baseMentionCounts[baseMarker] = 0;
                    }
                    const mentionDate = new Date(`${m.date}${TIME_SUFFIX}`);
                    if (!afterOrOnDate || mentionDate >= afterOrOnDate) {
                        baseMentionCounts[baseMarker] += m.mentions.length;
                    }
                }
            }
        }
    }
    return [ baseMentionCounts, displayMentionCounts ];
}

export function getBaseMarkerInfo(mentionsByDisplayMarker) {
    const mentionsByBaseMarker = {};
    const displayToBaseMap = {};
    for (const k of Object.keys(mentionsByDisplayMarker)) {
        if (mentionsByDisplayMarker[k].length) {
            const baseMarkers = new Set();
            for (const m of mentionsByDisplayMarker[k]) {
                if (m.mentions && m.mentions.length) {
                    const parsedCode = parseCode(m.code);
                    const baseMarker = (parsedCode as IParsedIndicator).baseKPI || (parsedCode as IParsedEvent).baseEvent;
                    if (!mentionsByBaseMarker[baseMarker]) {
                        mentionsByBaseMarker[baseMarker] = [];
                    }
                    mentionsByBaseMarker[baseMarker].push(m);
                    baseMarkers.add(baseMarker);
                }
            }
            // Create map from dkpi to base kpis
            displayToBaseMap[k.split(DISPLAY_DELIMITER)[1]] = [ ...baseMarkers ];
        }
    }
    return [ mentionsByBaseMarker, displayToBaseMap ];
}

export function getDataIndicators(inds: IFavorableMarker[],
                                  dkpiToBkpisMap,
                                  stringPrettifyFunction,
                                  baseMentionCounts,
                                  displayMentionCounts) {
    const SINGLE_ELEMENT_COUNT = 1;
    const EMPTY = 0;
    const favorableRatings = inds.reduce((acc, curr) => {
        acc[curr.marker] = curr.favorable;
        return acc;
    }, {});
    const indicators = Object.keys(dkpiToBkpisMap)
        .map((k) => ({
            marker: k,
            prettyName: stringPrettifyFunction(k, 'Unknown'),
            baseKpis: dkpiToBkpisMap[k]
                .map((e) => {
                    const favorable = favorableRatings[e];
                    return {
                        marker: e,
                        prettyName: stringPrettifyFunction(e, 'Unknown'),
                        favorable,
                    };
                }),
        }))
        .filter((e) => e.baseKpis.length);

    // Filter out base indicators in the rolling period, but not in the graph range
    Object.keys(indicators).forEach((indicatorKey) => {
        indicators[indicatorKey].baseKpis = indicators[indicatorKey].baseKpis.filter((baseKpi) => {
            return baseMentionCounts[baseKpi.marker] !== EMPTY;
        });
    });
    indicators.forEach((indicator, index) => {
        const marker = indicator.marker;
        if (displayMentionCounts[marker] === EMPTY) {
            indicators.splice(index, SINGLE_ELEMENT_COUNT);
        }
    });

    return indicators;
}

export function addFavorablePropertyToMentions(inds, data, mentionsByBaseKPI, ignoreFavorability?) {
    for (const kpi of inds) {
        // Only record base KPIs that are favorable 1 or -1, not 0.
        if (ignoreFavorability || kpi.favorable) {
            if (mentionsByBaseKPI[kpi.marker]) {
                data.raw[kpi.marker] = mentionsByBaseKPI[kpi.marker];
            }
            // Each mention must record favorabilty for trend stats later
            if (mentionsByBaseKPI[kpi.marker]) {
                for (const m of mentionsByBaseKPI[kpi.marker]) {
                    m.favorable = kpi.favorable;
                }
            }
        }
    }
}

export function calculateEventMentionFavorability(eventTypes, groupedMentions) {
    const events = {};
    for (const eventType of eventTypes) {
        const conceptKey = eventType.marker;
        if (groupedMentions[conceptKey] !== undefined) {
            for (const mention of groupedMentions[conceptKey]) {
                const code = parseCode(mention.code);
                if (events[mention.dateGroup] === undefined) {
                    events[mention.dateGroup] = { favorable: 0, neutral: 0, unfavorable: 0, mentions: [] };
                }
                // With events, direction indicates favoribility instead of trend direction
                if (eventType.favorable > 0) {
                    events[mention.dateGroup].favorable += mention.tot;
                    events[mention.dateGroup].mentions.push(...mention.mentions.map((m) => {
                        m.code = code;
                        m.direction = 1;
                        return m;
                    }));
                }
                else if (eventType.favorable < 0) {
                    events[mention.dateGroup].unfavorable += mention.tot;
                    events[mention.dateGroup].mentions.push(...mention.mentions.map((m) => {
                        m.code = code;
                        m.direction = -1;
                        return m;
                    }));
                }
                else {
                    events[mention.dateGroup].neutral += mention.tot;
                    events[mention.dateGroup].mentions.push(...mention.mentions.map((m) => {
                        m.code = code;
                        m.direction = 0;
                        return m;
                    }));
                }
            }
        }
    }
    return events;
}
