import { AuthRoles } from '../../../shared/interfaces/acl/acl-i';
import { IComment } from '../../../shared/interfaces/comments/comment-i';
import { EventOperation, EventSubject } from '../../../shared/interfaces/event';
import { getClLogger } from '../../../shared/util/clLogger';
import { prettyMonthDayHourMinutes } from '../../../shared/util/dateUtil';
import { getAcl, getUser } from '../../lib/layout';
import { servicesGetUser } from '../api/services';
import { addOffEventListener, attr, getById, onClick, show } from '../util/html';
import { STORAGE_INFO, fetchCacheKey, setCacheKey } from '../util/storageUtil';
import { joinRoom, leaveRoom } from '../ws/ioUtil';

// Create object representation for locally stored chatlog (not session because DMs should persist across pages)
const conversationPrefix = 'conversation';
const thisNS = '/static/lib/message/dm';
const thisUserWaitingNS = '/static/lib/message/userWaiting';
const thisAdminWaitingNS = '/static/lib/message/adminWaiting';

// One-minute intervals between pings
export const PING_INTERVAL = 60000;
export const NOTIFICATION_INTERVAL = 10000;
export const PING_MESSAGE = 'ping';
export const ADMIN_WAITING = 'adminWaitingRoom';
export const ADMIN_LEAVING = 'adminLeaving';
export const USER_WAITING = 'waitingRoom';
export const EXIT_MESSAGE = 'exitMessage';

const clLogger = getClLogger(__filename);
export class MessageController {
    public mc_id: string = '';
    public counter = 0;
    public messageHistory: IComment[] = [];
    public currentWindow: HTMLElement;
    public sendBtn: HTMLButtonElement;
    public typespace: HTMLInputElement;
    public user_id = '';

    private socket;
    private waitingSocket;
    private isFirstMessage = true;

    constructor(userId, msgHistory?: IComment[], chatId?: string) {
        this.user_id = userId;
        if (msgHistory && chatId) {
            this.messageHistory = msgHistory;
            this.mc_id = chatId;
            setCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${chatId}`, this.messageHistory, STORAGE_INFO.conversation.cacheMaxTime);
        }
    }

    public setId(chatId) {
        this.mc_id = chatId;
    }

    // Tie the message/announcement DM display to the message controller
    public updateWindow(window : HTMLElement) {
        this.currentWindow = window;
    }

    private getMessages() {
        const mHistory = fetchCacheKey<IComment[]>(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${this.mc_id}`);
        if (mHistory) {
            this.messageHistory = fetchCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${this.mc_id}`);
        }
        else {
            setCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${this.mc_id}`, this.messageHistory, STORAGE_INFO.conversation.cacheMaxTime);
        }
    }

    public setFirstMessage() {
        this.isFirstMessage = false;
    }

    public getFirst() {
        return this.isFirstMessage;
    }

    private addConversation(conversation: { user: string, id: string, name: string }) {
        const allConvos = fetchCacheKey<{ user: string, id: string, name: string }[]>(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
        const allConvoIds = allConvos.map(c => c.id);
        if (!allConvoIds.includes(conversation.id)) {
            allConvos.push(conversation);
            setCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key, allConvos, STORAGE_INFO.conversations.cacheMaxTime);
        }
    }

    private addUserId(userId: string, chatId: string, name: string) {
        const allUsers = fetchCacheKey<string[]>(STORAGE_INFO.users.scope, STORAGE_INFO.users.key);
        if (!allUsers.includes(userId)) {
            allUsers.push(userId);
            setCacheKey(STORAGE_INFO.users.scope, STORAGE_INFO.users.key, allUsers, STORAGE_INFO.users.cacheMaxTime);
        }
        else {
            const allConvos = fetchCacheKey<{ user: string, id: string, name: string }[]>(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key);
            let oldConversation = '';
            const allConvoIds = allConvos.map(c => c.id);
            allConvoIds.find(conversation => {
                if (conversation.endsWith(userId) && conversation !== chatId) {
                    oldConversation = conversation;
                }
                return oldConversation;
            });
            if (oldConversation !== '') {
                // Carry over the chat history to the new conversation
                const oldMessages = fetchCacheKey<string[]>(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${oldConversation}`);
                if (oldMessages) {
                    setCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${chatId}`, oldMessages, STORAGE_INFO.conversation.cacheMaxTime);
                }
                // Update the old conversation with the new one
                const index = allConvoIds.indexOf(oldConversation);
                allConvos[index] = { user: allConvos[index].user, id: chatId, name };
                setCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key, allConvos, STORAGE_INFO.conversation.cacheMaxTime);
            }
        }
    }

    public getSocket() {
        return this.socket;
    }

    public getWaitingSocket() {
        return this.waitingSocket;
    }

    public addMessage(newMessage: IComment) {
        this.messageHistory.push(newMessage);
        let mostRecentMsgs: IComment[] = [];
        const newMessages = fetchCacheKey<IComment[]>(STORAGE_INFO.newMessages.scope, STORAGE_INFO.newMessages.key);
        if (!newMessages) {
            setCacheKey(STORAGE_INFO.newMessages.scope, STORAGE_INFO.newMessages.key, mostRecentMsgs, STORAGE_INFO.newMessages.cacheMaxTime);
        }
        if (newMessage.creator !== this.user_id) {
            mostRecentMsgs = fetchCacheKey(STORAGE_INFO.newMessages.scope, STORAGE_INFO.newMessages.key);
            mostRecentMsgs.push(newMessage);
            setCacheKey(STORAGE_INFO.newMessages.scope, STORAGE_INFO.newMessages.key, mostRecentMsgs, STORAGE_INFO.newMessages.cacheMaxTime);
        }
        setCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${this.mc_id}`, this.messageHistory, STORAGE_INFO.conversation.cacheMaxTime);
    }

    private onNewMessageHandler(newMessage: IComment, adminSide?: boolean) {
        let messageType;
        let timestampType;
        let exitMessage = false;
        const isCreator = newMessage.creator === getUser().id;
        if (isCreator) {
        // There might be a better way to differentiate announcements and client DM's
            if (newMessage.first === 'Announcement') {
                messageType = 'announcement-box';
                timestampType = 'timestamp-announcement';
            }
            else {
                messageType = 'message-box';
                timestampType = 'timestamp-message';
            }
        }
        else if (newMessage.first === EXIT_MESSAGE) {
            clLogger.debug('EXIT MESSAGE DETECTED: ', newMessage);
            exitMessage = true;
        }
        else {
            // Check if this user and the sender are admins
            if (adminSide && newMessage.first === 'true') {
                // If inheriting another admin's conversation with a user, distinguish their text bubble colors
                messageType = 'response-box-admin';
            }
            else {
                messageType = 'response-box';
            }
            timestampType = 'timestamp-response';
        }

        const messageDiv = '<div class=' + messageType + '>' + newMessage.comment + '</div>';
        let timeDiv;

        if (newMessage.first === 'Announcement') {
            timeDiv = '<div class=' + timestampType + '>' + newMessage.last + ' ' + newMessage.created + '</div>';
        }
        else {
            timeDiv = '<div class=' + timestampType + '>' + (isCreator ? '' : newMessage.last + ' - ') + newMessage.created + '</div>';
        }
        if (this.currentWindow && !exitMessage) {
            this.currentWindow.innerHTML += messageDiv;
            this.currentWindow.innerHTML += timeDiv;
            this.currentWindow.scrollTop = this.currentWindow.scrollHeight;
            return true;
        }
        else {
            return false;
        }
    }

    public loadMessages(isAdmin: boolean) {
        // Populate a message/announcement DM
        this.getMessages();
        this.messageHistory.forEach((message) => {
            this.onNewMessageHandler(message, isAdmin);
        });
        this.currentWindow.scrollTop = this.currentWindow.scrollHeight;
    }

    private eventHandler = (_id: string, op: EventOperation, msg: any) => {
        if (msg.objectId === this.mc_id && op === EventOperation.Updated) {
            if (msg.first !== PING_MESSAGE) {
                if (msg.first !== EXIT_MESSAGE) {
                    this.messageHistory.push(msg);
                    setCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${this.mc_id}`, this.messageHistory, STORAGE_INFO.conversation.cacheMaxTime);
                }
                this.onNewMessageHandler(msg);
            }
            else {
                if (msg.creator !== this.user_id && msg.objectId === this.mc_id && msg.last === this.user_id) {
                    // If user gets a ping message from an admin for their conversation, respond back with a ping
                    const pingMessage = this.createMessage(EventSubject.Message, this.user_id, this.mc_id, '', PING_MESSAGE, this.user_id);
                    this.socket.emit(EventSubject.Message, pingMessage);
                }
            }
        }
    };

    private eventHandlerAdmin = (_id: string, op: EventOperation, msg: any) => {
        if (msg.objectId === this.mc_id && op === EventOperation.Updated) {
            if (msg.first !== PING_MESSAGE && msg.first !== EXIT_MESSAGE) {
                this.messageHistory.push(msg);
                setCacheKey(STORAGE_INFO.dashNotificationCount.scope, `${conversationPrefix}:${this.mc_id}`, this.messageHistory, STORAGE_INFO.dashNotificationCount.cacheMaxTime);
                this.onNewMessageHandler(msg, true);
                // Don't create notifications for other admins' exit messages
                if (msg.creator !== this.user_id) {
                    // Update the notification account for this DM
                    const chatId = msg.objectId;
                    let notificationCount = fetchCacheKey<number>(STORAGE_INFO.dashNotificationCount.scope, `${chatId}${STORAGE_INFO.dashNotificationCount.key}`);
                    const notif = getById(`${chatId}-notif`);
                    notificationCount += 1;
                    notif.innerText = notificationCount.toString();
                    show(notif);
                    setCacheKey(STORAGE_INFO.dashNotificationCount.scope, `${chatId}${STORAGE_INFO.dashNotificationCount.key}`, notificationCount,
                        STORAGE_INFO.dashNotificationCount.cacheMaxTime);
                    // Update the total notification count across all DMs
                    let totalNotifCount = fetchCacheKey<number>(STORAGE_INFO.dashNotificationCount.scope, `${this.user_id}${STORAGE_INFO.dashNotificationCount.key}`);
                    totalNotifCount += 1;
                    setCacheKey(STORAGE_INFO.dashNotificationCount.scope, `${this.user_id}${STORAGE_INFO.dashNotificationCount.key}`, totalNotifCount,
                        STORAGE_INFO.dashNotificationCount.cacheMaxTime);
                    const clientChatPage = getById('clientChatPage');
                    // Update navbar notification display if outside Client Chat Page
                    if (!clientChatPage) {
                        const dmNotifs = getById('adminDmNotifs');
                        dmNotifs.innerText = totalNotifCount.toString();
                        show(dmNotifs);
                    }
                }
            }
            if (msg.creator !== this.user_id && msg.objectId === this.mc_id && msg.creator === msg.last) {
                // If this admin gets a response from the target of the ping, update the chat icon color to represent online status
                const statusIcon = getById(`${this.mc_id}-status`);
                statusIcon.style.color = 'green';
                attr(statusIcon, 'timestamp', new Date().getTime().toString());
            }
        }
    };

    private waitingRoomAdminHandler = (id: string, op: EventOperation, msg: any) => {
        if (op === EventOperation.Updated) {
            if (id === USER_WAITING && msg.comment === 'Ready to join private room.') {
                // Check if the user has a pre-existing conversation and consolidate it
                if (fetchCacheKey(STORAGE_INFO.users.scope, STORAGE_INFO.users.key)) {
                    this.addUserId(msg.creator, msg.objectId, msg.last);
                }
                else {
                    const allUsers: string[] = [];
                    allUsers.push(msg.creator);
                    setCacheKey(STORAGE_INFO.users.scope, STORAGE_INFO.users.key, allUsers, STORAGE_INFO.users.cacheMaxTime);
                }
                if (fetchCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key)) {
                    this.addConversation({ user: msg.creator, id: msg.objectId, name: msg.last });
                }
                else {
                    const allConvos: string[] = [];
                    allConvos.push(msg.objectId);
                    setCacheKey(STORAGE_INFO.conversations.scope, STORAGE_INFO.conversations.key, allConvos, STORAGE_INFO.conversations.cacheMaxTime);
                }
            }
            else if (id === ADMIN_WAITING) {
                // After this admin passes off their conversations
                if (msg.last === ADMIN_LEAVING && msg.targetId !== this.user_id && msg.creator === this.user_id) {
                    this.leaveWaitingRoom(true);
                }
            }
        }
    };

    private waitingRoomHandler = (id: string, op: EventOperation, msg: any) => {
        if (id === USER_WAITING && op === EventOperation.Updated && msg.comment === 'Join your private room.' && msg.objectId === this.mc_id) {
            // If an admin receives your chatId, leave the waiting room
            this.leaveWaitingRoom();
        }
    };

    public async initWaitingRoom(adminWaitingRoom?: boolean) {
        this.waitingSocket = await joinRoom(
            adminWaitingRoom ? thisAdminWaitingNS : thisUserWaitingNS,
            EventSubject.WaitMessage,
            adminWaitingRoom ? ADMIN_WAITING : USER_WAITING,
            getAcl().hasRole(AuthRoles.CLAdmin) ? this.waitingRoomAdminHandler : this.waitingRoomHandler);
    }

    public async leaveWaitingRoom(adminWaitingRoom?: boolean) {
        await leaveRoom(
            adminWaitingRoom ? thisAdminWaitingNS : thisUserWaitingNS,
            EventSubject.WaitMessage,
            adminWaitingRoom ? ADMIN_WAITING : USER_WAITING,
            getAcl().hasRole(AuthRoles.CLAdmin) ? this.waitingRoomAdminHandler : this.waitingRoomHandler);
    }

    // Subscribe to a chatroom and its event listeners
    public async initSubscription(objectId: string) {
        const socket = await joinRoom(thisNS, EventSubject.Message, objectId, getAcl().hasRole(AuthRoles.CLAdmin) ? this.eventHandlerAdmin : this.eventHandler);
        this.socket = socket;
        return socket;
    }

    // End a chatroom subscription and all of its corresponding event listeners
    public async killSubscription(objectId: string) {
        await leaveRoom(thisNS, EventSubject.Message, objectId, getAcl().hasRole(AuthRoles.CLAdmin) ? this.eventHandlerAdmin : this.eventHandler);
    }

    // A blueprint for crafting messages
    public createMessage(msgType: string, userId: string, object: string, msg: string, firstMsg?: string, lastMsg?: string, msgHistoryMsg?: string, target?: string) {
        const date = new Date();
        const message = {
            id: null,
            creator: userId,
            created: prettyMonthDayHourMinutes(date),
            comment: msg,
            objectId: object,
            type: msgType,
            first: firstMsg,
            last: lastMsg,
            msgHistory: msgHistoryMsg,
            targetId: target,
            createdDate: date.toString(),
        };
        return message;
    }

    // Prepare a chatroom/announcement DM
    public async initMessages(id: string, userId: string, chatWindow: HTMLElement, sendBtn: HTMLButtonElement, typespace: HTMLInputElement) {
        this.mc_id = id;
        this.user_id = userId;
        this.currentWindow = chatWindow;
        this.sendBtn = sendBtn;
        this.typespace = typespace;
        const name = getUser().name;
        const isAdmin = (await servicesGetUser(userId)).rolesList.includes(AuthRoles.CLAdmin);
        // If a previous instance of a subscription exists, end it so that corresponding listeners will not duplicate
        await this.killSubscription(id);
        this.socket = await this.initSubscription(id);

        // Wire the socket to respond to pressing 'Enter' and the send button
        addOffEventListener(this.typespace, 'keyup', (event) => {
            if (event instanceof KeyboardEvent && event.key === 'Enter') {
                if (this.typespace.value) {
                    const message = this.createMessage(EventSubject.Message, this.user_id, this.mc_id, this.typespace.value, isAdmin.toString(), name);
                    this.socket.emit(EventSubject.Message, message);
                    this.typespace.value = '';
                }
            }
        });
        addOffEventListener(this.sendBtn, 'click', () => {
            if (this.typespace.value) {
                const message = this.createMessage(EventSubject.Message, this.user_id, this.mc_id, this.typespace.value, isAdmin.toString(), name);
                this.socket.emit(EventSubject.Message, message);
                this.typespace.value = '';
            }
        });

        // When starting a message, bring the chatroom view to the most recent messages
        onClick(this.typespace, () => {
            this.currentWindow.scrollTop = this.currentWindow.scrollHeight;
        });

        // Check & load any existing chat history from this session, in case an admin or user's browser is closed/disconnects
        if (!fetchCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${this.mc_id}`)) {
            setCacheKey(STORAGE_INFO.conversation.scope, `${STORAGE_INFO.conversation.key}${this.mc_id}`, [], STORAGE_INFO.conversation.cacheMaxTime);
        }
        else {
            this.currentWindow.innerHTML = '';
            this.loadMessages(isAdmin);
        }
    }
}
