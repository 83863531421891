function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_classes(s,r){return Array.isArray(s)?pug_classes_array(s,r):s&&"object"==typeof s?pug_classes_object(s):s||""}
function pug_classes_array(r,a){for(var s,e="",u="",c=Array.isArray(a),g=0;g<r.length;g++)(s=pug_classes(r[g]))&&(c&&a[g]&&(s=pug_escape(s)),e=e+u+s,u=" ");return e}
function pug_classes_object(r){var a="",n="";for(var o in r)o&&r[o]&&pug_has_own_property.call(r,o)&&(a=a+n+o,n=" ");return a}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (acl, roles, showAdd, showFilters, showTreeFilters) {
      if (showTreeFilters) {
pug_html = pug_html + "\u003Cspan class=\"selectionContainer\"\u003E\u003C\u002Fspan\u003E";
}
const mlCLass = showFilters ? 'ms-3' : 'ms-1';
pug_html = pug_html + "\u003Cspan\u003E\u003Cbutton" + (pug_attr("class", pug_classes(["btn","btn-secondary","dropdown-toggle",mlCLass], [false,false,false,true]), false, true)+" id=\"btnPickPortfolio\" type=\"button\" title=\"Portfolios\" data-bs-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"") + "\u003E\u003Ci class=\"far fa-star\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"dropdown-menu btnPickPortfolioMenu dropdown-menu-end\" aria-labelledby=\"btnPickPortfolio\" style=\"min-width: 200px\"\u003E\u003Ch6 class=\"dropdown-header\"\u003EMy Portfolios\u003C\u002Fh6\u003E\u003Cdiv class=\"favSymbolFilter\"\u003E\u003C\u002Fdiv\u003E";
if ((acl.hasRole(roles.BookmarksWrite) && showAdd)) {
pug_html = pug_html + "\u003Cbutton class=\"ms-2 btn btn-primary\" id=\"newPortfolioBtn\" role=\"button\"\u003E\u003Ci class=\"fa fa-plus me-1\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003E New Portfolio\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col\"\u003E\u003Cinput class=\"mx-2 d-none\" id=\"newPortfolioInput\" type=\"text\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col pt-2\"\u003E\u003Cbutton class=\"btn mx-2 btn-primary d-none\" id=\"newPortfolioInputBtn\" role=\"button\" style=\"white-space: nowrap;\"\u003E\u003Ci class=\"fa fa-plus\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003E Add\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if ((!acl.hasRole(roles.BookmarksRead))) {
pug_html = pug_html + "\u003Ca class=\"dropdown-item favSymbolCollectionLink\" href=\"#\"\u003EDefault \u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fspan\u003E";
    }.call(this, "acl" in locals_for_with ?
        locals_for_with.acl :
        typeof acl !== 'undefined' ? acl : undefined, "roles" in locals_for_with ?
        locals_for_with.roles :
        typeof roles !== 'undefined' ? roles : undefined, "showAdd" in locals_for_with ?
        locals_for_with.showAdd :
        typeof showAdd !== 'undefined' ? showAdd : undefined, "showFilters" in locals_for_with ?
        locals_for_with.showFilters :
        typeof showFilters !== 'undefined' ? showFilters : undefined, "showTreeFilters" in locals_for_with ?
        locals_for_with.showTreeFilters :
        typeof showTreeFilters !== 'undefined' ? showTreeFilters : undefined));
    ;;return pug_html;};module.exports=template;