function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_classes(s,r){return Array.isArray(s)?pug_classes_array(s,r):s&&"object"==typeof s?pug_classes_object(s):s||""}
function pug_classes_array(r,a){for(var s,e="",u="",c=Array.isArray(a),g=0;g<r.length;g++)(s=pug_classes(r[g]))&&(c&&a[g]&&(s=pug_escape(s)),e=e+u+s,u=" ");return e}
function pug_classes_object(r){var a="",n="";for(var o in r)o&&r[o]&&pug_has_own_property.call(r,o)&&(a=a+n+o,n=" ");return a}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_has_own_property=Object.prototype.hasOwnProperty;
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (Object, allPublishers, currentDirectionFilter, currentOffsetFilter, eclId, groups, isFavEcl, lastLogin, showAddPortfolio, showDirectionFilter, showOffsetFilter) {
      pug_mixins["quoteTemplate"] = pug_interp = function(data, allPublishers){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"row mb-1\"\u003E\u003Cdiv" + (" class=\"col alert articleQuote\""+pug_attr("data-id", data.articleAndLang, true, true)) + "\u003E\u003Cdiv class=\"row\"\u003E";
if (data.id) {
pug_html = pug_html + "\u003Cdiv class=\"col-9 pe-1\"\u003E\u003Cdiv\u003E\u003Csmall class=\"quoteTitle\"\u003E" + (pug_escape(null == (pug_interp = data.prettyFrom) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cspan class=\"quoteText\"\u003E\u003Ca" + (" target=\"_blank\""+pug_attr("href", data.readLink, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = `"${(data.quote || '-')}"`) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
if (eclId) {
if (isFavEcl) {
pug_html = pug_html + "\u003Cspan" + (" class=\"ms-2 fas fa-bookmark eclBtn\""+pug_attr("data-eclid", eclId, true, true)) + "\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan" + (" class=\"ms-2 far fa-bookmark eclBtn\""+pug_attr("data-eclid", eclId, true, true)) + "\u003E\u003C\u002Fspan\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003Cspan" + (" class=\"clearfix float-start sentenceText\""+pug_attr("data-id", data.articlePublisher + '_' + data.articleId, true, true)+pug_attr("data-sentence", data.sentence, true, true)+pug_attr("data-ecl-id", data.id, true, true)+pug_attr("data-origLang", data.origLang, true, true)) + "\u003E\u003C\u002Fspan\u003E";
if (data.direction === 1) {
pug_html = pug_html + "\u003Ci class=\"mx-1 fa fa-arrow-alt-square-up positive\"\u003E\u003C\u002Fi\u003E";
}
else
if (data.direction === -1) {
pug_html = pug_html + "\u003Ci class=\"mx-1 fa fa-arrow-alt-square-down negative\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-3 text-end\" style=\"display: flex; flex-direction: column; overflow-wrap: break-word;\"\u003E\u003Cdiv class=\"quoteDate float-end\"\u003E" + (pug_escape(null == (pug_interp = data.prettyDate || data.prettyPublishDate || 'Time NA') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
if (data.articlePublisher) {
const publisher = allPublishers.find((ap) => ap.publisherName === data.articlePublisher)?.label || 'NA';
pug_html = pug_html + "\u003Cdiv class=\"float-end\"\u003E" + (pug_escape(null == (pug_interp = publisher) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
else
if (data.publishers) {
const publisher = allPublishers.find((ap) => ap.publisherName === data.publishers[0])?.label || 'NA';
pug_html = pug_html + "\u003Cdiv class=\"publishDetailsLink float-end\"\u003E" + (pug_escape(null == (pug_interp = publisher) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
if (data.origLang && data.origLang !== 'en') {
pug_html = pug_html + "\u003Cspan" + (" class=\"ms-2 fa fa-language showTranslationBtn clickable\""+pug_attr("data-id", data.articlePublisher + '_' + data.articleId, true, true)+pug_attr("data-sentence", data.sentence, true, true)+pug_attr("data-ecl-id", data.id, true, true)+pug_attr("data-origLang", data.origLang, true, true)) + "\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};








pug_mixins["quote"] = pug_interp = function(quoteGroup){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (quoteGroup.readLink) {
pug_html = pug_html + "\u003Ch2\u003E\u003Ca" + (pug_attr("href", quoteGroup.readLink, true, true)+" target=\"_blank\"") + "\u003E" + (pug_escape(null == (pug_interp = quoteGroup.title) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fh2\u003E";
}
else
if (quoteGroup.title) {
pug_html = pug_html + "\u003Ch2\u003E\u003Cdiv\u003E" + (pug_escape(null == (pug_interp = quoteGroup.title) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fh2\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
const firstThree = quoteGroup.mentions.slice(0, 3);
const remainder = quoteGroup.mentions.slice(3);
// iterate firstThree
;(function(){
  var $$obj = firstThree;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var m = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = m.quote) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var m = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = m.quote) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

if (remainder.length) {
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = `${remainder.length} more...`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"moreQuote\""+pug_attr("hidden", true, true, true)) + "\u003E";
// iterate remainder
;(function(){
  var $$obj = remainder;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var m = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = `${m.quote}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var m = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv class=\"mb-2\"\u003E" + (pug_escape(null == (pug_interp = `${m.quote}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["quoteDate"] = pug_interp = function(quoteGroup){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"quoteDate float-end\"\u003E" + (pug_escape(null == (pug_interp = quoteGroup.dateStr || 'Time NA') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
if (quoteGroup.publisherLabel) {
pug_html = pug_html + "\u003Cdiv class=\"float-end\"\u003E" + (pug_escape(null == (pug_interp = quoteGroup.publisherLabel) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
if (quoteGroup.languageLabel) {
pug_html = pug_html + "\u003Cdiv\u003E" + (pug_escape(null == (pug_interp = quoteGroup.languageLabel) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
};
pug_mixins["mentionTable"] = pug_interp = function(groups, currentOffsetFilter, currentDirectionFilter, showAddPortfolio, showOffsetFilter, showDirectionFilter){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"flex-nowrap\"\u003E\u003C\u002Fdiv\u003E";
if (showOffsetFilter) {
pug_html = pug_html + "\u003Cselect class=\"form-control form-select selectMentionsFilter float-start me-1\" style=\"width: 20%;\"\u003E\u003Coption" + (" value=\"mentions\""+pug_attr("selected", currentOffsetFilter === 'mentions', true, true)) + "\u003EAny Timeframe\u003C\u002Foption\u003E\u003Coption" + (" value=\"forecast\""+pug_attr("selected", currentOffsetFilter === 'forecast', true, true)) + "\u003EForecasts\u003C\u002Foption\u003E\u003Coption" + (" value=\"history\""+pug_attr("selected", currentOffsetFilter === 'history', true, true)) + "\u003EPast and Present\u003C\u002Foption\u003E\u003C\u002Fselect\u003E";
}
if (showDirectionFilter) {
pug_html = pug_html + "\u003Cselect class=\"form-control form-select selectDirectionFilter float-start\" style=\"width: 20%;\"\u003E\u003Coption" + (" value=\"all\""+pug_attr("selected", currentDirectionFilter === 'all', true, true)) + "\u003EAny Direction\u003C\u002Foption\u003E\u003Coption" + (" value=\"up\""+pug_attr("selected", currentDirectionFilter === 'up', true, true)) + "\u003EIncreasing\u003C\u002Foption\u003E\u003Coption" + (" value=\"down\""+pug_attr("selected", currentDirectionFilter === 'down', true, true)) + "\u003EDecreasing\u003C\u002Foption\u003E\u003C\u002Fselect\u003E";
}
if (!groups || groups.length === 0) {
pug_html = pug_html + "\u003Cdiv\u003ENo mentions for the selected concept or time period.\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"float-end mb-2\"\u003E\u003Cdiv class=\"cl-spinner-export d-inline-block\"\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"btn btn-secondary show-mentions-modal exportXlsx\" role=\"button\" title=\"Export To XLS\" data-bs-toggle=\"tooltip\" data-bs-trigger=\"hover\"\u003E\u003Ci class=\"fas fa-download\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"fileSetMentions\""+" href=\"#\""+pug_attr("hidden", true, true, true)) + "\u003EThis is a hidden link where a blob download URL will be attached.\u003C\u002Fa\u003E\u003Ctable class=\"table table-striped table-hover\"\u003E\u003Cthead\u003E\u003Ctr\u003E\u003Cth class=\"index\" scope=\"col\" style=\"width: 1.5em;\"\u003E#\u003C\u002Fth\u003E\u003Cth scope=\"col\"\u003EGroup\u003C\u002Fth\u003E\u003Cth class=\"text-center\" scope=\"col\" style=\"width: 2.5em;\"\u003EMentions\u003C\u002Fth\u003E\u003C\u002Ftr\u003E\u003C\u002Fthead\u003E\u003Ctbody\u003E";
// iterate groups
;(function(){
  var $$obj = groups;
  if ('number' == typeof $$obj.length) {
      for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
        var group = $$obj[i];
pug_html = pug_html + "\u003Ctr\u003E\u003Cth class=\"index\" scope=\"row\"\u003E" + (pug_escape(null == (pug_interp = i + 1) ? "" : pug_interp)) + "\u003C\u002Fth\u003E\u003Ctd\u003E\u003Cspan class=\"align-middle\"\u003E" + (pug_escape(null == (pug_interp = group.prettyConcept) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (group.mentions.some((m) => m.filterValue)) {
const options = group.mentions.reduce((acc, m) => { acc[m.filterValue] = acc[m.filterValue] || { count: 0, label: m.filterLabel }; acc[m.filterValue].count++; return acc; }, {})
const showFilter = Object.keys(options).length > 1
const filtersToEnableClass = (showFilter) ? 'filtersToEnable' : ''
pug_html = pug_html + "\u003Cselect" + (pug_attr("class", pug_classes(["selectpicker","mx-3","selectMentionsFilterKPI",filtersToEnableClass], [false,false,false,true]), false, true)+" title=\"Filter\""+pug_attr("multiple", true, true, true)+" data-width=\"150px\"") + "\u003E";
// iterate Object.keys(options).sort((a,b) => options[b].count - options[a].count)
;(function(){
  var $$obj = Object.keys(options).sort((a,b) => options[b].count - options[a].count);
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var opt = $$obj[pug_index3];
pug_html = pug_html + "\u003Coption" + (pug_attr("value", opt, true, true)+pug_attr("data-subtext", options[opt].count, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = options[opt].label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var opt = $$obj[pug_index3];
pug_html = pug_html + "\u003Coption" + (pug_attr("value", opt, true, true)+pug_attr("data-subtext", options[opt].count, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = options[opt].label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
if (showAddPortfolio) {
pug_html = pug_html + "\u003Cdiv class=\"dropend d-inline\"\u003E";
const concept = group.conceptKey.split('-')[0]
pug_html = pug_html + "\u003Ci" + (" class=\"mx-3 btn-link-custom fa fa-chevron-right hidden-btn addToPortfolioBtn\""+" data-bs-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\""+pug_attr("data-concept", concept, true, true)+" title=\"Add to Portfolio\"") + "\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"dropdown-menu\"\u003E\u003Ch6 class=\"dropdown-header\"\u003EAdd to Portfolio\u003C\u002Fh6\u003E\u003Cdiv class=\"addToPortfolioItems\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd class=\"text-center\"\u003E" + (pug_escape(null == (pug_interp = group.mentions.length) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003Ctr class=\"collapse-row\"\u003E\u003Ctd colspan=\"3\"\u003E";
if (group.mentions.length) {
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
pug_mixins["quoteTemplate"](group.mentions[0], allPublishers);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (group.mentions.length > 1) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"load-more-btn btn btn-link\""+pug_attr("data-group", group.conceptKey, true, true)) + "\u003ELoad More ...\u003C\u002Fbutton\u003E";
}
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;
      var group = $$obj[i];
pug_html = pug_html + "\u003Ctr\u003E\u003Cth class=\"index\" scope=\"row\"\u003E" + (pug_escape(null == (pug_interp = i + 1) ? "" : pug_interp)) + "\u003C\u002Fth\u003E\u003Ctd\u003E\u003Cspan class=\"align-middle\"\u003E" + (pug_escape(null == (pug_interp = group.prettyConcept) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (group.mentions.some((m) => m.filterValue)) {
const options = group.mentions.reduce((acc, m) => { acc[m.filterValue] = acc[m.filterValue] || { count: 0, label: m.filterLabel }; acc[m.filterValue].count++; return acc; }, {})
const showFilter = Object.keys(options).length > 1
const filtersToEnableClass = (showFilter) ? 'filtersToEnable' : ''
pug_html = pug_html + "\u003Cselect" + (pug_attr("class", pug_classes(["selectpicker","mx-3","selectMentionsFilterKPI",filtersToEnableClass], [false,false,false,true]), false, true)+" title=\"Filter\""+pug_attr("multiple", true, true, true)+" data-width=\"150px\"") + "\u003E";
// iterate Object.keys(options).sort((a,b) => options[b].count - options[a].count)
;(function(){
  var $$obj = Object.keys(options).sort((a,b) => options[b].count - options[a].count);
  if ('number' == typeof $$obj.length) {
      for (var pug_index4 = 0, $$l = $$obj.length; pug_index4 < $$l; pug_index4++) {
        var opt = $$obj[pug_index4];
pug_html = pug_html + "\u003Coption" + (pug_attr("value", opt, true, true)+pug_attr("data-subtext", options[opt].count, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = options[opt].label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index4 in $$obj) {
      $$l++;
      var opt = $$obj[pug_index4];
pug_html = pug_html + "\u003Coption" + (pug_attr("value", opt, true, true)+pug_attr("data-subtext", options[opt].count, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = options[opt].label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
if (showAddPortfolio) {
pug_html = pug_html + "\u003Cdiv class=\"dropend d-inline\"\u003E";
const concept = group.conceptKey.split('-')[0]
pug_html = pug_html + "\u003Ci" + (" class=\"mx-3 btn-link-custom fa fa-chevron-right hidden-btn addToPortfolioBtn\""+" data-bs-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\""+pug_attr("data-concept", concept, true, true)+" title=\"Add to Portfolio\"") + "\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"dropdown-menu\"\u003E\u003Ch6 class=\"dropdown-header\"\u003EAdd to Portfolio\u003C\u002Fh6\u003E\u003Cdiv class=\"addToPortfolioItems\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd class=\"text-center\"\u003E" + (pug_escape(null == (pug_interp = group.mentions.length) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003Ctr class=\"collapse-row\"\u003E\u003Ctd colspan=\"3\"\u003E";
if (group.mentions.length) {
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
pug_mixins["quoteTemplate"](group.mentions[0], allPublishers);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (group.mentions.length > 1) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"load-more-btn btn btn-link\""+pug_attr("data-group", group.conceptKey, true, true)) + "\u003ELoad More ...\u003C\u002Fbutton\u003E";
}
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E";
}
};
pug_mixins["mentionTable"](groups, currentOffsetFilter, currentDirectionFilter, showAddPortfolio, showOffsetFilter, showDirectionFilter, lastLogin);
    }.call(this, "Object" in locals_for_with ?
        locals_for_with.Object :
        typeof Object !== 'undefined' ? Object : undefined, "allPublishers" in locals_for_with ?
        locals_for_with.allPublishers :
        typeof allPublishers !== 'undefined' ? allPublishers : undefined, "currentDirectionFilter" in locals_for_with ?
        locals_for_with.currentDirectionFilter :
        typeof currentDirectionFilter !== 'undefined' ? currentDirectionFilter : undefined, "currentOffsetFilter" in locals_for_with ?
        locals_for_with.currentOffsetFilter :
        typeof currentOffsetFilter !== 'undefined' ? currentOffsetFilter : undefined, "eclId" in locals_for_with ?
        locals_for_with.eclId :
        typeof eclId !== 'undefined' ? eclId : undefined, "groups" in locals_for_with ?
        locals_for_with.groups :
        typeof groups !== 'undefined' ? groups : undefined, "isFavEcl" in locals_for_with ?
        locals_for_with.isFavEcl :
        typeof isFavEcl !== 'undefined' ? isFavEcl : undefined, "lastLogin" in locals_for_with ?
        locals_for_with.lastLogin :
        typeof lastLogin !== 'undefined' ? lastLogin : undefined, "showAddPortfolio" in locals_for_with ?
        locals_for_with.showAddPortfolio :
        typeof showAddPortfolio !== 'undefined' ? showAddPortfolio : undefined, "showDirectionFilter" in locals_for_with ?
        locals_for_with.showDirectionFilter :
        typeof showDirectionFilter !== 'undefined' ? showDirectionFilter : undefined, "showOffsetFilter" in locals_for_with ?
        locals_for_with.showOffsetFilter :
        typeof showOffsetFilter !== 'undefined' ? showOffsetFilter : undefined));
    ;;return pug_html;};module.exports=template;