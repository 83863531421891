import { IArticleData, IArticleLang, IArticlesSearch, ISentenceQuote, TSearchLangs } from '../../../shared/interfaces/article/article';
import { IUserBookmarkCollectionAuthor } from '../../../shared/interfaces/bookmark/bookmark-i';
import { IParsedConcept } from '../../../shared/interfaces/dashboard';
import { IMeta } from '../../../shared/interfaces/metadata/meta-i';
import { IMetaStatsHistoryLog } from '../../../shared/interfaces/metadata/metaStats-i';
import { ajaxDelete, ajaxGet, ajaxPost, binaryPost } from '../ajax';
import { readThroughCacheIDBDatabase } from '../util/storageUtil';

export async function articlesGetSearchLangs() {
    const url = '/articles/searchLangs';
    return await readThroughCacheIDBDatabase(url, async () => {
        return await ajaxGet<TSearchLangs>(url);
    });
}

export async function articlesGetSearchMip() {
    const url = '/articles/searchMip';
    return await readThroughCacheIDBDatabase(url, async () => {
        return await ajaxGet<{ id: string, title: string, report: { link: string, date: string }, map: { link: string, date: string }[]}[]>(url);
    });
}

export function articlesGetArticle(publisher: string, id: string) {
    return ajaxGet<IArticleData>('/articles/article/:publisher/:id', { publisher, id });
}

export function articlesGetMetaHistory(publisher: string, id: string) {
    return ajaxGet<IMetaStatsHistoryLog[]>('/articles/meta/History/:publisher/:id', { publisher, id });
}

export function articlesGetMetaParams(publisher: string, id: string) {
    return ajaxGet('/articles/metaParams/:publisher/:id', { id, publisher, revs: true });
}

export function articlesGetCombined(publisher: string, id: string) {
    return ajaxGet<IArticleData>(`/articles/combined/:publisher/:id`, { id, publisher });
}

export function articlesGetParseAttempt(publisher: string, id: string) {
    return ajaxGet('/articles/parseAttempt/:publisher/:id', { id, publisher });
}

export async function articlesGetAuthors(authorLabels: string[]) {
    const url = '/articles/authors';
    const key = url + authorLabels?.sort();
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxGet<IUserBookmarkCollectionAuthor[]>(url, { authorLabels });
    });
}

export function articlesPostSearch(search: {query?: string, endDate?: string, startDate?: string, from?: number, size?: number,
    publishers?: string, symbols?: string, author?: string, languages?: string}) {
    return ajaxPost<IArticlesSearch>('/articles/search', search);
}

export function articlesPostMetaStats(ids: string[] | string) {
    return ajaxPost('/articles/meta/stats', { ids });
}

export async function articlesPostSentences(keys: IArticleLang[]) {
    const url = '/articles/sentences';
    const key = url + keys?.map((k) => k.lang + k.articleSentenceId).sort();
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxPost<ISentenceQuote[]>(url, { keys });
    });
}

export function articlesPostMeta(params: {articleId: string, publisher: string, context: string, type: string, subType: string, paragraph?: string,
    sentence?: string, begin: number, end: number, name: string, referenceId?: string, meta?: IMeta, response?: boolean, sentenceIdx?:string}) {
    return ajaxPost('/articles/meta', params);
}

export async function articlesPostQuotes(keys: string[]) {
    const url = '/articles/quotes';
    const key = url + keys?.sort();
    return await readThroughCacheIDBDatabase(key, async () => {
        return await ajaxPost<(ISentenceQuote & { phrase: string })[]>(url, { keys });
    });
}

export function articlesDeleteMeta(id: string, publisher: string, creator: string, metaId: string) {
    return ajaxDelete('/articles/meta/:publisher/:id/:creator/:metaId', { id, publisher, creator, metaId });
}

export function articlesDeleteMetaAll(id: string, publisher: string, creator?: string) {
    return ajaxDelete('/articles/meta/all/:publisher/:id', { id, publisher, creator });
}

export function articlesDeleteArticle(id: string, publisher: string) {
    return ajaxDelete('/articles/:publisher/:id', { id, publisher });
}

export function articlesBinaryPostMentionsExport(params:
    {format: 'xls', concept: string, data: IParsedConcept[], headers: string[][], dateFormat: { userFormatting: string, userTimeZone: string }}) {
    return binaryPost('/articles/mentionsExport', false, params);
}
